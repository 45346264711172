import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const KYC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <main>
        <div className="py-12 md:py-16">
          <div className="mx-auto max-w-[1280px] px-6 w-full">
            <div className="py-10">
              <div className="flex items-center gap-y-10 gap-x-6 flex-wrap">
                <p className="border-b-[2px] border-primary text-2xl font-bold">{t("l_t4")}</p>
                <Link
                  to="/privacy-policy"
                  className="text-[15px] font-bold text-primary"
                >
                  {t("l_t2")}
                </Link>
                <Link
                  to="/terms-of-use"
                  className="text-[15px] font-bold text-primary"
                >
                  {t("l_t1")}
                </Link>
                <Link
                  to="/cookie-policy"
                  className="text-[15px] font-bold text-primary"
                >
                  {t("l_t3")}
                </Link>
              </div>
              <div className="text-base font-semibold">
                <br />
                <br />
                <p>{t("kyc_t1")}</p>
                <br />
                <br />
                <p>{t("kyc_t2")}</p>
                <p>{t("kyc_t3")}</p>
                <br />
                <p>{t("kyc_t4")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("kyc_t5")}</li>
                  <li>{t("kyc_t6")}</li>
                  <li>{t("kyc_t7")}</li>
                  <li>{t("kyc_t8")}</li>
                </ul>
                <br />
                <p>{t("kyc_t9")}</p>
                <p>{t("kyc_t10")}</p>
                <p>{t("kyc_t11")}</p>
                <p>{t("kyc_t12")}</p>
                <p>{t("kyc_t13")}</p>
                <p>{t("kyc_t14")}</p>
                <p>{t("kyc_t15")}</p>
                <p>{t("kyc_t16")}</p>
                <br />
                <p>{t("kyc_t17")}</p>
                <p>{t("kyc_t18")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("kyc_t19")}</li>
                  <li>{t("kyc_t20")}</li>
                  <li>{t("kyc_t21")}</li>
                  <li>{t("kyc_t22")}</li>
                  <li>{t("kyc_t23")}</li>
                  <li>{t("kyc_t24")}</li>
                </ul>
                <p>{t("kyc_t25")}</p>

                <p>{t("kyc_t26")}</p>
                <p>{t("kyc_t27")}</p>
                <p>{t("kyc_t28")}</p>
                <p>{t("kyc_t29")}</p>
                <p>{t("kyc_t30")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("kyc_t31")}</li>
                  <li>{t("kyc_t32")}</li>
                  <li>{t("kyc_t33")}</li>
                </ul>
                <p>{t("kyc_t34")}</p>
                <br />
                <p>{t("kyc_t35")}</p>
                <p>{t("kyc_t36")}</p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default KYC;
