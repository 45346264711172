import "react-phone-input-2/lib/style.css";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Auth from "../../layout/Auth";
import Button from "../../common/Button";
import Input from "../../common/Input";
import Api from "../../api/api";
import toast from "react-hot-toast";

const initialData = {
  password: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const history = useHistory();
  const { token } = useParams();
  const [isDisable, setIsDisable] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ ...initialData });

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const submitHandle = async e => {
    e.preventDefault();

    if (data.password !== data.confirmPassword) {
      return toast.error("Password doesn't match");
    }

    setIsDisable(true);
    try {
      const res = await Api.resetPassword({ token, ...data });
      if (res.status === 200) {
        history.replace("/auth/signin");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);

  return (
    <Auth
      title="Reset Password"
      submitHandle={submitHandle}
    >
      <Input
        title="password"
        name="password"
        value={data.password}
        onChange={handleChange}
        type={show ? "text" : "password"}
        placeholder="Password"
      >
        <span
          className="absolute cursor-pointer w-fit right-4 top-[40px]"
          onClick={() => setShow(prev => !prev)}
        >
          {!show ? (
            <BsEyeFill
              fill="var(--primary)"
              size={22}
            />
          ) : (
            <BsFillEyeSlashFill
              fill="var(--primary)"
              size={22}
            />
          )}
        </span>
      </Input>

      <Input
        title="Confirm Password"
        name="confirmPassword"
        value={data.confirmPassword}
        onChange={handleChange}
        type={show ? "text" : "password"}
        placeholder="Confirm Password"
      >
        <span
          className="absolute cursor-pointer w-fit right-4 top-[40px]"
          onClick={() => setShow(prev => !prev)}
        >
          {!show ? (
            <BsEyeFill
              fill="var(--primary)"
              size={22}
            />
          ) : (
            <BsFillEyeSlashFill
              fill="var(--primary)"
              size={22}
            />
          )}
        </span>
      </Input>

      <br />

      <Button
        className="w-full"
        type="submit"
        disabled={isDisable}
      >
        Reset
      </Button>

      <p className="mt-5 text-center opacity-80 font-medium">
        Doesn't have Account?{" "}
        <Link
          to="/auth/signup"
          className="hover:text-primary"
        >
          Create Account
        </Link>
      </p>
    </Auth>
  );
};

export default ResetPassword;
