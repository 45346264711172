import { useState } from "react";
import DashboardLayout from "../../layout/Layout";

const Broadcast = () => {
  const [activeTab, setActiveTab] = useState("updates");
  return (
    <DashboardLayout title="Broadcast">
      <div className="flex items-center justify-center bg-gray rounded-md p-1 font-semibold text-sm w-fit mx-auto">
        <div
          onClick={() => setActiveTab("updates")}
          className={`rounded-md py-2 px-4 transition-all cursor-pointer ${activeTab === "updates" ? "bg-white  text-primary" : ""}`}
        >
          Latest Updates
        </div>
        <div
          onClick={() => setActiveTab("promotions")}
          className={`rounded-md py-2 px-4 transition-all cursor-pointer ${activeTab === "promotions" ? "bg-white text-primary" : ""}`}
        >
          Promotions
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Broadcast;
