import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const OverviewGraphs = () => {
  const [chartData, setChartData] = useState({
    series: [
      {
        data: [31, 40, 28, 51, 42, 109, 100, 23, 100, 24, 23, 22],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        selection: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "category",
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      tooltip: {
        x: {
          format: "MMM",
        },
      },
      grid: {
        show: false,
      },
    },
  });

  return (
    <div className="mt-4 w-full">
      <div className="w-full  bg-white border border-[#EFF0F6] p-4 rounded-[20px]">
        <div className="flex justify-between items-center pb-2">
          <span className="text-[14px] font-500 text-[#4D4D4D]">Account Balance</span>
          <select className="border-0 text-[12px] font-[600]">
            <option>Monthly</option>
          </select>
        </div>
        <hr style={{ border: "0.8px solid #E4E5E7" }} />
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="area"
            height={250}
          />
        </div>
      </div>
    </div>
  );
};

export default OverviewGraphs;
