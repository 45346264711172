import React, { useEffect, useState } from "react";
import { GoArrowUpRight } from "react-icons/go";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";

const series = [
  { x: "Jan", y: 0 },
  { x: "Feb", y: 0 },
  { x: "Mar", y: 0 },
  { x: "Apr", y: 0 },
];

const options = {
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  series: [
    {
      color: "#20E19F",
      data: series,
    },
  ],
  xaxis: {
    categories: series.map(item => item.x),
    labels: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  stroke: {
    curve: "smooth",
    width: 1,
    colors: ["#20E19F"],
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  tooltip: {
    enabled: false,
  },
};

const MarketTrend = ({ API_URL }) => {
  const { t } = useTranslation();
  const [data, setData] = useState([
    {
      coin: `${process.env.REACT_APP_API_URL}/upload/passve.svg`,
      coinAbbr: "SVE",
      coinName: "PASSVE",
      price: "$0.00998",
      percentage: "0.03%",
    },
    {
      coin: "https://assets.coincap.io/assets/icons/btc@2x.png",
      coinAbbr: "BTC",
      coinName: "BITCOIN",
      price: "$0",
      percentage: "1.41%",
    },
    {
      coin: "https://assets.coincap.io/assets/icons/eth@2x.png",
      coinAbbr: "ETH",
      coinName: "ETHEREUM",
      price: "$0",
      percentage: "2.22%",
    },
    {
      coin: "https://assets.coincap.io/assets/icons/bnb@2x.png",
      coinAbbr: "BNB",
      coinName: "BINANCE",
      price: "$0",
      percentage: "0.82%",
    },
  ]);

  const fetchPricesSequentially = async () => {
    for (let i = 0; i < data.length; i++) {
      const coinData = data[i];
      if (coinData.coinAbbr === "SVE") continue;

      try {
        const res = await axios.get(`${API_URL}?fsym=${coinData.coinAbbr}&tsyms=USD`);
        if (res.status === 200 && res.data?.USD) {
          const price = res.data.USD;
          setData(prevData =>
            prevData.map(item =>
              item.coinAbbr === coinData.coinAbbr ? { ...item, price: `$${price}` } : item,
            ),
          );
        }
      } catch (error) {
        console.error(`Error fetching price for ${coinData.coinAbbr}:`, error);
      }
    }
  };

  useEffect(() => {
    fetchPricesSequentially();
    const interval = setInterval(() => {
      fetchPricesSequentially();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="py-12 md:py-16 border-b border-b-[#f3f3f3]">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <p className="text-2xl font-semibold mb-3">{t("market_trend")}</p>
        <div className="flex flex-wrap gap-4">
          {data?.map((itm, idx) => (
            <div
              key={idx}
              className="max-md:w-full md:flex-1 rounded-xl border border-[#ddd] p-4"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <img
                    src={itm.coin}
                    alt={itm.coinName}
                    className="size-[40px] rounded-full overflow-hidden"
                  />
                  <span className="font-semibold">{itm.coinAbbr}</span>
                  <p className="text-[10px] bg-[#c6c6c6] rounded font-semibold px-2 py-1">
                    {itm.coinName}
                  </p>
                </div>
                <Link
                  to="/autonomous-funding"
                  className="bg-gray rounded-full p-1"
                >
                  <GoArrowUpRight size={22} />
                </Link>
              </div>
              <div className="w-full h-[2px] bg-gray my-4"></div>
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-semibold text-xl mb-1">{itm.price}</p>
                  {/* <p className="font-medium opacity-80">{itm.percentage}</p> */}
                  <p className="font-medium opacity-80">---</p>
                </div>
                <ReactApexChart
                  options={options}
                  series={options.series}
                  type="area"
                  width={120}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MarketTrend;
