import Header from "../common/Header";
import Footer from "../common/Footer";
import Hero from "../components/home/Hero";
import Stats from "../components/home/Stats";
import EcoSystem from "../components/home/Ecosystem";
import Crypto from "../components/home/Crypto";
import Featured from "../components/home/Featured";
import Newsletter from "../components/home/Newsletter";

const Home = () => {
  return (
    <>
      <Header />
      <main>
        <Hero />
        <Stats />
        <EcoSystem />
        <Featured />
        <Newsletter />
        <Crypto />
      </main>
      <Footer />
    </>
  );
};

export default Home;
