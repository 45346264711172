import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Button from "../../common/Button";
import Input from "../../common/Input";
import { useAuth } from "../../context/auth";
import Api from "../../api/api";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const Ticket = () => {
  const { user } = useAuth();
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({ email: "", subject: "", message: "" });
  let history = useHistory();

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const submitHandle = async e => {
    e.preventDefault();
    setIsDisable(true);

    try {
      const res = await Api.submitTicket({ ...data, ticketId: uuidv4() });
      if (res.status === 200) {
        history.push("/");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    if (user) setData(prev => ({ ...prev, email: user?.email }));
  }, [user]);

  return (
    <>
      <Header />
      <main>
        <br />
        <h2 className="text-center text-2xl lg:text-3xl font-bold">Submit a ticket</h2>
        <p className="text-center text-sm opacity-80 mt-2">
          Get in touch with our friendly support team
        </p>
        <form
          onSubmit={submitHandle}
          className="w-full max-w-[450px] 2xl:max-w-[500px] bg-white p-6 pt-3 rounded-xl mx-auto"
        >
          <Input
            title="email"
            type="email"
            name="email"
            value={data.email}
            onChange={handleChange}
            disabled
            required
          />
          <Input
            title="subject"
            name="subject"
            value={data.subject}
            onChange={handleChange}
            disabled={isDisable}
            required
          />
          <div className="relative mt-4">
            <label className="capitalize font-semibold mb-1 block text-sm">Message</label>
            <textarea
              name="message"
              value={data.message}
              onChange={handleChange}
              disabled={isDisable}
              required
              className="w-full bg-[#f9fafb] border border-[#ddd] min-h-28 rounded-md px-4 py-2 text-sm disabled:opacity-70"
            ></textarea>
          </div>

          <br />
          <Button
            className="w-full"
            type="submit"
            disabled={isDisable}
          >
            Submit
          </Button>
        </form>
      </main>
      <Footer />
    </>
  );
};

export default Ticket;
