import React from "react";
import { useTranslation } from "react-i18next";

const PayoutSystem = () => {
  const { t } = useTranslation();

  return (
    <div className="py-12 md:py-16">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <h2 className="text-center text-2xl md:text-4xl font-semibold mb-5">
          {t("traders_by_traders")}
        </h2>
        <p className="text-center font-medium mb-8 mx-auto max-w-[600px]">
          {t("traders_by_traders_desc")}
        </p>
        <div className="flex flex-wrap gap-8 md:gap-16 w-full">
          <div className="md:flex-1">
            <h2 className="text-2xl md:text-4xl font-bold mb-6">{t("instant_payout")}</h2>
            <p
              className="mb-6 font-medium text-sm"
              style={{ letterSpacing: 1 }}
            >
              {t("instant_payout_desc")}
            </p>
          </div>
          <div className="md:flex-1 text-center">
            <img
              className="w-full"
              src={`${process.env.REACT_APP_API_URL}/upload/traders.svg`}
              alt="traders"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoutSystem;
