import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import Button from "../../common/Button";
import profitImg from "../../assets/funding/profit.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FullProfit = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 md:py-16 ">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <h2 className="text-primary2 font-semibold text-center text-2xl md:text-4xl mb-2">
          {t("trade_your_skill")}
        </h2>
        <h2 className="text-primary2 font-semibold text-center text-2xl md:text-4xl mb-14">
          {t("amplify_your_results")}
        </h2>
        <div className="flex flex-wrap gap-6 w-full">
          <div className="md:flex-1 text-center">
            <img
              className="w-full"
              src={profitImg}
              alt=""
            />
          </div>
          <div className="md:flex-1">
            <p className="text-sm uppercase text-primary font-semibold mb-2">
              {t("f_transforming_success")}
            </p>
            <h2 className="text-2xl md:text-4xl font-bold mb-6">{t("full_profit_allocation")}</h2>
            <p className="mb-6 font-medium">{t("f_profit_desc")}</p>
            <div className="flex items-center justify-center gap-12 flex-wrap mb-6">
              <div className="flex items-center justify-center gap-2">
                <FaRegCheckCircle
                  size={22}
                  fill="var(--primary)"
                />
                <span className="text-primary font-semibold text-lg">{t("high_ratings")}</span>
              </div>
              <div className="flex items-center justify-center gap-2">
                <FaRegCheckCircle
                  size={22}
                  fill="var(--primary)"
                />
                <span className="text-primary font-semibold text-lg">{t("certified_firm")}</span>
              </div>
            </div>
            <Link to="/checkout">
              <Button>{t("learn_more")}</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullProfit;
