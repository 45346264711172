import Home from "../pages/home";
import APY from "../pages/apy";
import Market from "../pages/market";
import Portal from "../pages/portal";
import Privacy from "../pages/privacy";
import Staking from "../pages/staking";
import Terms from "../pages/terms";
import Checkout from "../pages/checkout";
import Cookies from "../pages/cookies";
import FAQ2 from "../pages/client/faq2";
import FAQs from "../pages/faqs";
import Funding from "../pages/funding";
import KYC from "../pages/kyc";
import Broadcast from "../pages/client/broadcast";
import Dashboard from "../pages/client/dashboard";
import Downloads from "../pages/client/download";
import Leaderboard from "../pages/client/leaderboard";
import OrderHistory from "../pages/client/orderHistory";
import Overview from "../pages/client/overview";
import Payout from "../pages/client/payout";
import Plans from "../pages/client/plans";
import Settings from "../pages/client/settings";
import Signup from "../pages/auth/signup";
import Login from "../pages/auth/login";
import ForgotPassword from "../pages/auth/forgot";
import ResetPassword from "../pages/auth/reset";
import VerifyAccount from "../pages/auth/verify";
import AdminDashboard from "../pages/admin/dashboard";
import AdminUsers from "../pages/admin/users";
import AdminOrders from "../pages/admin/orders";
import AdminUserAdd from "../pages/admin/users/add";
import Profile from "../pages/client/profile";
import Password from "../pages/client/password";
import AdminOrderAdd from "../pages/admin/orders/add";
import AdminAddons from "../pages/admin/addons";
import AdminPlatform from "../pages/admin/platforms";
import Ticket from "../pages/client/ticket";
import Tickets from "../pages/admin/tickets";
import TicketsListing from "../pages/client/ticketsListing";
import SuccessPayment from "../pages/successPayment";
import PayoutRequest from "../pages/client/payoutRequest";
import AdminPayout from "../pages/admin/payouts";

export const ROUTES = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/portal",
    component: Portal,
  },
  {
    path: "/market",
    component: Market,
  },
  {
    path: "/autonomous-funding",
    component: Funding,
  },
  {
    path: "/faqs",
    component: FAQs,
  },
  {
    path: "/apy",
    component: APY,
  },
  {
    path: "/staking",
    component: Staking,
  },
  {
    path: "/terms-of-use",
    component: Terms,
  },
  {
    path: "/privacy-policy",
    component: Privacy,
  },
  {
    path: "/cookie-policy",
    component: Cookies,
  },
  {
    path: "/kyc",
    component: KYC,
  },
];

export const AUTH_ROUTES = [
  {
    path: "/auth/signin",
    component: Login,
  },
  {
    path: "/auth/signup",
    component: Signup,
  },
  {
    path: "/auth/forgot",
    component: ForgotPassword,
  },
  {
    path: "/auth/reset/:token",
    component: ResetPassword,
  },
  {
    path: "/auth/verify/:token",
    component: VerifyAccount,
  },
];

export const CLIENT_ROUTES = [
  {
    path: "/dashboard",
    // component: Dashboard,
    component: Plans,
  },
  {
    path: "/dashboard/payout",
    component: Payout,
  },
  {
    path: "/dashboard/payout/request",
    component: PayoutRequest,
  },
  {
    path: "/dashboard/order-history",
    component: OrderHistory,
  },
  {
    path: "/dashboard/leaderboard",
    component: Leaderboard,
  },
  {
    path: "/dashboard/broadcast",
    component: Broadcast,
  },
  {
    path: "/dashboard/settings",
    component: Settings,
  },
  {
    path: "/dashboard/faqs",
    component: FAQ2,
  },
  {
    path: "/dashboard/downloads",
    component: Downloads,
  },
  {
    path: "/dashboard/new-plan",
    component: Plans,
  },
  {
    path: "/dashboard/account-overview",
    component: Overview,
  },
  {
    path: "/dashboard/profile",
    component: Profile,
  },
  {
    path: "/dashboard/password",
    component: Password,
  },
  {
    path: "/dashboard/tickets",
    component: TicketsListing,
  },
  {
    path: "/checkout",
    component: Checkout,
  },
  {
    path: "/payment/success",
    component: SuccessPayment,
  },
  {
    path: "/submit-ticket",
    component: Ticket,
  },
];

export const ADMIN_ROUTES = [
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
  },
  {
    path: "/admin/dashboard/users",
    component: AdminUsers,
  },
  {
    path: "/admin/dashboard/user/:id",
    component: AdminUserAdd,
  },
  {
    path: "/admin/dashboard/orders",
    component: AdminOrders,
  },
  {
    path: "/admin/dashboard/order/:id",
    component: AdminOrderAdd,
  },
  {
    path: "/admin/dashboard/platforms",
    component: AdminPlatform,
  },
  {
    path: "/admin/dashboard/addons",
    component: AdminAddons,
  },
  {
    path: "/admin/dashboard/payouts",
    component: AdminPayout,
  },
  {
    path: "/admin/dashboard/profile",
    component: Profile,
  },
  {
    path: "/admin/dashboard/password",
    component: Password,
  },
  {
    path: "/admin/dashboard/tickets",
    component: Tickets,
  },
];
