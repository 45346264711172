import React from "react";
import { LOADER_OVERRIDE } from "../utils/constants";
import { FadeLoader } from "react-spinners";

const Loader = ({ color = "var(--primary)" }) => {
  return (
    <FadeLoader
      color={color}
      loading={true}
      cssOverride={{ ...LOADER_OVERRIDE }}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default Loader;
