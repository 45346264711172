import React, { useCallback, useEffect, useMemo, useState } from "react";
import DashboardLayout from "../../../layout/Layout";
import Input from "../../../common/Input";
import { useHistory, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Api from "../../../api/api";
import Select from "../../../common/Select";
import { countries } from "../../../utils/countries";
// import PhoneInput from "react-phone-input-2";
import Button from "../../../common/Button";
import { program } from "../../../utils/constants";
import { acceleratedHead, evaluationHead } from "../../../utils/plans";

const initialData = {
  program: "",
  platform: "",
  capital: "",
  price: "",
  orderExtra: [],
  billingInfo: {
    fname: "",
    lname: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    phone: "",
  },
};

const AdminOrderAdd = () => {
  const { id } = useParams();
  const isNew = useMemo(() => id === "_new", [id]);

  const history = useHistory();
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({ ...initialData });
  const [platform, setPlatform] = useState([]);
  const [orderExtras, setOrderExtras] = useState([]);

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleChangeBilling = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, billingInfo: { ...prev.billingInfo, [name]: value } }));
  };

  const handleOrderExtraChange = (e, itm) => {
    if (e.target.checked) {
      setData(prev => ({
        ...prev,
        orderExtra: [...prev.orderExtra, itm],
      }));
    } else {
      setData(prev => ({
        ...prev,
        orderExtra: prev.orderExtra.filter(item => item._id !== itm._id),
      }));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!data.orderExtra.length === 0) {
      return toast.error("Select addon(s)");
    }

    setIsDisable(true);
    try {
      let newData = {
        program: data.program,
        capital: data.capital,
        price: data.price,
        platform: data.platform,
        billingInfo: {
          fname: data.billingInfo.fname,
          lname: data.billingInfo.lname,
          email: data.billingInfo.email,
          country: data.billingInfo.country,
          city: data.billingInfo.city,
          state: data.billingInfo.state,
          address: data.billingInfo.address,
          zip: data.billingInfo.zip,
          phone: data.billingInfo.phone,
        },
        orderExtra: data.orderExtra?.map(itm => itm._id),
      };
      const res = isNew ? await Api.signup({ ...data }) : await Api.updateOrder(id, { ...newData });
      if (res.status === 200) {
        history.replace("/admin/dashboard/orders");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  const getOrderExtras = useCallback(async () => {
    try {
      let res = await Api.getOrderExtra(false);
      if (res?.status === 200) {
        setOrderExtras(res.data.data?.map(itm => ({ _id: itm?._id, name: itm?.name })) || []);
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [setOrderExtras]);

  const getPlatforms = useCallback(async () => {
    try {
      let res = await Api.getPlatforms(false);
      if (res?.status === 200) {
        let platformData = [];
        res?.data?.data?.map(itm => platformData.push(itm.name));
        // setPlatform(platformData)
        setPlatform(res.data.data);
        // setPlatformArray(res.data.data)
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [setPlatform]);

  const getOrder = useCallback(async () => {
    const res = await Api.getOrderById(id);
    if (res.status === 200) {
      setData(res.data.data);
    }
  }, [setData]);

  useEffect(() => {
    getPlatforms();
    getOrderExtras();

    if (id && !isNew) {
      getOrder();
    }
  }, [isNew, id]);

  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);

  return (
    <DashboardLayout title={`${isNew ? "Add" : "Edit"} Order`}>
      <form onSubmit={handleSubmit}>
        <div className="grid gap-x-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <Select
            title="Program"
            name="program"
            value={data.program}
            onChange={handleChange}
            required
            options={program.map(itm => ({ value: itm, label: itm }))}
          />
          <Select
            title="Platform"
            name="platform"
            value={data.platform?._id}
            onChange={handleChange}
            required
            options={platform.map(itm => ({ value: itm?._id, label: itm?.name }))}
          />
          <Select
            title="Capital Size"
            name="capital"
            value={data.capital}
            onChange={handleChange}
            required
            options={(data?.program === "Accelerated" ? acceleratedHead : evaluationHead).map(
              itm => ({ value: itm?.capital, label: itm?.capital }),
            )}
          />

          <Input
            type="number"
            title="Amount"
            name="price"
            value={data?.price}
            onChange={handleChange}
            placeholder="Amount"
            required
          />
          <Input
            title="First Name"
            name="fname"
            value={data?.billingInfo?.fname}
            onChange={handleChangeBilling}
            placeholder="First Name"
            required
          />
          <Input
            title="Last Name"
            name="lname"
            value={data?.billingInfo?.lname}
            onChange={handleChangeBilling}
            placeholder="Last Name"
            required
          />
          <Input
            title="email"
            type="email"
            name="email"
            value={data?.billingInfo?.email}
            onChange={handleChangeBilling}
            placeholder="youremail@example.com"
            required
          />

          <Input
            title="Address"
            name="address"
            value={data?.billingInfo?.address}
            onChange={handleChangeBilling}
            placeholder="Address"
            required
          />
          <Input
            title="City"
            name="city"
            value={data?.billingInfo?.city}
            onChange={handleChangeBilling}
            placeholder="City"
            required
          />
          <Input
            title="State"
            name="state"
            value={data?.billingInfo?.state}
            onChange={handleChangeBilling}
            placeholder="State"
            required
          />
          <Input
            type="number"
            title="Zip Code"
            name="zipcode"
            value={data?.billingInfo?.zip}
            onChange={handleChangeBilling}
            placeholder="Zip Code"
            required
          />
          <Select
            title="Country"
            name="country"
            value={data?.billingInfo?.country}
            onChange={handleChangeBilling}
            options={countries}
            required
          />
          <Input
            type="number"
            title="Phone Number"
            name="phone"
            value={data?.billingInfo?.phone}
            onChange={handleChangeBilling}
            placeholder="Phone"
            required
          />

          {/* <div className="mt-4">
            <label className="capitalize font-semibold mb-1 block text-sm">Phone Number</label>
            <PhoneInput
              country={data?.country?.toLowerCase()}
              className="bg-[#f9fafb] border border-[#ddd] rounded-md overflow-hidden text-sm w-full [&>.form-control]:!h-[38px] [&>.form-control]:!w-full [&>.form-control]:!bg-[#f9fafb] [&>.flag-dropdown]:!border-none [&>.flag-dropdown]:!bg-[#f9fafb] [&_.selected-flag]:hover:!bg-[#f9fafb] [&>.form-control]:!border-none [&>.form-control]:!rounded-md [&>.form-control]:!pl-10 [&>.flag-dropdown]:!rounded-md"
              onChange={e => setData(prev => ({ ...prev, phone: e }))}
              value={data?.billingInfo?.phone}
              required
            />
          </div> */}
        </div>

        <p className="font-medium mt-4 mb-1">Addons:</p>
        <div className="flex items-center gap-x-8 gap-y-1 flex-wrap">
          {orderExtras?.map(itm => (
            <div
              key={itm?._id}
              className="flex items-center gap-2"
            >
              <input
                id={itm?._id}
                type="checkbox"
                checked={data?.orderExtra?.some(item => item._id === itm?._id)}
                onClick={e => handleOrderExtraChange(e, itm)}
                className="cursor-pointer"
              />
              <label
                className=" cursor-pointer text-base opacity-90 font-medium"
                htmlFor={itm?._id}
              >
                {itm?.name}
              </label>
            </div>
          ))}
        </div>
        <br />

        <Button
          type="submit"
          disabled={isDisable}
        >
          Submit
        </Button>
      </form>
    </DashboardLayout>
  );
};

export default AdminOrderAdd;
