import React from "react";
import Counter from "../../common/Counter";
import { useTranslation } from "react-i18next";

const data = [
  {
    title: "total_trading_volume",
    count: (
      <>
        $
        <Counter
          end={150080071}
          thousandsSeparator=","
        />
      </>
    ),
  },
  {
    title: "open_interest",
    count: (
      <>
        $
        <Counter
          end={10300400}
          thousandsSeparator=","
        />
      </>
    ),
  },
  {
    title: "live_users",
    count: (
      <Counter
        end={350000}
        thousandsSeparator=","
      />
    ),
  },
];

const Stats = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 md:py-16 text-center bg-secondary">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <div className="flex flex-wrap gap-5">
          {data?.map((itm, idx) => (
            <div
              key={idx}
              className="max-md:w-full md:flex-1 bg-white rounded-xl p-6 md:p-10 shadow-lg"
            >
              <p className="text-xl font-medium">{t(itm.title)}</p>
              <p className="text-2xl font-semibold">{itm.count}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stats;
