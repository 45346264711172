import React from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useTranslation } from "react-i18next";
import { FaGlobe } from "react-icons/fa6";

const Sidebar = ({ toggle, setToggle, links, activeLink, getSelectedLng, i18next }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`sidebar lg:hidden fixed h-[100vh] top-0 ${toggle ? "left-0" : "left-[-300px]"} w-[300px] p-8 z-[9982] transition-all`}
    >
      <div className="w-full flex justify-end">
        <IoIosCloseCircle
          size={26}
          className="cursor-pointer"
          style={{ transform: "rotateY(180deg" }}
          onClick={() => [
            setToggle(false),
            (document.querySelector("body").style.overflowY = "scroll"),
          ]}
        />
      </div>
      <div className="mt-4">
        {links?.map((itm, idx) => (
          <Link
            key={idx + 1}
            to={itm.to}
            className={`mb-4 parent-link block w-fit font-medium ${activeLink(itm.to)}`}
          >
            {!itm?.hasChildren ? (
              t(itm.title)
            ) : (
              <Accordion allowZeroExpanded>
                <AccordionItem key={`link_${idx + 1}`}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{t(itm.title)}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Link
                      to="/apy"
                      className={`${activeLink("/apy")}`}
                    >
                      {t("apy")}
                    </Link>
                  </AccordionItemPanel>
                  <AccordionItemPanel>
                    <Link
                      to="/staking"
                      className={`${activeLink("/staking")}`}
                    >
                      {t("staking")}
                    </Link>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            )}
          </Link>
        ))}
        <a
          target="_blank"
          href="https://passve.gitbook.io/docs"
          className="parent-link relative font-medium text-base 2xl:text-xl hover:text-primary hover:transition"
        >
          {t("docs")}
        </a>
        <br />
        <br />
        <div className="flex items-center gap-1 text-sm">
          <FaGlobe
            fill="var(--primary)"
            size={16}
          />
          <select
            className="bg-transparent"
            onChange={e => {
              i18next.changeLanguage(e.target.value);
              localStorage.setItem("lng", e.target.value);
            }}
          >
            <option
              value="en"
              selected={getSelectedLng("en")}
            >
              English
            </option>
            <option
              value="es"
              selected={getSelectedLng("es")}
            >
              Español
            </option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
