import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Cookies = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <main>
        <div className="py-12 md:py-16">
          <div className="mx-auto max-w-[1280px] px-6 w-full">
            <div className="py-10">
              <div className="flex items-center gap-y-10 gap-x-6 flex-wrap">
                <p className="border-b-[2px] border-primary text-2xl font-bold">{t("l_t3")}</p>
                <Link
                  to="/privacy-policy"
                  className="text-[15px] font-bold text-primary"
                >
                  {t("l_t2")}
                </Link>
                <Link
                  to="/terms-of-use"
                  className="text-[15px] font-bold text-primary"
                >
                  {t("l_t1")}
                </Link>
                <Link
                  to="/kyc"
                  className="text-[15px] font-bold text-primary"
                >
                  {t("l_t4")}
                </Link>
              </div>
              <div className="text-base font-semibold">
                <br />
                <br />
                <p>{t("cp_t1")}</p>
                <br />
                <br />
                <p>{t("cp_t2")}</p>
                <br />
                <p>{t("cp_t3")}</p>
                <p>{t("cp_t4")}</p>
                <br />
                <p>{t("cp_t5")}</p>
                <p>{t("cp_t6")}</p>
                <br />
                <p>{t("cp_t7")}</p>
                <p>{t("cp_t8")}</p>
                <br />
                <p>{t("cp_t9")}</p>
                <p>{t("cp_t10")}</p>
                <br />
                <p>{t("cp_t11")}</p>
                <p>{t("cp_t12")}</p>
                <p>{t("cp_t13")}</p>
                <p>{t("cp_t14")}</p>
                <br />
                <p>{t("cp_t15")}</p>
                <p>{t("cp_t16")}</p>
                <p>{t("cp_t17")}</p>
                <p>{t("cp_t18")}</p>
                <p>{t("cp_t19")}</p>
                <p>{t("cp_t20")}</p>
                <p>{t("cp_t21")}</p>
                <p>{t("cp_t22")}</p>
                <p>{t("cp_t23")}</p>
                <p>{t("cp_t24")}</p>
                <br />
                <p>{t("cp_t25")}</p>
                <p>{t("cp_t26")}</p>
                <p>{t("cp_t27")}</p>
                <p>{t("cp_t28")}</p>
                <br />
                <p>{t("cp_t29")}</p>
                <p>{t("cp_t30")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("cp_t31")}</li>
                  <li>{t("cp_t32")}</li>
                  <li>{t("cp_t33")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Cookies;
