import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  acceleratedBody,
  acceleratedHead,
  evaluationBody,
  evaluationHead,
} from "../../utils/plans";

const types = [
  { type: "Accelerated", profit: "80%" },
  { type: "Evaluation", profit: "100%", isPopular: true },
];

const Cards1 = [
  {
    title: "challenge",
    desc: "f_challenge_desc",
  },
  {
    title: "verification",
    desc: "f_verification_desc",
  },
  {
    title: "funded_trader",
    desc: "f_funded_trader_desc",
  },
];

const Cards2 = [
  {
    title: "fast_track",
    desc: "f_fast_track_desc",
  },
];

const Objectives = () => {
  const { t } = useTranslation();
  const [activeType, setActiveType] = useState(types[1].type);

  const cardsData = useMemo(() => (activeType === "Accelerated" ? Cards2 : Cards1), [activeType]);

  return (
    <div className="pt-12 md:pt-16 ">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <h2 className="text-center text-2xl md:text-4xl font-semibold mb-9">
          {t("view_trading_objectives")}
        </h2>

        <div className="flex justify-center  gap-6 w-full">
          {types?.map((itm, idx) => (
            <div
              key={idx}
              onClick={() => [setActiveType(itm.type)]}
              className={`transition-all relative cursor-pointer rounded-lg pb-2 w-[200px] flex items-center justify-center flex-col [&>p]:font-semibold text-center
                  ${activeType === itm.type ? "[&>p]:text-white bg-primary2" : "[&>p]:text-black bg-gray"}
                  min-h-full overflow-hidden
              `}
            >
              {itm?.isPopular && (
                <span className="block bg-[#E72D04] py-[2px] w-full text-white font-semibold text-xs">
                  {t("most_popular")}
                </span>
              )}
              <p className="mt-1 capitalize">{t(itm.type.toLowerCase())}</p>
              <p>
                {itm.profit} {t("profit_split")}
              </p>
            </div>
          ))}
        </div>

        <div className="flex flex-wrap gap-6 w-full justify-center mt-12 md:[&>div]:flex-1">
          {cardsData.map((itm, idx) => (
            <Card
              data={itm}
              key={idx}
              step={idx + 1}
            />
          ))}
        </div>
        <br />
        <br />
        <div className="px-2 py-4 border border-[#ddd] rounded-md overflow-x-scroll">
          <table className="w-full min-w-[700px]">
            <thead>
              <tr>
                <th className="flex justify-center">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/upload/passve.svg`}
                    alt="passve"
                  />
                </th>
                {(activeType === "Accelerated" ? acceleratedHead : evaluationHead).map(
                  (itm, idx) => (
                    <th
                      className="text-center p-2"
                      key={idx}
                    >
                      ${itm?.capital}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody className="text-sm font-semibold">
              {(activeType === "Accelerated" ? acceleratedBody : evaluationBody)?.map(
                (itm, idx) => (
                  <tr
                    key={idx}
                    className="[&>td]:text-center"
                  >
                    <td className="font-bold py-3">{itm.head}</td>
                    <td colSpan={itm?.span || 1}>{itm.text1}</td>
                    {!itm?.span && (
                      <>
                        <td>{itm.text2}</td>
                        <td>{itm.text3}</td>
                        <td>{itm.text4}</td>
                        <td>{itm.text5}</td>
                        {itm?.text6 && (
                          <>
                            <td>{itm.text6}</td>
                            <td>{itm.text7}</td>
                            <td>{itm.text8}</td>
                          </>
                        )}
                      </>
                    )}
                  </tr>
                ),
              )}
              <tr>
                <td></td>
                {Array(activeType === "Accelerated" ? 5 : 8)
                  .fill()
                  .map((_, idx) => (
                    <td key={idx}>
                      <Link
                        to="/checkout"
                        onClick={() => {
                          const plan = (
                            activeType === "Accelerated" ? acceleratedHead : evaluationHead
                          )[idx]?.capital;
                          sessionStorage.setItem("passve-plan", btoa(plan));
                          sessionStorage.setItem("passve-type", btoa(activeType));
                        }}
                        className="block max-md:mr-1 min-w-[100px] mx-auto w-fit px-3 py-2 bg-primary2 text-white rounded-[50px] hover:underline"
                      >
                        {t("select_plan")}
                      </Link>
                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Card = ({ data, step }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#060644] p-4 md:p-6 rounded-lg [&>p]:text-white max-w-[395px] md:h-[282px]">
      {step && (
        <p className="font-medium text-xs capitalize">
          {t("step")} {step}
        </p>
      )}
      <p className="mt-2 text-lg font-semibold">{t(data?.title)}</p>
      <p
        className="mt-3 text-sm mb-4" // Added mb-4 class for space after the description
      >
        {t(data.desc)}
      </p>
    </div>
  );
};

export default Objectives;
