import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import DashboardLayout from "../../layout/Layout";
import Input from "../../common/Input";
import Api from "../../api/api";
import Select from "../../common/Select";
import { countries } from "../../utils/countries";
import PhoneInput from "react-phone-input-2";
import Button from "../../common/Button";

const initialData = {
  fname: "",
  lname: "",
  email: "",
  address: "",
  phone: "",
  country: "",
};

const Profile = () => {
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({ ...initialData });

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!data.phone) {
      return toast.error("Phone number is required");
    }

    setIsDisable(true);
    try {
      const res = await Api.updateMyProfile(data);
      if (res.status === 200) {
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  const getMyProfile = async () => {
    try {
      const res = await Api.getMeProfile();
      if (res.status === 200) {
        const user = res.data.data;
        setData({
          fname: user?.fname,
          lname: user?.lname,
          email: user?.email,
          address: user?.address,
          phone: user?.phone,
          country: user?.country,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMyProfile();

    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);

  return (
    <DashboardLayout title="Profile">
      <form onSubmit={handleSubmit}>
        <div className="grid gap-x-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <Input
            title="First Name"
            name="fname"
            value={data.fname}
            onChange={handleChange}
            placeholder="First Name"
            disabled
            required
          />
          <Input
            title="Last Name"
            name="lname"
            value={data.lname}
            onChange={handleChange}
            placeholder="Last Name"
            disabled
            required
          />
          <Input
            title="Email"
            value={data?.email}
            disabled
          />
          <Input
            title="Address"
            name="address"
            value={data.address}
            onChange={handleChange}
            placeholder="Address"
            required
          />

          <Select
            title="Country"
            name="country"
            value={data.country}
            onChange={handleChange}
            options={countries}
            required
          />
          <div className="mt-4">
            <label className="capitalize font-semibold mb-1 block text-sm">Phone Number</label>
            <PhoneInput
              country={data?.country?.toLowerCase()}
              className="bg-[#f9fafb] border border-[#ddd] rounded-md overflow-hidden text-sm w-full [&>.form-control]:!h-[38px] [&>.form-control]:!w-full [&>.form-control]:!bg-[#f9fafb] [&>.flag-dropdown]:!border-none [&>.flag-dropdown]:!bg-[#f9fafb] [&_.selected-flag]:hover:!bg-[#f9fafb] [&>.form-control]:!border-none [&>.form-control]:!rounded-md [&>.form-control]:!pl-10 [&>.flag-dropdown]:!rounded-md"
              onChange={e => setData(prev => ({ ...prev, phone: e }))}
              value={data?.phone}
              required
            />
          </div>
        </div>

        <br />

        <Button
          type="submit"
          disabled={isDisable}
        >
          Update
        </Button>
      </form>
    </DashboardLayout>
  );
};

export default Profile;
