import React, { useMemo, useRef, useState } from "react";
import Cookies from "js-cookie";
import { HiMiniBars3BottomLeft } from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import { MdFileDownload } from "react-icons/md";
import { REACT_APP_KEY_NAME } from "../utils/constants";
import { useAuth } from "../context/auth";
import DashboardSidebar from "../common/DashboardSidebar";
import Usermenu from "../common/usermenu";

const DashboardLayout = ({ children, title }) => {
  const location = useLocation();
  const { user } = useAuth();
  const showUserMenuRef = useRef(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);

  const isAdminLinks = useMemo(() => {
    if (location.pathname.includes("/admin/dashboard")) return true;
    return false;
  }, [location.pathname]);

  const handleLogout = () => {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
    window.location.replace("/auth/signin");
  };

  return (
    <div className="layout w-full min-h-screen h-screen overflow-hidden bg-white">
      <div className="w-full h-full">
        <DashboardSidebar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          handleLogout={handleLogout}
          isAdminLinks={isAdminLinks}
        />
        <div className="w-full h-full ml-auto lg:w-[calc(100%_-_250px)]">
          <div className="border-b border-[#EFF0F6] flex items-center justify-between p-4 shadow-sm">
            <div className="flex items-center gap-2">
              <HiMiniBars3BottomLeft
                size={24}
                onClick={() => setShowSidebar(true)}
                className="lg:hidden cursor-pointer"
              />
              <h2 className="text-xl font-bold">{title}</h2>
            </div>
            <div className="relative">
              <div
                onClick={() => setShowUserMenu(true)}
                className="size-10 bg-primary text-white grid place-items-center rounded-full cursor-pointer font-bold uppercase"
              >
                {user?.fname[0]}
                {user?.lname[0]}
              </div>
              {showUserMenu && (
                <Usermenu
                  isAdmin={isAdminLinks}
                  name={user?.fname + " " + user?.lname}
                  email={user?.email}
                  showUserMenuRef={showUserMenuRef}
                  setShowUserMenu={setShowUserMenu}
                />
              )}
            </div>
          </div>
          <div className="p-4 h-[calc(100%_-_73px)] overflow-y-scroll">
            {!isAdminLinks ||
              (["/profile", "/password"].includes(location.pathname) && (
                <div className="flex items-center gap-4 flex-wrap justify-between mb-6">
                  <div className="flex items-center gap-5 flex-wrap">
                    <div className="flex items-center gap-1 font-medium text-sm">
                      <span>Account Number:</span>
                      <select className="font-semibold rounded-[50px] py-1 px-2 border">
                        <option>123456</option>
                      </select>
                    </div>
                    <div className="flex items-center gap-1 font-medium text-sm">
                      <span>Timeframe:</span>
                      <select className="font-semibold rounded-[50px] py-1 px-2 border">
                        <option>All frame</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex items-center gap-1 cursor-pointer text-sm font-medium">
                    <MdFileDownload size={22} />
                    <span>Download</span>
                  </div>
                </div>
              ))}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
