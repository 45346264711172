import { useState, useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import DashboardLayout from "../../layout/Layout";
import Table, { Td } from "../../common/Table";
import Pagination from "../../common/Pagination";
import Api from "../../api/api";
import Loader from "../../common/Loader";
import Input from "../../common/Input";
import { jsonToQueryParams } from "../../helpers/functions";
import { FaEye } from "react-icons/fa";
import CustomModal from "../../layout/Modal";
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import Button from "../../common/Button";
import { BiSolidCopy } from "react-icons/bi";
import { PAYMENT_METHOD } from "../../utils/constants";

const columns = [
  "Order Number",
  "Account Type",
  "Capital Size",
  "Discount code",
  "Quote Date",
  "Price",
  "Status",
  "Payment Status",
  "Method",
  "Action",
];

const OrderHistory = () => {
  const [showQR, setShowQR] = useState(false);
  const [qrData, setQRData] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const page = parseInt(params.get("page") || 1);
  const limit = parseInt(params.get("limit") || 10);
  const status = params.get("status") || "all";

  const [query, setQuery] = useState("");
  const [orders, setOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [activeType, setActiveType] = useState(status);

  const handleSearch = event => {
    const { value } = event.target;
    setQuery(value);
  };

  const getOrders = useCallback(
    async ({ search, page, limit, status }) => {
      try {
        const res = await Api.userGetAllOrders(search, page, limit, status);
        if (res?.status === 200) {
          setOrders(res.data.data?.orders);
          setTotalPages(res.data.data?.totalPages);
          // setTotalOrders(res.data.data?.totalOrders);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [setOrders],
  );

  useEffect(() => {
    const _query = jsonToQueryParams({ page: 1, limit, search: query || "", status: activeType });
    history.push(`${location.pathname}?${_query}`);
  }, [query, activeType]);

  useEffect(() => {
    getOrders({ search: query, page, limit, status });
    setIsLoading(false);
  }, [location.pathname, location.search]);

  return (
    <DashboardLayout title="Order History">
      <div className="flex items-center gap-3 flex-wrap mb-5">
        {["all", "paid", "placed", "pending", "cancelled"].map((itm, idx) => (
          <div
            key={idx}
            onClick={() => [setActiveType(itm)]}
            className={`transition-all capitalize relative cursor-pointer rounded-lg py-1 px-4 flex items-center justify-center flex-col [&>p]:font-semibold text-center
                  ${activeType === itm ? "[&>p]:text-white bg-primary2" : "[&>p]:text-black bg-gray"}
                  min-h-full overflow-hidden
              `}
          >
            <p>{itm}</p>
          </div>
        ))}
      </div>
      <div className="my-4 w-fit">
        <Input
          value={query}
          onChange={handleSearch}
          placeholder="Search orders..."
        />
      </div>
      <Table columns={columns}>
        {!isLoading &&
          orders &&
          orders?.map(itm => (
            <tr>
              <Td>{itm?.orderId}</Td>
              <Td>{itm?.program}</Td>
              <Td>${itm?.capital}</Td>
              <Td>-</Td>
              <Td>{new Date(itm?.createdAt).toDateString()}</Td>
              <Td>${itm?.price || 0}</Td>
              <Td>{itm?.status}</Td>
              <Td>{itm?.transaction?.payment_status}</Td>
              <Td>{itm?.method}</Td>
              <Td>
                <div className="flex items-center gap-3">
                  <FaEye
                    onClick={() => {
                      setQRData(itm);
                      setShowQR(true);
                    }}
                    size={18}
                    className="cursor-pointer fill-blue-500"
                  />
                </div>
              </Td>
            </tr>
          ))}
      </Table>

      <br />
      {isLoading ? (
        <div className="grid place-items-center">
          <Loader />
        </div>
      ) : (
        <Pagination totalPages={totalPages} />
      )}
      {showQR && (
        <CustomModal
          title="Order Detail"
          onHide={() => setShowQR(false)}
        >
          {qrData?.method === PAYMENT_METHOD.CRYPTO && (
            <>
              {" "}
              <QRCode
                height={150}
                width={150}
                value={qrData?.transaction?.pay_address}
                className="block mx-auto size-[150px] mb-3"
              />
              <p className="text-center my-1 text-sm">OR</p>
              <div className="flex items-center justify-center flex-wrap gap-3 mt-4 mb-2 w-full">
                <p className="max-sm:w-[90%] border border-dashed border-primary bg-[#016fd014] text-primary max-sm:text-sm font-[600] px-4 py-2 md:px-6 md:py-3 rounded-[10px] text-ellipsis overflow-hidden">
                  {qrData?.transaction?.pay_address}
                </p>
                <CopyToClipboard
                  text={qrData?.transaction?.pay_address}
                  onCopy={() => toast.success("Payment address copied!")}
                >
                  <Button className="max-sm:w-[90%] flex justify-center items-center gap-x-2">
                    <BiSolidCopy
                      fill="white"
                      size={20}
                    />
                    <span className="text-[#ffffff]">Copy</span>
                  </Button>
                </CopyToClipboard>
              </div>
              <br />
              <h2 className="text-center">
                <b>Asset Name: {(qrData?.transaction?.pay_currency).toUpperCase()}</b>
              </h2>
              <br />
              <h2
                className="text-center"
                style={{ color: "red" }}
              >
                Expire in 1 Day from now
              </h2>
              <br />
              <p className="text-xl font-[600]">Order Summary</p>
            </>
          )}
          <table>
            <tr>
              <td className="w-[120px]">
                <h4>Program: </h4>
              </td>
              <td>
                <h4>{qrData?.program}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Capital: </h4>
              </td>
              <td>
                <h4>${qrData?.capital}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Amount: </h4>
              </td>
              <td>
                <h4>${qrData?.price || 0}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Platform: </h4>
              </td>
              <td>
                <h4>{qrData?.platform?.name}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Order Extra: </h4>
              </td>
              <td>
                <h4>{qrData?.orderExtra?.map(item => item?.name).join(", ")}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Created At: </h4>
              </td>
              <td>
                <h4>{new Date(qrData?.createdAt).toLocaleString()}</h4>
              </td>
            </tr>
          </table>
        </CustomModal>
      )}
    </DashboardLayout>
  );
};

export default OrderHistory;
