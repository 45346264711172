import React from "react";
import Button from "../../common/Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Crypto = () => {
  const { t } = useTranslation();

  return (
    <div className="py-12 md:py-16 px-6">
      <div className="mx-auto max-w-[1230px] w-full border border-[#ddd] rounded-xl">
        <div className="flex items-center flex-wrap gap-6 justify-between w-full p-6">
          <div>
            <h3 className="text-2xl font-semibold mb-4">{t("new_in_cryptocurrency")}</h3>
            <p className="md:max-w-[530px]">{t("h_cryptocurrency_desc")}</p>
          </div>
          <Link to={"/autonomous-funding"}>
            <Button>{t("learn_explore")}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Crypto;
