import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { borrowAPY, supplyAPY } from "../../utils/data";
import ReactSwitch from "react-switch";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const APYTables = () => {
  const { t } = useTranslation();
  return (
    <div className="pb-12 md:pb-16">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <div className="mb-4 flex items-center justify-end">
          <div className="bg-[#101118] rounded-md px-3 py-2 text-base relative">
            <CiSearch
              size={22}
              fill="#ccc"
              className="absolute left-2"
            />
            <input
              type="text"
              placeholder={t("search_coin")}
              className="w-full border-none h-full text-white bg-transparent pl-6"
            />
          </div>
        </div>
        <div className="flex max-md:flex-wrap w-full gap-8">
          <div className="md:flex-1 max-md:w-full max-md:overflow-x-scroll bg-[#101118] py-2 px-3 rounded">
            <p className="text-xl font-bold mb-4 text-white">{t("supply")}</p>
            <table className="w-full">
              <thead>
                <tr className="border-b border-[#ddd]">
                  {["asset", "APY", "wallet", "collateral"]?.map((itm, idx) => (
                    <td
                      key={idx}
                      className="text-start text-[#5d6388] font-semibold p-2"
                    >
                      {t(itm)}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {supplyAPY?.map((itm, idx) => (
                  <Tr
                    itm={itm}
                    key={idx}
                    idx={idx}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div className="md:flex-1 max-md:w-full max-md:overflow-x-scroll bg-[#101118] py-2 px-3 rounded">
            <p className="text-xl font-bold mb-4 text-white">{t("borrow")}</p>
            <table className="w-full">
              <thead>
                <tr className="border-b border-[#ddd]">
                  {["asset", "APY", "wallet", "liquidity"]?.map((itm, idx) => (
                    <td
                      key={idx}
                      className="text-start text-[#5d6388] font-semibold p-2"
                    >
                      {t(itm)}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {borrowAPY?.map((itm, idx) => (
                  <tr
                    key={idx}
                    className="border-b font-semibold border-[#ddd] text-sm [&>td]:p-2"
                  >
                    <td>
                      <div className="flex items-center gap-2">
                        <img
                          src={itm?.coinURL}
                          alt={itm?.coin}
                          className="rounded-full overflow-hidden size-8 min-w-5"
                        />
                        <span className="capitalize text-white">{itm?.coin}</span>
                        <span className="uppercase text-white opacity-85 pl-2 border-l border-[#ddd]">
                          {itm?.coinAbbr}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center gap-4">
                        {itm?.up ? (
                          <FaChevronUp fill="#22c55e" />
                        ) : (
                          <FaChevronDown fill="#ef4444" />
                        )}
                        <span className={`${itm.up ? "text-green-500" : "text-red-500"}`}>
                          {itm.APY}
                        </span>
                      </div>
                    </td>
                    <td className="text-white">
                      {itm.wallet} {itm.coin}
                    </td>
                    <td className="text-white">{itm?.liquidity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const Tr = ({ itm, idx }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <tr
      key={idx}
      className="border-b font-semibold border-[#ddd] text-sm [&>td]:p-2"
    >
      <td>
        <div className="flex items-center gap-2">
          <img
            src={itm?.coinURL}
            alt={itm?.coin}
            className="rounded-full overflow-hidden min-w-5 size-8"
          />
          <span className="capitalize text-white">{itm?.coin}</span>
          <span className="uppercase text-white opacity-85 pl-2 border-l border-[#ddd]">
            {itm?.coinAbbr}
          </span>
        </div>
      </td>
      <td>
        <div className="flex items-center gap-4">
          {itm?.up ? <FaChevronUp fill="#22c55e" /> : <FaChevronDown fill="#ef4444" />}
          <span className={`${itm.up ? "text-green-500" : "text-red-500"}`}>{itm.APY}</span>
        </div>
      </td>
      <td className="text-white">
        {itm.wallet} {itm.coin}
      </td>
      <td>
        <ReactSwitch
          checked={toggle}
          onChange={() => setToggle(prev => !prev)}
        />
      </td>
    </tr>
  );
};

export default APYTables;
