import React, { useMemo } from "react";
import {
  TbChevronLeft,
  TbChevronLeftPipe,
  TbChevronRight,
  TbChevronRightPipe,
} from "react-icons/tb";
import { useHistory, useLocation } from "react-router-dom";

const Pagination = ({ totalPages = 0 }) => {
  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const page = parseInt(params.get("page") || 1);
  const limit = parseInt(params.get("limit") || 10);

  const handleGoPage = key => {
    let _page = page;
    if (key === "_prev") {
      _page = 1;
    } else if (key === "_next") {
      _page = totalPages;
    } else if (key === "prev" && page > 1) {
      _page = page - 1;
    } else if (key === "next" && page < totalPages) {
      _page = page + 1;
    }

    params.set("page", _page);
    params.set("limit", limit);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    params.set(name, value);
    if (name !== "page") params.set("page", 1);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  return (
    <div className="flex items-center justify-center gap-3 text-sm max-md:flex-wrap">
      <div className="flex items-center gap-3 max-md:w-full justify-center">
        <button
          onClick={() => handleGoPage("_prev")}
          disabled={page === 1}
          className="cursor-pointer disabled:cursor-not-allowed"
        >
          <TbChevronLeftPipe size={16} />
        </button>
        <button
          onClick={() => handleGoPage("prev")}
          disabled={page === 1}
          className="cursor-pointer disabled:cursor-not-allowed"
        >
          <TbChevronLeft size={16} />
        </button>
        <button
          onClick={() => handleGoPage("next")}
          disabled={page === totalPages}
          className="cursor-pointer disabled:cursor-not-allowed"
        >
          <TbChevronRight size={16} />
        </button>
        <button
          onClick={() => handleGoPage("_next")}
          disabled={page === totalPages}
          className="cursor-pointer disabled:cursor-not-allowed"
        >
          <TbChevronRightPipe size={16} />
        </button>
      </div>
      <span>
        Page{" "}
        <b>
          {page} of {totalPages}
        </b>
      </span>
      <span>Go to Page:</span>
      <input
        type="number"
        min={1}
        name="page"
        value={page}
        max={totalPages}
        onChange={handleChange}
        className="font-semibold max-w-14 text-center rounded-lg py-1 px-2 border border-[#ddd] outline-none"
      />
      <br className="md:hidden" />
      <span className="pl-5 md:pl-2">Show Entries:</span>
      <select
        name="limit"
        value={limit}
        onChange={handleChange}
        className="font-semibold max-w-14 text-center rounded-lg py-1 px-2 border border-[#ddd] outline-none"
      >
        <option>5</option>
        <option>10</option>
        <option>20</option>
        <option>50</option>
      </select>
    </div>
  );
};

export default Pagination;
