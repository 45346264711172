import React from "react";
import img1 from "../../assets/funding/news/img1.png";
import img2 from "../../assets/funding/news/img2.png";
import img3 from "../../assets/funding/news/img3.png";
import img4 from "../../assets/funding/news/img4.png";
import { useTranslation } from "react-i18next";

const data = [
  {
    img: img1,
    tag: "Finance Pack",
    title: "How to Manage your Porfolio.",
    desc: "A crypto wallet is a place where you can securely keep your crypto..",
  },
  {
    img: img2,
    tag: "CRYPTO BASIC",
    title: "Facts which make bitcoin better than Gold",
    desc: "Bitcoin is the world's first widely adopted cryptocurrency, it allows for secure..",
  },
  {
    img: img3,
    tag: "TIPS & TRICKS",
    title: "Analysing Price Action. What Not to do?",
    desc: "When prices are fluctuating, how do you know when to buy?",
  },
  {
    img: img4,
    tag: "TIPS & TRICKS",
    title: "Trade by Linking Technicals with Fundamentals?",
    desc: "Welcome to decentralized finance or DeFi, the new frontier of crypto that will..",
  },
];

const News = () => {
  const { t } = useTranslation();

  return (
    <div className="py-12 md:py-16 ">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <h2 className="text-center text-2xl md:text-4xl font-semibold mb-5">
          {t("insights_tips")}
        </h2>
        <p className="text-center font-medium mb-8 mx-auto max-w-[600px]">
          {t("insights_tips_desc")}
        </p>
        <div className="flex flex-wrap gap-6 [&>div]:md:flex-1">
          {data?.map((itm, idx) => (
            <div
              key={idx}
              className="max-md:w-full rounded-[18px] bg-secondary overflow-hidden"
            >
              <img
                src={itm.img}
                alt={itm.title}
                className="w-full h-[191px] object-cover"
              />
              <div className="p-3">
                <p className="mt-1 mb-3 bg-[#060644] text-white uppercase rounded px-2 py-[2px] text-xs font-bold w-fit">
                  {itm.tag}
                </p>
                <p className="font-semibold text-lg mb-2">{itm.title}</p>
                <p className="font-medium text-sm">{itm.desc}</p>
              </div>
            </div>
          ))}
        </div>
        <p className="text-center mt-8">
          <a
            href="#"
            className="underline text-primary text-sm font-semibold"
          >
            {t("see_all_articles")}
          </a>
        </p>
      </div>
    </div>
  );
};

export default News;
