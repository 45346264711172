import React from "react";

const Table = ({ columns = [], children }) => {
  return (
    <div className="flex flex-col">
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="border border-[#ddd] rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-[#ddd]">
              <thead className="bg-[#f9fafb]">
                <tr>
                  {columns.map(itm => (
                    <Th key={itm}>{itm}</Th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-[#ddd]">{children}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const Th = ({ children }) => {
  return (
    <th
      scope="col"
      className="px-5 py-3 text-start text-xs font-semibold text-[#6b7280] uppercase"
    >
      {children}
    </th>
  );
};

export const Td = ({ className = "", children }) => {
  return (
    <td className={`px-5 py-3 whitespace-nowrap text-sm text-gray-800 ${className}`}>{children}</td>
  );
};

export default Table;
