import React from "react";
import tokens from "../../assets/portal/tokens.svg";
import buySell from "../../assets/portal/buySell.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const data = [
  { img: tokens, count: "+21%", title: "earn_interest", to: "/apy" },
  { img: buySell, title: "trade_now", to: "/autonomous-funding" },
  {
    img: `${process.env.REACT_APP_API_URL}/upload/portalFunding.svg`,
    title: "autonomous_funding",
    to: "/autonomous-funding",
  },
];

const PortalStats = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 md:py-16 bg-secondary">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <div className="flex gap-4 flex-wrap">
          {data?.map((itm, idx) => (
            <div
              key={idx}
              className="p-4 md:p-6 flex max-md:w-full md:flex-1 gap-4 justify-between rounded-xl border border-primary bg-[#016fd014]"
            >
              <div className="flex flex-col justify-between gap-3">
                <p className="text-lg font-semibold">{t(itm.title)}</p>
                <Link
                  to={itm.to}
                  className="w-fit rounded-full text-sm bg-primary text-white font-semibold px-6 py-[6px]"
                >
                  {t("view")}
                </Link>
              </div>
              <div className="relative">
                <img
                  src={itm.img}
                  alt={itm.title}
                  className="w-[110px]"
                />
                {itm?.count && (
                  <span className="absolute md:text-lg font-semibold top-0 left-0">
                    {itm.count}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortalStats;
