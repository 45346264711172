import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { TbLogout2 } from "react-icons/tb";
import logo from "../assets/logo.png";
import Button from "./Button";
import { adminDashboardLinks, dashboardLinks } from "../utils/constants";
import { FaArrowLeft } from "react-icons/fa6";

const DashboardSidebar = ({ showSidebar, setShowSidebar, handleLogout, isAdminLinks }) => {
  const location = useLocation();

  const links = useMemo(
    () => (isAdminLinks ? adminDashboardLinks : dashboardLinks),
    [isAdminLinks],
  );

  const activeLink = path => {
    if (location.pathname === path) return true;
    return false;
  };

  return (
    <div
      className={`bg-white p-6 fixed ${
        showSidebar ? "left-0" : "-left-[250px]"
      } lg:left-0 transition-all top-0 border-r border-r-[#EFF0F6] bottom-0 w-[250px] h-full z-40 max-lg:shadow-2xl`}
    >
      <FaArrowLeft
        fill="var(--primary)"
        size={24}
        className="cursor-pointer mb-4 md:hidden"
        onClick={() => setShowSidebar(false)}
      />

      <Link to="/">
        <div className="mb-6">
          <img
            src={logo}
            alt="Passve"
            className="w-full"
          />
        </div>
      </Link>

      {!isAdminLinks && (
        <Link
          to="/dashboard/new-plan"
          className="block w-full"
        >
          <Button className={"!bg-primary2 w-full"}>Start New Plan</Button>
        </Link>
      )}
      <div className="mt-5 h-[calc(100%_-_136px)] overflow-y-scroll space-y-1">
        {links?.map((itm, idx) => (
          <Link
            key={idx}
            to={itm.to}
            className={`w-full flex items-center gap-3 rounded-lg p-3 cursor-pointer transition-all ${activeLink(itm.to) ? "bg-[#1B59F81A]" : ""} hover:bg-[#1B59F81A]`}
          >
            <itm.icon
              size={22}
              fill={activeLink(itm.to) ? "#016fd0" : "#000000B2"}
            />
            <span
              className={`text-sm ${activeLink(itm.to) ? "text-[#016fd0]" : "text-[#000000B2]"} font-semibold`}
            >
              {itm.title}
            </span>
          </Link>
        ))}
        <div
          onClick={handleLogout}
          className={`w-full flex items-center gap-3 rounded-lg p-3 cursor-pointer transition-all hover:bg-[#1B59F81A]`}
        >
          <TbLogout2
            size={22}
            stroke={"#000000B2"}
          />
          <span className={`text-sm text-[#000000B2] font-semibold`}>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default DashboardSidebar;
