import { useCallback, useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import DashboardLayout from "../../layout/Layout";
import Table, { Td } from "../../common/Table";
import Api from "../../api/api";
import Loader from "../../common/Loader";
import Select from "../../common/Select";
import Button from "../../common/Button";
import CustomModal from "../../layout/Modal";
import { useLocation, useHistory } from "react-router-dom";
import { jsonToQueryParams } from "../../helpers/functions";
import Input from "../../common/Input";
import Pagination from "../../common/Pagination";

const columns = ["#", "Email", "Subject", "Message", "status", "created", "Actions"];

const Tickets = () => {
  const [tickets, setTickets] = useState([]);
  const [input, setInput] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [query, setQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const page = parseInt(params.get("page") || 1);
  const limit = parseInt(params.get("limit") || 10);

  /* eslint-disable no-restricted-globals */
  const handleDeletePlatform = async id => {
    if (!id) return;
    const isOK = confirm("Are you sure you want to delete this record?");
    if (isOK) {
      setTickets(prev => prev.filter(i => i._id !== id));
      // await Api.deletePlatform(id);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setIsDisabled(true);
      const res = await Api.updateTicketStatus(input?._id, { status: input?.status });
      if (res?.status === 200) {
        getTickets();
        setShowModal(false);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsDisabled(false);
      setIsLoading(false);
    }
  };

  const getTickets = useCallback(
    async ({ search, page, limit }) => {
      try {
        const res = await Api.getAllTickets(search, page, limit);
        if (res?.status === 200) {
          setTickets(res.data.data.tickets);
          setTotalPages(res.data.data?.totalPages);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [setTickets],
  );

  const handleSearch = event => {
    const { value } = event.target;
    setQuery(value);
  };

  useEffect(() => {
    const _query = jsonToQueryParams({ page: 1, limit, search: query || "" });
    history.push(`${location.pathname}?${_query}`);
  }, [query]);

  useEffect(() => {
    getTickets({ search: query, page, limit });
    setIsLoading(false);
  }, [location.pathname, location.search]);

  return (
    <>
      <DashboardLayout title={`Tickets`}>
        <div className="my-4 w-fit">
          <Input
            value={query}
            onChange={handleSearch}
            placeholder="Search..."
          />
        </div>
        <Table columns={columns}>
          {!isLoading &&
            tickets &&
            tickets?.map(itm => (
              <tr>
                <Td>#{itm?._id}</Td>
                <Td>{itm?.email}</Td>
                <Td>{itm?.subject}</Td>
                <Td>{itm?.message}</Td>
                <Td>
                  <p
                    className={`px-3 py-[3px] w-fit font-bold text-center ${itm?.status !== "closed" ? "bg-[#1dff1d40] text-[green]" : "bg-[#ff1d1d40] text-[#e90a0a]"}  rounded-md`}
                  >
                    {itm?.status}
                  </p>
                </Td>
                <Td>{new Date(itm?.createdAt).toLocaleString()}</Td>
                <Td>
                  <div className="flex items-center gap-3">
                    <FaEdit
                      size={18}
                      className="cursor-pointer fill-blue-500"
                      onClick={() => {
                        setInput({ _id: itm._id, status: itm?.status });
                        setShowModal(true);
                      }}
                    />
                    <FaTrash
                      size={18}
                      className="cursor-pointer fill-red-500"
                      onClick={() => handleDeletePlatform(itm?._id)}
                    />
                  </div>
                </Td>
              </tr>
            ))}
        </Table>

        <br />
        {isLoading ? (
          <div className="grid place-items-center">
            <Loader />
          </div>
        ) : (
          <Pagination totalPages={totalPages} />
        )}
      </DashboardLayout>

      {showModal && (
        <CustomModal
          title={`Update Status`}
          onHide={() => setShowModal(false)}
        >
          <form onSubmit={handleSubmit}>
            <Select
              title="Status"
              value={input?.status}
              onChange={e => setInput(prev => ({ ...prev, status: e.target.value }))}
              options={[
                { value: "open", label: "Open" },
                { value: "closed", label: "Closed" },
              ]}
              required
            />
            <Button
              type="submit"
              disabled={isDisabled}
              className="mt-4 !w-full"
            >
              Submit
            </Button>
          </form>
        </CustomModal>
      )}
    </>
  );
};

export default Tickets;
