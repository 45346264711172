import React from "react";
import Button from "../../common/Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Subscribe = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 md:py-16 !pt-0">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <div className="flex justify-center flex-wrap items-center gap-3">
          <input
            type="email"
            placeholder={`${t("email_here")}...`}
            className="bg-gray rounded-xl p-3 w-full md:max-w-[300px]"
          />
          <Link to={"/auth/signup"}>
            <Button>{t("subscribe_now")}</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
