import EURUSD from "../assets/forex/bitcoin-ic 1.png";
import GBPUSD from "../assets/forex/Mask Group.png";
import sol from "../assets/forex/sol.png";
import sve from "../assets/forex/sve.png";
import bnb from "../assets/forex/bitcoin-ic 1.png";
import usdt from "../assets/forex/USDT.png";
import usdc from "../assets/forex/usdc.png";
import nvda from "../assets/stock/nvda.png";
import apple from "../assets/stock/aapl.png";
import amazon from "../assets/stock/amzn.png";
import mstr from "../assets/stock/mstr.png";
import meta from "../assets/stock/meta.png";
import msft from "../assets/stock/msft.png";
import tesla from "../assets/stock/tsla.png";
import btc from "../assets/indices/btc.png";
import usdt2 from "../assets/indices/usdt.png";
import sol2 from "../assets/indices/sol.png";
import sve2 from "../assets/indices/sve.png";
import yeild from "../assets/yeild/yeild.png";
import gold from "../assets/commodities/gold.png";
import silver from "../assets/commodities/silver.png";
import plat from "../assets/commodities/plat.png";
import oil from "../assets/commodities/oil.png";
import wheat from "../assets/commodities/wheat.png";

export const data = {
  crypto: [
    {
      no: 1,
      coin: "Bitcoin",
      coinAbbr: "btc",
      coinURL: "https://assets.coincap.io/assets/icons/btc@2x.png",
      lastPrice: "$0",
      change: "1.41%",
      up: true,
    },
    {
      no: 2,
      coin: "Ethereum",
      coinAbbr: "Eth",
      coinURL: "https://assets.coincap.io/assets/icons/eth@2x.png",
      lastPrice: "$0",
      change: "2.22%",
      up: true,
    },
    {
      no: 3,
      coin: "Binance",
      coinAbbr: "bnb",
      coinURL: "https://assets.coincap.io/assets/icons/bnb@2x.png",
      lastPrice: "$0",
      change: "-0.82%",
      up: true,
    },
    {
      no: 4,
      coin: "Tether",
      coinAbbr: "usdt",
      coinURL: "https://assets.coincap.io/assets/icons/usdt@2x.png",
      lastPrice: "$0",
      change: "-0.03%",
      up: true,
    },
    {
      no: 5,
      coin: "Solana",
      coinAbbr: "Sol",
      coinURL: "https://assets.coincap.io/assets/icons/sol@2x.png",
      lastPrice: "$0",
      change: "-0.53%",
      up: false,
    },
    {
      no: 6,
      coin: "Passve",
      coinAbbr: "sve",
      coinURL: `${process.env.REACT_APP_API_URL}/upload/passve.svg`,
      lastPrice: "$0.00998",
      change: "-0.44%",
      up: false,
    },
    {
      no: 7,
      coin: "USD Coin",
      coinAbbr: "USDC",
      coinURL: "https://assets.coincap.io/assets/icons/usdc@2x.png",
      lastPrice: "$0",
      change: "-0.03%",
      up: false,
    },
    {
      no: 8,
      coin: "Chainlink",
      coinAbbr: "link",
      coinURL: "https://assets.coincap.io/assets/icons/link@2x.png",
      lastPrice: "$0",
      change: "-0.82%",
      up: true,
    },
    {
      no: 9,
      coin: "Compound",
      coinAbbr: "Comp",
      coinURL: "https://assets.coincap.io/assets/icons/comp@2x.png",
      lastPrice: "$0",
      change: "-0.53%",
      up: false,
    },
    {
      no: 10,
      coin: "Uniswap",
      coinAbbr: "Uni",
      coinURL: "https://assets.coincap.io/assets/icons/uni@2x.png",
      lastPrice: "$0",
      change: "-0.44%",
      up: false,
    },
    {
      no: 11,
      coin: "Polkadot",
      coinAbbr: "dot",
      coinURL: "https://assets.coincap.io/assets/icons/dot@2x.png",
      lastPrice: "$0",
      change: "-0.03%",
      up: false,
    },
    {
      no: 12,
      coin: "Dai",
      coinAbbr: "Dai",
      coinURL: "https://assets.coincap.io/assets/icons/dai@2x.png",
      lastPrice: "$0",
      change: "-0.03%",
      up: true,
    },
  ],
  forex: [
    {
      no: 1,
      coin: "EURUSD",
      coinURL: EURUSD,
      lastPrice: "---",
      change: "1.31%",
      up: true,
    },
    {
      no: 2,
      coin: "GBPUSD",
      coinURL: GBPUSD,
      lastPrice: "---",
      change: "2.22%",
      up: true,
    },
    {
      no: 3,
      coin: "EURGBP",
      coinURL: bnb,
      lastPrice: "---",
      change: "0.82%",
      up: true,
    },
    {
      no: 4,
      coin: "USDJPY",
      coinURL: usdt,
      lastPrice: "---",
      change: "-0.03%",
      up: true,
    },
    {
      no: 5,
      coin: "USDCAD",
      coinURL: sol,
      lastPrice: "---",
      change: "-0.53%",
      up: false,
    },
    {
      no: 6,
      coin: "USDCHF",
      coinURL: sve,
      lastPrice: "---",
      change: "-0.44%",
      up: false,
    },
    {
      no: 7,
      coin: "AUDUSD",
      coinURL: usdc,
      lastPrice: "---",
      change: "-0.03%",
      up: false,
    },
  ],
  indices: [
    {
      no: 1,
      coin: "S&P 500",
      coinURL: btc,
      lastPrice: "---",
      change: "1.41%",
      up: true,
    },
    {
      no: 2,
      coin: "US30",
      coinURL: btc,
      lastPrice: "---",
      change: "2.22%",
      up: true,
    },
    {
      no: 3,
      coin: "Nas100",
      coinURL: btc,
      lastPrice: "---",
      change: "-0.82%",
      up: true,
    },
    {
      no: 4,
      coin: "FTSE 100",
      coinURL: usdt2,
      lastPrice: "---",
      change: "-0.03%",
      up: true,
    },
    {
      no: 5,
      coin: "DAX 40",
      coinURL: sol2,
      lastPrice: "---",
      change: "-0.53%",
      up: false,
    },
    {
      no: 6,
      coin: "Nikkie 225",
      coinURL: sve2,
      lastPrice: "---",
      change: "-0.44%",
      up: false,
    },
  ],
  stocks: [
    {
      no: 1,
      coin: "NVIDIA Corporation ",
      coinURL: nvda,
      lastPrice: "---",
      change: "1.41%",
      up: true,
    },
    {
      no: 2,
      coin: "Apple Inc.",
      coinURL: apple,
      lastPrice: "---",
      change: "2.22%",
      up: true,
    },
    {
      no: 3,
      coin: "Microsoft Corporation",
      coinURL: msft,
      lastPrice: "---",
      change: "-0.82%",
      up: true,
    },
    {
      no: 4,
      coin: "Tesla, Inc.",
      coinURL: tesla,
      lastPrice: "---",
      change: "-0.03%",
      up: true,
    },
    {
      no: 5,
      coin: "Meta Platforms, Inc.",
      coinURL: meta,
      lastPrice: "---",
      change: "-0.53%",
      up: false,
    },
    {
      no: 6,
      coin: "Amazon.com, Inc.",
      coinURL: amazon,
      lastPrice: "---",
      change: "-0.44%",
      up: false,
    },
    {
      no: 7,
      coin: "MicroStrategy , Incorporated",
      coinURL: mstr,
      lastPrice: "---",
      change: "-0.44%",
      up: false,
    },
  ],
  yields: [
    {
      no: 1,
      coin: "US03MY",
      coinURL: yeild,
      lastPrice: "---",
      change: "1.41%",
      up: true,
    },
    {
      no: 2,
      coin: "US01Y",
      coinURL: yeild,
      lastPrice: "---",
      change: "1.41%",
      up: true,
    },
    {
      no: 3,
      coin: "US02Y",
      coinURL: yeild,
      lastPrice: "---",
      change: "2.22%",
      up: true,
    },
    {
      no: 4,
      coin: "US05Y",
      coinURL: yeild,
      lastPrice: "---",
      change: "-0.03%",
      up: true,
    },
    {
      no: 5,
      coin: "US10Y",
      coinURL: yeild,
      lastPrice: "---",
      change: "-0.53%",
      up: false,
    },
    {
      no: 6,
      coin: "US30Y",
      coinURL: yeild,
      lastPrice: "---",
      change: "-0.44%",
      up: false,
    },
  ],
  commodities: [
    {
      no: 1,
      coin: "Gold",
      coinURL: gold,
      lastPrice: "---",
      change: "1.41%",
      up: true,
    },
    {
      no: 2,
      coin: "Silver",
      coinURL: silver,
      lastPrice: "---",
      change: "2.22%",
      up: true,
    },
    {
      no: 3,
      coin: "Platinum",
      coinURL: plat,
      lastPrice: "---",
      change: "-0.03%",
      up: true,
    },
    {
      no: 4,
      coin: "Oil",
      coinURL: oil,
      lastPrice: "---",
      change: "-0.53%",
      up: true,
    },
    {
      no: 5,
      coin: "Wheat",
      coinURL: wheat,
      lastPrice: "---",
      change: "-0.44%",
      up: false,
    },
  ],
};

export const FAQ1 = [
  {
    q: "Is this real or simulated money ?",
    a: (
      <p>
        All current trading is done under a <b>simulated</b> environment. Passve is in the process
        of introducing a hybrid model.
      </p>
    ),
  },
  {
    q: "What are the prohibited trading practices ?",
    a: "Passve welcomes all traders from all countries",
  },
  {
    q: "How do I request my payout?",
    a: `In your dashboard on our website, you can see "Payouts" in the menu. By filling in this form you can request your payout. If you fill in this form the connected trading account will be switched to view-only mode until the payout is processed, therefore all trades have to be closed. The minimum amount for a payout is 1% of the initial balance including our split.`,
  },
  {
    q: "What is the commission per Lot?",
    a: `You can trade Forex, Crypto, Indices, Metals & Energies with RAW spreads and a 2$ commission per standard lot round turn. Crypto, Indices and Oil are commission free.`,
  },
  {
    q: "When does the evaluation process begin?",
    a: `As soon as your first trade is executed that’s when the evaluation process commences. `,
  },
  {
    q: "Is hedging allowed?",
    a: (
      <p>
        Hedging is allowed in all our programs as long as it is executed within the same account.
        However, oppositeaccount hedging is not allowed. <br />
        <br /> Allowed: Hedging <br /> Buying US30 in one account (Account 1) and selling US30 in
        the same account. (Account 1) <br />
        <br /> Not Allowed: Opposite Account Hedging <br /> Buying US30 in one account (Account 1)
        and selling US30 in another account. (Account 2)
      </p>
    ),
  },
  {
    q: "Am I being A or B booked?",
    a: `Currently Passve is working with a B booking business model, however Passve is in the process of introducing a hybrid model.`,
  },
];

export const FAQ2 = [
  {
    q: "Why is my payment getting declined?",
    a: "Ensure funds are available and try a different card or alternatively use our crypto payment option.",
  },
  {
    q: `Common rejection reasons for verification documents ?`,
    a: "Non-matching verification data to account / Expired Documents / Blurred (distorted) documentation evidence.",
  },
  {
    q: `How many trading positions can I take?`,
    a: "As many positions as your account margin allows.",
  },
  {
    q: `Can I cancel my order and get a refund?`,
    a: "In the event that NO trade has been executed a user may submit a refund request. A 14 day cooling off period is provided upon every plan from the date of purchase.",
  },
  {
    q: `How do I increase my limit?`,
    a: "The lot size limit per position is 50 lots. However, you can open multiple positions of 50 lots as much as your account margin allows.",
  },
  {
    q: `Is their a consistency rule ?`,
    a: "At Passve, we provide traders with the freedom to choose their trading styles and strategies without any restrictions. Termination will occur if no trade is placed within 30 days, as the account will be labeled as inactive.",
  },
];

export const supplyAPY = [
  {
    coin: "USDC",
    coinURL: "https://assets.coincap.io/assets/icons/usdc@2x.png",
    APY: "19.8%",
    wallet: "0",
    up: true,
  },
  {
    coin: "USDT",
    coinURL: "https://assets.coincap.io/assets/icons/usdt@2x.png",
    APY: "20.2%",
    wallet: "0",
    up: true,
  },
  {
    coin: "DAI",
    coinURL: "https://assets.coincap.io/assets/icons/dai@2x.png",
    APY: "21.5%",
    wallet: "0",
    up: true,
  },
  {
    coin: "SVE",
    coinURL: `${process.env.REACT_APP_API_URL}/upload/passve.svg`,
    APY: "63%",
    wallet: "0",
    up: true,
  },
  {
    coin: "WBTC",
    coinURL: "https://assets.coincap.io/assets/icons/wbtc@2x.png",
    APY: "5.08%",
    wallet: "0 ",
    up: true,
  },
  {
    coin: "ETH",
    coinURL: "https://assets.coincap.io/assets/icons/eth@2x.png",
    APY: "6.82%",
    wallet: "0",
    up: true,
  },
  {
    coin: "LINK",
    coinURL: "https://assets.coincap.io/assets/icons/link@2x.png",
    APY: "6.32%",
    wallet: "0",
    up: true,
  },
  {
    coin: "COMP",
    coinURL: "https://assets.coincap.io/assets/icons/comp@2x.png",
    APY: "11.17%",
    wallet: "0 ",
    up: true,
  },
  {
    coin: "UNI",
    coinURL: "https://assets.coincap.io/assets/icons/uni@2x.png",
    APY: "7.89%",
    wallet: "0 ",
    up: true,
  },
  {
    coin: "FDUSD",
    coinURL: "https://cryptologos.cc/logos/first-digital-usd-fdusd-logo.png",
    APY: "16.02%",
    wallet: "0 ",
    up: true,
  },
  {
    coin: "ADA",
    coinURL: "https://assets.coincap.io/assets/icons/ada@2x.png",
    APY: "5.1%",
    wallet: "0 ",
    up: true,
  },
  {
    coin: "wstETH",
    coinURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/12409.png",
    APY: "5.59%",
    wallet: "0 ",
    up: true,
  },
  {
    coin: "SOL",
    coinURL: "https://assets.coincap.io/assets/icons/sol@2x.png",
    APY: "29.22%",
    wallet: "0",
    up: true,
  },
];

export const borrowAPY = [
  {
    coin: "USDC",
    coinURL: "https://assets.coincap.io/assets/icons/usdc@2x.png",
    APY: "19.8%",
    wallet: "0",
    up: true,
    liquidity: "$0.1",
  },
  {
    coin: "USDT",
    coinURL: "https://assets.coincap.io/assets/icons/usdt@2x.png",
    APY: "20.2%",
    wallet: "0",
    up: false,
    liquidity: "$1.1",
  },
  {
    coin: "DAI",
    coinURL: "https://assets.coincap.io/assets/icons/dai@2x.png",
    APY: "3.12%",
    wallet: "0",
    up: false,
    liquidity: "$915.87K",
  },
  {
    coin: "SVE",
    coinURL: `${process.env.REACT_APP_API_URL}/upload/passve.svg`,
    APY: "19.8%",
    wallet: "0 ",
    liquidity: "$12",
  },
  {
    coin: "WBTC",
    coinURL: "https://assets.coincap.io/assets/icons/wbtc@2x.png",
    APY: "5.08%",
    wallet: "0 ",
    up: true,
    liquidity: "$0.1",
  },
  {
    coin: "ETH",
    coinURL: "https://assets.coincap.io/assets/icons/eth@2x.png",
    APY: "6.82%",
    wallet: "0",
    up: true,
    liquidity: "$0.1",
  },
  {
    coin: "LINK",
    coinURL: "https://assets.coincap.io/assets/icons/link@2x.png",
    APY: "6.32%",
    wallet: "0",
    up: false,
    liquidity: "$0.1",
  },
  {
    coin: "COMP",
    coinURL: "https://assets.coincap.io/assets/icons/comp@2x.png",
    APY: "11.17%",
    wallet: "0 ",
    up: true,
    liquidity: "$0.1",
  },
  {
    coin: "UNI",
    coinURL: "https://assets.coincap.io/assets/icons/uni@2x.png",
    APY: "7.89%",
    wallet: "0 ",
    up: true,
    liquidity: "$0.1",
  },
  {
    coin: "FDUSD",
    coinURL: "https://cryptologos.cc/logos/first-digital-usd-fdusd-logo.png",
    APY: "16.02%",
    wallet: "0 ",
    up: true,
    liquidity: "$0.1",
  },
  {
    coin: "ADA",
    coinURL: "https://assets.coincap.io/assets/icons/ada@2x.png",
    APY: "5.1%",
    wallet: "0 ",
    up: true,
    liquidity: "$0.1",
  },
  {
    coin: "wstETH",
    coinURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/12409.png",
    APY: "5.59%",
    wallet: "0 ",
    up: true,
    liquidity: "$0.1",
  },
  {
    coin: "SOL",
    coinURL: "https://assets.coincap.io/assets/icons/sol@2x.png",
    APY: "29.22%",
    wallet: "0",
    up: true,
    liquidity: "$0.1",
  },
];
