import React, { useCallback, useEffect, useMemo, useState } from "react";
import DashboardLayout from "../../../layout/Layout";
import Input from "../../../common/Input";
import { useHistory, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Api from "../../../api/api";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Select from "../../../common/Select";
import { countries } from "../../../utils/countries";
import PhoneInput from "react-phone-input-2";
import Button from "../../../common/Button";
import { ROLES } from "../../../utils/constants";

const initialData = {
  fname: "",
  lname: "",
  email: "",
  password: "",
  confirmPassword: "",
  address: "",
  phone: "",
  country: "",
  role: ROLES.USER,
  isVerified: false,
  isActivated: false,
};

const AdminUserAdd = () => {
  const { id } = useParams();
  const isNew = useMemo(() => id === "_new", [id]);

  const history = useHistory();
  const [isDisable, setIsDisable] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ ...initialData });

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (data.password && data.password !== data.confirmPassword) {
      return toast.error("Password doesn't match");
    }

    if (!data.phone) {
      return toast.error("Phone number is required");
    }

    setIsDisable(true);
    try {
      let { confirmPassword, ..._data } = data;
      const res = isNew
        ? await Api.signup({ ..._data, isNew: true })
        : await Api.updateUser(id, { ..._data });

      if (res.status === 200) {
        history.replace("/admin/dashboard/users");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  const getUser = useCallback(async () => {
    const res = await Api.getUser(id);
    if (res.status === 200) {
      setData(res.data.data);
    }
  }, [setData]);

  useEffect(() => {
    if (id && !isNew) {
      getUser();
    }
  }, [isNew, id]);

  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);

  return (
    <DashboardLayout title={`${isNew ? "Add" : "Edit"} User`}>
      <form onSubmit={handleSubmit}>
        <div className="grid gap-x-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <Input
            title="First Name"
            name="fname"
            value={data.fname}
            onChange={handleChange}
            placeholder="First Name"
            required
          />
          <Input
            title="Last Name"
            name="lname"
            value={data.lname}
            onChange={handleChange}
            placeholder="Last Name"
            required
          />
          <Input
            title="email"
            type="email"
            name="email"
            value={data.email}
            onChange={handleChange}
            placeholder="youremail@example.com"
            required
          />

          <Input
            title="Address"
            name="address"
            value={data.address}
            onChange={handleChange}
            placeholder="Address"
            required
          />

          <Select
            title="Country"
            name="country"
            value={data.country}
            onChange={handleChange}
            options={countries}
            required
          />
          <Select
            title="Role"
            name="role"
            value={data.role}
            onChange={handleChange}
            required
            options={[
              { value: ROLES.ADMIN, label: "Admin" },
              { value: ROLES.USER, label: "User" },
            ]}
          />

          <div className="mt-4">
            <label className="capitalize font-semibold mb-1 block text-sm">Phone Number</label>
            <PhoneInput
              country={data?.country?.toLowerCase()}
              className="bg-[#f9fafb] border border-[#ddd] rounded-md overflow-hidden text-sm w-full [&>.form-control]:!h-[38px] [&>.form-control]:!w-full [&>.form-control]:!bg-[#f9fafb] [&>.flag-dropdown]:!border-none [&>.flag-dropdown]:!bg-[#f9fafb] [&_.selected-flag]:hover:!bg-[#f9fafb] [&>.form-control]:!border-none [&>.form-control]:!rounded-md [&>.form-control]:!pl-10 [&>.flag-dropdown]:!rounded-md"
              onChange={e => setData(prev => ({ ...prev, phone: e }))}
              value={data?.phone}
              required
            />
          </div>
        </div>

        <div className="grid gap-x-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <Input
            title="password"
            name="password"
            value={data.password}
            onChange={handleChange}
            type={show ? "text" : "password"}
            placeholder="Password"
          >
            <span
              className="absolute cursor-pointer w-fit right-4 top-[32px]"
              onClick={() => setShow(prev => !prev)}
            >
              {!show ? (
                <BsEyeFill
                  fill="var(--primary)"
                  size={22}
                />
              ) : (
                <BsFillEyeSlashFill
                  fill="var(--primary)"
                  size={22}
                />
              )}
            </span>
          </Input>

          <Input
            title="Confirm Password"
            name="confirmPassword"
            value={data.confirmPassword}
            onChange={handleChange}
            type={show ? "text" : "password"}
            placeholder="Confirm Password"
          >
            <span
              className="absolute cursor-pointer w-fit right-4 top-[32px]"
              onClick={() => setShow(prev => !prev)}
            >
              {!show ? (
                <BsEyeFill
                  fill="var(--primary)"
                  size={22}
                />
              ) : (
                <BsFillEyeSlashFill
                  fill="var(--primary)"
                  size={22}
                />
              )}
            </span>
          </Input>
        </div>

        <div className="mt-4 grid gap-4 grid-cols-2 lg:grid-cols-3">
          <div className="flex items-center gap-3">
            <input
              id="verified"
              type="checkbox"
              checked={data?.isVerified}
              onClick={() => setData(prev => ({ ...prev, isVerified: !prev.isVerified }))}
              className="size-4 cursor-pointer"
            />
            <label
              htmlFor="verified"
              className="capitalize font-semibold text-sm cursor-pointer"
            >
              Is verified?
            </label>
          </div>
          <div className="flex items-center gap-3">
            <input
              id="activated"
              type="checkbox"
              checked={data?.isActivated}
              onClick={() => setData(prev => ({ ...prev, isActivated: !prev.isActivated }))}
              className="size-4 cursor-pointer"
            />
            <label
              htmlFor="activated"
              className="capitalize font-semibold text-sm cursor-pointer"
            >
              Is Activated?
            </label>
          </div>
        </div>
        <br />

        <Button
          type="submit"
          disabled={isDisable}
        >
          Submit
        </Button>
      </form>
    </DashboardLayout>
  );
};

export default AdminUserAdd;
