import React, { useEffect, useState } from "react";
import Auth from "../../layout/Auth";
import Button from "../../common/Button";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Input from "../../common/Input";
import { Link } from "react-router-dom";
import Api from "../../api/api";
import { ROLES } from "../../utils/constants";

const Login = () => {
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({ email: "", password: "" });
  const [show, setShow] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const submitHandle = async e => {
    e.preventDefault();
    setIsDisable(true);

    try {
      const res = await Api.login(data);
      if (res.status === 200) {
        if ([ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(res.data.data.role)) {
          window.location.replace("/admin/dashboard");
        } else {
          window.location.replace("/dashboard");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ email: "", password: "" });
      setIsDisable(false);
    };
  }, []);

  return (
    <Auth
      title="Sign in to your account"
      submitHandle={submitHandle}
    >
      <Input
        title="email"
        type="email"
        name="email"
        value={data.email}
        onChange={handleChange}
        placeholder="youremail@example.com"
        required
      />

      <Input
        title="password"
        name="password"
        value={data.password}
        onChange={handleChange}
        type={show ? "text" : "password"}
        placeholder="Password"
        required
      >
        <span
          className="absolute cursor-pointer w-fit right-4 top-[32px]"
          onClick={() => setShow(prev => !prev)}
        >
          {!show ? (
            <BsEyeFill
              fill="var(--primary)"
              size={22}
            />
          ) : (
            <BsFillEyeSlashFill
              fill="var(--primary)"
              size={22}
            />
          )}
        </span>
      </Input>

      <div className="flex items-center justify-between my-6">
        <div className="flex items-center gap-4">
          <input
            id="remember"
            type="checkbox"
            className="size-4"
          />
          <label
            htmlFor="remember"
            className="opacity-80 font-semibold"
          >
            Remember me
          </label>
        </div>
        <Link
          to="/auth/forgot"
          className="font-semibold"
        >
          Forgot password?
        </Link>
      </div>

      <Button
        className="w-full"
        type="submit"
        disabled={isDisable}
      >
        Sign in
      </Button>

      <Link
        to="/auth/signup"
        className="mt-4 block"
      >
        <Button
          className="w-full text-main !bg-gray"
          disabled={isDisable}
        >
          Create Account
        </Button>
      </Link>
    </Auth>
  );
};

export default Login;
