import React from "react";

const Button = ({ children, className = "", ...rest }) => {
  return (
    <button
      type="button"
      className={`rounded-[10px] bg-primary text-[#ffffff] text-[16px] text-center px-6 py-2 font-semibold ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
