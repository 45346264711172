import React from "react";
import { AiOutlineExport } from "react-icons/ai";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { FAQ1, FAQ2 } from "../../utils/data";
import DashboardLayout from "../../layout/Layout";

const FAQS2 = () => {
  return (
    <DashboardLayout title="FAQs">
      <div className="my-7">
        <p className="text-xl font-semibold mb-4">Commonly Asked Question</p>
        <div className="w-full flex justify-center max-md:flex-wrap gap-16">
          <div className="w-full md:max-w-[370px] flex flex-col min-h-full justify-between">
            <div>
              <Accordion allowZeroExpanded>
                {FAQ1.map((item, idx) => (
                  <AccordionItem key={`faq1-${idx + 1}`}>
                    <AccordionItemHeading>
                      <AccordionItemButton>{item.q}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{item.a}</AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
            {/*<p className="border-[2px] border-[#ddd] rounded-lg font-semibold mt-8 w-full py-8 text-center">*/}
            {/*  Customer Service Portal*/}
            {/*</p>*/}
          </div>
          <div className="w-full md:max-w-[370px] flex flex-col min-h-full justify-between">
            <div>
              <Accordion allowZeroExpanded>
                {FAQ2.map((item, idx) => (
                  <AccordionItem key={`faq2-${idx + 1}`}>
                    <AccordionItemHeading>
                      <AccordionItemButton>{item.q}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{item.a}</AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
            {/*<p className="border-[2px] border-[#ddd] rounded-lg font-semibold mt-8 w-full py-8 text-center">*/}
            {/*  B2B Service Portal*/}
            {/*</p>*/}
          </div>
        </div>
      </div>
      <a
        href="/faqs"
        target="_blank"
        className="cursor-pointer py-2 px-4 text-lg font-semibold w-fit flex items-center justify-center mx-auto border rounded-lg"
      >
        <span>View More Questions </span>
        <AiOutlineExport size={24} />
      </a>
    </DashboardLayout>
  );
};

export default FAQS2;
