import React, { useEffect, useMemo, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useHistory, useLocation } from "react-router-dom";
import Table from "./Table";
import { data as _data } from "../../utils/data";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MarketUpdates = ({ API_URL }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const filters = ["Crypto", "Forex", "Stocks", "Indices", "Commodities", "Yields"];
  const query = new URLSearchParams(location.search).get("query");
  const [activeFilter, setActiveFilter] = useState(query ?? "Crypto");
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState({ ..._data });

  const filterData = useMemo(() => {
    return data[activeFilter?.toLowerCase()]?.filter(itm =>
      itm.coin?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
    );
  }, [searchTerm, activeFilter, data]);

  const onActive = itm => {
    if (itm === activeFilter) return "bg-primary2 text-white font-semibold";
    return "bg-gray";
  };

  const onActiveClick = itm => {
    setActiveFilter(itm);
    history.push(`/market?query=${itm}`);
  };

  const fetchPricesSequentially = async () => {
    for (let i = 0; i < data.crypto.length; i++) {
      const coinData = data.crypto[i];
      if (coinData.coinAbbr === "SVE") continue;

      try {
        const res = await axios.get(`${API_URL}?fsym=${coinData.coinAbbr}&tsyms=USD`);
        if (res.status === 200 && res.data?.USD) {
          const price = res.data.USD;
          setData(prev => ({
            ...prev,
            crypto: prev.crypto.map(item =>
              item.coinAbbr === coinData.coinAbbr ? { ...item, lastPrice: `$${price}` } : item,
            ),
          }));
        }
      } catch (error) {
        console.error(`Error fetching price for ${coinData.coinAbbr}:`, error);
      }
    }
  };

  useEffect(() => {
    if (activeFilter !== "Crypto") return;
    fetchPricesSequentially();
    const interval = setInterval(() => {
      if (activeFilter === "Crypto") {
        fetchPricesSequentially();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [activeFilter]);

  return (
    <div className="py-12 md:py-16">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <h2 className="text-2xl md:text-3xl font-semibold mb-2">{t("market_update")}</h2>
        <p className="opacity-80 font-medium mb-4">{t("market_categories")}</p>
        <div className="mb-4 flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center gap-2 flex-wrap">
            {filters.map((itm, idx) => (
              <button
                key={idx}
                onClick={() => onActiveClick(itm)}
                className={`${onActive(itm)} rounded-lg px-3 py-2 font-medium text-sm`}
              >
                {itm}
              </button>
            ))}
          </div>
          <div className="bg-gray rounded-md px-3 py-2 text-base relative">
            <CiSearch
              size={22}
              fill="#000"
              className="absolute left-2"
            />
            <input
              type="text"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              placeholder={t("search_coin")}
              className="w-full border-none h-full bg-transparent pl-6"
            />
          </div>
        </div>
        <Table data={filterData} />
      </div>
    </div>
  );
};

export default MarketUpdates;
