import { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import DashboardLayout from "../../../layout/Layout";
import { FaEdit, FaTrash } from "react-icons/fa";
import Table, { Td } from "../../../common/Table";
import Pagination from "../../../common/Pagination";
import Api from "../../../api/api";
import Loader from "../../../common/Loader";
import Input from "../../../common/Input";
import { countries } from "../../../utils/countries";
import Button from "../../../common/Button";
import { ROLES_MAP } from "../../../utils/constants";
import { jsonToQueryParams } from "../../../helpers/functions";

const columns = [
  "First Name",
  "Last Name",
  "Email",
  "Phone",
  "City",
  "Country",
  "Zip Code",
  "Role",
  "Verified",
  "Activated",
  "Actions",
];

const AdminUsers = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const page = parseInt(params.get("page") || 1);
  const limit = parseInt(params.get("limit") || 10);

  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const country = c =>
    countries.find(val => val.value?.toLowerCase() === c?.toLowerCase())?.label || "";

  const handleSearch = event => {
    const { value } = event.target;
    setQuery(value);
  };

  /* eslint-disable no-restricted-globals */
  const handleDeleteUser = async id => {
    if (!id) return;
    const isOK = confirm("Are you sure you want to delete this record?");
    if (isOK) {
      setUsers(prev => prev.filter(user => user._id !== id));
      setTotalUsers(prev => (prev > 0 ? prev - 1 : prev));
      await Api.deleteUser(id);
    }
  };

  const getUsers = useCallback(
    async ({ search, page, limit }) => {
      try {
        const res = await Api.getAllUsers({ search, page, limit });
        if (res?.status === 200) {
          setUsers(res.data.data?.users);
          setTotalPages(res.data.data?.totalPages);
          setTotalUsers(res.data.data?.totalUsers);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [setUsers],
  );

  useEffect(() => {
    const _query = jsonToQueryParams({ page: 1, limit, search: query || "" });
    history.push(`${location.pathname}?${_query}`);
  }, [query]);

  useEffect(() => {
    getUsers({ search: query, page, limit });
  }, [location.pathname, location.search]);

  return (
    <>
      <DashboardLayout title={`Users (${totalUsers})`}>
        <div className="flex justify-between items-end mb-4">
          <Input
            value={query}
            onChange={handleSearch}
            placeholder="Search users..."
          />
          <Button onClick={() => history.push("/admin/dashboard/user/_new")}>Add User</Button>
        </div>
        <Table columns={columns}>
          {!isLoading &&
            users &&
            users?.map(itm => (
              <tr>
                <Td>{itm?.fname}</Td>
                <Td>{itm?.lname}</Td>
                <Td>{itm?.email}</Td>
                <Td>{itm?.phone}</Td>
                <Td>{itm?.city}</Td>
                <Td>{country(itm?.country) || ""}</Td>
                <Td>{itm?.zipcode}</Td>
                <Td>{ROLES_MAP[itm?.role]}</Td>
                <Td>
                  <p
                    className={`px-3 py-[3px] w-fit font-bold text-center ${itm?.isVerified ? "bg-[#1dff1d40] text-[green]" : "bg-[#ff1d1d40] text-[#e90a0a]"}  rounded-md`}
                  >
                    {itm?.isVerified ? "Yes" : "No"}
                  </p>
                </Td>
                <Td>
                  <p
                    className={`px-3 py-[3px] w-fit font-bold text-center ${itm?.isActivated ? "bg-[#1dff1d40] text-[green]" : "bg-[#ff1d1d40] text-[#e90a0a]"}  rounded-md`}
                  >
                    {itm?.isActivated ? "Yes" : "No"}
                  </p>
                </Td>
                <Td>
                  <div className="flex items-center gap-3">
                    <Link to={`/admin/dashboard/user/${itm?._id}`}>
                      <FaEdit
                        size={18}
                        className="cursor-pointer fill-blue-500"
                      />
                    </Link>
                    <FaTrash
                      size={18}
                      className="cursor-pointer fill-red-500"
                      onClick={() => handleDeleteUser(itm?._id)}
                    />
                  </div>
                </Td>
              </tr>
            ))}
        </Table>

        <br />
        {isLoading ? (
          <div className="grid place-items-center">
            <Loader />
          </div>
        ) : (
          <Pagination totalPages={totalPages} />
        )}
      </DashboardLayout>
    </>
  );
};

export default AdminUsers;
