import { useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../layout/Layout";
import {
  acceleratedBody,
  acceleratedHead,
  evaluationBody,
  evaluationHead,
} from "../../utils/plans";

const Plans = () => {
  const [activeTab, setActiveTab] = useState("Accelerated");
  return (
    <DashboardLayout title="Broadcast">
      <br />
      <div className="flex items-center justify-center bg-gray rounded-md p-1 font-semibold text-sm w-fit mx-auto">
        <div
          onClick={() => setActiveTab("Accelerated")}
          className={`rounded-md py-2 px-4 transition-all cursor-pointer ${activeTab === "Accelerated" ? "bg-white  text-primary" : ""}`}
        >
          Accelerated
        </div>
        <div
          onClick={() => setActiveTab("Evaluation")}
          className={`rounded-md py-2 px-4 transition-all cursor-pointer ${activeTab === "Evaluation" ? "bg-white text-primary" : ""}`}
        >
          Evaluation
        </div>
      </div>
      <br />
      <div className="px-2 py-4 border border-[#ddd] rounded-md overflow-x-scroll">
        <table className="w-full min-w-[700px]">
          <thead>
            <tr>
              <th className="flex justify-center">
                <img
                  src={`${process.env.REACT_APP_API_URL}/upload/passve.svg`}
                  alt="passve"
                />
              </th>
              {(activeTab === "Accelerated" ? acceleratedHead : evaluationHead).map((itm, idx) => (
                <th
                  className="text-center p-2"
                  key={idx}
                >
                  ${itm?.capital}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-sm font-semibold">
            {(activeTab === "Accelerated" ? acceleratedBody : evaluationBody)?.map((itm, idx) => (
              <tr
                key={idx}
                className="[&>td]:text-center"
              >
                <td className="font-bold py-3">{itm.head}</td>
                <td colSpan={itm?.span || 1}>{itm.text1}</td>
                {!itm?.span && (
                  <>
                    <td>{itm.text2}</td>
                    <td>{itm.text3}</td>
                    <td>{itm.text4}</td>
                    <td>{itm.text5}</td>
                    {itm?.text6 && (
                      <>
                        <td>{itm?.text6}</td>
                        <td>{itm?.text7}</td>
                        <td>{itm?.text8}</td>
                      </>
                    )}
                  </>
                )}
              </tr>
            ))}
            <tr>
              <td></td>
              {Array(activeTab === "Accelerated" ? 5 : 8)
                .fill()
                .map((_, idx) => (
                  <td key={idx}>
                    <Link
                      to="/checkout"
                      onClick={() => {
                        const plan = (
                          activeTab === "Accelerated" ? acceleratedHead : evaluationHead
                        )[idx]?.capital;
                        sessionStorage.setItem("passve-plan", btoa(plan));
                        sessionStorage.setItem("passve-type", btoa(activeTab));
                      }}
                      className="block mx-auto w-fit px-3 py-2 bg-primary2 text-white rounded-[50px] hover:underline"
                    >
                      Select Plan
                    </Link>
                  </td>
                ))}
            </tr>
          </tbody>
        </table>
      </div>
    </DashboardLayout>
  );
};

export default Plans;
