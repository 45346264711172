import Header from "../common/Header";
import Footer from "../common/Footer";
import FundingHero from "../components/funding/Hero";
import FullProfit from "../components/funding/Profit";
import Featured from "../components/home/Featured";
import PayoutSystem from "../components/funding/Payout";
import WhyChoose from "../components/funding/Choose";
import News from "../components/funding/News";
import Subscribe from "../components/funding/Subscribe";
import Objectives from "../components/funding/Objectives";

const Funding = () => {
  return (
    <>
      <Header />
      <main>
        <FundingHero />
        <FullProfit />
        <Featured />
        <Objectives />
        <PayoutSystem />
        <WhyChoose />
        <News />
        <Subscribe />
      </main>
      <Footer />
    </>
  );
};

export default Funding;
