import { FaTrophy } from "react-icons/fa6";
import DashboardLayout from "../../layout/Layout";
import avatar from "../../assets/avatar.png";
import { BsGraphUpArrow, BsThreeDotsVertical } from "react-icons/bs";
import Table, { Td } from "../../common/Table";
import Pagination from "../../common/Pagination";

const columns = ["Rank", "Name", "Return Rate", "Profit", ""];

const Leaderboard = () => {
  const data = [
    {
      name: "George",
      rate: "30.3%",
      profit: "$36900",
    },
    {
      name: "Mercy.K",
      rate: "26.8%",
      profit: "$23400",
    },
    {
      name: "Loza.J",
      rate: "21.4%",
      profit: "$21854",
    },
    {
      name: "Kyrylo.P",
      rate: "17.8%",
      profit: "$18400",
    },
    {
      name: "William.P",
      rate: "13.5%",
      profit: "$16452",
    },
    {
      name: "Kev.W",
      rate: "11.7%",
      profit: "$13253",
    },
    {
      name: "Miguel.R",
      rate: "7.4%",
      profit: "$8278",
    },
    {
      name: "Juan.S",
      rate: "5.1%",
      profit: "$3231",
    },
  ];
  return (
    <DashboardLayout title="Leaderboard">
      <div className="flex items-center gap-3 flex-wrap mb-7">
        <FaTrophy
          size={32}
          fill="#ffd241"
        />
        <p className="text-2xl font-bold italic">Highest Performing Traders</p>
      </div>

      <Table columns={columns}>
        {data?.map((itm, idx) => (
          <tr>
            <Td className="flex items-center gap-3">
              <span>{idx + 1}</span>
              <img
                src={avatar}
                alt="avatar"
                className="rounded-full size-8"
              />
            </Td>
            <Td>{itm.name}</Td>
            <Td className="flex items-center gap-2">
              <BsGraphUpArrow fill="#1EED58" />
              <span>{itm.rate}</span>
            </Td>
            <Td>{itm.profit}</Td>
            <Td>
              <BsThreeDotsVertical className="cursor-pointer" />
            </Td>
          </tr>
        ))}
      </Table>
      <br />
      <Pagination />
    </DashboardLayout>
  );
};

export default Leaderboard;
