import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { jsonToQueryParams } from "../../helpers/functions";
import Input from "../../common/Input";
import Table, { Td } from "../../common/Table";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import Api from "../../api/api";
import CustomModal from "../../layout/Modal";
import toast from "react-hot-toast";
import Select from "../../common/Select";
import Button from "../../common/Button";
import { countries } from "../../utils/countries";

const columns = [
  "Payout Id",
  "Withdraw Option",
  "Submission",
  "Amount",
  "User",
  "Email",
  "Status",
  "Action",
];

const PayoutTable = ({ isAdmin = false }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [editStatus, setEditStatus] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const page = parseInt(params.get("page") || 1);
  const limit = parseInt(params.get("limit") || 10);

  const [query, setQuery] = useState("");
  const [payouts, setPayouts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const handleSearch = event => {
    const { value } = event.target;
    setQuery(value);
  };

  const handleDelete = async id => {
    if (!id) return;
    // eslint-disable-next-line no-restricted-globals
    const isOK = confirm("Are you sure you want to delete this record?");
    if (isOK) {
      try {
        let res = await Api.deletePayout(id);
        if (res.status === 200) {
          toast.success("Payout deleted successfully");
          // eslint-disable-next-line no-restricted-globals
          await getPayouts({ search: query, page, limit });
        }
      } catch (e) {}
    }
  };

  const getPayouts = useCallback(
    async ({ search, page, limit, status }) => {
      try {
        const res = isAdmin
          ? await Api.adminGetPayouts(search, page, limit, status)
          : await Api.payoutByUserId(search, page, limit, status);
        if (res?.status === 200) {
          setPayouts(res.data.data?.orders);
          setTotalPages(res.data.data?.totalPages);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [setPayouts],
  );

  useEffect(() => {
    const _query = jsonToQueryParams({ page: 1, limit, search: query || "" });
    history.push(`${location.pathname}?${_query}`);
  }, [query]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    getPayouts({ search: query, page, limit });
    setIsLoading(false);
  }, [location.pathname, location.search]);

  async function handleUpdate(e) {
    e.preventDefault();
    try {
      const res = await Api.updatepayoutStatus(modalData?._id, { status: editStatus });
      if (res?.status === 200) {
        setOpenEditModal(false);
        // eslint-disable-next-line no-restricted-globals
        await getPayouts({ search: query, page, limit });
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <>
      <div className="my-4 w-fit">
        <Input
          value={query}
          onChange={handleSearch}
          placeholder="Search Payouts..."
        />
      </div>
      <Table columns={columns}>
        {!isLoading &&
          payouts &&
          payouts?.map(itm => (
            <tr>
              <Td>{itm?.payoutId}</Td>
              <Td>{itm?.method}</Td>
              <Td>${itm?.createdAt ? new Date(itm?.createdAt)?.toLocaleString() : ""}</Td>
              <Td>${itm?.amount || 0}</Td>
              <Td>{itm?.userId?.fname + " " + itm?.userId?.lname}</Td>
              <Td>{itm?.userId?.email}</Td>
              {itm?.status === "pending" && (
                <Td className="font-[700] text-[blue]">{itm?.status}</Td>
              )}
              {itm?.status === "approve" && (
                <Td className="font-[700] text-[orange]">{itm?.status}</Td>
              )}
              {itm?.status === "complete" && (
                <Td className="font-[700] text-[green]">{itm?.status}</Td>
              )}
              {itm?.status === "rejected" && (
                <Td className="font-[700] text-[red]">{itm?.status}</Td>
              )}
              <Td>
                <div className="flex items-center gap-3">
                  {isAdmin && (
                    <>
                      <FaEdit
                        size={18}
                        className="cursor-pointer fill-blue-500"
                        onClick={() => {
                          setModalData(itm);
                          setOpenEditModal(true);
                        }}
                      />
                      <FaTrash
                        size={18}
                        className="cursor-pointer fill-red-500"
                        onClick={() => handleDelete(itm?._id)}
                      />
                    </>
                  )}
                  <FaEye
                    onClick={() => {
                      setModalData(itm);
                      setOpenModal(true);
                    }}
                    size={18}
                    className="cursor-pointer"
                  />
                </div>
              </Td>
            </tr>
          ))}
      </Table>

      <br />
      {isLoading ? (
        <div className="grid place-items-center">
          <Loader />
        </div>
      ) : (
        <Pagination totalPages={totalPages} />
      )}

      {openModal && (
        <CustomModal
          title="Payout Detail"
          onHide={() => setOpenModal(false)}
        >
          <table>
            <tr>
              <td className="w-[120px]">
                <h4>User: </h4>
              </td>
              <td>
                <h4>{modalData?.userId?.fname + " " + modalData?.userId?.lname}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Email: </h4>
              </td>
              <td>
                <h4>{modalData?.userId?.email}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>PayoutId: </h4>
              </td>
              <td>
                <h4>{modalData?.payoutId}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Amount: </h4>
              </td>
              <td>
                <h4>{modalData?.amount}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Method: </h4>
              </td>
              <td>
                <h4>{modalData?.method}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Status: </h4>
              </td>
              <td>
                <h4>{modalData?.status}</h4>
              </td>
            </tr>
            {modalData?.method === "CRYPTO" ? (
              <>
                <tr>
                  <td className="w-[120px]">
                    <h4>Crypto Name: </h4>
                  </td>
                  <td>
                    <h4>{modalData?.cryptoName}</h4>
                  </td>
                </tr>
                <tr>
                  <td className="w-[120px]">
                    <h4>Network: </h4>
                  </td>
                  <td>
                    <h4>{modalData?.network}</h4>
                  </td>
                </tr>
                <tr>
                  <td className="w-[120px]">
                    <h4>Address: </h4>
                  </td>
                  <td>
                    <h4>{modalData?.address}</h4>
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <td className="w-[200px]">
                    <h4>Account Name: </h4>
                  </td>
                  <td>
                    <h4>{modalData?.accountName}</h4>
                  </td>
                </tr>
                <tr>
                  <td className="w-[200px]">
                    <h4>SwiftCode/BIC: </h4>
                  </td>
                  <td>
                    <h4>{modalData?.swiftCode_BIC}</h4>
                  </td>
                </tr>
                <tr>
                  <td className="w-[200px]">
                    <h4>IBAN/ Acc Number: </h4>
                  </td>
                  <td>
                    <h4>{modalData?.iban}</h4>
                  </td>
                </tr>
                <tr>
                  <td className="w-[200px]">
                    <h4>Bank Name: </h4>
                  </td>
                  <td>
                    <h4>{modalData?.bankName}</h4>
                  </td>
                </tr>
                <tr>
                  <td className="w-[200px]">
                    <h4>Branch Address: </h4>
                  </td>
                  <td>
                    <h4>{modalData?.branchAddress}</h4>
                  </td>
                </tr>
                <tr>
                  <td className="w-[200px]">
                    <h4>Bank Telephone: </h4>
                  </td>
                  <td>
                    <h4>{modalData?.bankTelephone}</h4>
                  </td>
                </tr>
                <tr>
                  <td className="w-[200px]">
                    <h4>Country: </h4>
                  </td>
                  <td>
                    <h4>{countries.find(c => c.value === modalData?.country).label}</h4>
                  </td>
                </tr>
                <tr>
                  <td className="w-[200px]">
                    <h4>BSB / ABA routing / (Sort/Branch/Transit) code: </h4>
                  </td>
                  <td>
                    <h4>{modalData?.branchCode}</h4>
                  </td>
                </tr>
              </>
            )}
          </table>
        </CustomModal>
      )}
      {openEditModal && (
        <CustomModal
          title="Edit Payout"
          onHide={() => setOpenEditModal(false)}
        >
          <form onSubmit={handleUpdate}>
            <Select
              title="Status"
              value={editStatus}
              onChange={e => setEditStatus(e.target.value)}
              options={[
                { value: "approve", label: "approve" },
                { value: "complete", label: "complete" },
                { value: "rejected", label: "rejected" },
              ]}
              required
            />
            <Button
              type="submit"
              className="mt-4 !w-full"
            >
              Submit
            </Button>
          </form>
        </CustomModal>
      )}
    </>
  );
};

export default PayoutTable;
