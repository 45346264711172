import { useCallback, useEffect, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import DashboardLayout from "../../layout/Layout";
import Table, { Td } from "../../common/Table";
import Api from "../../api/api";
import Loader from "../../common/Loader";
import Input from "../../common/Input";
import Button from "../../common/Button";
import CustomModal from "../../layout/Modal";
import toast from "react-hot-toast";

const columns = ["Platform", "Disabled", "Actions"];

const Platforms = () => {
  const [platforms, setPlatforms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNew, setIsNew] = useState(true);
  const [input, setInput] = useState({ name: "" });
  const [showModal, setShowModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDisablePlatform = async id => {
    if (!id) return;

    setPlatforms(prev =>
      prev.map(itm => (itm._id === id ? { ...itm, isDisabled: !itm?.isDisabled } : itm)),
    );
    await Api.updatePlatformStatus(id);
  };

  /* eslint-disable no-restricted-globals */
  const handleDeletePlatform = async id => {
    if (!id) return;
    const isOK = confirm("Are you sure you want to delete this record?");
    if (isOK) {
      setPlatforms(prev => prev.filter(i => i._id !== id));
      await Api.deletePlatform(id);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setIsDisabled(true);
      const res = isNew
        ? await Api.addPlatform({ ...input })
        : await Api.updatePlatform(input._id, { ...input });
      if (res.status === 200) {
        toast.success(isNew ? "Added successfully" : "Updated successfully");
        getPlatforms();
        setInput({ name: "" });
        setIsNew(true);
        setShowModal(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisabled(false);
    }
  };

  const getPlatforms = useCallback(async () => {
    try {
      const res = await Api.getPlatforms("");
      if (res?.status === 200) {
        setPlatforms(res.data.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [setPlatforms]);

  useEffect(() => {
    getPlatforms();
  }, []);

  return (
    <>
      <DashboardLayout title={`Platforms`}>
        <div className="flex justify-end mb-4">
          <Button
            onClick={() => {
              setInput({ name: "" });
              setIsNew(true);
              setShowModal(true);
            }}
          >
            Add
          </Button>
        </div>
        <Table columns={columns}>
          {!isLoading &&
            platforms &&
            platforms?.map(itm => (
              <tr>
                <Td>{itm?.name}</Td>
                <Td>
                  <input
                    type="checkbox"
                    checked={itm?.isDisabled}
                    onClick={() => handleDisablePlatform(itm._id)}
                  />
                </Td>

                <Td>
                  <div className="flex items-center gap-3">
                    <FaEdit
                      size={18}
                      className="cursor-pointer fill-blue-500"
                      onClick={() => {
                        setInput({ _id: itm._id, name: itm?.name });
                        setIsNew(false);
                        setShowModal(true);
                      }}
                    />
                    <FaTrash
                      size={18}
                      className="cursor-pointer fill-red-500"
                      onClick={() => handleDeletePlatform(itm?._id)}
                    />
                  </div>
                </Td>
              </tr>
            ))}
        </Table>

        <br />
        {isLoading && (
          <div className="grid place-items-center">
            <Loader />
          </div>
        )}
      </DashboardLayout>

      {showModal && (
        <CustomModal
          title={`${isNew ? "Add" : "Edit"} Platform`}
          onHide={() => setShowModal(false)}
        >
          <form onSubmit={handleSubmit}>
            <Input
              title="Platform"
              value={input.name}
              onChange={e => setInput(prev => ({ ...prev, name: e.target.value }))}
              required
            />
            <Button
              type="submit"
              disabled={isDisabled}
              className="mt-4 !w-full"
            >
              Submit
            </Button>
          </form>
        </CustomModal>
      )}
    </>
  );
};

export default Platforms;
