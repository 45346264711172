import { Redirect, Route } from "react-router-dom";
import Cookies from "js-cookie";
import { REACT_APP_KEY_NAME } from "./utils/constants";
import { ROLES } from "./utils/constants";
import { jwtDecode } from "jwt-decode";

export default function Protected({ component: Cmp, ...rest }) {
  const token = Cookies.get(REACT_APP_KEY_NAME);
  const decode_token = token ? jwtDecode(token) : {};

  return (
    <Route
      {...rest}
      render={props =>
        Cookies.get(REACT_APP_KEY_NAME) !== undefined &&
        localStorage.getItem(REACT_APP_KEY_NAME) ? (
          [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(decode_token?.role) ? (
            <Cmp {...props} />
          ) : (
            <Redirect to="/dashboard" />
          )
        ) : (
          <>
            {Cookies.remove(REACT_APP_KEY_NAME)}
            <Redirect to="/auth/signin" />
          </>
        )
      }
    />
  );
}
