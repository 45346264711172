import "react-phone-input-2/lib/style.css";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import Auth from "../../layout/Auth";
import Button from "../../common/Button";
import Input from "../../common/Input";
import Select from "../../common/Select";
import { countries } from "../../utils/countries";
import Api from "../../api/api";
import toast from "react-hot-toast";

const initialData = {
  name: "name",
  fname: "",
  lname: "",
  email: "",
  password: "",
  confirmPassword: "",
  address: "",
  zipcode: "",
  city: "",
  phone: "",
  country: "",
};

const Signup = () => {
  const history = useHistory();
  const [isDisable, setIsDisable] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ ...initialData });

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const submitHandle = async e => {
    e.preventDefault();

    if (data.password !== data.confirmPassword) {
      return toast.error("Password doesn't match");
    }

    if (!data.phone) {
      return toast.error("Phone number is required");
    }

    setIsDisable(true);
    try {
      let { confirmPassword, ..._data } = data;
      const res = await Api.signup({ ..._data, isNew: false });
      if (res.status === 200) {
        history.replace("/auth/signin");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);

  return (
    <Auth
      title="Create an account"
      submitHandle={submitHandle}
    >
      <div className="flex items-center justify-between flex-wrap">
        <div className="w-full md:w-[48%]">
          <Input
            title="First Name"
            name="fname"
            value={data.fname}
            onChange={handleChange}
            placeholder="First Name"
            required
          />
        </div>
        <div className="w-full md:w-[48%]">
          <Input
            title="Last Name"
            name="lname"
            value={data.lname}
            onChange={handleChange}
            placeholder="Last Name"
            required
          />
        </div>
      </div>
      <Input
        title="email"
        type="email"
        name="email"
        value={data.email}
        onChange={handleChange}
        placeholder="youremail@example.com"
        required
      />

      <Input
        title="password"
        name="password"
        value={data.password}
        onChange={handleChange}
        type={show ? "text" : "password"}
        placeholder="Password"
        required
      >
        <span
          className="absolute cursor-pointer w-fit right-4 top-[32px]"
          onClick={() => setShow(prev => !prev)}
        >
          {!show ? (
            <BsEyeFill
              fill="var(--primary)"
              size={22}
            />
          ) : (
            <BsFillEyeSlashFill
              fill="var(--primary)"
              size={22}
            />
          )}
        </span>
      </Input>

      <Input
        title="Confirm Password"
        name="confirmPassword"
        value={data.confirmPassword}
        onChange={handleChange}
        type={show ? "text" : "password"}
        placeholder="Confirm Password"
      >
        <span
          className="absolute cursor-pointer w-fit right-4 top-[32px]"
          onClick={() => setShow(prev => !prev)}
        >
          {!show ? (
            <BsEyeFill
              fill="var(--primary)"
              size={22}
            />
          ) : (
            <BsFillEyeSlashFill
              fill="var(--primary)"
              size={22}
            />
          )}
        </span>
      </Input>

      <Input
        title="Address"
        name="address"
        value={data.address}
        onChange={handleChange}
        placeholder="Address"
        required
      />

      <div className="flex items-center justify-between flex-wrap">
        <div className="w-full md:w-[48%]">
          <Input
            title="City"
            name="city"
            value={data.city}
            onChange={handleChange}
            placeholder="City"
            required
          />
        </div>
        <div className="w-full md:w-[48%]">
          <Input
            title="Postal/Zip Code"
            type="text"
            name="zipcode"
            value={data.zipcode}
            onChange={handleChange}
            placeholder="Postal/Zip Code"
            required
          />
        </div>
      </div>

      <div className="flex items-center justify-between flex-wrap">
        <div className="w-full md:w-[48%]">
          <Select
            title="Country"
            name="country"
            value={data.country}
            onChange={handleChange}
            options={countries}
            required
          />
        </div>
        <div className="w-full md:w-[48%]">
          <div className="mt-4">
            <label className="capitalize font-semibold mb-1 block text-sm">Phone Number</label>
            <PhoneInput
              country={data?.country?.toLowerCase()}
              className="bg-[#f9fafb] border border-[#ddd] rounded-md overflow-hidden text-sm w-full [&>.form-control]:!h-[38px] [&>.form-control]:!w-full [&>.form-control]:!bg-[#f9fafb] [&>.flag-dropdown]:!border-none [&>.flag-dropdown]:!bg-[#f9fafb] [&_.selected-flag]:hover:!bg-[#f9fafb] [&>.form-control]:!border-none [&>.form-control]:!rounded-md [&>.form-control]:!pl-10 [&>.flag-dropdown]:!rounded-md"
              onChange={e => setData(prev => ({ ...prev, phone: e }))}
              value={data?.phone}
              required
            />
          </div>
        </div>
      </div>

      <div className="flex items-start gap-4 my-5">
        <input
          id="remember"
          type="checkbox"
          required
          className="size-4"
        />
        <label
          htmlFor="remember"
          className="opacity-80 font-medium text-sm"
        >
          I certify that I am 18 of age or older, agree to{" "}
          <a
            href="#"
            className="font-semibold underline"
          >
            User Agreement
          </a>
          , and acknowledge the{" "}
          <a
            href="#"
            className="font-semibold underline"
          >
            Privacy Policy
          </a>
        </label>
      </div>

      <Button
        className="w-full"
        type="submit"
        disabled={isDisable}
      >
        Create Account
      </Button>

      <p className="mt-5 text-center opacity-80 font-medium">
        Already have an account?{" "}
        <Link
          to="/auth/signin"
          className="hover:text-primary"
        >
          Login Here
        </Link>
      </p>
    </Auth>
  );
};

export default Signup;
