import React from "react";
import Header from "../common/Header";
import APYStats from "../components/apy/Stats";
import APYTables from "../components/apy/Tables";

import { FaInstagram, FaDiscord, FaGithub, FaTelegram, FaChartPie } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";
import { IoGrid } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const social = [
  { icon: FaInstagram, link: "https://medium.com/@passve" },
  { icon: FaSquareXTwitter, link: "https://x.com/Passve_Exchange" },
  { icon: FaDiscord, link: "https://discord.com/invite/nsGKMuQfUe" },
  { icon: FaGithub, link: "https://github.com/Passve/Passve.git " },
  { icon: FaTelegram, link: "https://t.me/passve_exchange" },
];

const APY = () => {
  return (
    <div className="bg-[#04060a]">
      <div className="bg-white">
        <Header />
      </div>
      <main>
        <div className="bg-[#101118] py-2">
          <EarnTabs />
        </div>
        <APYStats />
        <APYTables />
      </main>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export const EarnTabs = () => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center bg-[#04060a] rounded-md p-2 font-semibold w-fit mx-auto">
      <Link
        to={"/apy"}
        className={`flex items-center gap-1 rounded-md py-2 px-4 transition-all cursor-pointer ${location.pathname === "/apy" ? "bg-white  text-primary" : " text-[#5d6388]"}`}
      >
        <IoGrid
          size={20}
          fill={location.pathname === "/apy" ? "#016fd0" : "#5d6388"}
        />
        {t("dashboard")}
      </Link>
      <Link
        to={"/staking"}
        className={`flex items-center gap-1 rounded-md py-2 px-4 transition-all cursor-pointer ${location.pathname === "/staking" ? "bg-white text-primary" : "text-[#5d6388]"}`}
      >
        <FaChartPie
          size={20}
          fill={location.pathname === "/staking" ? "#016fd0" : "#5d6388"}
        />
        {t("staking")}
      </Link>
    </div>
  );
};

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-[#101118] fixed left-0 right-0 bottom-0 z-20">
      <div className="w-full px-4 md:px-6 py-4 max-w-[1367px] mx-auto">
        <div className="flex items-center justify-end gap-10">
          <div className="flex text-white items-center gap-1 !text-sm">
            <p className="size-3 rounded-full bg-green-500"></p> {t("latest_block")}:
            <b className="text-white text-lg pl-[2px]">19249321</b>
          </div>
          <div className="flex items-center gap-4">
            {social?.map((itm, idx) => (
              <a
                href={itm?.link}
                key={idx}
                target="_blank"
              >
                <itm.icon
                  fill="#fff"
                  size={20}
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};
export default APY;
