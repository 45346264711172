import React from "react";
import Button from "../../common/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FundingHero = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 md:py-16 bg-primary2">
      <div className="mx-auto max-w-[1280px] px-6 w-full flex flex-wrap items-center">
        <div className="md:w-1/2 md:px-2 max-md:mb-8">
          <p
            className="md:text-lg text-white font-medium italic"
            style={{ letterSpacing: 3 }}
          >
            {t("markets_awaits_you")}
          </p>
          <h1 className="text-white font-bold text-3xl md:text-6xl mt-3 mb-5 leading-[1.2]">
            {t("f_funding_capital")}
          </h1>
          <p
            className="text-white max-md:text-sm italic mb-7"
            style={{ letterSpacing: 3 }}
          >
            {t("f_funding_capital_desc")}
          </p>
          <Link to="/checkout">
            <Button>{t("get_started")}</Button>
          </Link>
        </div>
        <div className="md:w-1/2 md:px-2">
          <img
            src={`${process.env.REACT_APP_API_URL}/upload/funding.svg`}
            alt="Markets Awaits You"
          />
        </div>
      </div>
    </div>
  );
};

export default FundingHero;
