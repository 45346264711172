import React from "react";
import toast from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BiSolidCopy } from "react-icons/bi";
import Button from "../../common/Button";
import { useTranslation } from "react-i18next";

const Refer = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 md:py-16 border-b border-b-[#f3f3f3]">
      <div className="mx-auto text-center max-w-[1280px] px-6 w-full">
        <h2 className="text-2xl md:text-4xl font-semibold mb-5">Refer a Friend</h2>
        <p className="md:text-lg font-semibold opacity-60">{t("refer_friend_desc")}</p>
        <div className="flex items-center justify-center flex-wrap gap-3 mt-4 mb-2 w-full">
          <p className="max-sm:w-[90%] border border-dashed border-primary bg-[#016fd014] text-primary max-sm:text-sm font-[600] px-4 py-2 md:px-6 md:py-3 rounded-[10px] text-ellipsis overflow-hidden">
            https://www.passve.com/
          </p>
          <CopyToClipboard
            text="https://www.passve.com/"
            onCopy={() => toast.success(t("link_copied"))}
          >
            <Button className="max-sm:w-[90%] flex justify-center items-center gap-x-2">
              <BiSolidCopy
                fill="white"
                size={20}
              />
              <span className="text-[#ffffff]">{t("copy")}</span>
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default Refer;
