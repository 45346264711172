import React from "react";
import { Link } from "react-router-dom";
import Button from "../../common/Button";
import { FaStar } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 md:py-16 text-center bg-primary2">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <h2 className="text-2xl md:text-4xl font-bold mb-6 text-white">{t("h_hero_title")}</h2>
        <p className="font-medium mb-8 mx-auto max-w-[600px] text-white">{t("h_hero_subtitle")}</p>
        <Link to="/portal">
          <Button className="mx-auto">{t("get_started")}</Button>
        </Link>
        <div className="flex items-center gap-3 mt-4 justify-center text-[13px]">
          <span className="font-semibold text-white">Excellent</span>
          <p className="text-white">
            <span className="font-semibold text-white">4.9</span> out of 5
          </p>
          <p className="flex items-center gap-1">
            <FaStar fill="#219653" />
            <span className="font-semibold text-white">Trustpilot</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
