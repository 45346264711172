import axios from "axios";
import Cookies from "js-cookie";
import { REACT_APP_KEY_NAME } from "../utils/constants";
import toast from "react-hot-toast";
import { jwtDecode } from "jwt-decode";
import { jsonToQueryParams } from "../helpers/functions";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const getJwt = () => {
  if (Cookies.get(REACT_APP_KEY_NAME) !== undefined && localStorage.getItem(REACT_APP_KEY_NAME)) {
    return Cookies.get(REACT_APP_KEY_NAME);
  } else {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
  }
};

const setJwt = key => {
  Cookies.set(REACT_APP_KEY_NAME, key, {
    expires: 1,
    path: "/",
    secure: false,
  });
  localStorage.setItem(REACT_APP_KEY_NAME, key);
};

const setAuthHeader = token => {
  if (!token) return;
  axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
  setJwt(token);
};

if (getJwt()) {
  setAuthHeader(getJwt());
}

export default class Api {
  //login
  static login = async data => {
    try {
      const res = await axios.post(`/api/v1/auth/login`, data);
      setAuthHeader(res.data?.token);
      toast.success(res?.data?.message);
      return res;
    } catch (error) {
      localStorage.removeItem(REACT_APP_KEY_NAME);
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //signup
  static signup = async data => {
    try {
      const res = await axios.post(`/api/v1/auth/signup`, data);
      toast.success(res?.data?.message);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //get me profile
  static getMeProfile = async () => {
    try {
      const res = await axios.get(`/api/v1/auth/me`);
      const token = Cookies.get(REACT_APP_KEY_NAME);
      const decode_token = token ? jwtDecode(token) : {};
      if (decode_token.role !== jwtDecode(res.data?.token).role) {
        Cookies.remove(REACT_APP_KEY_NAME);
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.replace("/auth/signin");
      }
      return res;
    } catch (error) {
      Cookies.remove(REACT_APP_KEY_NAME);
      localStorage.removeItem(REACT_APP_KEY_NAME);
      toast.error(error.response.data?.message);
      window.location.replace("/auth/signin");
      return error.response.data;
    }
  };

  //forgot password
  static forgotPassword = async data => {
    try {
      const res = await axios.post(`/api/v1/auth/password/forgot`, data);
      toast.success(res?.data?.message);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //reset password
  static resetPassword = async data => {
    try {
      const res = await axios.post(`/api/v1/auth/password/reset`, data);
      toast.success(res?.data?.message);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //change password
  static changePassword = async data => {
    try {
      const res = await axios.put(`/api/v1/auth/password/change`, data);
      toast.success(res?.data?.message);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //verify account
  static verifyAccount = async token => {
    try {
      const res = await axios.post(`/api/v1/auth/email/verify/${token}`);
      toast.success(res?.data?.message);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //get all users
  static getAllUsers = async query => {
    try {
      const res = await axios.get(`/api/v1/users/all?${jsonToQueryParams(query)}`);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //update my prfoile
  static updateMyProfile = async data => {
    try {
      const res = await axios.put(`/api/v1/user`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //get user
  static getUser = async id => {
    try {
      const res = await axios.get(`/api/v1/user/${id}`);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //update user
  static updateUser = async (id, data) => {
    try {
      const res = await axios.put(`/api/v1/user/${id}`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //delete user
  static deleteUser = async id => {
    try {
      const res = await axios.delete(`/api/v1/user/${id}`);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //get platform
  static getPlatforms = async isDisabled => {
    try {
      const res = await axios.get(`/api/v1/platform/all?isDisabled=${isDisabled}`);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //add platform
  static addPlatform = async data => {
    try {
      const res = await axios.post(`/api/v1/platform/add/`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //delete platform
  static deletePlatform = async id => {
    try {
      const res = await axios.delete(`/api/v1/platform/delete/${id}`);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //update platform
  static updatePlatform = async (id, data) => {
    try {
      const res = await axios.put(`/api/v1/platform/update/${id}`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //update platform status
  static updatePlatformStatus = async id => {
    try {
      const res = await axios.put(`/api/v1/platform/update/status/${id}`);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //get orderextra
  static getOrderExtra = async isDisabled => {
    try {
      const res = await axios.get(`/api/v1/orderextra/all?isDisabled=${isDisabled}`);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //add platform
  static addOrderExtra = async data => {
    try {
      const res = await axios.post(`/api/v1/orderextra/add/`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //delete platform
  static deleteOrderExtra = async id => {
    try {
      const res = await axios.delete(`/api/v1/orderextra/delete/${id}`);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //update platform
  static updateOrderExtra = async (id, data) => {
    try {
      const res = await axios.put(`/api/v1/orderextra/update/${id}`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //update order extra status
  static updateOrderExtraStatus = async id => {
    try {
      const res = await axios.put(`/api/v1/orderextra/update/status/${id}`);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //place order
  static placeOrder = async data => {
    try {
      const res = await axios.post(`/api/v1/order/add/`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //get order by user id
  static userGetAllOrders = async (search, page, limit, status) => {
    try {
      const res = await axios.get(
        `/api/v1/order/user?page=${page}&limit=${limit}&search=${search}&status=${status}`,
      );
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //admin all order
  static adminGetAllOrders = async (search, page, limit, status) => {
    try {
      const res = await axios.get(
        `/api/v1/order/admin?page=${page}&limit=${limit}&search=${search}&status=${status}`,
      );
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //delete order
  static deleteOrder = async id => {
    try {
      const res = await axios.delete(`/api/v1/order/delete/${id}`);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //get order by id
  static getOrderById = async id => {
    try {
      const res = await axios.get(`/api/v1/order/id/${id}`);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //update order
  static updateOrder = async (id, data) => {
    try {
      const res = await axios.put(`/api/v1/order/update/${id}`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //add transaction
  static addTransaction = async data => {
    try {
      const res = await axios.post(`/api/v1/transaction/add/`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  // submit ticket
  static submitTicket = async data => {
    try {
      const res = await axios.post(`/api/v1/support/generate`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  // admin get all tickets
  static getAllTickets = async (search, page, limit) => {
    try {
      const res = await axios.get(
        `/api/v1/support/all?page=${page}&limit=${limit}&search=${search}`,
      );
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  // user tickets
  static getUserTickets = async (search, page, limit) => {
    try {
      const res = await axios.get(
        `/api/v1/support/user/id/?page=${page}&limit=${limit}&search=${search}`,
      );
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //update ticket status
  static updateTicketStatus = async (id, data) => {
    try {
      const res = await axios.put(`/api/v1/support/update/${id}`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  // stripe checkout session
  static stripeCheckout = async data => {
    try {
      const res = await axios.post(`/api/v1/stripe/checkout-session`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  // add payout
  static addPayout = async data => {
    try {
      const res = await axios.post(`/api/v1/payout/add`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //get payout by userID
  static payoutByUserId = async (search, page, limit, status = "") => {
    try {
      const res = await axios.get(
        `/api/v1/payout/user/?page=${page}&limit=${limit}&search=${search}&status=${status}`,
      );
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //get all payout for admin
  static adminGetPayouts = async (search, page, limit, status = "") => {
    try {
      const res = await axios.get(
        `/api/v1/payout/all/?page=${page}&limit=${limit}&search=${search}&status=${status}`,
      );
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //delete payout
  static deletePayout = async id => {
    try {
      const res = await axios.delete(`/api/v1/payout/delete/${id}`);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };

  //update payout status
  static updatepayoutStatus = async (id, data) => {
    try {
      const res = await axios.put(`/api/v1/payout/update/${id}`, data);
      return res;
    } catch (error) {
      toast.error(error.response.data?.message);
      return error.response.data;
    }
  };
}
