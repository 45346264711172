import Button from "../../common/Button";
import DashboardLayout from "../../layout/Layout";
import PayoutTable from "../../components/payout/Table";
import { useState } from "react";
import Api from "../../api/api";
import Input from "../../common/Input";
import Select from "../../common/Select";
import { countries } from "../../utils/countries";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const METHODS = {
  CRYPTO: "CRYPTO",
  BANK: "BANK",
};

const initialData = {
  amount: 0,
  method: METHODS.CRYPTO,
  status: "pending",
  // for crypto
  cryptoName: "",
  network: "",
  address: "",
  // for bank
  accountName: "",
  branchCode: "",
  swiftCode_BIC: "",
  iban: "",
  accountNumber: "",
  bankName: "",
  branchAddress: "",
  bankTelephone: "",
  country: "",
};
const PayoutRequest = () => {
  const history = useHistory();

  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({ ...initialData });

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    let customData = null;

    if (data.method === METHODS.CRYPTO) {
      customData = {
        payoutId: uuidv4(),
        amount: data.amount,
        method: data.method,
        status: data.status,
        cryptoName: data.cryptoName,
        network: data.network,
        address: data.address,
      };
    } else {
      customData = {
        payoutId: uuidv4(),
        amount: data.amount,
        method: data.method,
        status: data.status,
        accountName: data.accountName,
        branchCode: data.branchCode,
        swiftCode_BIC: data.swiftCode_BIC,
        iban: data.iban,
        accountNumber: data.accountNumber,
        bankName: data.bankName,
        branchAddress: data.branchAddress,
        bankTelephone: data.bankTelephone,
        country: data.country,
      };
    }

    setIsDisable(true);
    try {
      const res = await Api.addPayout(customData);
      if (res.status === 200) {
        setData({ ...initialData });
        history.push("/dashboard/payout");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  return (
    <DashboardLayout title="Payouts">
      <form onSubmit={handleSubmit}>
        <div className="w-fit">
          <Input
            title="Payout Request Amount"
            name="amount"
            type="number"
            value={data.amount}
            onChange={handleChange}
            required
          />
        </div>

        <div className="my-5">
          <p className="capitalize font-semibold mb-2 block text-sm">Withdraw Options </p>
          <div className="flex items-center gap-4 md:gap-8 flex-wrap">
            <div className="flex items-center gap-2">
              <input
                type="radio"
                name="method"
                id="method1"
                value={METHODS.CRYPTO}
                onChange={handleChange}
                checked={data.method === METHODS.CRYPTO}
              />
              <label
                htmlFor="method1"
                className="capitalize font-semibold mb-1 block text-sm"
              >
                Crypto
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="radio"
                name="method"
                id="method2"
                value={METHODS.BANK}
                onChange={handleChange}
                checked={data.method === METHODS.BANK}
              />
              <label
                htmlFor="method2"
                className="capitalize font-semibold mb-1 block text-sm"
              >
                Bank Transfer
              </label>
            </div>
          </div>
        </div>

        {data.method === METHODS.CRYPTO ? (
          <div className="grid gap-x-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            <Input
              title="Cryptocurrency"
              name="cryptoName"
              value={data.cryptoName}
              onChange={handleChange}
              required
            />
            <Input
              title="Network"
              name="network"
              value={data.network}
              onChange={handleChange}
              required
            />
            <Input
              title="Withdrawal Address "
              name="address"
              value={data.address}
              onChange={handleChange}
              required
            />
          </div>
        ) : (
          <div className="grid gap-x-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            <Input
              title="Full Account Name"
              name="accountName"
              value={data.accountName}
              onChange={handleChange}
              required
            />
            <Input
              title="Swift code / BIC"
              name="swiftCode_BIC"
              value={data.swiftCode_BIC}
              onChange={handleChange}
              required
            />
            <Input
              title="Account number / IBAN"
              name="iban"
              value={data.iban}
              onChange={handleChange}
              required
            />
            <Input
              title="Bank Name"
              name="bankName"
              value={data.bankName}
              onChange={handleChange}
              required
            />
            <Input
              title="Branch Address"
              name="branchAddress"
              value={data.branchAddress}
              onChange={handleChange}
              required
            />
            <Input
              title="Bank Telephone"
              name="bankTelephone"
              value={data.bankTelephone}
              onChange={handleChange}
              required
            />
            <Input
              title="BSB / ABA routing / (Sort/Branch/Transit) code"
              name="branchCode"
              value={data.branchCode}
              onChange={handleChange}
              required
            />
            <Select
              title="Country"
              name="country"
              value={data.country}
              onChange={handleChange}
              options={countries}
              required
            />
          </div>
        )}

        <Button
          type="submit"
          className={"!rounded-[50px] cursor-pointer my-5"}
        >
          Submit Request
        </Button>
      </form>
      {/*<PayoutTable />*/}
    </DashboardLayout>
  );
};

export default PayoutRequest;
