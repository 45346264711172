import React from "react";
import { GoDotFill } from "react-icons/go";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { IoKeySharp } from "react-icons/io5";
import { FaShareAlt } from "react-icons/fa";

const OverviewCards = () => {
  return (
    <div className="flex flex-wrap gap-4">
      <div className="max-md:w-full min-h-full">
        <div className="w-full f-full md:max-w-xs bg-white border border-[#EFF0F6] p-4 rounded-[20px]">
          <div className="flex justify-between items-center mb-4">
            <p className="text-sm font-[500] text-[#4D4D4D]">Account Status</p>
            <p className="text-[9.7px] font-bold">190458</p>
          </div>
          <h2 className="text-[32px] font-extrabold my-4 text-center">In Progress</h2>
          <p className="font-bold text-sm my-2 text-center">Start Date: 26 July 2024</p>
          <p className="font-medium text-xs text-[#4D4D4D] text-center mb-6">
            $300k Phase Evaluation 1 - Passve
          </p>
          <div className="flex justify-center items-center gap-3">
            <button aria-label="Previous">
              <SlArrowLeft />
            </button>
            <GoDotFill fill="#60B7FF" />
            <button aria-label="Next">
              <SlArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className="w-full sm:flex-1 min-h-full">
        <div className="h-full text-sm w-full bg-white border border-[#EFF0F6] p-4 rounded-[20px]">
          <div className="h-full p-6 sm:p-10 flex justify-between gap-4 flex-col">
            <p className="flex justify-between items-center">
              <span className="font-bold">Starting Balance</span>
              <span className="font-medium">$300,000</span>
            </p>
            <p className="flex justify-between items-center">
              <span className="font-bold">Current Performance</span>
              <span className="font-medium">0.0%</span>
            </p>
            <p className="flex justify-between items-center">
              <span className="font-bold">Remaining Days </span>
              <span className="font-medium">Unlimited</span>
            </p>
          </div>
        </div>
      </div>
      <div className="max-md:w-full">
        <div className="mb-3 w-full sm:w-[130px] h-[100px] bg-white rounded-xl border border-[#eff0f6] flex-col flex p-3">
          <span className="text-[rgba(0,0,0,0.7)] text-sm font-[500]">Share</span>
          <FaShareAlt
            style={{ fontSize: "32px" }}
            className="block mx-auto w-fit mt-2"
          />
        </div>
        <div className="w-full sm:w-[130px] h-[100px] bg-white rounded-xl border border-[#eff0f6] flex-col flex p-3">
          <span className="text-[rgba(0,0,0,0.7)] text-sm font-[500]">View Account</span>
          <span className="font-[400]  text-sm">Credentials</span>
          <IoKeySharp
            style={{ fontSize: "32px" }}
            className="block mx-auto w-fit"
          />
        </div>
      </div>
    </div>
  );
};

export default OverviewCards;
