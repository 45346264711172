import { useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { REACT_APP_KEY_NAME } from "../utils/constants";
import { FaKey, FaUser } from "react-icons/fa6";
import { TbLogout2 } from "react-icons/tb";

const Usermenu = ({ name = "", email = "", isAdmin = false, showUserMenuRef, setShowUserMenu }) => {
  const logoutHandler = () => {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
    window.location.reload();
  };

  useEffect(() => {
    let handler = e => {
      if (!showUserMenuRef.current.contains(e.target)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, []);

  return (
    <div
      ref={showUserMenuRef}
      className="absolute right-0 bg-white w-[240px] rounded-md z-10"
      style={{ boxShadow: "0 0 50px 0 rgba(82, 63, 105, 0.15)" }}
    >
      <div className="px-4 p-3 border-b border-[#eff2f5] font-medium">
        <p className="capitalize">{name}</p>
        <span className="opacity-80 text-sm">{email}</span>
      </div>
      <div className="p-2">
        <div>
          <Link
            to={`${isAdmin ? "/admin" : ""}/dashboard/profile`}
            className="w-full flex items-center gap-3 rounded-lg p-3 cursor-pointer transition-all hover:bg-[#1B59F81A]"
          >
            <FaUser
              size={20}
              fill={"#000000B2"}
            />
            <span className={`text-sm text-[#000000B2] font-semibold`}>Profile</span>
          </Link>
          <Link
            to={`${isAdmin ? "/admin" : ""}/dashboard/password`}
            className="w-full flex items-center gap-3 rounded-lg p-3 cursor-pointer transition-all hover:bg-[#1B59F81A]"
          >
            <FaKey
              size={20}
              fill={"#000000B2"}
            />
            <span className={`text-sm text-[#000000B2] font-semibold`}>Password</span>
          </Link>
          <div
            onClick={logoutHandler}
            className="w-full flex items-center gap-3 rounded-lg p-3 cursor-pointer transition-all hover:bg-[#1B59F81A]"
          >
            <TbLogout2
              size={22}
              stroke={"#000000B2"}
            />
            <span className={`text-sm text-[#000000B2] font-semibold`}>Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usermenu;
