import React from "react";

const Input = ({ title = "", children, ...rest }) => {
  return (
    <div className="relative mt-4">
      {title && <label className="capitalize font-semibold mb-1 block text-sm">{title}</label>}
      <input
        className="w-full bg-[#f9fafb] border border-[#ddd] rounded-md px-4 py-2 text-sm disabled:opacity-70"
        {...rest}
      />
      {children}
    </div>
  );
};

export default Input;
