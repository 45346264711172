export const acceleratedHead = [
  { capital: 5000, amount: 89 },
  { capital: 10000, amount: 189 },
  { capital: 30000, amount: 399 },
  { capital: 50000, amount: 799 },
  { capital: 100000, amount: 1400 },
];

export const acceleratedBody = [
  {
    head: "Target",
    span: 5,
    text1: "10% Targets – You get paid 80% of the profits you earn in your account",
  },
  {
    head: "Trading Days",
    span: 5,
    text1: "Unlimited",
  },
  {
    head: "Min Trading Days",
    span: 5,
    text1: "5 Days",
  },
  {
    head: "Leverage",
    span: 5,
    text1: "1:100",
  },
  {
    head: "Daily Drawdown",
    text1: "$250",
    text2: "$500",
    text3: "$1500",
    text4: "$2500",
    text5: "$5000",
  },
  {
    head: "Max Drawdown",
    text1: "$500",
    text2: "$1000",
    text3: "$3000",
    text4: "$6000",
    text5: "$11000",
  },
  {
    head: "Refundable fee",
    text1: "$89",
    text2: "$189",
    text3: "$399",
    text4: "$799",
    text5: "$1400",
  },
];

export const evaluationHead = [
  { capital: 5000, amount: 55 },
  { capital: 10000, amount: 114 },
  { capital: 30000, amount: 189 },
  { capital: 50000, amount: 280 },
  { capital: 100000, amount: 399 },
  { capital: 200000, amount: 899 },
  { capital: 300000, amount: 1299 },
  { capital: 400000, amount: 1699 },
];

export const evaluationBody = [
  {
    head: "Target",
    span: 8,
    text1: `Phase I = 7.5%.\n\nPhase II = 5%`,
  },
  {
    head: "Trading Days",
    span: 8,
    text1: "Unlimited",
  },
  {
    head: "Min Trading Days",
    span: 8,
    text1: "5 Days",
  },
  {
    head: "Leverage",
    span: 8,
    text1: "1:500",
  },
  {
    head: "Daily Drawdown",
    text1: "$250",
    text2: "$750",
    text3: "$1500",
    text4: "$2500",
    text5: "$5000",
    text6: "$10000",
    text7: "$15000",
    text8: "$20000",
  },
  {
    head: "Max Drawdown",
    text1: "$650",
    text2: "$1300",
    text3: "$2500",
    text4: "$5000",
    text5: "$10000",
    text6: "$20000",
    text7: "$30000",
    text8: "$35000",
  },
  {
    head: "Refundable fee",
    text1: "$55",
    text2: "$114",
    text3: "$189",
    text4: "$280",
    text5: "$399",
    text6: "$899",
    text7: "$1299",
    text8: "$1699",
  },
];
