import React, { useEffect, useState } from "react";
import Auth from "../../layout/Auth";
import Button from "../../common/Button";
import Input from "../../common/Input";
import { Link, useHistory } from "react-router-dom";
import Api from "../../api/api";

const ForgotPassword = () => {
  const history = useHistory();
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({ email: "" });

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const submitHandle = async e => {
    e.preventDefault();
    setIsDisable(true);

    try {
      const res = await Api.forgotPassword(data);
      if (res.status === 200) {
        history.replace("/auth/signin");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ email: "" });
      setIsDisable(false);
    };
  }, []);

  return (
    <Auth
      title="Forgot Password"
      submitHandle={submitHandle}
    >
      <Input
        title="email"
        type="email"
        name="email"
        value={data.email}
        onChange={handleChange}
        placeholder="youremail@example.com"
      />

      <br />
      <Button
        className="w-full"
        type="submit"
        disabled={isDisable}
      >
        Forgot
      </Button>

      <p className="mt-5 text-center opacity-80 font-medium">
        Already have an account?{" "}
        <Link
          to="/auth/signin"
          className="hover:text-primary"
        >
          Login Here
        </Link>
      </p>
    </Auth>
  );
};

export default ForgotPassword;
