import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <main>
        <div className="py-12 md:py-16">
          <div className="mx-auto max-w-[1280px] px-6 w-full">
            <div className="py-10">
              <div className="flex items-center gap-y-10 gap-x-6 flex-wrap">
                <p className="border-b-[2px] border-primary text-2xl font-bold">{t("l_t2")}</p>
                <Link
                  to="/terms-of-use"
                  className="text-[15px] font-bold text-primary"
                >
                  {t("l_t1")}
                </Link>
                <Link
                  to="/cookie-policy"
                  className="text-[15px] font-bold text-primary"
                >
                  {t("l_t3")}
                </Link>
                <Link
                  to="/kyc"
                  className="text-[15px] font-bold text-primary"
                >
                  {t("l_t4")}
                </Link>
              </div>
              <div className="text-base font-semibold">
                <br />
                <br />
                <p>{t("pp_t1")}</p>
                <br />
                <br />
                <p>{t("pp_t2")}</p>
                <p>{t("pp_t3")}</p>
                <p>{t("pp_t4")}</p>
                <p>{t("pp_t5")}</p>
                <p>{t("pp_t6")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("pp_t7")}</li>
                  <li>{t("pp_t8")}</li>
                  <li>{t("pp_t9")}</li>
                  <li>{t("pp_t10")}</li>
                  <li>{t("pp_t11")}</li>
                  <li>{t("pp_t12")}</li>
                  <li>{t("pp_t13")}</li>
                  <li>{t("pp_t14")}</li>
                  <li>{t("pp_t15")}</li>
                </ul>
                <br />
                <p>{t("pp_t16")}</p>
                <p>{t("pp_t17")}</p>
                <p>{t("pp_t18")}</p>
                <br />
                <p>{t("pp_t19")}</p>
                <p>{t("pp_t20")}</p>
                <p>{t("pp_t21")}</p>
                <p>{t("pp_t22")}</p>
                <p>{t("pp_t23")}</p>
                <p>{t("pp_t24")}</p>
                <p>{t("pp_t25")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("pp_t26")}</li>
                  <li>{t("pp_t27")}</li>
                  <li>{t("pp_t28")}</li>
                  <li>{t("pp_t29")}</li>
                  <li>{t("pp_t30")}</li>
                  <li>{t("pp_t31")}</li>
                  <li>{t("pp_t32")}</li>
                  <li>{t("pp_t33")}</li>
                  <li>{t("pp_t34")}</li>
                </ul>
                <p>{t("pp_t35")}</p>
                <br />
                <p>{t("pp_t36")}</p>
                <p>{t("pp_t37")}</p>
                <p>{t("pp_t38")}</p>
                <p>{t("pp_t39")}</p>
                <p>{t("pp_t40")}</p>

                <p>{t("pp_t41")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("pp_t42")}</li>
                  <li>{t("pp_t43")}</li>
                  <li>{t("pp_t44")}</li>
                  <li>{t("pp_t45")}</li>
                  <li>{t("pp_t46")}</li>
                  <li>{t("pp_t47")}</li>
                </ul>
                <br />
                <p>{t("pp_t48")}</p>
                <p>{t("pp_t49")}</p>
                <p>{t("pp_t50")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("pp_t51")}</li>
                  <li>{t("pp_t52")}</li>
                  <li>{t("pp_t53")}</li>
                  <li>{t("pp_t54")}</li>
                  <li>{t("pp_t55")}</li>
                  <li>{t("pp_t56")}</li>
                  <li>{t("pp_t57")}</li>
                  <li>{t("pp_t58")}</li>
                  <li>{t("pp_t59")}</li>
                  <li>{t("pp_t60")}</li>
                  <li>{t("pp_t61")}</li>
                  <li>{t("pp_t62")}</li>
                  <li>{t("pp_t63")}</li>
                  <li>{t("pp_t64")}</li>
                  <li>{t("pp_t65")}</li>
                  <li>{t("pp_t66")}</li>
                </ul>
                <br />
                <p>{t("pp_t67")}</p>
                <p>{t("pp_t68")}</p>
                <p>{t("pp_t69")}</p>
                <p>{t("pp_t70")}</p>
                <p>{t("pp_t71")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("pp_t72")}</li>
                  <li>{t("pp_t73")}</li>
                  <li>{t("pp_t74")}</li>
                  <li>{t("pp_t75")}</li>
                  <li>{t("pp_t76")}</li>
                </ul>
                <p>{t("pp_t77")}</p>

                <ul className="list-disc pl-7">
                  <li>{t("pp_t78")}</li>
                  <li>{t("pp_t79")}</li>
                  <li>{t("pp_t80")}</li>
                  <li>{t("pp_t81")}</li>
                  <li>{t("pp_t82")}</li>
                  <li>{t("pp_t83")}</li>
                  <li>{t("pp_t84")}</li>
                  <li>{t("pp_t85")}</li>
                  <li>{t("pp_t86")}</li>
                  <li>{t("pp_t87")}</li>
                  <li>{t("pp_t88")}</li>
                  <li>{t("pp_t89")}</li>
                  <li>{t("pp_t90")}</li>
                  <li>{t("pp_t91")}</li>
                  <li>{t("pp_t92")}</li>
                  <li>{t("pp_t93")}</li>
                  <li>{t("pp_t94")}</li>
                  <li>{t("pp_t95")}</li>
                  <li>{t("pp_t96")}</li>
                  <li>{t("pp_t97")}</li>
                  <li>{t("pp_t98")}</li>
                  <li>{t("pp_t99")}</li>
                  <li>{t("pp_t100")}</li>
                  <li>{t("pp_t101")}</li>
                  <li>{t("pp_t102")}</li>
                  <li>{t("pp_t103")}</li>
                  <li>{t("pp_t104")}</li>
                  <li>{t("pp_t105")}</li>
                  <li>{t("pp_t106")}</li>
                  <li>{t("pp_t107")}</li>
                  <li>{t("pp_t108")}</li>
                  <li>{t("pp_t109")}</li>
                  <li>{t("pp_t110")}</li>
                  <li>{t("pp_t111")}</li>
                  <li>{t("pp_t112")}</li>
                  <li>{t("pp_t113")}</li>
                  <li>{t("pp_t114")}</li>
                  <li>{t("pp_t115")}</li>
                  <li>{t("pp_t116")}</li>
                  <li>{t("pp_t117")}</li>
                  <li>{t("pp_t118")}</li>
                  <li>{t("pp_t119")}</li>
                  <li>{t("pp_t120")}</li>
                  <li>{t("pp_t121")}</li>
                  <li>{t("pp_t122")}</li>
                  <li>{t("pp_t123")}</li>
                  <li>{t("pp_t124")}</li>
                  <li>{t("pp_t125")}</li>
                  <li>{t("pp_t126")}</li>
                  <li>{t("pp_t127")}</li>
                  <li>{t("pp_t128")}</li>
                  <li>{t("pp_t129")}</li>
                  <li>{t("pp_t130")}</li>
                </ul>

                <p>{t("pp_t131")}</p>
                <p>{t("pp_t132")}</p>
                <p>{t("pp_t133")}</p>
                <p>{t("pp_t134")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("pp_t135")}</li>
                  <li>{t("pp_t136")}</li>
                </ul>
                <p>{t("pp_t137")}</p>
                <p>{t("pp_t138")}</p>
                <p>{t("pp_t139")}</p>
                <p>{t("pp_t140")}</p>
                <p>{t("pp_t141")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("pp_t142")}</li>
                  <li>{t("pp_t143")}</li>
                  <li>{t("pp_t144")}</li>
                  <li>{t("pp_t145")}</li>
                  <li>{t("pp_t146")}</li>
                </ul>
                <p>{t("pp_t147")}</p>
                <p>{t("pp_t148")}</p>
                <p>{t("pp_t149")}</p>
                <p>{t("pp_t150")}</p>
                <p>{t("pp_t151")}</p>
                <p>{t("pp_t152")}</p>
                <p>{t("pp_t153")}</p>
                <p>{t("pp_t154")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("pp_t155")}</li>
                  <li>{t("pp_t156")}</li>
                  <li>{t("pp_t157")}</li>
                  <li>{t("pp_t158")}</li>
                  <li>{t("pp_t159")}</li>
                  <li>{t("pp_t160")}</li>
                  <li>{t("pp_t161")}</li>
                </ul>
                <p>{t("pp_t162")}</p>
                <p>{t("pp_t163")}</p>
                <p>{t("pp_t164")}</p>
                <p>{t("pp_t165")}</p>
                <p>{t("pp_t166")}</p>
                <p>{t("pp_t167")}</p>
                <p>{t("pp_t168")}</p>
                <p>{t("pp_t169")}</p>
                <ul className="list-disc pl-7">
                  <li>{t("pp_t170")}</li>
                  <li>{t("pp_t171")}</li>
                  <li>{t("pp_t172")}</li>
                </ul>
                <p>{t("pp_t173")}</p>
                <p>{t("pp_t174")}</p>
                <p>{t("pp_t175")}</p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Privacy;
