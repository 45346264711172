import { Link } from "react-router-dom";
import Button from "../../common/Button";
import DashboardLayout from "../../layout/Layout";
import PayoutTable from "../../components/payout/Table";

const Payout = () => {
  return (
    <DashboardLayout title="Payout">
      <div className="p-4 text-sm font-medium rounded-lg border border-[#ddd] mb-6">
        Welcome to Passve payouts. If you have a authorised account and are eligible to request a
        withdrawal, you can submit withdrawal requests here. All standard accounts have a 30 day
        payout time-frame from the time the first trade is placed or after a previous withdrawal.
        All subsequent withdrawals can be requested 14 days or 7 days from the date following your
        first trade date after a previous withdrawal on the basis the additional cover option was
        selected. In order to process a withdrawal simply select your account and click on the
        “Submit” button. Payouts are processed within 3-5 business days of the initial request.
      </div>

      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4 italic">Pending Payment: $0.00</h2>
        <Link to={"/dashboard/payout/request"}>
          <Button className={"!rounded-[50px] cursor-pointer"}>Edit Payout Request</Button>
        </Link>
      </div>

      <PayoutTable />
    </DashboardLayout>
  );
};

export default Payout;
