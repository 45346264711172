const espanol = {
  //header
  home: "Hogar",
  trade: "Comercio",
  market: "Mercado",
  autonomous_funding: "Financiación autónoma",
  docs: "Docs",
  earn: "Ganar",
  apy: "APY",
  staking: "Estaca",
  open_portal: "Portal abierto",
  client_area: "Área de Clientes",
  submit_ticket: " Enviar una solicitud",
  connect_wallet: " Conectar billetera",

  //footer
  cookies_policy: "Política de cookies",
  term_condition: "Términos y condiciones",
  privacy_policy: "Política de privacidad",
  rights_reserved: "Reservados todos los derechos",

  //home-hero
  h_hero_title: "Todo en uno - centro descentralizado",
  h_hero_subtitle:
    "Pioneros en la industria en el desarrollo de tecnología de punta cortada para continuar mejorando la experiencia del usuario final.",
  get_started: "Empezar",

  //home-stats
  total_trading_volume: "Volumen total de operaciones",
  open_interest: "Interés abierto",
  live_users: "Usuarios Activos",

  //home-ecosystem
  h_ecosystem_title: "Explora nuestro ecosistema",
  h_ecosystem_subtitle:
    "Explora características sensacionales que te ayudarán en tu camino financiero. No más barreras para alcanzar tu máximo potencial.",
  trade_now: "Comercio ahora",
  view: "Vista",
  earn_interest: "Ganar intereses",
  earn_interest_desc:
    "Admite una variedad de monedas digitales que permiten a los usuarios participar en la acumulación pasiva de recompensas en sus tenencias.",
  raw_spreads: "Spreads crudos",
  raw_spreads_desc:
    "Entre y salga de posiciones con un spread mínimo y un impacto bajo en el precio. Obtenga el precio óptimo sin incurrir en costos adicionales.",
  autonomous_funding_desc:
    "Obtenga acceso para liberar su potencial comercial con nuestra tecnología de financiación. Elija entre nuestro sustituto nativo descentralizado.",
  explore_more: "Explorar más",

  //home-feaured
  we_are_featured_in: "Presentes en",

  //home-newsletter
  subscribe_newsletter: "Suscríbete a nuestro boletín informativo",
  create_account: "Crear una cuenta",
  h_account_subtitle: "Abre tu cuenta gratuita hoy y explora la variedad de servicios disponibles.",
  join_our_community: "Únete a nuestra comunidad",
  members_online: "2000+ Miembros en línea",

  //home-crypto
  new_in_cryptocurrency: "¿Nuevo en criptomonedas?",
  h_cryptocurrency_desc:
    "No invierta a menos que esté dispuesto a perder todo el dinero que invierta. Se trata de una inversión de alto riesgo y no debe esperar estar protegido si algo sale mal. Tómese 2 minutos para obtener más información.",
  learn_explore: "Aprenda y explore ahora",

  //portal-welcome
  welcome_back: "Bienvenido de nuevo",
  p_welcome_subtite:
    "Llegue a su destino deseado con facilidad, a través de nuestros portales integrados.",

  //portal-refer
  copy: "Copiar",
  link_copied: "Enlace copiado!",
  refer_friend: "Refer a Friend",
  refer_friend_desc: "Invita a amigos a ganar hasta 2000 USDT",

  //funding-hero
  markets_awaits_you: "Los mercados te esperan",
  f_funding_capital: "Hasta $900.000 dólares en capital de financiación",
  f_funding_capital_desc:
    "Agilice su carrera y gane dinero como trader profesional con nuestros programas de trading autónomos y personalizados. Elija entre una solución descentralizada dedicada.",
  f_profit_desc:
    "Embárcate en un programa único de evaluación de 2 pasos para traders. Este proceso de evaluación consta de un único desafío, seguido de una verificación con el objetivo de encontrar talento en el trading. Una vez completado con éxito, se te ofrecerá un puesto en la firma de trading propietario Passve, donde podrás gestionar de forma remota una cuenta con un saldo de hasta 900,000 USD. Tu camino hacia ese objetivo puede ser desafiante, pero nuestras aplicaciones educativas, análisis de cuentas y entrenadores de rendimiento están aquí para ayudarte en tu esfuerzo por lograr la independencia financiera.",
  learn_more: "Más información",
  high_ratings: "Calificaciones altas",
  certified_firm: "Firma Certificada",
  full_profit_allocation: "Asignación total de utilidades",

  //funding-profit
  trade_your_skill: "Intercambia tus habilidades",
  amplify_your_results: "Amplifica tus resultados",
  f_transforming_success: "LOS VERDADEROS TRADERS MERECEN CAPITAL SERIO",

  //funding-trading
  view_trading_objectives: "Ver sus objetivos comerciales",
  most_popular: "Más populares",
  profit_split: "División de beneficios",
  accelerated: "Acelerado",
  evaluation: "Evaluación",
  step: "Paso",
  challenge: "Desafío",
  f_challenge_desc:
    "Completa la Fase I manteniendo los parámetros de reducción y cumpliendo con el objetivo de ganancias correspondiente. Cuando lo logres, pasarás a la etapa de verificación en 24 horas.",
  verification: "Verificación",
  f_verification_desc:
    "La fase II consiste en demostrar sus habilidades una vez más siguiendo las reglas y cumpliendo con los requisitos de objetivos de ganancias menos exigentes. Una vez verificado, se le ofrecerá oficialmente un puesto para operar en nuestra empresa de operaciones por cuenta propia.",
  funded_trader: "Comerciante financiado",
  f_funded_trader_desc:
    "Ahora eres elegible para unirte a nuestra Firma de Trading Propietario. Debes seguir mostrando un nivel profesional de gestión de riesgos, así como consistencia, y recibirás hasta el 100% de las ganancias. Tu rendimiento será evaluado regularmente y, si cumples los requisitos, podrás escalar hasta un saldo de cuenta de $5,000,000 y obtener la ejecución de órdenes con liquidez en vivo en el mercado abierto.",
  fast_track: "Vía rápida",
  f_fast_track_desc:
    "Objetivo del 10% - Comienza a ganar el 80% de las ganancias mientras mantienes los parámetros de drawdown de la cuenta. Omite la evaluación y acelera tu camino uniéndote a nuestro plan acelerado, recibiendo pagos instantáneamente según tu rendimiento.",
  select_plan: "Seleccionar plan",

  //funding-payout
  traders_by_traders: "Para los comerciantes por los comerciantes",
  traders_by_traders_desc:
    "Cultivar un movimiento y conectar a los traders más hábiles para que se unan a una comunidad que recompensa el crecimiento.",
  instant_payout: "Sistema de pago automático instantáneo",
  instant_payout_desc:
    "La proporción de pago predeterminada para las cuentas en vivo de Passve Trader varía según cada plan. Ofrecemos la oportunidad de ser elegible para nuestro Plan de Escalado. Si cumples con los términos según la cuenta seleccionada, aumentaremos tu saldo en incrementos de hasta el 25%. ¡También serás actualizado a ejecuciones de órdenes de liquidez reguladas en el mercado en vivo! Como nuestro Trader Financiado, podrás solicitar pagos a través del Área de Cliente. El pago puede solicitarse a través de varias opciones, incluyendo transferencia bancaria directa, criptomonedas, Coinbase, PayPal, Payoneer, Revolut, Wise, etc. ¡Tu retiro es nuestra prioridad, ya que nos esforzamos por procesar todos los pagos dentro de 24 horas!",

  //funding-why
  why_passve_funding: "¿Por qué elegir Financiación Autónoma Pasiva?",
  why_passve_funding_desc:
    "En Passve nos preocupamos por su éxito. El desarrollo y la capitalización son la base de nuestra filosofía. Nos esforzamos constantemente por invertir en tecnologías de vanguardia para crear el mejor entorno comercial posible para nuestros operadores.",
  talk_actively_desc:
    "Habla activamente con nosotros, nuestra comunidad y nuestros seguidores en Discord. Ofrecemos charlas e información en tiempo real para promover activamente el buen comportamiento en el trading. ¡Únete a nuestro Discord y descubre de qué se trata todo este alboroto!",
  no_waiting_desc:
    "Sin esperas, obtén las credenciales de tu cuenta al instante al comprar el programa que hayas elegido. Ya no tienes que esperar para comenzar tu viaje.",
  funding_option_desc:
    "La primera opción de financiación descentralizada. Elige entre una variedad de plataformas para alojar tu cuenta financiada. Incluye la opción descentralizada nativa Passve.",
  support_team_desc:
    "Nuestro equipo de soporte 24 horas al día, 7 días a la semana, está disponible para todas tus necesidades de cuenta financiada de FOREX, de día o de noche, estamos ahí para ti. Cuando nos necesitas, siempre estamos ahí para ayudarte.",
  active_user: "Usuario activo",
  locations: "Ubicaciones",
  payouts: "Pagos",
  global_community_reach: "Alcance de la comunidad global",
  global_community_reach_desc:
    "Los traders son el centro de cada decisión. Nos esforzamos por operar de manera que podamos seguir brindando servicios a través de todas las fronteras para ayudar a los traders a alcanzar sus objetivos financieros",

  //funding-insights
  insights_tips: "Información, consejos y últimas noticias financieras",
  insights_tips_desc:
    "Obtenga acceso a fuentes de datos de investigación en vivo sobre las tendencias económicas globales actuales",
  see_all_articles: "Ver todos los artículos",
  email_here: "Ingrese su correo electrónico aquí para recibir actualizaciones de noticias",
  subscribe_now: "Suscríbete ahora",

  //market
  market_trend: "Tendencia del mercado",
  market_update: "Actualización del mercado",
  market_categories: "Categorías del mercado",
  search_coin: "Buscar Activo",
  sr: "Sr.",
  name: "Nombre",
  last_price: "Último precio",
  change: "Cambio",
  market_stats: "Estadísticas del mercado",

  //earn-apy
  dashboard: "Dashboard",
  available_credit: "Crédito disponible",
  daily_earnings: "Ganancias diarias",
  supply_balance: "Saldo de oferta",
  borrow_balance: "Saldo de préstamo",
  supply: "Oferta",
  borrow: "Préstamo",
  borrow_limit: "Límite de préstamo",
  asset: "Activo",
  wallet: "Billetera",
  collateral: "Colateral",
  liquidity: "Liquidez",
  latest_block: "Último bloque",

  //
  support_hero_title: "How can we help? ",
  support_placeholder: "Look up here...",
  support_search: "Search",
  support_text11: "Chat With Us",
  support_text12:
    "Give our Lexi chat a try, and she will connect you to a live human to chat with if needed! (Live chat hours: Mon-Fri 8am GMT-5pm GMT).",
  support_text21: "Submit a ticket",
  support_text22: "Get in touch with our friendly support team.",
  support_text31: "Support Information",
  support_text32: "Login directly to have access to live updates to open tickets.",

  faq_title: "Commonly Asked Question",
  faq_text11: "How can verify my Identity? ",
  faq_text12: "How long does take for a  transaction to go through? ",
  faq_text13: "Why did I receive less than I bought?",
  faq_text14: "Why have I been charged twice?",
  faq_text15: "What is the minimum order amount?",
  faq_text16: "How can I update my information?",
  faq_text17: "Customer Service Portal",
  faq_text21: "Why is my payment getting declined?",
  faq_text22: "Common rejection reasons for verification documents ?",
  faq_text23: "How can I delete my data ?",
  faq_text24: "Can I cancel my order?",
  faq_text25: "How do I increase my limit?",
  faq_text26: "My transaction is still processing, is everything ok?",
  faq_text27: "B2B Service Portal",

  // stacking
  s_rss: "Participación de ingresos por staking",
  s_t11: "Total Bloqueado",
  s_t12: "APR de Bloqueo",
  s_t13: "Total Adquirido",
  s_t14: "APR de Adquisición",
  s_t21: "Total SVE Bloqueado",
  s_t22: "Reserva de la Bóveda",
  s_t23: "Emisión de Penalización SVE",
  s_t31: "Bloquear SVE",
  s_t32:
    "Bloquea SVE y gana ingresos de la plataforma y tarifas de penalización en SVE desbloqueado. El período de bloqueo es de 2 semanas. El SVE bloqueado continuará generando ingresos después de que los bloqueos expiren si no retiras",
  s_t33: "Cantidad",
  s_t34: "Saldo 0SVE",
  s_t35: "Máximo",
  s_t36: "Aprobar",
  s_t41: "SVE Reclamable",
  s_t42: "SVE Adquirido",
  s_t43: "Reclamar",
  s_t44: "SVE en Adquisición",
  s_t45: "Cantidad de SVE que se puede reclamar con una penalización por reclamo anticipado",
  s_t46: "Reclamar todo el SVE anterior",
  s_t47: "Penalización por salida anticipada",
  s_t48: "Reclamar con penalización",
  s_t49: "Bloqueado Expirado",
  s_t410: "Retirar",
  s_t51: "Adquisiciones de SVE",
  s_t52: "Total",
  s_t53: "No se encontró adquisición",

  // título legal
  l_t1: "Términos y Condiciones",
  l_t2: "Política de Privacidad",
  l_t3: "Política de Cookies",
  l_t4: "KYC/ Contra el Lavado de Dinero",

  // legal term and conditions
  tc_t1: "Última actualización: 18 de diciembre de 2022",
  tc_t2:
    "Estos Términos y Condiciones establecen sus derechos y obligaciones legales al utilizar los Servicios Financieros Passve, la Cuenta Digital Passve. Le recomendamos que los lea detenidamente. Si utiliza la Cuenta Digital, su derecho a canjear dinero electrónico se explica en la cláusula 9.10. Las tarifas pagaderas en el canje se explican en nuestro Portal o en nuestro sitio web.",
  tc_t3: "1. FORMACIÓN DEL CONTRATO Y VISIÓN GENERAL:",
  tc_t4:
    "1.1 En este documento, las referencias a Passve/nosotros/nuestro son a Passve PLC. En todo momento, cualquier manejo o entrega de Criptomonedas será realizado por Passve PLC, y cualquier manejo o trato con cualquier moneda fiduciaria será realizado por Passve PLC London Department (incluido cualquier recibo de pagos por compras que realice con Passve PLC). Cualquier referencia a Cliente/usted/suyo son referencias a usted como cliente de Passve y usuario de nuestros servicios. Cualquier referencia al 'Servicio' incluye sitios web, API o aplicaciones móviles.",
  tc_t5:
    "1.2 Usted acepta que la responsabilidad de Passve PLC bajo este Acuerdo está limitada y que Passve solo será responsable de sus propias obligaciones respectivas bajo este Acuerdo, por cualquier incumplimiento.",
  tc_t6:
    "1.3 Lea este documento detenidamente, ya que establece los términos y condiciones bajo los cuales Passve le proporcionará servicios a través de nuestros sitios web, Interfaces de Programación de Aplicaciones ('API') o aplicaciones móviles (en conjunto, nuestro 'Sitio').",
  tc_t7:
    "1.4 Al registrarse para crear una Cuenta con Passve y utilizar nuestros Servicios, usted acepta que ha leído, entendido y aceptado todos los términos y condiciones contenidos en este Acuerdo, así como nuestra Política de Privacidad y Política de Cookies.",
  tc_t8:
    "1.5 Este Acuerdo regirá el uso de los Servicios proporcionados por Passve. Al utilizar los Servicios, usted acepta estar sujeto a los términos y condiciones de este Acuerdo. Si no está de acuerdo con alguno de los términos de este Acuerdo, o cualquier modificación posterior a este Acuerdo, no se le permitirá utilizar los Servicios y su Cuenta con nosotros será cerrada. Este Acuerdo entrará en vigor cuando confirme electrónicamente que lo acepta. Le recomendamos que conserve una copia de este Acuerdo y de los registros de transacciones.",
  tc_t9:
    "1.6 El uso de criptomonedas puede ser ilegal en algunas jurisdicciones. Es su responsabilidad conocer los requisitos regulatorios relacionados con las transacciones con criptomonedas en su jurisdicción antes de utilizar los Servicios.",
  tc_t10: "1.7 Para los fines de este Acuerdo:",
  tc_t11:
    "1.7.1 una 'Cuenta' significa una cuenta en línea creada por usted para acceder a los Servicios de Passve;",
  tc_t12:
    "1.7.2 un 'Día Hábil' significa cualquier día que no sea sábado, domingo o feriado en la respectiva jurisdicción mencionada en la cláusula 1.1, y en la cual los bancos estén abiertos para negocios en dicha jurisdicción;",
  tc_t13:
    "1.7.3 'Criptomoneda' o 'Criptomonedas' significa una representación digital de valor o derechos contractuales asegurada criptográficamente que utiliza una forma de tecnología de libro mayor distribuido y puede transferirse, almacenarse o comercializarse electrónicamente. La definición de Criptomoneda también incluye el 'derecho a, o interés en, la Criptomoneda';",
  tc_t14: "1.7.4 'Orden' significa una solicitud de los Servicios;",
  tc_t15:
    "1.7.5 'Socio' significa empresas de terceros que lo refieren a Passve para comprar y/o vender Criptomonedas;",
  tc_t16: "1.7.6 'Sitios de Socios' significa las aplicaciones móviles y sitios web del Socio;",
  tc_t17: "1.7.7 'Servicios' significa la compra y/o venta de Criptomonedas de Passve;",
  tc_t18:
    "1.7.8 'Billetera' significa una instalación digital segura en la cual se mantienen las Criptomonedas.",
  tc_t19: "1.8 Usted ha entendido, reconocido y aceptado el siguiente AVISO LEGAL:",
  tc_t20:
    "1.8.1 El riesgo de pérdida en la negociación o tenencia de Bitcoin o cualquier otra Criptomoneda puede ser sustancial. Por lo tanto, debe considerar cuidadosamente si negociar o mantener Bitcoin o cualquier otra criptomoneda es adecuado para usted a la luz de su situación financiera. Al considerar si negociar o mantener Criptomonedas, debe ser consciente de que el precio o valor de las Criptomonedas puede cambiar rápidamente, disminuir e incluso potencialmente caer a cero.",
  tc_t21:
    "1.8.2 Usted reconoce que Passve no es responsable de proteger o mantener su Criptomoneda, o cualquier clave privada u otra información de seguridad para acceder a su Criptomoneda, y que Passve no es responsable de cualquier pérdida de Criptomoneda resultante de robo, pérdida o mal manejo de claves privadas de Criptomoneda u otra información de seguridad fuera de su control.",
  tc_t22:
    "1.8.3 Cada compra y venta de Criptomoneda se efectúa y confirma en la red respectiva de esa Criptomoneda. La confirmación toma un período de tiempo (generalmente menos de una hora, pero posiblemente un día o más). Una Orden no está completa hasta que se confirma. La Criptomoneda asociada con las Órdenes que están en estado pendiente se designará en consecuencia.",
  tc_t23:
    "1.8.4 En el Reino Unido, nuestros Servicios no están cubiertos por el Servicio de Defensor del Pueblo Financiero o el Esquema de Compensación de Servicios Financieros.",
  tc_t24: "2. Elegibilidad",
  tc_t25:
    "2.1 Para ser elegible para utilizar cualquiera de los Servicios de Passve, confirmas que:",
  tc_t26: "2.1.1 tienes al menos 18 años;",
  tc_t27: "2.1.2 tienes la capacidad suficiente para celebrar contratos legalmente vinculantes;",
  tc_t28: "2.1.3 resides en un país en el que los Servicios relevantes de Passve son accesibles; y",
  tc_t29:
    "2.1.4 estás dispuesto a proporcionarnos cualquier documento de identificación personal válido y actual que solicitemos (una vez alcanzado el límite de umbral).",
  tc_t30: "2.2 La lista de países accesibles se puede encontrar aquí.",
  tc_t31: "3. Servicios proporcionados a ti",
  tc_t32:
    "3.1 Una vez que hayas completado el proceso de registro (como se establece en la cláusula 4 a continuación), Passve te proporcionará los Servicios.",
  tc_t33:
    "3.2 Al utilizar los Servicios, estás comprando Criptomonedas de, o vendiendo Criptomonedas a, Passve directamente. Passve no actúa como intermediario ni como mercado entre otros compradores y vendedores de Criptomonedas.",
  tc_t34:
    "3.3 Passve enviará / entregará Criptomonedas a la dirección de Billetera indicada en el momento de la Orden, sujeto a las condiciones de este Acuerdo;",
  tc_t35:
    "3.4 En ningún momento durante la compra o venta, Passve estará en posesión o control de fondos de clientes.",
  tc_t36:
    "3.5 Passve no proporciona Billeteras de Criptomonedas, ni Passve aloja Billeteras o es custodio de fondos o activos; y",
  tc_t37: "3.6 Las Órdenes a través de Passve se ejecutan individualmente, una por una.",
  tc_t38:
    "3.7 Passve NO facilita ni proporciona cuentas o instalaciones de negociación, inversión o corretaje, ni Passve proporciona asesoramiento de inversión ni ningún otro consejo financiero.",
  tc_t39: "4. Proceso de registro del cliente",
  tc_t40:
    "4.1 Para utilizar los Servicios de Passve, deberás registrarte para obtener una Cuenta proporcionando tu nombre, dirección de correo electrónico y aceptando los términos de este Acuerdo. Al utilizar los Servicios de Passve, aceptas y declaras que usarás los Servicios de Passve solo para ti mismo, y no en nombre de ningún tercero. Tras completar exitosamente el proceso de registro, Passve establecerá tu Cuenta. Eres completamente responsable de toda la actividad que ocurra bajo tu Cuenta Passve. Podemos, a nuestra exclusiva discreción, negar la apertura de una Cuenta Passve para ti, limitar el número de Cuentas que puedes tener o suspender o terminar cualquier Cuenta, y no estamos obligados a proporcionarte las razones para tomar dicha acción.",
  tc_t41:
    "Como parte del proceso de registro, debes proporcionar a Passve la información solicitada como parte del proceso de apertura de Cuenta para identificar y verificar tu identidad y para la detección de lavado de dinero, financiamiento del terrorismo, fraude u otros delitos financieros y permitirnos mantener un registro de dicha información. Deberás completar ciertos procedimientos de verificación antes de que se te permita comenzar a utilizar los Servicios de Passve.",
  tc_t42:
    "4.2 La información que solicitamos puede incluir cierta información personal, incluyendo, pero no limitada a, tu nombre, dirección, número de teléfono, dirección de correo electrónico, fecha de nacimiento, número de identificación fiscal, número de identificación gubernamental, información sobre tu cuenta bancaria (como el nombre del banco, el tipo de cuenta, número de ruta y número de cuenta), estado de la red, tipo de cliente, rol del cliente, tipo de facturación, identificadores de dispositivo móvil (IMSI e IMEI) y otros detalles de estado del suscriptor. Al proporcionarnos esta o cualquier otra información que pueda ser requerida, confirmas que la información es precisa y correcta y acuerdas mantenernos actualizados si alguna de la información que proporcionas cambia. Trataremos esta información de acuerdo con las disposiciones de protección de datos de este Acuerdo, como se establece en la cláusula 13.",
  tc_t43:
    "4.3 Nos autorizas a hacer consultas, ya sea directamente o a través de terceros, que consideremos necesarias para verificar tu identidad o protegerte a ti y/o a nosotros contra fraude u otros delitos financieros, y a tomar las acciones que razonablemente consideremos necesarias basadas en los resultados de tales consultas. Cuando realicemos estas consultas, reconoces y aceptas que tu información personal puede ser divulgada a agencias de referencia de crédito y prevención de fraude o delitos financieros y que estas agencias pueden responder a nuestras consultas en su totalidad. Esto es solo una verificación de identidad y no debería tener un efecto adverso en tu calificación crediticia. Adicionalmente, podemos requerir que esperes un tiempo después de completar una transacción antes de permitirte usar más Servicios de Passve y/o antes de permitirte participar en transacciones que excedan ciertos límites de volumen.",
  tc_t44:
    "4.4 El no proporcionar cualquier información que Passve solicite razonablemente de ti de acuerdo con las leyes y regulaciones de lavado de dinero aplicables después de que te hayas convertido en Cliente será motivo para la suspensión de la prestación de Servicios a ti (incluyendo acceso a tu Cuenta) y/o la terminación de este Acuerdo. La naturaleza y extensión de la información que debes proporcionar puede diferir, por ejemplo, basado en los Servicios proporcionados a ti bajo este Acuerdo y/o los medios de pago que utilices.",
  tc_t45:
    "4.5 Donde seas referido a nosotros por nuestros Socios a través de Sitios de Socios, aún deberás registrarte para obtener una Cuenta con Passve y este Acuerdo se aplicará en su totalidad, independientemente de cualquier otro término al que te inscribas con nuestros Socios.",
  tc_t46: "5. Límites de transacciones y diligencia debida mejorada",
  tc_t47:
    "5.1 El uso de todos los Servicios de Passve está sujeto a un límite en el volumen, expresado en GBP, USD, EUR u otra moneda fiduciaria que puedes transaccionar o transferir en un período dado (por ejemplo, diario). Para ver tus límites, inicia sesión en tu Cuenta Passve. Tus límites de transacción pueden variar dependiendo de tu método de pago, los pasos de verificación que has completado y otros factores. Nos reservamos el derecho de cambiar los límites aplicables según lo consideremos necesario y, cuando sea posible, te proporcionaremos un aviso previo, aunque en algunos casos esto no será posible, y serás informado después de que se hayan realizado cambios en los límites aplicables. Si deseas aumentar tus límites más allá de las cantidades publicadas, puedes enviar una solicitud a support@passve.com.",
  tc_t48:
    '5.2 Podemos requerir que envíes información adicional sobre ti mismo y proporciones registros adicionales si deseas aumentar tus límites ("Diligencia Debida Mejorada"). A nuestra discreción, podemos negarnos a aumentar tus límites o podemos reducir tus límites en un momento posterior incluso si has completado la Diligencia Debida Mejorada.',
  tc_t49: "6. Pedidos de criptomonedas",
  tc_t50:
    "6.1 Podrás comprar y vender Criptomonedas (si estás en una región compatible) de y a nosotros, a través de nuestro Sitio y a través de Sitios de Socios, sujeto a las tarifas y límites aplicables que se muestran durante el flujo de compra y venta. El precio, el tipo de cambio y la cantidad de Criptomonedas que deseas comprar o vender se confirmarán en el momento en que realices un Pedido con nosotros.",
  tc_t51:
    "6.2 La aceptación por parte de nosotros de un Pedido no garantiza que recibirás la cantidad correspondiente de Criptomonedas o moneda fiduciaria. El Pedido de Criptomonedas está condicionado a la recepción real por nuestra parte de los fondos de tu tarjeta de crédito o débito, así como al pago de cualquier tarifa aplicable.",
  tc_t52:
    "6.3 Sujeto a la cláusula 6.2 y a que el Pedido sea aceptado por el banco del Cliente, proveedor de tarjeta u otra parte relevante, las compras de Criptomonedas se acreditarán en cualquier Billetera de Criptomonedas, como la que proporcionaste en el momento del Pedido, tan pronto como sea posible una vez que la compra de Criptomonedas haya sido confirmada por la red de Criptomonedas. Una vez enviado a una red de Criptomonedas, una compra de Criptomonedas estará no confirmada por un período de tiempo en espera de una confirmación suficiente del Pedido por parte de la red de Criptomonedas. Los Pedidos de Criptomonedas que están en un estado pendiente no se acreditarán en la Billetera.",
  tc_t53:
    "6.4 Sujeto a las cláusulas 6.2 y 8, las ventas de Criptomonedas se debitarán de cualquier Billetera de Criptomonedas, como la que proporcionaste en el momento del Pedido, tan pronto como sea posible una vez que la venta de Criptomonedas haya sido confirmada por la red de Criptomonedas. Una vez enviado a una red de Criptomonedas, una venta de Criptomonedas estará no confirmada por un período de tiempo en espera de una confirmación suficiente del Pedido por parte de la red de Criptomonedas. Los Pedidos de Criptomonedas que están en un estado pendiente no se debitarán de la Billetera. Una vez confirmada la venta de Criptomonedas, los fondos fiduciarios se acreditarán en el banco del Cliente, proveedor de tarjeta u otra parte relevante.",
  tc_t54:
    "6.5 Passve puede utilizar un procesador de pagos de terceros para procesar cualquier pago fiduciario entre tú y Passve.",
  tc_t55:
    '6.6 Transacciones recurrentes. Si configuras una compra o venta recurrente de Criptomonedas (una "Transacción Futura"), nos autorizas a iniciar pagos electrónicos recurrentes de acuerdo con tu Pedido de Criptomonedas seleccionado. Tus Transacciones Futuras se realizarán en plazos periódicos idénticos, basados en tu selección de período (por ejemplo, diario, semanal, mensual), hasta que tú o Passve cancelen la Transacción Futura. Esta autorización permanecerá en pleno vigor y efecto hasta que cambies la configuración de tu Transacción Futura o hasta que nos proporciones un aviso por escrito a través de support@passve.com.',
  tc_t56:
    "6.7 Ten en cuenta que como parte de acceder a nuestros Servicios, es posible que debas registrarte en términos separados e independientes al utilizar los Sitios de Socios y con cualquier procesador de pagos de terceros.",
  tc_t57: "7. Almacenamiento de criptomonedas",
  tc_t58:
    "7.1 Passve no proporciona ninguna Billetera ni mantiene, protege o administra ninguna clave privada u otra información de seguridad o Criptomonedas para sus Clientes.",
  tc_t59:
    "7.2 Al utilizar los Servicios, se te pedirá que nos proporciones la dirección de tu Billetera ya sea:",
  tc_t60: "7.2.1 proporcionando un código QR que represente la dirección de tu Billetera; o",
  tc_t61:
    "7.2.2 escribiendo manualmente la dirección de tu Billetera donde se proporciona al realizar un Pedido.",
  tc_t62:
    "7.3 Es tu responsabilidad proporcionarnos una dirección de Billetera verdadera, precisa y completa al realizar un Pedido. Por lo tanto, es importante que la dirección de Billetera que proporcionaste para un Pedido sea correcta. Entiendes y aceptas que Passve no acepta responsabilidad por proporcionarte una dirección de Billetera incorrecta o imprecisa como parte de un Pedido. Al proporcionarnos una dirección de Billetera, confirmas que esta es la dirección de Billetera que debe usarse para el Pedido relevante y no seremos, ni tenemos responsabilidad, de verificar si has proporcionado una dirección de Billetera correcta y precisa para el Pedido.",
  tc_t63: "8. Retrasos en la transmisión",
  tc_t64:
    "8.1 Passve hará esfuerzos de buena fe para cumplir con los Pedidos en el momento en que realices dicho Pedido, incluyendo la tarifa aplicable y los costos. Sin embargo, de vez en cuando, puede ser necesario que Passve retrase el cumplimiento de un Pedido hasta que podamos ejecutar el Pedido. En tales casos, te notificaremos antes de la finalización de tu Pedido tanto:",
  tc_t65: "8.1.1 la cantidad de Criptomonedas que recibirás; y",
  tc_t66: "8.1.2 el precio en el momento (incluyendo tarifas y costos asociados).",
  tc_t67:
    "8.2 Passve no tiene control sobre los tiempos de transacción para la Red de Criptomonedas y puede haber ocasiones en las que los tiempos de transacción tarden más de lo habitual. Como tal, aceptas el riesgo de que un Pedido facilitado por Passve pueda ser retrasado y confirmas que no responsabilizarás a Passve por pérdidas, daños o perjuicios derivados de o relacionados con dicho retraso.",
  tc_t68: "9. Cancelaciones y reembolsos",
  tc_t69: "9.1 Una vez realizado un Pedido, no puede ser cancelado ni revocado.",
  tc_t70: "9.2 Todos los Pedidos son finales y no pueden ser reembolsados.",
  tc_t71:
    "9.3 Una vez que un Pedido haya sido enviado a la Billetera de Criptomonedas, no puede ser revocado ni recuperado bajo ninguna circunstancia.",
  tc_t72:
    "9.4 Por la presente aceptas que al momento de la entrega, no tendrás derecho a ningún crédito o reembolso y todas las compras y ventas de Criptomonedas son finales. La obligación de Passve hacia ti quedará totalmente cumplida al entregar la Criptomoneda a tu Billetera o los fondos fiduciarios a tu cuenta bancaria y no tendrás ninguna reclamación o derecho contra Passve después de dicha entrega.",
  tc_t73:
    "9.5 Ten en cuenta también que las transacciones de Criptomonedas son irreversibles. Es tu única responsabilidad estar atento a cualquier fraude o error y mantener tu clave privada segura. Passve no asumirá responsabilidad por emitir reembolsos, independientemente de si fuiste víctima de fraude, error o pérdida de clave privada.",
  tc_t74: "10. Suspensión, terminación y cancelación",
  tc_t75:
    "10.1 Passve puede: (a) negarse a completar, bloquear o cancelar lo que has autorizado, (b) suspender, restringir o terminar tu acceso a cualquiera o a todos los Servicios de Passve, y/o (c) desactivar o cancelar tu Cuenta de Passve con efecto inmediato por cualquier motivo, incluyendo pero no limitado a los siguientes:",
  tc_t76: "10.1.1 creamos razonablemente que necesitamos hacerlo para proteger nuestra reputación;",
  tc_t77:
    "10.1.2 estemos, en nuestra opinión razonable, obligados a hacerlo por ley aplicable, regulación o cualquier tribunal u otra autoridad a la que estemos sujetos en cualquier jurisdicción;",
  tc_t78: "10.1.3 sospechemos razonablemente que estás actuando en violación de este Acuerdo;",
  tc_t79:
    "10.1.4 tengamos preocupaciones sobre si una transacción es errónea o sobre la seguridad de tu Cuenta de Passve, o sospechemos que los Servicios de Passve están siendo utilizados de manera fraudulenta o no autorizada;",
  tc_t80:
    "10.1.5 sospechemos de lavado de dinero, financiamiento del terrorismo, fraude o cualquier otro crimen financiero;",
  tc_t81:
    "10.1.6 el uso de tu Cuenta de Passve está sujeto a cualquier litigio, investigación o procedimiento gubernamental pendiente y / o percibimos un riesgo elevado de incumplimiento legal o regulatorio asociado con la actividad de tu Cuenta de Passve; y / o",
  tc_t82:
    "10.1.7 tomes cualquier acción que pueda eludir nuestros controles, como abrir múltiples Cuentas de Passve o abusar de promociones que podamos ofrecer de vez en cuando.",
  tc_t83:
    "10.1.8 no proporciones a solicitud la documentación que Passve (o cualquier tercero cuyos servicios utilicemos para proporcionarte los Servicios bajo este Acuerdo) requiera razonablemente para cumplir con sus obligaciones bajo las leyes y regulaciones aplicables contra el lavado de dinero o para asegurar la verificación de tu identidad y/o fuentes de fondos a satisfacción de Passve.",
  tc_t84: "10.1.9 cualquier Pedido de Criptomonedas sea significativamente mayor en tamaño;",
  tc_t85:
    "10.1.10 Passve crea razonablemente que es necesario o deseable hacerlo para proteger la seguridad de la Cuenta, incluyendo circunstancias en las que los detalles de la Cuenta puedan haberse perdido o robado.",
  tc_t86:
    "10.2 En el caso de cualquier suspensión, Passve hará esfuerzos razonables para informarte sobre la retención o suspensión, siempre que dicha divulgación",
  tc_t87:
    "10.2.1 no infrinja ninguna ley o regulación aplicable y no contravenga las instrucciones de cualquier autoridad competente o regulador; y",
  tc_t88: "10.2.2 no comprometa las medidas de seguridad razonables de Passve.",
  tc_t89:
    "10.3 Passve no será responsable ante ti por las pérdidas que puedas sufrir como resultado de cualquier acción razonable que tome para suspender la Cuenta o retener el pago de un Pedido de Criptomonedas de acuerdo con esta cláusula 10.",
  tc_t90:
    "Cuando los motivos para las acciones de Passve bajo esta cláusula 10 cesen, Passve puede, a su discreción, reintegrar el acceso a la Cuenta y a los Servicios y/o emitir nuevos detalles de la Cuenta al Cliente y se reserva el derecho de solicitarte que completes nuevamente los procedimientos de apertura de Cuenta según se describe en este Acuerdo y de resolver cualquier problema pendiente con tu Cuenta antes de que se pueda levantar una restricción.",
  tc_t91:
    "No obstante lo anterior, podemos suspender, restringir o terminar tu acceso a cualquiera o a todos los Servicios de Passve y/o desactivar o cancelar tu Cuenta de Passve, sin razón, dándote un mes de aviso. Reconoces que nuestra decisión de tomar ciertas acciones, incluyendo limitar el acceso, suspender o cerrar tu Cuenta de Passve, puede basarse en criterios confidenciales que son esenciales para los propósitos de nuestros procedimientos de gestión de riesgos y seguridad. Aceptas que no estamos obligados a divulgar los detalles de nuestros procedimientos de gestión de riesgos y seguridad.",
  tc_t92:
    "10.4 También podrás cancelar tu Cuenta de Passve, en cualquier momento y de forma gratuita, y solo se te pedirá que pagues por aquellos Servicios utilizados que están sujetos a cargos. Si algún Pedido está en estado pendiente en el momento en que tu Cuenta de Passve sea cancelada o suspendida, dicho Pedido se completará antes de que se efectúe la cancelación. No podrás cancelar tu Cuenta de Passve para evadir una investigación o evitar pagar cualquier cantidad que de otro modo sea debida a Passve.",
  tc_t93:
    "10.5 En el caso de que tú o nosotros terminemos este Acuerdo o tu acceso a los Servicios, o desactivemos o cancelamos tu Cuenta de Passve, seguirás siendo responsable de todas las cantidades adeudadas bajo este Acuerdo hasta ese momento, incluidos todos los honorarios y cargos.",
  tc_t94: "11. Tarifas",
  tc_t95:
    "11.1 Todas las tarifas pagaderas bajo este Acuerdo se muestran antes de la compra o venta de Criptomonedas mediante el uso de los Servicios, y deberán pagarse en la moneda fiduciaria aplicable. Por favor, ten en cuenta que nuestras tarifas se te comunican en el punto de venta, y en este punto se te pedirá que confirmes que estás al tanto de la tarifa y que, al proceder, aceptas la tarifa antes de que Passve ejecute el Pedido.",
  tc_t96:
    "11.2 Passve se reserva el derecho de reclamar cualquier pérdida financiera sufrida debido a que presentes un procedimiento de contracargo con tu banco. Estos pueden incluir tarifas de administración impuestas por el adquirente de tarjetas y los esquemas de tarjetas, así como el valor monetario de las Criptomonedas.",
  tc_t97: "12. Impuestos",
  tc_t98:
    "Eres responsable de determinar si, y en qué medida, se aplican impuestos a las transacciones asociadas con estos Servicios. Debes retener, cobrar, informar y remitir las cantidades correctas de impuestos a las autoridades fiscales correspondientes.",
  tc_t99: "13. Ley de protección de datos",
  tc_t100:
    "Estamos comprometidos a mantener segura tu información personal. Procesamos la información personal de acuerdo con la legislación aplicable en materia de protección de datos. Por favor, lee nuestra política de privacidad para entender cómo usamos y protegemos la información que nos proporcionas (una copia de nuestra política de privacidad se puede acceder aquí: https://www.passve.com/privacy-policy",
  tc_t101: "14. Responsabilidad",
  tc_t102:
    "14.1 Ni Passve, ni ninguno de sus directores, empleados o agentes, será responsable por cualquier pérdida o daño sufrido por ti como resultado directo o indirecto de la provisión de los Servicios de Passve, salvo que nada en este Acuerdo excluirá o restringirá la responsabilidad de Passve derivada de:",
  tc_t103: "14.1.1 muerte o daño personal;",
  tc_t104: "14.1.2 fraude, tergiversación fraudulenta o declaración fraudulenta; y/o",
  tc_t105: "14.1.3 cualquier responsabilidad legal que no pueda ser limitada.",
  tc_t106:
    "14.2 En ningún caso Passve será responsable por pérdida de beneficios, pérdida de oportunidades, pérdida de negocio, pérdida de ahorros, pérdida de buena voluntad, pérdida de Criptomonedas, reclamaciones de terceros, pérdida de ahorros anticipados (ya sea directa o indirecta) o por cualquier tipo de pérdida especial, directa, indirecta o consecuente, incluso si es causada por negligencia de Passve y/o incumplimiento del contrato y aunque dicha pérdida era razonablemente previsible o Passve hubiera sido advertida de la posibilidad de que incurrieras en la misma.",
  tc_t107:
    "14.3 Passve rechaza toda responsabilidad asociada con el uso de Criptomonedas, incluyendo:",
  tc_t108: "14.3.1 defectos técnicos inherentes desconocidos;",
  tc_t109: "14.3.2 cambios regulatorios o legislativos; y",
  tc_t110: "14.3.3 fluctuación de divisas.",
  tc_t111:
    "14.4 Passve no asumirá ninguna responsabilidad por cualquier daño o interrupciones causados por virus informáticos, spyware, scareware, caballos de Troya, gusanos u otro malware que pueda afectar a tu computadora u otro equipo, o cualquier phishing, spoofing u otro ataque. Es tu responsabilidad usar un software de detección y prevención de virus reputado y disponible. También debes ser consciente de que los servicios de SMS y correo electrónico son vulnerables a ataques de spoofing y phishing y debes tener cuidado al revisar mensajes que aparentan originarse de Passve.",
  tc_t112:
    "14.5 Indemnizas y mantienes indemne a nosotros, nuestras subsidiarias, miembros, directores, socios, funcionarios, empleados, contratistas y agentes de cualquier pérdida, responsabilidad, reclamación, demanda, daños, costos, gastos (incluidos honorarios legales) que puedan surgir de o en relación con los Servicios, cualquier contenido en los Servicios compartido por ti u otros usuarios, cualquier sitio web o recurso de terceros encontrado a través de los servicios, cualquier usuario de los servicios, o cualquier incumplimiento de este Acuerdo, leyes aplicables o cualquier ley o regulación en cualquier jurisdicción.",
  tc_t113:
    "14.6 A excepción de las declaraciones expresas establecidas en este Acuerdo, por la presente reconoces y aceptas que no has confiado en ninguna otra declaración o entendimiento, ya sea escrito u oral, con respecto a tu uso y acceso a los Servicios y al Sitio de Passve.",
  tc_t114: "15. Garantías y representaciones",
  tc_t115:
    "15.1 Al aceptar este Acuerdo, representas, garantizas y te comprometes ante nosotros que:",
  tc_t116: "15.1.1 tienes pleno poder y autoridad para celebrar este Acuerdo;",
  tc_t117:
    "15.1.2 entiendes y reconoces que no garantizamos que cualquiera de los Servicios disponibles a través de nuestra API sea adecuado o apropiado para tus necesidades y que debes obtener tu propio asesoramiento legal e independiente sobre tales Servicios;",
  tc_t118: "15.1.3 estás celebrando este Acuerdo como principal y no en nombre de ningún tercero;",
  tc_t119:
    "15.1.4 no violarás ninguna ley aplicable al celebrar este Acuerdo o recibir los Servicios proporcionados bajo el mismo;",
  tc_t120: "15.1.5 no proporcionarás información falsa, engañosa o inexacta;",
  tc_t121:
    "15.1.6 no facilitarás virus, malware, gusanos, caballos de Troya u otros programas informáticos que puedan dañar, corromper, interrumpir, mal utilizar o acceder de manera no autorizada a datos, información del sistema o servicios de Passve;",
  tc_t122:
    "15.1.7 los fondos o Criptomonedas transferidos a la Billetera o cualquier sub-Billetera han sido adquiridos de manera legal;",
  tc_t123:
    "15.1.8 no utilizarás un proxy anónimo; utilizarás dispositivos automáticos, spider o procesos manuales para copiar o monitorear nuestros sitios web sin nuestra previa autorización por escrito;",
  tc_t124: "15.1.9 no acosarás y/o amenazarás a nuestros empleados, agentes u otros usuarios;",
  tc_t125:
    "15.1.10 entiendes y reconoces que, aunque hacemos esfuerzos razonables para asegurar la precisión de la información que proporcionamos, y que a su vez se te proporciona, ni nosotros ni ninguno de nuestros directores, empleados o agentes hacemos ninguna representación o garantía, expresa o implícita, sobre la precisión o integridad de dicha información;",
  tc_t126:
    "15.1.11 cualquier información proporcionada por ti a Passve bajo este Acuerdo es verdadera, completa, precisa, actualizada y no engañosa; y",
  tc_t127:
    "15.1.12 proporcionarás toda la asistencia razonablemente solicitada por Passve para permitirle cumplir con sus obligaciones bajo este Acuerdo.",
  tc_t128:
    "15.2 Los Servicios de Passve se proporcionan en una base de 'tal como está' y 'según disponibilidad', sin promesas adicionales por nuestra parte sobre la disponibilidad de los Servicios de Passve. Específicamente, no damos ninguna garantía implícita de título, comerciabilidad, adecuación para un propósito particular y/o no infracción. No hacemos promesas de que el acceso al Sitio, a cualquiera de los Servicios de Passve, o a cualquiera de los materiales contenidos en él, será continuo, ininterrumpido, oportuno o libre de errores.",
  tc_t129:
    "15.3 Passve no hace ninguna representación o garantía de que los Servicios sean aplicables o apropiados para su uso por Clientes en todas las jurisdicciones y es tu responsabilidad garantizar el cumplimiento con las leyes de cualquier jurisdicción relevante de tu residencia.",
  tc_t130: "16. Detalles de tarjeta almacenados",
  tc_t131:
    "16.1 Estamos legalmente obligados a asegurar tu consentimiento para permitirnos almacenar los detalles de tu tarjeta para uso futuro y no podemos procesar tu pago antes de que aceptes dicho almacenamiento. Al aceptar este Acuerdo, nos das permiso para hacerlo.",
  tc_t132:
    "16.2 Tu tarjeta permanecerá almacenada en tu Cuenta para fines transaccionales, a menos que decidas eliminarla, o hasta que la tarjeta expire. Para fines legales, continuaremos almacenando registros de tus transacciones de acuerdo con nuestra Política de Privacidad.",
  tc_t133:
    "16.3 Necesitamos hacer esto para que puedas depositar y retirar más fácilmente en el futuro y también nos ayuda a prevenir y detectar cualquier actividad fraudulenta.",
  tc_t134:
    "16.4 Los detalles almacenados se utilizan principalmente para que no tengas que volver a ingresar todos tus detalles para cada transacción que realices. En tu Cuenta verás los últimos 4 dígitos del número de la tarjeta, y esto forma la referencia para que puedas identificar la tarjeta correcta que deseas usar.",
  tc_t135:
    "16.5 También comparamos los detalles almacenados en nuestros sistemas de detección de fraudes, para prevenir que tus detalles sean utilizados en otras Cuentas y proteger tus datos.",
  tc_t136:
    "16.6 Según nuestra política, si no estás de acuerdo con que almacenemos los detalles de tu tarjeta de pago, no podremos procesar tu depósito desde la tarjeta utilizada.",
  tc_t137:
    "16.7 Los cambios en el uso permitido requerirán tu consentimiento, te notificaremos si esto ocurre.",
  tc_t138: "17. Derecho de desistimiento",
  tc_t139:
    "17.1 Tienes 14 (catorce) días naturales para ejercer tu derecho de desistimiento de este Acuerdo, sin necesidad de justificar ningún motivo o pagar ninguna penalización. Este período de desistimiento comienza al día siguiente de la fecha en que tu solicitud es aceptada por nosotros.",
  tc_t140:
    "17.2 Normalmente no proporcionaremos los Servicios durante el período de desistimiento. Cualquier Servicio u Orden que se haya completado en su totalidad antes de un desistimiento no podrá ser revertido.",
  tc_t141:
    '17.3 Debes notificarnos tu solicitud de desistimiento dentro del período estipulado por correo electrónico a <a href="mailto:support@passve.com">support@passve.com</a>',
  tc_t142:
    "17.4 Si ejerces tu derecho de desistimiento, este Acuerdo se terminará sin costo para ti.",
  tc_t143: "18. Plazo",
  tc_t144:
    "18.1 Este Acuerdo comenzará de la manera establecida en la cláusula 1 y continuará a menos que cualquiera de las partes notifique a la otra sobre la terminación, por escrito, de acuerdo con este Acuerdo.",
  tc_t145:
    "18.2 Este Acuerdo puede ser terminado de inmediato por el Cliente proporcionando un aviso por escrito a Passve.",
  tc_t146:
    "18.3 Este Acuerdo puede ser terminado por Passve de acuerdo con todas las disposiciones de este Acuerdo.",
  tc_t147: "19. Seguridad",
  tc_t148:
    "19.1 Se le proporcionarán detalles de seguridad de nuestra parte que serán necesarios para que pueda acceder a su Cuenta con nosotros. Debe mantener todos esos detalles de seguridad (incluidos nombres de usuario y contraseñas) en privado y no compartir dichos detalles con ningún tercero.",
  tc_t149:
    "19.2 Debe monitorear su Cuenta y leer todos los mensajes que se le han enviado. Si sospecha que alguna característica de su Cuenta (por ejemplo, detalles de inicio de sesión, contraseña u otra característica de seguridad) se ha perdido, robado, mal utilizado, usado sin autorización o de alguna otra manera comprometido, debe contactarnos inmediatamente notificándonos de dicha acción y acepta cambiar su contraseña de inmediato si es necesario.",
  tc_t150:
    "19.3 Nunca le pediremos que comparta su contraseña con nosotros o con cualquier tercero y nunca debe divulgarla a nadie ni permitir que alguien lo observe cuando esté accediendo a su Cuenta. Se recomienda que para mantener su Cuenta segura, cambie su contraseña regularmente, ya que esto minimizará el riesgo de que su Cuenta se vea comprometida.",
  tc_t151:
    "19.4 También debe asegurarse de que su(s) cuenta(s) de correo electrónico registrada(s) estén seguras y solo puedan ser accedidas por usted, ya que su dirección de correo electrónico puede ser utilizada en el proceso de restablecimiento de contraseñas o podemos enviarle comunicaciones a su cuenta de correo electrónico relacionadas con la seguridad de su Cuenta con nosotros. En caso de que alguna de las direcciones de correo electrónico registradas con su Cuenta se vea comprometida, debe contactarnos de inmediato y también ponerse en contacto con su proveedor de servicios de correo electrónico, tan pronto como tenga conocimiento de ello.",
  tc_t152: "20. Fuerza mayor",
  tc_t153:
    "Excepto como se establece de otra manera, ninguna de las partes será responsable de ninguna pérdida causada directa o indirectamente por circunstancias que no estén bajo su control, incluidas, entre otras, actos de Dios, restricciones gubernamentales, fallos del mercado o decisiones relacionadas con los valores, compensaciones o intercambios de materias primas, incluidos los suspensiones de negociación o extensiones de horarios de negociación, cortes de tiempo y días festivos, actos de autoridad civil o militar, emergencias nacionales, desastres naturales, guerras, disturbios o actos de terrorismo, disputas laborales, actos o regulaciones de cualquier organismo gubernamental o supranacional y cuerpos o la falla o mal funcionamiento de cualquier servicio de telecomunicaciones o computadoras.",
  tc_t154: "21. Notificaciones",
  tc_t155:
    "21.1 Todas las notificaciones y comunicaciones en virtud o en relación con este Acuerdo:",
  tc_t156:
    "21.1.1 Deben estar en inglés, por escrito y ser legibles (usted confirma al firmar este Acuerdo que posee el conocimiento y la comprensión adecuados del idioma inglés)",
  tc_t157: "21.1.2 Deben entregarse y/o enviarse a nosotros a la siguiente dirección:",
  tc_t158: "Dirección: 20-22 Wenlock Road, Londres, Inglaterra, N1 7GU.",
  tc_t159: "Correo electrónico: support@passve.com",
  tc_t160: "o a otra dirección (dentro del Reino Unido) que le notifiquemos de vez en cuando; y",
  tc_t161:
    "21.1.3 Serán entregados o enviados a usted a la dirección postal o dirección de correo electrónico que nos haya notificado o proporcionado en relación con este Acuerdo.",
  tc_t162:
    "21.2 Cualquier notificación u otra comunicación enviada por correo será enviada por correo prepagado de primera clase (si es hacia/desde el Reino Unido) o por correo aéreo prepagado (si es hacia/desde fuera del Reino Unido).",
  tc_t163:
    "21.3 Las partes reconocen que cualquier notificación u otra comunicación se considerará dada de la siguiente manera:",
  tc_t164:
    "21.3.1 Si se entrega, en el momento y en la fecha de entrega si se entrega durante un Día Hábil, o al comienzo del siguiente Día Hábil si se entrega en cualquier otro momento;",
  tc_t165:
    "21.3.2 Si se envía por correo hacia y desde un lugar dentro del Reino Unido, al comienzo del segundo Día Hábil después de haber sido enviada por correo;",
  tc_t166:
    "21.3.3 Si se envía por correo hacia o desde un lugar fuera del Reino Unido, al comienzo del quinto Día Hábil después de haber sido enviada por correo; o",
  tc_t167:
    "21.3.4 Si se envía por correo electrónico, en el momento y en la fecha de transmisión si se transmite durante el horario normal de oficina (09:00-17:30) en un Día Hábil (hora local en el lugar de recepción) y, en cualquier otro caso, al comienzo del Día Hábil siguiente a la fecha de transmisión.",
  tc_t168:
    "21.4 Esta cláusula no se aplicará a la notificación de ningún proceso o de otros documentos en ninguna acción legal por las partes. Passve no aceptará la notificación de procesos o cualquier acción legal por correo electrónico por su parte o de cualquier tercero.",
  tc_t169:
    "21.5 Podemos (cuando la ley lo permita) comunicarnos con usted publicando información en su Cuenta o en el Sitio, en cuyo caso la información se considerará recibida por usted cuando la publiquemos.",
  tc_t170:
    "21.6 Quejas y consultas generales Si tiene algún comentario o pregunta, contáctenos a través de nuestro correo electrónico de soporte al cliente en support@passve.com. Por favor, proporcione su nombre, dirección y cualquier otra información que podamos necesitar para identificarle, su Cuenta y el Pedido sobre el que tiene comentarios o preguntas.",
  tc_t171:
    "21.7 Para cualquier queja relacionada con los Servicios, se le aconseja contactar a support@passve.com. Si recibimos una queja de su parte, llevaremos a cabo de inmediato una investigación independiente de su queja y le proporcionaremos una respuesta por escrito. Nuestro objetivo es responderle dentro de los treinta (30) días siguientes a la recepción de su queja por escrito con nuestra respuesta final. En relación con el Reino Unido, no podrá remitir una queja al Servicio de Defensoría Financiera.",
  tc_t172: "22. General",
  tc_t173:
    "22.1 Debe cumplir con todas las leyes, regulaciones, requisitos de licencia y derechos de terceros aplicables (incluidas, entre otras, las leyes de privacidad de datos) en el uso de los Servicios de Passve.",
  tc_t174:
    '22.2 Le concedemos una licencia limitada, no exclusiva, intransferible, sujeta a los términos de este Acuerdo, para acceder y usar el Sitio y el contenido, materiales e información relacionados (colectivamente, el "Contenido") únicamente para fines aprobados según lo permitido por nosotros de vez en cuando. Cualquier otro uso del Sitio o del Contenido está expresamente prohibido y todos los demás derechos, títulos e intereses en el Sitio o Contenido son propiedad exclusiva de Passve y sus licenciantes. Usted acepta no copiar, transmitir, distribuir, vender, licenciar, realizar ingeniería inversa, modificar, publicar, participar en la transferencia o venta, crear obras derivadas de, o de cualquier otra manera explotar cualquiera de los Contenidos, en su totalidad o en parte.',
  tc_t175:
    '22.3 "Passve", passve.com y todos los logotipos relacionados con los Servicios de Passve o exhibidos en el Sitio son marcas comerciales o marcas registradas de Passve o sus licenciantes. No puede copiar, imitar o usar estas marcas sin nuestro consentimiento previo por escrito o los derechos de autor, secretos comerciales, patentes u otros derechos de propiedad intelectual de terceros, o derechos de publicidad o privacidad.',
  tc_t176:
    "22.4 Su uso de los Servicios de Passve y del Sitio está sujeto a controles de exportación internacionales y requisitos de sanciones económicas. Al enviar, comprar, vender, criptomonedas a través del Sitio o los Servicios de Passve, acepta que cumplirá con esos requisitos. No se le permite adquirir criptomonedas o usar ninguno de los Servicios de Passve a través del Sitio si:",
  tc_t177:
    '22.4.1 se encuentra en, bajo el control de, o es nacional o residente de Cuba, Irán, Corea del Norte, Sudán o Siria o cualquier otro país sujeto a embargo de Estados Unidos, sanciones de la ONU, de la Unión Europea o regímenes de sanciones financieras del Tesoro de Su Majestad (cada uno un "País Sancionado"), o si es una persona en la Lista de Nacionales Especialmente Designados del Departamento del Tesoro de EE.UU. o en la Lista de Personas Denegadas, Lista No Verificada, Lista de Entidades, o el régimen de sanciones financieras de la UE o del Tesoro de Su Majestad (una "Persona Sancionada"); o',
  tc_t178:
    "22.4.2 tiene la intención de suministrar cualquier criptomoneda adquirida o almacenada o Servicios de Passve a un País Sancionado (o a un nacional o residente de un País Sancionado) o a una Persona Sancionada.",
  tc_t179:
    "22.5 Le notificaremos cualquier cambio en este Acuerdo en su primer uso de los Servicios de Passve después de cualquier enmienda. Se considerará que ha aceptado el cambio si continúa usando los Servicios de Passve. Si no acepta el cambio, debe informarnos, y el Acuerdo se terminará.",
  tc_t180:
    "22.6 Podemos hacer todas las demás enmiendas al Acuerdo publicando el Acuerdo revisado en el Sitio o enviándoselo por correo electrónico, indicando cuándo el Acuerdo revisado se vuelve efectivo. Aunque nos esforzaremos por proporcionarle un aviso previo cuando sea posible, donde sea legal, podemos indicar que el Acuerdo revisado será efectivo de inmediato y, si no está de acuerdo con alguna modificación, debe cerrar su Cuenta de Passve y cesar el uso de los Servicios de Passve.",
  tc_t181:
    "22.7 Copias de la versión más actualizada del Acuerdo estarán disponibles en el Sitio en todo momento y se le proporcionarán por correo electrónico a su solicitud.",
  tc_t182:
    "22.8 Nada en este Acuerdo debe ser considerado o tiene la intención de ser considerado, ni debe causar que usted o Passve sean tratados como socios o empresas conjuntas, o que usted o Passve sean tratados como el agente del otro.",
  tc_t183:
    "22.9 Si recibe información sobre otro usuario a través de los Servicios de Passve, debe mantener la información confidencial y solo usarla en relación con los Servicios de Passve. No puede divulgar ni distribuir la información de un usuario a un tercero o usar la información excepto cuando sea razonablemente necesario para llevar a cabo una transacción y otras funciones razonablemente incidentales a ella, como soporte, conciliación y contabilidad, a menos que reciba el consentimiento expreso del usuario para hacerlo. No puede enviar comunicaciones no solicitadas a otro usuario a través de Passve.",
  tc_t184:
    "22.10 Usted es responsable de mantener actualizada su dirección de correo electrónico y número de teléfono en su perfil de Cuenta de Passve para recibir cualquier notificación o alerta que podamos enviarle (incluidas notificaciones o alertas de brechas de seguridad reales o sospechadas).",
  tc_t185:
    "22.11 Este Acuerdo (incluidos los documentos incorporados por referencia en el mismo) constituye el entendimiento y acuerdo completo entre usted y Passve en cuanto al asunto del mismo, y sustituye todas las discusiones, acuerdos y entendimientos previos de cualquier tipo (incluyendo sin limitación cualquier versión anterior de este Acuerdo), y de cualquier naturaleza entre usted y Passve.",
  tc_t186:
    "22.12 Los encabezamientos de las secciones de este Acuerdo son solo para conveniencia y no regirán el significado o interpretación de ninguna disposición de este Acuerdo.",
  tc_t187:
    "22.13 Este Acuerdo es personal para usted y no puede transferir ni ceder sus derechos, licencias, intereses y/o obligaciones a nadie más. Podemos transferir o ceder nuestros derechos, licencias, intereses y/o nuestras obligaciones en cualquier momento a cualquier persona, incluidos, entre otros, como parte de una fusión, adquisición u otra reorganización corporativa que involucre a Passve, siempre que esta transferencia o cesión no impacte materialmente la calidad de los Servicios de Passve que recibe. Sujeto a lo anterior, este Acuerdo vinculará y redundará en beneficio de las partes, sus sucesores y cesionarios permitidos.",
  tc_t188:
    "22.14 Si alguna disposición de este Acuerdo se determina como inválida o inaplicable bajo cualquier ley aplicable, esto no afectará la validez de cualquier otra disposición. Si alguna disposición se considera inaplicable, la disposición inaplicable se separará, y las disposiciones restantes se aplicarán.",
  tc_t189:
    "22.15 Puede que no siempre apliquemos estrictamente nuestros derechos bajo este Acuerdo. Si elegimos no hacer valer nuestros derechos en algún momento, esto es una medida temporal y podemos hacer cumplir nuestros derechos estrictamente nuevamente en cualquier momento.",
  tc_t190:
    "22.16 Este Acuerdo y cualquier información o notificación que usted o nosotros debamos proporcionar deben estar en inglés. Cualquier traducción de este Acuerdo u otros documentos se proporciona solo para su conveniencia y puede no representar con precisión la información en el inglés original. En caso de inconsistencia, prevalecerá la versión en inglés de este Acuerdo u otros documentos.",
  tc_t191:
    "22.17 En caso de que Passve sea adquirida por o se fusione con una entidad de terceros, nos reservamos el derecho, en cualquiera de estas circunstancias, de transferir o ceder la información que hemos recopilado de usted y nuestra relación con usted (incluido este Acuerdo) como parte de dicha fusión, adquisición, venta u otro cambio de control.",
  tc_t192:
    "22.18 A menos que se indique lo contrario, nadie más tiene derechos bajo este Acuerdo. Este Acuerdo es entre usted y nosotros. Ninguna otra persona tendrá derechos para hacer cumplir cualquiera de sus términos, incluidos bajo la Ley de Derechos de Terceros (Contracts (Rights of Third Parties) Act 1999).",
  tc_t193:
    "22.19 Todas las disposiciones de este Acuerdo que por su naturaleza se extienden más allá de la expiración o terminación de este Acuerdo, incluidas, sin limitación, las Secciones relacionadas con la suspensión o terminación, cancelación de Cuenta Passve, deudas pendientes con Passve, uso general del Sitio, disputas con Passve y disposiciones generales, continuarán siendo vinculantes y operarán después de la terminación o expiración de este Acuerdo.",
  tc_t194:
    "22.20 Este Acuerdo se regirá por, y se interpretará de acuerdo con, las leyes de Inglaterra y Gales y, sujeto a cualquier requisito legal superior, las partes se someten irrevocablemente a la jurisdicción exclusiva de los Tribunales de Inglaterra y Gales. Este Acuerdo y cualquier información o notificaciones que usted o nosotros debamos proporcionar deben estar en inglés.",

  // legal cokkie policy
  cp_t1: "Última actualización: 1 de enero de 2023",
  cp_t2:
    "Una cookie es un pequeño archivo de datos (archivo de texto) que un sitio web, cuando es visitado por un usuario, le pide a su navegador que almacene en su dispositivo para recordar información sobre usted, como su preferencia de idioma o información de inicio de sesión. Esas cookies son establecidas por nosotros y se llaman cookies de primera parte. También usamos cookies de terceros, que son cookies de un dominio diferente al dominio del sitio web que está visitando, para nuestros esfuerzos de publicidad y marketing. Más específicamente, usamos cookies y otras tecnologías de seguimiento para los siguientes propósitos:",
  cp_t3: "Cookies Estríctamente Necesarias",
  cp_t4:
    "Estas cookies son necesarias para que el sitio web funcione y no se pueden desactivar en nuestros sistemas. Generalmente solo se establecen en respuesta a acciones realizadas por usted que equivalen a una solicitud de servicios, como configurar sus preferencias de privacidad, iniciar sesión o completar formularios. Puede configurar su navegador para bloquear o alertarle sobre estas cookies, pero algunas partes del sitio no funcionarán entonces. Estas cookies no almacenan información personal identificable.",
  cp_t5: "Cookies Funcionales",
  cp_t6:
    "Estas cookies permiten que el sitio web proporcione funcionalidad mejorada y personalización. Pueden ser establecidas por nosotros o por proveedores de terceros cuyos servicios hemos agregado a nuestras páginas. Si no permite estas cookies, algunos o todos estos servicios pueden no funcionar correctamente.",
  cp_t7: "Cookies de Rendimiento",
  cp_t8:
    "Estas cookies nos permiten contar visitas y fuentes de tráfico para que podamos medir y mejorar el rendimiento de nuestro sitio. Nos ayudan a saber qué páginas son las más y menos populares y ver cómo los visitantes se mueven por el sitio. Toda la información que recopilan estas cookies es agregada y, por lo tanto, anónima. Si no permite estas cookies, no sabremos cuándo ha visitado nuestro sitio y no podremos monitorear su rendimiento.",
  cp_t9: "Cookies de Segmentación",
  cp_t10:
    "Estas cookies pueden ser establecidas a través de nuestro sitio por nuestros socios publicitarios. Pueden ser utilizadas por esas empresas para crear un perfil de sus intereses y mostrarle anuncios relevantes en otros sitios. No almacenan información personal directamente, sino que se basan en la identificación única de su navegador y dispositivo de internet. Si no permite estas cookies, experimentará publicidad menos segmentada.",
  cp_t11: "¿Qué son las cookies?",
  cp_t12:
    "Nuestro sitio web, al igual que muchos otros, utiliza cookies para funcionar correctamente.",
  cp_t13:
    "Las cookies son pequeños archivos de texto que se descargan en su dispositivo cuando accede a nuestro sitio web. Nos permiten reconocer su dispositivo y almacenar algo de información sobre sus preferencias o acciones pasadas en nuestro sitio web, lo que nos permite mejorar su experiencia. Esta política de cookies describe los tipos de información que recopilamos, cómo usamos la información y por qué necesitamos almacenar estas cookies. También explicaremos cómo puede evitar que se almacenen estas cookies; sin embargo, esto puede degradar o romper ciertos elementos de la funcionalidad del sitio.",
  cp_t14:
    "Para obtener información más general sobre las cookies, consulte este artículo de Wikipedia.",
  cp_t15: "Cómo usamos las cookies",
  cp_t16:
    "Usamos cookies para entender cómo los usuarios interactúan con nuestros servicios y así cómo podemos mejorarlos. Los detalles de este uso se describen a continuación. Desafortunadamente, en la mayoría de los casos no hay opciones estándar de la industria para desactivar las cookies sin desactivar completamente la funcionalidad y las características que agregan al sitio. Se recomienda que acepte nuestro uso de cookies si no está seguro de si las necesita o no, en caso de que sean necesarias para un servicio que utiliza.",
  cp_t17: "Los tipos de cookies que usamos",
  cp_t18:
    "Si crea una cuenta en nuestro sitio, usaremos cookies para la gestión del proceso de registro y la administración general. Estas cookies generalmente se eliminarán cuando cierre sesión; sin embargo, en algunos casos pueden permanecer después para recordar sus preferencias del sitio cuando haya cerrado sesión.",
  cp_t19:
    "Usamos cookies cuando está conectado, para que podamos recordar este hecho. Esto evita que tenga que iniciar sesión cada vez que visite una nueva página. Estas cookies generalmente se eliminan o limpian cuando cierra sesión para garantizar que solo pueda acceder a funciones y áreas restringidas cuando esté conectado.",
  cp_t20:
    "Cuando envía datos a través de un formulario, como una página de contacto o un cuadro de comentarios, pueden establecerse cookies para recordar sus detalles de usuario para futuras correspondencias. Para brindarle una excelente experiencia en este sitio, proporcionamos la funcionalidad para establecer sus preferencias sobre cómo se ejecuta este sitio cuando lo usa.",
  cp_t21:
    "Para recordar sus preferencias, necesitamos establecer cookies para que esta información pueda ser llamada cada vez que interactúe con una página que se vea afectada por sus preferencias. Algunas de las cookies que usamos son persistentes, lo que significa que permanecen almacenadas en su dispositivo durante un año.",
  cp_t22:
    "Como vendemos servicios, es importante para nosotros entender estadísticas sobre cuántos de los visitantes de nuestro sitio realmente realizan una compra, y por lo tanto, las cookies rastrearán este tipo de datos. Esto es importante para usted ya que significa que podemos hacer predicciones comerciales precisas que nos permiten monitorear nuestros costos de publicidad y productos para garantizar el mejor precio posible.",
  cp_t23:
    "Las cookies de publicidad comportamental utilizadas por este sitio están diseñadas para asegurarnos de que le proporcionemos los anuncios más relevantes posible al rastrear anónimamente sus intereses y presentar cosas similares que le puedan gustar.",
  cp_t24:
    "En algunos casos, podemos proporcionarle contenido personalizado basado en lo que nos dice sobre usted, ya sea directamente o indirectamente si vincula una cuenta de redes sociales. Estos tipos de cookies simplemente nos permiten proporcionarle contenido que creemos puede ser de su interés.",
  cp_t25: "Uso de cookies de terceros",
  cp_t26:
    "En algunos casos especiales también utilizamos cookies proporcionadas por terceros de confianza. La siguiente sección especifica qué cookies de terceros podría encontrar en este sitio y las razones por las que las usamos.",
  cp_t27:
    "Las cookies de análisis de terceros se utilizan para rastrear y medir el uso de este sitio para que podamos seguir produciendo contenido atractivo. Estas cookies pueden rastrear cosas como cuánto tiempo pasa en el sitio o las páginas que visita, lo que nos ayuda a entender cómo podemos mejorar el sitio para usted. De vez en cuando, probamos nuevas características y realizamos cambios sutiles en la forma en que se entrega el sitio. Mientras estamos probando nuevas características, estas cookies pueden ser utilizadas para garantizar que reciba una experiencia consistente en nuestro sitio. De esa manera, podremos entender qué optimizaciones aprecian más nuestros usuarios.",
  cp_t28:
    "Varios socios publicitan en nuestro nombre y las cookies de seguimiento de afiliados simplemente nos permiten ver si nuestros clientes han llegado al sitio a través de uno de nuestros sitios asociados. Esto nos permite acreditarlos adecuadamente y también le permite disfrutar de cualquier bonificación que puedan estar ofreciendo por el uso de su servicio.",
  cp_t29: "Cómo desactivar las cookies",
  cp_t30:
    "Muchos navegadores web permiten controlar las cookies a través de la configuración. Puede prevenir la configuración de cookies ajustando la configuración del navegador (consulte la sección de ayuda de su navegador para las instrucciones). Descubra cómo administrar las cookies en los navegadores populares:",
  cp_t31:
    "Tenga en cuenta que desactivar las cookies afectará la funcionalidad de nuestro sitio web. Desactivar las cookies generalmente afectará la funcionalidad y ciertas características de los sitios.",
  cp_t32: "Más información",
  cp_t33:
    "Esperamos que este artículo haya aclarado las cosas para usted. Como se mencionó anteriormente, es mejor dejar las cookies habilitadas por si acaso se necesitan mientras usa nuestro sitio. Sin embargo, si aún desea más información, no dude en contactarnos",

  // legal kyc
  kyc_t1: "Última actualización: 4 de diciembre de 2022",
  kyc_t2:
    "La Política de Prevención de Lavado de Dinero y Conozca a Su Cliente de Passve (en adelante, la “Política AML/KYC”) está destinada a prevenir y mitigar los posibles riesgos de que Passve se vea involucrado en cualquier tipo de actividad ilegal.",
  kyc_t3:
    'Tanto las regulaciones internacionales como locales requieren que Passve implemente procedimientos y mecanismos internos efectivos para prevenir el lavado de dinero, la financiación del terrorismo, el tráfico de drogas y de personas, la proliferación de armas de destrucción masiva, la corrupción y el soborno, y tomar medidas en caso de cualquier forma de actividad sospechosa por parte de sus Usuarios. Para solicitudes de las fuerzas del orden, dirija su documento oficial a nuestro equipo de cumplimiento <a href="mailto:support@passve.com"> support@passve.com</a>',
  kyc_t4: "La Política AML/KYC cubre los siguientes temas:",
  kyc_t5: "Procedimientos de verificación",
  kyc_t6: "Oficial de Cumplimiento",
  kyc_t7: "Monitoreo de transacciones",
  kyc_t8: "Evaluación de riesgos",
  kyc_t9: "1. Procedimientos de verificación",
  kyc_t10:
    "Uno de los estándares internacionales para prevenir actividades ilegales es la debida diligencia del cliente (“CDD”). De acuerdo con CDD, Passve establece sus propios procedimientos de verificación dentro de los estándares de prevención de lavado de dinero y los marcos de “Conozca a Su Cliente”, incluida la debida diligencia mejorada para clientes que presenten un mayor riesgo, como las Personas Políticamente Expuestas (PEPs).",
  kyc_t11:
    "El procedimiento de verificación de identidad de Passve requiere que el Usuario proporcione a Passve documentos, datos o información de fuentes confiables e independientes (por ejemplo, identificación nacional, pasaporte internacional, extracto bancario, factura de servicios). Con tales fines, Passve se reserva el derecho de recopilar la información de identificación del Usuario para los fines de la Política AML/KYC.",
  kyc_t12:
    "Passve tomará medidas para confirmar la autenticidad de los documentos e información proporcionados por los Usuarios. Se utilizarán todos los métodos legales para verificar la información de identificación y Passve se reserva el derecho de investigar a ciertos Usuarios que se hayan determinado como riesgosos o sospechosos.",
  kyc_t13:
    "Passve se reserva el derecho de verificar la identidad del Usuario de manera continua, especialmente cuando su información de identificación haya cambiado o su actividad parezca sospechosa (inusual para el Usuario en particular). Además, Passve se reserva el derecho de solicitar documentos actualizados a los Usuarios, aunque hayan pasado la verificación de identidad en el pasado.",
  kyc_t14:
    "La información de identificación del Usuario será recopilada, almacenada, compartida y protegida estrictamente de acuerdo con la política de privacidad de Passve y las regulaciones relacionadas.",
  kyc_t15:
    "Una vez que la identidad del Usuario haya sido verificada, Passve puede eximirse de responsabilidad legal en una situación en la que sus Servicios sean utilizados para llevar a cabo actividades ilegales.",
  kyc_t16:
    "Passve tiene prohibido transaccionar con individuos, empresas y países que estén en listas de sanciones prescritas. Por lo tanto, Passve se enfrentará a las listas de sanciones de las Naciones Unidas, la Unión Europea, el Tesoro del Reino Unido y la Oficina de Control de Activos Extranjeros de EE.UU. (OFAC) en todas las jurisdicciones en las que operamos.",
  kyc_t17: "2. Oficial de Cumplimiento",
  kyc_t18:
    "El Oficial de Cumplimiento es la persona, debidamente autorizada por Passve, cuyo deber es asegurar la implementación y aplicación efectiva de la Política AML/KYC. Es responsabilidad del Oficial de Cumplimiento supervisar todos los aspectos del anti-lavado de dinero y la financiación del terrorismo de Passve, incluyendo pero no limitado a:",
  kyc_t19: "Recopilar la información de identificación de los Usuarios;",
  kyc_t20:
    "Establecer y actualizar políticas y procedimientos internos para la cumplimentación, revisión, presentación y retención de todos los informes y registros requeridos bajo las leyes y regulaciones aplicables;",
  kyc_t21:
    "Monitorear transacciones e investigar cualquier desviación significativa de la actividad normal;",
  kyc_t22:
    "Implementar un sistema de gestión de registros para el almacenamiento y recuperación adecuados de documentos, archivos, formularios y registros;",
  kyc_t23: "Actualizar la evaluación de riesgos regularmente;",
  kyc_t24:
    "Proporcionar a las fuerzas del orden la información requerida bajo las leyes y regulaciones aplicables.",
  kyc_t25:
    "El Oficial de Cumplimiento tiene derecho a interactuar con las fuerzas del orden, que están involucradas en la prevención del lavado de dinero, la financiación del terrorismo y otras actividades ilegales.",
  kyc_t26: "3. Monitoreo de transacciones",
  kyc_t27:
    "Los Usuarios se conocen no solo verificando su identidad (quiénes son) sino, más importante aún, analizando sus patrones de transacción (qué hacen). Por lo tanto, Passve confía en el análisis de datos como una herramienta de evaluación de riesgos y detección de sospechas. Passve realiza una variedad de tareas relacionadas con el cumplimiento, incluyendo la captura de datos, filtrado, mantenimiento de registros, gestión de investigaciones e informes. Las funcionalidades del sistema incluyen:",
  kyc_t28:
    "Verificación diaria de los Usuarios contra “listas negras” reconocidas (por ejemplo, OFAC), agregación de transferencias por múltiples puntos de datos, colocación de Usuarios en listas de vigilancia y de denegación de servicios, apertura de casos para investigación cuando sea necesario, envío de comunicaciones internas y llenado de informes estatutarios, si corresponde;",
  kyc_t29: "Gestión de casos y documentos.",
  kyc_t30:
    "Con respecto a la Política AML/KYC, Passve monitoreará todas las transacciones y se reserva el derecho de:",
  kyc_t31:
    "Asegurarse de que las transacciones de naturaleza sospechosa sean reportadas a las autoridades correspondientes a través del Oficial de Cumplimiento;",
  kyc_t32:
    "Solicitar al Usuario que proporcione cualquier información y documentos adicionales en caso de transacciones sospechosas;",
  kyc_t33:
    "Suspender o cancelar la Cuenta del Usuario cuando Passve tenga una sospecha razonable de que dicho Usuario ha participado en actividades ilegales.",
  kyc_t34:
    "La lista anterior no es exhaustiva y el Oficial de Cumplimiento monitoreará las transacciones de los Usuarios día a día para definir si tales transacciones deben ser reportadas y tratadas como sospechosas o si deben ser tratadas como de buena fe.",
  kyc_t35: "4. Evaluación de riesgos",
  kyc_t36:
    "Passve, en línea con los requisitos internacionales, ha adoptado un enfoque basado en riesgos para combatir el lavado de dinero y la financiación del terrorismo. Al adoptar un enfoque basado en riesgos, Passve puede garantizar que las medidas para prevenir o mitigar el lavado de dinero y la financiación del terrorismo sean proporcionales a los riesgos identificados. Esto permitirá que los recursos se asignen de las maneras más eficientes. El principio es que los recursos deben ser dirigidos de acuerdo con las prioridades para que los mayores riesgos reciban la mayor atención.",

  // privacy policy
  pp_t1: "Última actualización: 7 de septiembre de 2022",
  pp_t2: "Introducción",
  pp_t3:
    "Su privacidad y la protección de su información personal son extremadamente importantes para nosotros. En Passve PLC, estamos comprometidos a proteger y respetar su privacidad y a manejar su información de manera abierta y transparente.",
  pp_t4:
    'Esta Política de Privacidad ("Política") explica, entre otras cosas: qué hacemos con su información personal; qué hacemos para mantenerla segura; con quién compartimos su información; sus derechos en relación con la información personal que tenemos sobre usted; y quién puede contactar para obtener más información.',
  pp_t5:
    "Modificaremos o actualizaremos esta Política regularmente para reflejar cambios en nuestras prácticas con respecto al procesamiento de información personal, o cambios en la legislación aplicable. Le recomendamos que lea esta Política cuidadosamente y que revise regularmente esta página para ver cualquier cambio que podamos hacer a los términos de esta Política.",
  pp_t6:
    "Si no desea leer todos los detalles, aquí están los aspectos más destacados que podría desear conocer:",
  pp_t7:
    "Passve PLC es un servicio de pago internacional que ofrece servicios de transferencia de dinero internacional y remesas en más de 150 países y en más de 70 monedas.",
  pp_t8: 'Nos conocerá por nuestras marcas: "Passve"',
  pp_t9:
    "Cuando sea apropiado, compartimos su información personal dentro del Grupo Passve, afiliados, socios y otras empresas. A solicitud o cuando estemos legalmente obligados, podemos compartir su información con agencias públicas.",
  pp_t10:
    "También recopilamos de usted información relacionada con otras personas (por ejemplo, detalles de los destinatarios de sus transferencias de dinero). Al proporcionar esta información, nos confirma que tiene su permiso para hacerlo.",
  pp_t11:
    "Usamos algunos terceros para procesar su información personal en nuestro nombre para realizar nuestros servicios para usted, y debido a la naturaleza internacional de nuestro negocio, algunos de ellos están ubicados fuera del Reino Unido y del Área Económica Europea.",
  pp_t12:
    "Tiene varios derechos sobre su información personal, y la forma en que puede ejercer esos derechos está contenida en esta Política.",
  pp_t13:
    "Le enviaremos marketing directo si se nos permite hacerlo. Hacemos esto para animarle a utilizar nuestros servicios y enviarle ofertas o promociones que puedan interesarle. Puede pedirnos que dejemos de enviarle información de marketing en cualquier momento. Los detalles de cómo darse de baja estarán incluidos en cada correo electrónico que le enviemos. Alternativamente, envíenos un correo electrónico a dataprivacy@passve.com con “URGENTE - SOLICITUD DE BAJA” en la línea de asunto y la dirección de correo electrónico que desea que se elimine dentro del correo electrónico.",
  pp_t14:
    "También utilizamos su información personal para mostrar anuncios en línea y marketing relacionado con nuestros servicios en nuestro sitio web, canales de medios en línea o enviándole notificaciones push si usa nuestra aplicación móvil.",
  pp_t15:
    "No utilizamos intencionadamente información de categorías especiales ni información de niños (nuestro sitio web y la aplicación móvil no están destinados a niños).",
  pp_t16: "¿Quiénes somos?",
  pp_t17:
    'Este sitio web es operado y proporcionado por Passve PLC ("Passve", "nosotros", "nuestro") Londres / Dubái.',
  pp_t18:
    'Si reside en un país donde le brindamos nuestros servicios y es usuario de nuestro sitio web passve.com (“nuestro sitio web”, “nuestro sitio”) y/o de nuestras aplicaciones móviles (la “Aplicación Móvil”), somos un controlador de datos a los efectos de la Legislación de Protección de Datos (como se define a continuación). En esta Política, las referencias a "usted" y "su" son referencias a un usuario de nuestro sitio web y/o de nuestras aplicaciones móviles.',
  pp_t19: "Definiciones",
  pp_t20:
    "Para ayudar a aclarar nuestro significado, hemos utilizado ciertos términos en mayúsculas en esta Política. Estos términos y su significado son:",
  pp_t21:
    "“Legislación de Protección de Datos” significa las Regulaciones Generales de Protección de Datos del Reino Unido adaptadas por la Ley de Protección de Datos de 2018 y toda la demás legislación aplicable relacionada con la privacidad o la protección de datos.",
  pp_t22:
    "“Marketing” significa cualquier acción o anuncio o material de promoción o marketing como encuestas, cupones, investigaciones y eventos. Esta lista no es exhaustiva.",
  pp_t23: "La información personal que recopilamos sobre usted",
  pp_t24:
    "Podemos recopilar, registrar y utilizar información sobre usted en forma física y electrónica y retendremos, utilizaremos y procesaremos de otro modo estos datos de acuerdo con la Legislación de Protección de Datos y según lo establecido en esta Política.",
  pp_t25: "La información personal que recopilamos y utilizamos puede incluir, entre otras cosas:",
  pp_t26: "Su nombre;",
  pp_t27:
    "Su información de contacto, como su dirección de correo electrónico, dirección postal y número de teléfono o cualquier número de teléfono utilizado para llamarnos;",
  pp_t28: "Su información demográfica, como edad, educación, género e intereses;",
  pp_t29: "pruebas de su identidad (por ejemplo, información del pasaporte);",
  pp_t30: "la razón de su solicitud de transferencia;",
  pp_t31: "identificadores únicos, como su nombre de usuario, número de cuenta y contraseña;",
  pp_t32:
    "Sus perfiles y publicaciones en cualquier otra aplicación de redes sociales y servicios que proporcionemos o que usted nos ponga a disposición;",
  pp_t33:
    "Sus detalles de pago y otros datos financieros (por ejemplo, el nombre de su banco o proveedor de método de pago y su número de cuenta y código de clasificación); y",
  pp_t34:
    "información sobre su visita a nuestro sitio web, incluidas las URL completas (Localizadores Uniformes de Recursos), clickstream hacia, a través de y desde nuestro sitio web (incluida la fecha y la hora), la duración de las visitas a ciertas páginas, la información de interacción de la página (como desplazamiento, clics y movimientos del ratón) y métodos utilizados para navegar fuera de la página.",
  pp_t35:
    "También recopilamos de usted información relacionada con otras personas (por ejemplo, detalles de los destinatarios de sus transferencias de dinero), cuando nos proporciona dicha información. Al proporcionar esta información, nos confirma que ha obtenido todos los permisos necesarios de ellos para el uso razonable de su información para los fines y de la manera descrita en esta Política, o que de otro modo está autorizado a darnos esta información en su nombre. Por favor, asegúrese también de que esas otras personas están al tanto de este aviso y de que las disposiciones de este aviso se les han comunicado claramente.",
  pp_t36: "Cómo recopilamos su información personal",
  pp_t37:
    "Información que nos proporciona cuando utiliza nuestros servicios. Si utiliza nuestros servicios, deberá proporcionar cierta información, incluyendo su nombre, dirección y número de tarjeta, así como el nombre y otros detalles de los destinatarios de las transferencias de dinero que nos instruya realizar. Esta información debe ser completa y precisa, y si no lo es, es posible que tengamos que tomar medidas adicionales para completar la transacción.",
  pp_t38:
    "Otra información que nos proporciona. Esta es información sobre usted que nos proporciona al completar formularios en nuestro sitio web (por ejemplo, la sección 'contáctenos') o cuando se comunica con nosotros por teléfono, correo electrónico o de otro modo. Incluye, por ejemplo, información que proporciona cuando se registra en el sitio web para que lo contactemos, cuando consulta sobre cualquiera de nuestros servicios, cuando se suscribe para recibir notificaciones y cuando informa un problema con nuestro sitio web. La información que nos proporciona puede incluir, entre otros datos, su nombre y dirección de correo electrónico. También podemos grabar nuestra conversación telefónica con usted con fines de capacitación y monitoreo.",
  pp_t39:
    "Redes sociales. Dependiendo de su configuración o las políticas de privacidad para los servicios de redes sociales y mensajería, como Facebook, LinkedIn e Instagram, puede darnos permiso para acceder a información de esas cuentas o servicios.",
  pp_t40:
    "Otras fuentes. Podemos recibir información sobre usted de otras fuentes, incluidas bases de datos disponibles públicamente, y combinar estos datos con la información que ya tenemos sobre usted. Esto nos ayuda a actualizar, expandir y analizar nuestros registros y proporcionar servicios que puedan interesarle.",
  pp_t41: "Información que recopilamos sobre Usted:",
  pp_t42:
    'Como la mayoría de los sitios web, usamos "cookies" para ayudarnos a mejorar nuestro sitio y la manera en que lo utiliza. Las cookies significan que un sitio web le recordará. Son pequeños archivos de texto que los sitios web transfieren a su computadora (o teléfono/tableta). Mejoran el uso y la velocidad del sitio web, por ejemplo, al completar automáticamente su nombre y dirección en campos de texto. Por favor, consulte nuestra política de cookies para más información.',
  pp_t43:
    "Archivos de registro. Además, en relación con cada una de sus visitas a nuestro sitio, recopilaremos automáticamente cierta información (por ejemplo, tipo de navegador, proveedor de servicios de internet (ISP), páginas de referencia/salida, sistema operativo, marca de tiempo de la fecha/hora y/o datos de clics). Podemos combinar esta información recopilada automáticamente con otra información que recopilamos sobre usted.",
  pp_t44:
    "Widgets de redes sociales. Nuestro sitio web incluye Widgets o Características de Redes Sociales, como el botón Me gusta de Facebook y el botón de Twitter, que son mini-programas interactivos que se ejecutan en nuestro sitio para proporcionar servicios específicos de otra empresa (por ejemplo, mostrar noticias, opiniones, música, etc.). La información personal, como su dirección de correo electrónico, puede ser recopilada a través del Widget. Las cookies también pueden ser establecidas por el Widget para que funcione correctamente. La información recopilada por este Widget está gobernada por la política de privacidad de la empresa que lo creó.",
  pp_t45:
    "Aplicación móvil. Cuando descarga nuestra aplicación móvil, además de la información mencionada anteriormente, nosotros:",
  pp_t46:
    "recopilamos automáticamente información sobre el tipo de dispositivo que usa, la versión del sistema operativo, y la información del sistema y el rendimiento. Podemos enviarle notificaciones push de vez en cuando para actualizarlo sobre eventos o promociones que podamos estar llevando a cabo. Si ya no desea recibir este tipo de comunicaciones, puede desactivarlas a nivel de dispositivo. Para asegurarnos de que reciba notificaciones adecuadas, necesitaremos recopilar cierta información sobre su dispositivo, como el sistema operativo y la información de identificación del usuario; y",
  pp_t47:
    "podemos utilizar software de análisis móvil para permitirnos comprender mejor la funcionalidad de nuestro software móvil en su dispositivo. Este software puede registrar información como la frecuencia con la que utiliza la aplicación móvil, los eventos que ocurren dentro de ella, el uso agregado, los datos de rendimiento y desde dónde se descargó la aplicación móvil. No vinculamos la información que almacenamos dentro del software de análisis con ninguna información personal que usted envíe dentro de la aplicación móvil.",
  pp_t48: "Cómo usamos su información personal",
  pp_t49:
    "Nuestro objetivo principal al recopilar información de los usuarios es proporcionarle una experiencia segura, fluida, eficiente y personalizada, y brindar los servicios que ha solicitado. También podemos usar la información que ha proporcionado para asegurarnos de que el contenido de nuestro sitio se presente de la manera más efectiva para usted y su computadora o dispositivo.",
  pp_t50: "Utilizamos su información para los siguientes propósitos específicos:",
  pp_t51:
    "para cumplir con nuestras obligaciones derivadas de cualquier contrato celebrado entre usted y nosotros o de la ley aplicable, como las leyes contra el lavado de dinero, y para brindarle los servicios de acuerdo con nuestros términos de uso y con esta Política;",
  pp_t52: "para registrarlo con una cuenta digital;",
  pp_t53: "para cumplir con su solicitud de transacción;",
  pp_t54: "para enviarle confirmaciones u otras notificaciones;",
  pp_t55:
    "para notificarle sobre cambios temporales o permanentes en nuestros servicios u otros mensajes relacionados con el servicio;",
  pp_t56: "para asistirlo cuando las solicitudes en línea no se completen;",
  pp_t57: "para prevenir fraudes, lavado de dinero y cualquier otra actividad ilegal;",
  pp_t58:
    "para asegurarnos de que el contenido de nuestro sitio se presente de la manera más efectiva para usted y su computadora;",
  pp_t59: "para promover nuestro negocio;",
  pp_t60:
    "para enviarle comunicaciones de marketing, cuando haya mostrado interés o haya comprado nuestros servicios y, en el curso de hacerlo, haya proporcionado sus datos de contacto y no haya optado por no recibir comunicaciones de marketing.",
  pp_t61:
    "para administrar nuestro sitio y para operaciones internas, que incluyen solución de problemas, análisis de datos, análisis de perfiles y segmentación, pruebas, investigación, fines estadísticos y encuestas;",
  pp_t62:
    "como parte de nuestros esfuerzos para mantener nuestro sitio, aplicación móvil y nuestros servicios seguros;",
  pp_t63:
    "para medir o comprender la efectividad de la publicidad que le servimos a usted y a otros, y para entregar publicidad relevante para usted;",
  pp_t64:
    "para hacer sugerencias y recomendaciones a usted y a otros usuarios de nuestro sitio sobre servicios que pueden interesarle a usted o a ellos;",
  pp_t65:
    "para capacitar, monitorear y evaluar la efectividad de la manera en que le brindamos los servicios;",
  pp_t66:
    "para considerar e investigar cualquier inquietud o queja que pueda tener; y para mostrar testimonios personales de clientes satisfechos en nuestro sitio web además de otros respaldos, donde haya acordado con un sitio de reseñas de terceros que podamos hacerlo. Si desea actualizar o eliminar su testimonio, contáctenos en dataprivacy@passve.com",
  pp_t67: "Toma de decisiones automatizada",
  pp_t68:
    "No estará sujeto a decisiones que tendrán un impacto significativo en usted basadas únicamente en la toma de decisiones automatizada, excepto si: (i) la toma de decisiones automatizada es requerida o autorizada por la ley; (ii) es necesaria para la celebración o ejecución del contrato; o (iii) se basa en su consentimiento explícito por escrito.",
  pp_t69: "Nuestra base legal para procesar su información personal",
  pp_t70:
    "Solo utilizamos su información personal para un propósito específico; y antes de hacerlo, debemos tener una razón legal. Por ejemplo, cuando es necesario que ejecutemos un contrato con usted para nuestros servicios, para nuestro interés legítimo, o cuando nos haya dado su consentimiento, o para cumplir con nuestras obligaciones legales. Estos son todos conocidos como la 'base legal para el procesamiento'.",
  pp_t71: "Actividad de procesamiento",
  pp_t72:
    "El procesamiento es necesario para la ejecución de un contrato o para tomar medidas antes de celebrar un contrato con nosotros.",
  pp_t73:
    "Entregar nuestros productos y proporcionarle nuestros servicios, como realizar transferencias de dinero, servicios de pago y recargas de teléfonos móviles. Esto puede incluir 'conozca a su cliente' y verificar su identidad y confirmar sus detalles financieros.",
  pp_t74:
    "Notificarle cualquier cambio en nuestros servicios, aplicaciones o nuestro sitio, o cambios en nuestros términos o política.",
  pp_t75: "Permitir que terceros realicen funciones técnicas, logísticas u otras para nosotros.",
  pp_t76: "Responder y resolver cualquier queja que pueda tener.",
  pp_t77:
    "El procesamiento es necesario para nuestros intereses legítimos (en la medida en que tales intereses legítimos no sean anulados por sus intereses).",
  pp_t78: "Para cumplir con nuestras obligaciones regulatorias y de cumplimiento;",
  pp_t79: "proporcionando nuestros sitios, nuestras aplicaciones o nuestros servicios a usted;",
  pp_t80:
    "Establecer, ejercer, proteger o defender nuestros derechos legales e intereses comerciales;",
  pp_t81:
    "Contactarlo para facilitar o avanzar en el uso de nuestros servicios, incluidos los problemas que pueda tener para completar su transacción, siempre sujeto al cumplimiento de la ley aplicable;",
  pp_t82: "Gestionar y operar los asuntos financieros y comerciales de nuestro negocio;",
  pp_t83: "Para promover y llevar a cabo nuestro negocio;",
  pp_t84: "Para personalizar su experiencia con nuestros servicios;",
  pp_t85: "Realizar encuestas, informes de satisfacción e investigación de mercado;",
  pp_t86:
    "Para desarrollar nuevos servicios y productos, e informarle sobre cualquier nuevo producto y servicio que pueda interesarle:",
  pp_t87:
    "Cuando se registre en nuestro sitio y en nuestra aplicación, utilizamos los detalles de contacto que proporciona en el registro para enviarle marketing por correo electrónico, SMS y notificaciones push en nuestra aplicación EXCEPTO SI OPTA POR NO RECIBIRLOS;",
  pp_t88:
    "Para contactarlo con marketing dirigido sobre nuestros servicios en plataformas o sitios web de terceros, incluidos los anuncios que enviamos o mostramos en Google, Facebook, etc;",
  pp_t89: "Analizar la efectividad de nuestras campañas de marketing.",
  pp_t90: "Gestionar y mantener nuestras comunicaciones y sistemas de TI;",
  pp_t91:
    "Detectar y proteger contra: fraude; lavado de dinero, actividad criminal; uso ilícito de nuestros servicios; violaciones de nuestras políticas y leyes aplicables;",
  pp_t92: "Actividades de reclutamiento y manejo de solicitudes de empleo;",
  pp_t93:
    "Seguridad: seguridad física de nuestras instalaciones (incluidos los registros de visitas a nuestras instalaciones); grabaciones de CCTV; y seguridad electrónica (incluidos registros de inicio de sesión y detalles de acceso).",
  pp_t94: "El procesamiento es necesario para cumplir con una obligación legal o de cumplimiento.",
  pp_t95:
    "Salud y seguridad: evaluaciones de salud y seguridad y mantenimiento de registros; proporcionar un entorno seguro y protegido en nuestras instalaciones; y cumplimiento con las obligaciones legales relacionadas.",
  pp_t96:
    "Mantenimiento de registros para cumplir con requisitos legales, de auditoría, regulatorios y fiscales;",
  pp_t97:
    "Para investigar fraude, lavado de dinero, financiamiento del terrorismo y cualquier otra actividad ilegal;",
  pp_t98:
    "Para cumplir con los deberes legales y regulatorios relacionados con el lavado de dinero, el financiamiento del terrorismo; detección, prevención y enjuiciamiento de fraude y robo y prevención del uso ilícito de nuestros servicios o cualquier otra actividad ilegal o inapropiada;",
  pp_t99: "Para cumplir con la legislación de protección de datos y cualquier otra ley aplicable;",
  pp_t100:
    "Para cumplir con órdenes judiciales, divulgaciones a agencias de aplicación de la ley o agencias fiscales; solicitudes de la Oficina del Comisionado de Información, la Autoridad de Conducta Financiera u otra agencia gubernamental o reguladora con autoridad supervisora sobre nosotros.",
  pp_t101: "Hemos obtenido su consentimiento previo para el procesamiento.",
  pp_t102:
    "Con su consentimiento, podemos enviarle comunicaciones de marketing y promocionales por correo electrónico, SMS y notificaciones push (dependiendo de su elección de contacto). Para obtener más información, consulte la sección de Marketing Directo de esta política.",
  pp_t103:
    "Podemos enviar marketing dirigido sobre nuestros servicios en servicios en línea de terceros como Google, Instagram y Facebook si creemos que es probable que esté interesado en utilizar nuestros servicios según su perfil. Su consentimiento para recibir anuncios dirigidos habría sido obtenido por estos terceros en nuestro nombre.",
  pp_t104:
    "Encuestas: involucrarlo con el propósito de obtener sus opiniones sobre nuestros sitios, nuestras aplicaciones o nuestros servicios.",
  pp_t105: "Marketing Directo",
  pp_t106:
    "Podemos usar los detalles de contacto que proporcionó para enviarle comunicaciones de marketing por correo electrónico, teléfono, correo directo u otros formatos de comunicación sobre productos o servicios similares donde lo permita la ley aplicable (a menos que haya optado por no recibirlos).",
  pp_t107:
    "En otros casos, podemos solicitar su consentimiento para enviarle comunicaciones de marketing sobre productos o servicios ofrecidos por nosotros.",
  pp_t108: "Opciones de Exclusión de Marketing Directo",
  pp_t109:
    "Si tiene una cuenta de Passve, puede optar por no recibir comunicaciones de marketing de Passve modificando sus suscripciones por correo electrónico o SMS haciendo clic en el enlace de cancelación de suscripción o siguiendo el mensaje de exclusión incluido en la comunicación. Alternativamente, simplemente envíe un correo electrónico a dataprivacy@passve.com con “URGENTE - SOLICITUD DE CANCELACIÓN” en el asunto y la dirección de correo electrónico que desea que se elimine dentro del correo electrónico.",
  pp_t110:
    "Tenga en cuenta que si solicita que dejemos de enviarle mensajes de marketing o se da de baja, podemos continuar enviándole comunicaciones de servicio y administrativas como actualizaciones de transferencias y otra información importante sobre su transacción.",
  pp_t111: "Con quién podríamos compartir su información personal",
  pp_t112:
    "Su información personal es muy importante para nosotros. Sin embargo, hay circunstancias en las que es necesario que compartamos su información para fines comerciales legítimos (incluido el funcionamiento de nuestro sitio y nuestra aplicación móvil, y la prestación de servicios a usted), de acuerdo con la ley aplicable y sujeto a los requisitos profesionales y regulatorios aplicables respecto a la confidencialidad y el secreto profesional. Además, podemos divulgar su información personal a:",
  pp_t113: "para nuestras necesidades internas de negocio, operativas, de gestión y de servicio;",
  pp_t114:
    "para actividades de cumplimiento, regulatorias y de auditoría y en relación con reclamaciones e investigaciones legales, regulatorias y fiscales;",
  pp_t115:
    "para comprender y apoyar a nuestros clientes y otras personas que utilizan nuestros servicios, aplicaciones y visitan nuestros sitios; y",
  pp_t116: "para enviar comunicaciones de marketing.",
  pp_t117:
    "Procesadores de terceros, que incluyen organizaciones o individuos con los que hacemos negocios o que nos prestan servicios. Podemos divulgar su información personal para responder a requisitos legales (por ejemplo, como parte de nuestras obligaciones de “Conozca a su Cliente” y diligencia debida), hacer cumplir nuestras políticas, relacionarnos con autoridades judiciales o regulatorias según sea necesario bajo la ley aplicable, comprender la naturaleza de la transacción que ha realizado, proporcionar nuestros servicios y proteger nuestros derechos y propiedades. Si contratamos a un procesador de terceros para procesar su información, el Procesador estará sujeto a obligaciones contractuales vinculantes para: usar la información personal solo de acuerdo con nuestras instrucciones por escrito; y usar medidas para proteger la confidencialidad y seguridad de la información personal, junto con cualquier requisito adicional. Los procesadores pueden incluir:",
  pp_t118:
    "Agencias de referencia de crédito u otros proveedores de servicios para verificar su identidad o la identidad de los destinatarios del dinero transferido a través de nuestros servicios o para cualquier otro propósito relacionado con la prestación de nuestros servicios;",
  pp_t119: "Asesores profesionales, como nuestros auditores y abogados",
  pp_t120:
    "Empresas que capturan o analizan información para ayudarnos a entender cómo se utilizan y funcionan nuestros servicios, sitios y aplicaciones móviles o para adaptar nuestros servicios y promociones, incluida la mejora de los servicios que prestamos;",
  pp_t121:
    "Empresas que nos proporcionan asistencia en marketing, incluyendo: la gestión de operaciones de marketing por correo electrónico, SMS y otros servicios que implementan marketing en internet o plataformas de redes sociales (como Facebook y Google); la realización de encuestas y otras actividades de retroalimentación; así como el análisis de la efectividad de cualquier marketing o participación del cliente que realicemos;",
  pp_t122:
    "Bancos, procesadores de tarjetas de pago y otros proveedores de servicios que procesan transferencias bancarias, pagos con tarjeta de crédito y débito o que de otro modo proporcionan servicios de infraestructura financiera para permitirnos proporcionar nuestros servicios;",
  pp_t123:
    "Nuestros proveedores de servicios, incluidos aquellos que brindan servicios de alojamiento de datos, servicios de prevención de fraudes; servicios tecnológicos y herramientas tecnológicas que nos permiten monitorear, probar y mejorar nuestros servicios, sitios y aplicaciones móviles;",
  pp_t124:
    "Empresas a las que hemos encargado la prestación de servicios para o en nuestro nombre para aquellos fines que pueden deducirse razonablemente de las circunstancias en las que se presentó la información;",
  pp_t125:
    "Otras entidades comerciales si planeamos fusionarnos con, ser adquiridos por o recibir inversión de esa entidad comercial, o si experimentamos una reorganización corporativa; y",
  pp_t126: "Cualquier sucesor en el negocio de nosotros.",
  pp_t127:
    "Agencias de Prevención de Fraudes - Donde tengamos un buen motivo para sospechar fraude, lavado de dinero, financiamiento del terrorismo o cualquier otra actividad ilegal, podemos, a solicitud o donde estemos legalmente obligados, compartir su información personal con agencias de prevención del crimen y otros terceros con el propósito de detectar, prevenir o reportar delitos o para cualquier violación real o sospechada de cualquier ley o regulación aplicable. Estos terceros pueden incluir socios comerciales y empresas que prestan servicios a nosotros, organismos de aplicación de la ley, autoridades regulatorias y de supervisión, proveedores de servicios de prevención y detección de fraudes. Tenga en cuenta que podemos suspender la actividad en su cuenta o cualquier transacción, negar el acceso a su cuenta o cancelar cualquier transacción que desee realizar, si pensamos que existe un riesgo de actividad ilegal.",
  pp_t128:
    "Agencias Públicas - Publicamos cuentas u otra información personal cuando consideramos que es apropiado para cumplir con la ley, hacer cumplir nuestros Términos y Condiciones y cualquier otro acuerdo para proteger los derechos, propiedades o seguridad de Passve, nuestros empleados, clientes, socios comerciales y el público. En estas circunstancias, solo proporcionaremos la información que requieran para cumplir con su función.",
  pp_t129:
    "Google, usamos Google Analytics, que utiliza cookies y tecnologías similares para recopilar y analizar información sobre el uso de los sitios y reportar actividades y tendencias. Este servicio también puede recopilar información sobre el uso de otros sitios web, aplicaciones y servicios en línea. Puede conocer las prácticas de Google visitando google.com/policies/privacy/partners, y optar por no participar descargando el complemento de navegador de exclusión de Google Analytics, disponible en tools.google.com/dlpage/gaoptout",
  pp_t130:
    "Empresas de Investigación - Podemos compartir información personal de manera segura para permitir que empresas de investigación y proveedores de retroalimentación se contacten directamente con usted en nuestro nombre para obtener sus opiniones o rechazar la participación en la investigación o encuestas.",
  pp_t131: "Enlaces a Otros Sitios",
  pp_t132:
    "Nuestros sitios pueden contener enlaces a otros sitios web, incluidos a través de nuestros botones de redes sociales. Aunque intentamos enlazar solo a sitios web que comparten nuestros altos estándares y respeto por la privacidad, no somos responsables del contenido, seguridad o prácticas de privacidad empleadas por otros sitios web y un enlace no constituye una recomendación de ese sitio web. Una vez que se vincule a otro sitio web desde nuestro sitio, está sujeto a los términos y condiciones de ese sitio web, incluidos, entre otros, su política de privacidad en internet y prácticas. Por favor, consulte estas políticas antes de enviar cualquier dato a estos sitios web.",
  pp_t133: "Transferencia internacional de su información personal",
  pp_t134:
    "Debido a la naturaleza internacional de nuestro negocio, podemos transferir la información personal que recopilamos a países fuera del EEE que no proporcionan el mismo nivel de protección de datos que el país en el que reside y no están reconocidos por la Comisión Europea como proporcionando un nivel adecuado de protección de datos. Solo transferimos información personal a estos países cuando es necesario para los servicios que le proporcionamos, o es necesario para el establecimiento, ejercicio o defensa de reclamaciones legales o sujeto a salvaguardias que aseguren la protección de su información personal, tales como:",
  pp_t135:
    "Las cláusulas contractuales estándar aprobadas por la Comisión Europea en contratos para la transferencia de información personal a terceros países.",
  pp_t136:
    "Otros mecanismos aprobados por la UE, según lo requiera la legislación de protección de datos. Más información sobre estas medidas se puede encontrar en https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/rules-international-transfers-personal-data_e",
  pp_t137: "Cómo mantenemos su información personal segura",
  pp_t138:
    "Hemos implementado medidas de seguridad técnicas y organizativas adecuadas diseñadas para proteger su información personal contra destrucción accidental o ilegal, pérdida, alteración, divulgación no autorizada, acceso no autorizado y otras formas ilegales o no autorizadas de procesamiento, de acuerdo con la ley aplicable.",
  pp_t139:
    "Desafortunadamente, la transmisión de información a través de Internet (incluido por correo electrónico) no es completamente segura. Aunque haremos todo lo posible para proteger su información personal, no podemos garantizar la seguridad de sus datos transmitidos a nuestro sitio; y cualquier transmisión es bajo su propio riesgo. Una vez que hayamos recibido su información, utilizaremos procedimientos estrictos y características de seguridad para intentar prevenir el acceso no autorizado.",
  pp_t140: "Protegiendo su propia información",
  pp_t141:
    "Es importante mantener su contraseña segura para prevenir el uso fraudulento de su cuenta. No divulgue su contraseña a nadie más y especialmente a quienes la soliciten por teléfono o correo electrónico. Passve nunca le pedirá que proporcione su contraseña o información personal por correo electrónico. Tenga en cuenta lo siguiente:",
  pp_t142:
    "Cambie su contraseña regularmente y evite usar la misma contraseña en otros sitios web para prevenir que los hackers accedan a su cuenta;",
  pp_t143: "Evite contraseñas simples que puedan ser fácilmente adivinadas;",
  pp_t144:
    "Tenga en cuenta que las redes WiFi públicas son riesgosas y los hackers pueden capturar sus transacciones en línea e información personal. Solo conéctese a una red en la que pueda confiar;",
  pp_t145:
    "Si usa una computadora compartida, asegúrese de cerrar sesión inmediatamente después de haber terminado de usar el sitio web; y",
  pp_t146:
    "Tenga cuidado con el ‘phishing’ donde los delincuentes pueden intentar robar su información enviándole correos electrónicos falsos.",
  pp_t147: "Cuánto tiempo conservaremos su información personal",
  pp_t148:
    "Tomamos todas las medidas razonables para asegurar que su información personal solo se retenga durante el tiempo que sea necesario para los fines establecidos en esta Política. Por lo tanto, solo mantendremos la información que recopilamos sobre usted durante el tiempo que sea necesario para los fines descritos anteriormente, o según se requiera para cumplir con cualquier obligación legal o regulatoria a la que estemos sujetos. Esto implicará revisar regularmente nuestros archivos para verificar que la información sea precisa, esté actualizada y aún sea necesaria.",
  pp_t149:
    "Además, después de que haya dejado de ser cliente, podemos tomar medidas para eliminar su información personal o mantenerla en una forma que ya no lo identifique (por ejemplo, podemos seguir utilizando sus datos en un formato anonimizado para fines de investigación y otros fines comerciales). El período durante el cual retendremos la información sobre usted variará según el tipo de información y los fines para los que la usemos. En general, a menos que la ley aplicable requiera un período de retención más largo, mantendremos nuestros registros mientras sea cliente nuestro y durante 6 años después del final de su relación con nosotros.",
  pp_t150:
    "Para obtener más detalles sobre los períodos de retención que aplicamos a su información personal, comuníquese con dataprivacy@passve.com",
  pp_t151: "Sus Derechos",
  pp_t152:
    "Sujeto a la ley aplicable, puede tener varios derechos con respecto a nuestro procesamiento de su información personal relevante, incluidos:",
  pp_t153:
    "El derecho a no proporcionar su información personal a nosotros (sin embargo, tenga en cuenta que es posible que no podamos proporcionarle el beneficio completo de nuestro sitio, nuestra aplicación móvil o nuestros servicios, si no nos proporciona su información personal);",
  pp_t154:
    "El derecho a solicitar acceso a su información personal, junto con información sobre la naturaleza, el procesamiento y la divulgación de esa información personal;",
  pp_t155:
    "El derecho a solicitar la corrección de cualquier inexactitud en su información personal;",
  pp_t156:
    "El derecho a solicitar la eliminación de su información personal. Esto también se conoce como el derecho al olvido. Mantendremos una nota de su nombre si solicita que se elimine su información personal;",
  pp_t157: "El derecho a que restrinjamos el uso de su información personal;",
  pp_t158:
    "El derecho a oponerse a nuestro uso de su información personal. Siempre que utilicemos el interés legítimo como base para procesar su información personal, puede oponerse a nuestro uso de ella",
  pp_t159:
    "El derecho a que cierta información personal sea transferida a otro Responsable, en un formato estructurado, comúnmente utilizado y legible por máquina, en la medida en que sea aplicable;",
  pp_t160:
    "Cuando utilicemos su información personal sobre la base de su consentimiento, tiene el derecho a retirar ese consentimiento (notando que dicha retirada no afecta la legalidad de ningún procesamiento realizado antes de la fecha en la que recibimos notificación de dicha retirada, y no impide el procesamiento de su información personal en base a cualquier otra base legal disponible); y",
  pp_t161:
    "El derecho a presentar quejas ante una Autoridad de Protección de Datos respecto al uso de su información personal por nosotros o en nuestro nombre.",
  pp_t162:
    "Podemos requerir prueba de su identidad antes de poder hacer efectivos estos derechos. También debe tener en cuenta que algunos de estos derechos están calificados y no son absolutos; por lo tanto, pueden aplicarse excepciones o limitaciones. Por ejemplo, podemos negarnos a proporcionar información si cumplir con su solicitud revelaría información personal sobre otra persona, o si solicita que eliminemos información que estamos obligados a retener por ley, que tenemos intereses legítimos apremiantes para conservar, o que necesitamos para cumplir con nuestras obligaciones legales.",
  pp_t163:
    "La forma más rápida y fácil de hacer una solicitud de sujeto de datos es contactarnos directamente para ejercer sus derechos enviando un correo electrónico a dataprivacy@passve.com. Nos esforzaremos por responder dentro de un período razonable y, en cualquier caso, dentro de un mes (tres meses para solicitudes complejas o numerosas), de conformidad con la Legislación de Protección de Datos.",
  pp_t164:
    "Nos reservamos el derecho de cobrar una tarifa razonable (reflejando los costos de proporcionar la información) o de negarnos a responder cuando las solicitudes sean manifiestamente infundadas o excesivas: en este caso, explicaremos la situación y le informaremos sobre sus derechos.",
  pp_t165: "Verificación de Identidad",
  pp_t166:
    "Solo procesaremos su solicitud de derechos de sujeto de datos cuando estemos satisfechos de que hemos verificado con éxito su identidad y usted es quien solicita que se procese su información personal. No procesaremos datos personales si dudamos de la identidad del solicitante. Puede que se le pida que demuestre su identidad al hacer una solicitud de sujeto de datos.",
  pp_t167: "Solicitudes realizadas por terceros",
  pp_t168:
    "De acuerdo con nuestras prácticas de privacidad y para proteger sus derechos y libertades, no podemos procesar una solicitud de derechos de sujeto de datos realizada por un tercero (como Rightly) cuando; haya dudas de que el tercero tiene la autoridad apropiada para actuar en su nombre; y cuando no estemos satisfechos con la evidencia adecuada proporcionada en apoyo de la verificación de identidad. Siempre intentaremos asegurarnos de que un tercero tenga el consentimiento o la autoridad apropiada para actuar en su nombre, y lo más importante, que usted esté plenamente consciente de que el tercero ha contactado específicamente con nosotros, respecto al ejercicio de sus derechos de sujeto de datos.",
  pp_t169:
    "Antes de que podamos procesar una solicitud de derechos de sujeto de datos realizada por un tercero en su nombre, requerimos como mínimo del tercero:",
  pp_t170: "Una copia de un documento de identidad para usted;",
  pp_t171: "Evidencia de su dirección; y",
  pp_t172:
    "Una autorización firmada físicamente por usted; o una autorización verbal proporcionada directamente a nosotros por usted y anotada en su cuenta; y si se proporciona electrónicamente, la autorización debe venir directamente de su dirección de correo electrónico que coincida con nuestros registros.",
  pp_t173:
    "No accederemos ni procesaremos sus datos personales sin estar seguros de que estamos actuando bajo sus instrucciones específicas.",
  pp_t174: "Cookies y tecnologías similares",
  pp_t175:
    "Cuando visite nuestro sitio o use una aplicación móvil, podemos colocar Cookies en su dispositivo, o leer Cookies ya presentes en su dispositivo, siempre sujeto a obtener su consentimiento de acuerdo con la ley aplicable. Usamos Cookies para registrar información sobre su dispositivo, su navegador y, en algunos casos, sus preferencias y hábitos de navegación. Podemos utilizar su información personal a través de Cookies y tecnologías similares, de acuerdo con nuestra política de Cookies.",
};

export const getEspanol = () => {
  return espanol;
};
