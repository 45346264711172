import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import Header from "../common/Header";
import Button from "../common/Button";
import Footer from "../common/Footer";
import { program } from "../utils/constants";
import Api from "../api/api";
import CustomModal from "../layout/Modal";
import { useAuth } from "../context/auth";
import { v4 as uuidv4 } from "uuid";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiSolidCopy } from "react-icons/bi";
import QRCode from "react-qr-code";
import Select from "react-select";
import { acceleratedHead, evaluationHead } from "../utils/plans";
import { countries } from "../utils/countries";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { PAYMENT_METHOD } from "../utils/constants";

const public_key = process.env.REACT_APP_STRIPE_PUBLIC_TEST;
const stripePromise = loadStripe(public_key);

const initialData = {
  fname: "",
  lname: "",
  email: "",
  city: "",
  country: "",
  zip: "",
  state: "",
  address: "",
  phone: "",
  method: PAYMENT_METHOD.CRYPTO,
};

const Checkout = () => {
  const plan = sessionStorage.getItem("passve-plan")
    ? atob(sessionStorage.getItem("passve-plan"))
    : 0;
  const activeType = sessionStorage.getItem("passve-type")
    ? atob(sessionStorage.getItem("passve-type"))
    : "Evaluation";

  const { user } = useAuth();
  const history = useHistory();
  const [isDisable, setIsDisable] = useState(false);
  const [platform, setPlatform] = useState([]);
  const [platformArray, setPlatformArray] = useState([]);
  const [orderExtras, setOrderExtras] = useState([]);
  const [billing, setBilling] = useState({ ...initialData });
  const [orderExtra, setOrderExtra] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState("");
  const [payModal, setPayModal] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const [price, setPrice] = useState(
    activeType === "Evaluation"
      ? evaluationHead.find(itm => itm.capital === Number(plan)).amount
      : acceleratedHead.find(itm => itm.capital === Number(plan)).amount,
  );
  const [selections, setSelections] = useState({
    program: activeType,
    capital: Number(plan),
    platform: platform?.[0],
  });
  const [clientSecret, setClientSecret] = useState("");

  const handleInputChange = e => {
    const { name, value } = e.target;
    setBilling(prev => ({ ...prev, [name]: value }));
  };

  const handlePay = async e => {
    e.preventDefault();
    setIsDisable(true);
    try {
      if (PAYMENT_METHOD.CRYPTO === billing.method) {
        const orderId = uuidv4();
        let res = await axios.post(
          "https://api.nowpayments.io/v1/payment",
          {
            // price_amount: selections.capital,
            price_amount: Number(price),
            price_currency: "usd",
            pay_currency: currency,
            ipn_callback_url: "https://nowpayments.io",
            order_id: orderId,
            order_description: `${user.email}: ${selections.platform} ${selections.program} $${selections.capital}`,
            customer_email: user.email,
          },
          {
            headers: {
              "x-api-key": process.env.REACT_APP_NOW_PAYMENT_KEY,
            },
          },
        );
        if (res?.status === 201) {
          setPaymentData(res.data);
          handleSubmit(orderId, res.data, PAYMENT_METHOD.CRYPTO);
        }
      } else {
        // const response = await fetch(backend_url + "create-checkout-recipe-session", {
        //   method: "POST",
        // });

        let data = {
          items: [
            {
              name: `Payment for Passve program: ${selections.program} with capital: $${selections.capital} using platform: ${selections.platform}`,
              price: parseInt(price),
              quantity: 1,
            },
          ],
        };

        const res = await Api.stripeCheckout(data);

        if (res.data.status === 200) {
          let data = {
            program: selections.program,
            capital: selections.capital,
            platform: platformArray?.find(item => item.name == selections.platform)?._id,
            billingInfo: { ...billing, fname: user?.fname, lname: user?.lname, email: user?.email },
            orderExtra: orderExtra?.map(item => item._id),
            status: "placed",
            price: parseInt(price),
            method: PAYMENT_METHOD.STRIPE,
          };
          sessionStorage.setItem("payment_data", JSON.stringify(data));
          window.location.href = res?.data?.data?.url;
        } else {
          toast.success("Error occured while payment");
        }
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsDisable(false);
    }
  };

  const handleOrderExtraChange = (e, itm) => {
    if (e.target.checked) {
      setOrderExtra(prev => [...prev, itm]);
    } else {
      setOrderExtra(prev => prev.filter(item => item._id !== itm._id));
    }
  };

  const handleSubmit = async (orderId, transaction, method) => {
    let data = {
      program: selections.program,
      capital: selections.capital,
      platform: platformArray?.find(item => item.name == selections.platform)?._id,
      billingInfo: { ...billing, fname: user?.fname, lname: user?.lname, email: user?.email },
      orderExtra: orderExtra?.map(item => item._id),
      status: "placed",
      price: parseInt(price),
      method: method,
    };

    try {
      let res = await Api.placeOrder({ data: { ...data, orderId }, transaction });
      if (res.status === 200) {
        toast.success("Order placed successfully");
        setPayModal(false);
        setShowQR(true);
      }
    } catch (error) {
      toast.error("Unable to place Order: " + error.message);
    }
  };

  const getOrderExtras = useCallback(async () => {
    try {
      let res = await Api.getOrderExtra(false);
      if (res?.status === 200) {
        setOrderExtras(res.data.data?.map(itm => ({ _id: itm?._id, name: itm?.name })) || []);
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [setOrderExtras]);

  const getPlatforms = useCallback(async () => {
    try {
      let res = await Api.getPlatforms(false);
      if (res?.status === 200) {
        let platformData = [];
        res?.data?.data?.map(itm => platformData.push(itm.name));
        setPlatform(platformData);
        setPlatformArray(res.data.data);
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [setPlatform]);

  const getCurrencies = useCallback(async () => {
    try {
      let res = await axios.get("https://api.nowpayments.io/v1/merchant/coins", {
        headers: {
          "x-api-key": process.env.REACT_APP_NOW_PAYMENT_KEY,
        },
      });
      if (res?.status === 200) {
        setCurrencies(res.data?.selectedCurrencies || []);
        setCurrency(res.data?.selectedCurrencies?.[0]);
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [setCurrencies]);

  useEffect(() => {
    getPlatforms();
    getOrderExtras();
    getCurrencies();
    return () => {
      setBilling({ ...initialData });
    };
  }, []);

  return (
    <>
      <Header />
      <main>
        <form
          className="py-12 md:py-16"
          onSubmit={e => {
            e.preventDefault();
            if (!selections.platform) return alert("Please select platform");
            // if (orderExtra.length === 0) return alert("Please select addon(s)");
            if (PAYMENT_METHOD.CRYPTO === billing.method) {
              setPayModal(true);
            } else {
              handlePay(e);
            }
          }}
        >
          <div className="mx-auto max-w-[1280px] px-6 w-full">
            <Selection
              title="Program Selection"
              name="program"
              array={program}
              data={selections}
              setData={setSelections}
              price={false}
              setPrice={setPrice}
            />
            <Selection
              title="Capital Size"
              name="capital"
              array={selections.program === "Accelerated" ? acceleratedHead : evaluationHead}
              data={selections}
              setData={setSelections}
              price={true}
              setPrice={setPrice}
            />
            <Selection
              title="Platform"
              name="platform"
              array={platform}
              data={selections}
              setData={setSelections}
              price={false}
            />
            <div className="p-3 rounded-md bg-secondary">
              <div>
                <h3 className="text-[22px] font-semibold mb-3">Order Summary</h3>
                <div className="pl-3">
                  <p className="font-medium mb-1">
                    Program: <span className="font-semibold pl-8">{selections.program}</span>
                  </p>
                  <p className="font-medium mb-1">
                    Capital Size:{" "}
                    <span className="font-semibold pl-2">
                      ${selections.capital.toLocaleString()}
                    </span>
                  </p>
                  <p className="font-medium">
                    Platform: <span className="font-semibold pl-8">{selections.platform}</span>
                  </p>
                  <p className="font-medium">
                    Amount: <span className="font-semibold pl-8">${price}</span>
                  </p>
                </div>
              </div>
              <div className="mt-4 pl-3">
                <div className="p-4 rounded-md bg-white">
                  <p className="text-lg font-semibold">Billing Information</p>
                  <span className="text-xs font-medium">
                    *Only Required for Centralised Broker Solutions. Just Connect Wallet for
                    Decentralised Option.
                  </span>
                  <div className="mt-3 flex items-center max-md:flex-wrap gap-3 mb-3">
                    <Input
                      name="fname"
                      placeholder="First Name"
                      value={user?.fname}
                      disabled
                      onChange={handleInputChange}
                      required
                    />
                    <Input
                      name="lname"
                      placeholder="Last Name"
                      value={user?.lname}
                      disabled
                      onChange={handleInputChange}
                      required
                    />
                    <Input
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={user?.email}
                      disabled
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="flex items-center max-md:flex-wrap gap-3 mb-3">
                    <Select2
                      title="Country"
                      name="country"
                      value={billing.country}
                      onChange={handleInputChange}
                      options={countries}
                      required
                    />
                    <Input
                      name="city"
                      placeholder="City"
                      value={billing.city}
                      onChange={handleInputChange}
                      required
                    />
                    <Input
                      name="state"
                      placeholder="State"
                      value={billing.state}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="flex items-center max-md:flex-wrap gap-3">
                    <Input
                      name="address"
                      placeholder="Address"
                      value={billing.address}
                      onChange={handleInputChange}
                      required
                    />
                    <Input
                      name="zip"
                      type="text"
                      placeholder="Postcode/Zip Code"
                      value={billing.zip}
                      onChange={handleInputChange}
                      required
                    />
                    <Input
                      type="text"
                      name="phone"
                      placeholder="Phone Number"
                      value={billing.phone}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 pl-3">
                <div className="p-4 rounded-md bg-white">
                  <p className="text-lg font-semibold">Additional Cover Options </p>

                  <div className="mt-2 flex items-center gap-x-8 gap-y-1 flex-wrap">
                    {orderExtras?.map(itm => (
                      <div
                        key={itm?._id}
                        className="flex items-center gap-2"
                      >
                        <input
                          id={itm?._id}
                          type="checkbox"
                          onClick={e => handleOrderExtraChange(e, itm)}
                          className="cursor-pointer"
                        />
                        <label
                          className=" cursor-pointer text-base opacity-90 font-medium"
                          htmlFor={itm?._id}
                        >
                          {itm?.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="mt-4 pl-3">
                <div className="p-4 rounded-md bg-white">
                  <p className="text-lg font-semibold">Payment Method </p>

                  <div className="flex items-center max-md:flex-wrap gap-3 mb-3">
                    <Select2
                      title="method"
                      name="method"
                      value={billing.method}
                      onChange={handleInputChange}
                      options={[
                        { value: PAYMENT_METHOD.CRYPTO, label: PAYMENT_METHOD.CRYPTO },
                        { value: PAYMENT_METHOD.STRIPE, label: PAYMENT_METHOD.STRIPE },
                      ]}
                      required
                    />
                  </div>
                </div>
              </div>

              <p className="mt-3 pl-3 text-sm flex items-center gap-3">
                <input
                  type="checkbox"
                  id="confirm"
                  className="cursor-pointer"
                  required
                />
                <label
                  htmlFor="confirm"
                  className="cursor-pointer"
                >
                  I hereby agree to the{" "}
                  <a
                    href="/privacy-policy"
                    className="text-primary2 underline"
                  >
                    Privacy & Policy
                  </a>{" "}
                  in conjunction with the{" "}
                  <a
                    href="/terms-of-use"
                    className="text-primary2 underline"
                  >
                    Terms & Conditions
                  </a>
                  .
                </label>
              </p>

              <div className="mt-5 pl-3">
                <Button
                  type="submit"
                  disabled={isDisable}
                >
                  Confirm Order
                </Button>
              </div>
            </div>
          </div>
        </form>
      </main>
      <Footer />

      {payModal && (
        <CustomModal
          title="Pay"
          onHide={() => setPayModal(false)}
        >
          <form onSubmit={handlePay}>
            <p className="text-lg font-medium mb-4 text-center">
              Amount: <span className="font-semibold text-xl">${price}</span>
            </p>
            <Select
              title="Select currency"
              value={currencies.find(itm => itm.value === currency)}
              onChange={val => setCurrency(val.value)}
              required
              options={currencies?.map(itm => ({ value: itm, label: itm?.toUpperCase() }))}
            />
            <br />
            <Button
              disabled={isDisable}
              type="submit"
              className="!w-full"
            >
              Pay
            </Button>

            <div id="checkout">
              {clientSecret && (
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{ clientSecret }}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              )}
            </div>
          </form>
        </CustomModal>
      )}

      {showQR && (
        <CustomModal
          title="Payment Address"
          onHide={() => {
            sessionStorage.removeItem("passve-plan");
            sessionStorage.removeItem("passve-type");
            history.replace("/dashboard/order-history");
            setShowQR(false);
          }}
        >
          <QRCode
            height={150}
            width={150}
            value={paymentData?.pay_address}
            className="block mx-auto size-[150px] mb-3"
          />
          <p className="text-center my-1 text-sm">OR</p>
          <div className="flex items-center justify-center flex-wrap gap-3 mt-4 mb-2 w-full">
            <p className="max-sm:w-[90%] border border-dashed border-primary bg-[#016fd014] text-primary max-sm:text-sm font-[600] px-4 py-2 md:px-6 md:py-3 rounded-[10px] text-ellipsis overflow-hidden">
              {paymentData?.pay_address}
            </p>
            <CopyToClipboard
              text={paymentData?.pay_address}
              onCopy={() => toast.success("Payment address copied!")}
            >
              <Button className="max-sm:w-[90%] flex justify-center items-center gap-x-2">
                <BiSolidCopy
                  fill="white"
                  size={20}
                />
                <span className="text-[#ffffff]">Copy</span>
              </Button>
            </CopyToClipboard>
          </div>
          <br />
          <h2 className="text-center">
            <b>Asset Name: {currency.toUpperCase()}</b>
          </h2>
          <br />
          <h2
            className="text-center"
            style={{ color: "red" }}
          >
            Expire in 1 Day from now
          </h2>
          <br />
          <p className="text-xl font-[600]">Order Summary</p>
          <table>
            <tr>
              <td className="w-[120px]">
                <h4>Program: </h4>
              </td>
              <td>
                <h4>{selections.program}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Capital: </h4>
              </td>
              <td>
                <h4>${selections.capital}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Amount: </h4>
              </td>
              <td>
                <h4>${price}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Platform: </h4>
              </td>
              <td>
                <h4>{selections.platform}</h4>
              </td>
            </tr>
            <tr>
              <td className="w-[120px]">
                <h4>Order Extra: </h4>
              </td>
              <td>
                <h4>{orderExtra?.map(item => item?.name).join(", ")}</h4>
              </td>
            </tr>
          </table>
        </CustomModal>
      )}
    </>
  );
};

const Selection = ({ title, data, setData, name, array, price, setPrice }) => {
  return (
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-3">{title}</h3>
      <div className="flex items-center gap-4 flex-wrap">
        {array?.map((itm, idx) => (
          <p
            key={idx}
            onClick={() => {
              setData(prev => ({ ...prev, [name]: price ? itm?.capital : itm }));
              price
                ? setPrice(itm?.amount)
                : name === "program"
                  ? setPrice(
                      itm === "Evaluation"
                        ? evaluationHead.find(itm => itm.capital === data?.capital).amount
                        : acceleratedHead.find(itm => itm.capital === data?.capital).amount,
                    )
                  : console.log();
            }}
            className={`${(price ? itm?.capital : itm) === data[name] ? "bg-primary2 text-white" : "bg-gray"}
                            cursor-pointer font-medium rounded-[100px] p-2 px-7`}
          >
            {price ? `$${itm?.capital?.toLocaleString()}` : itm}
          </p>
        ))}
      </div>
    </div>
  );
};

const Input = ({ ...rest }) => {
  return (
    <input
      className="w-full bg-[#f9fafb] border border-[#ddd] rounded-md px-4 py-2 text-base md:[&>input]:flex-1"
      {...rest}
    />
  );
};

const Select2 = ({ options = [], ...rest }) => {
  return (
    <select
      className="w-full bg-[#f9fafb] border border-[#ddd] rounded-md px-4 py-2 text-base md:[&>input]:flex-1"
      {...rest}
    >
      {options?.map(itm => (
        <option value={itm.value}>{itm.label}</option>
      ))}
    </select>
  );
};

export default Checkout;
