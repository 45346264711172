import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

const Auth = ({ title = "", submitHandle, children }) => {
  return (
    <div className="flex min-h-screen bg-gray">
      <div className="grid w-full place-items-center min-h-[100%] p-6">
        <form
          onSubmit={submitHandle}
          className="w-full max-w-[450px] 2xl:max-w-[500px] bg-white p-6 rounded-xl"
        >
          <Link
            to="/"
            className="grid place-items-center border-b border-gray pb-4"
          >
            <img
              className="w-[250px]"
              src={logo}
              alt="Passve Logo"
            />
          </Link>
          <h1 className=" mt-4 mb-5 text-xl font-semibold 2xl:text-3xl">{title}</h1>
          {children}
        </form>
      </div>
    </div>
  );
};

export default Auth;
