import React from "react";
import { FaInstagram, FaDiscord, FaGithub, FaTelegram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const social = [
  { icon: FaInstagram, link: "https://medium.com/@passve" },
  { icon: FaSquareXTwitter, link: "https://x.com/Passve_Exchange" },
  { icon: FaDiscord, link: "https://discord.com/invite/nsGKMuQfUe" },
  { icon: FaGithub, link: "https://github.com/Passve/Passve.git " },
  { icon: FaTelegram, link: "https://t.me/passve_exchange" },
];

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-gray">
      <div className="w-full text-center px-4 md:px-6 py-8 md:py-12 max-w-[1367px] mx-auto">
        <div className="space-y-4 sm:space-x-12">
          <Link
            to="/cookie-policy"
            className="font-medium hover:text-primary max-sm:block"
          >
            {t("cookies_policy")}
          </Link>
          <Link
            to="/terms-of-use"
            className="font-medium hover:text-primary max-sm:block"
          >
            {t("term_condition")}
          </Link>
          <Link
            to="/privacy-policy"
            className="font-medium hover:text-primary max-sm:block"
          >
            {t("privacy_policy")}
          </Link>
        </div>
        <div className="w-full flex items-center justify-center gap-6 my-5">
          {social?.map((itm, idx) => (
            <a
              href={itm?.link}
              key={idx}
              target="_blank"
            >
              <itm.icon
                fill="#6b6b6b"
                size={24}
              />
            </a>
          ))}
        </div>
        <p className="opacity-80">
          {new Date().getFullYear()} Passve. {t("rights_reserved")}.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
