import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout/Layout";
import Input from "../../common/Input";
import Api from "../../api/api";
import Button from "../../common/Button";

const initialData = {
  oldPassword: "",
  confirmPassword: "",
  newPassword: "",
};

const Password = () => {
  const [isDisable, setIsDisable] = useState(false);
  const [data, setData] = useState({ ...initialData });

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setIsDisable(true);
    try {
      const res = await Api.changePassword(data);
      if (res.status === 200) {
        setData({ ...initialData });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsDisable(false);
    }
  };

  useEffect(() => {
    return () => {
      setData({ ...initialData });
      setIsDisable(false);
    };
  }, []);

  return (
    <DashboardLayout title="Password">
      <form onSubmit={handleSubmit}>
        <div className="max-w-[450px]">
          <Input
            type="password"
            title="Old Password"
            name="oldPassword"
            value={data.oldPassword}
            onChange={handleChange}
            placeholder="Old Password"
            required
          />
          <Input
            type="password"
            title="New Password"
            name="newPassword"
            value={data.newPassword}
            onChange={handleChange}
            placeholder="New Password"
            required
          />
          <Input
            type="password"
            title="Confirm Password"
            name="confirmPassword"
            value={data.confirmPassword}
            onChange={handleChange}
            placeholder="Confirm Password"
            required
          />
        </div>
        <br />

        <Button
          type="submit"
          disabled={isDisable}
        >
          Change
        </Button>
      </form>
    </DashboardLayout>
  );
};

export default Password;
