import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { IoIosCloseCircleOutline } from "react-icons/io";

const CustomModal = ({ children, title, onHide }) => {
  const modalRef = useRef();
  useEffect(() => {
    let handler = e => {
      if (!modalRef.current.contains(e.target)) {
        onHide();
      }
    };

    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, [onHide]);

  return createPortal(
    <>
      <div
        className="fixed top-0 left-0 right-0 bottom-0 z-[900] bg-[#0000004D]"
        style={{ backdropFilter: "blur(2px)" }}
      ></div>
      <div
        ref={modalRef}
        className="model-cont fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[1000] max-sm:max-w-[90vw] w-full max-w-[500px] h-auto p-4 md:p-6 bg-white rounded-xl"
      >
        <div className="flex items-center justify-between pb-2 mb-2 font-[500]">
          <p className="text-xl font-[600]">{title}</p>
          <IoIosCloseCircleOutline
            onClick={onHide}
            size={22}
            className="cursor-pointer"
          />
        </div>
        <div className="custom_modal_body">{children}</div>
      </div>
    </>,
    document.getElementById("portal"),
  );
};

export default CustomModal;
