import DashboardLayout from "../../layout/Layout";
import avatar from "../../assets/avatar.png";
import { IoCardOutline } from "react-icons/io5";
import { LuCircleDollarSign } from "react-icons/lu";
import { GrLanguage } from "react-icons/gr";
import { FaRegBell } from "react-icons/fa";
import { MdOutlineCookie } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/auth";

const links = [
  {
    icon: IoCardOutline,
    text: "Payment Method",
    to: "#",
  },
  {
    icon: LuCircleDollarSign,
    text: "Default Currency",
    to: "#",
  },
  {
    icon: GrLanguage,
    text: "Language",
    to: "#",
  },
  {
    icon: FaRegBell,
    text: "Notifications",
    to: "#",
  },
  {
    icon: MdOutlineCookie,
    text: "Cookies Policy",
    to: "#",
  },
  {
    icon: BiSupport,
    text: "Help & Support",
    to: "/faqs",
    targetBlank: true,
  },
  {
    icon: RiDeleteBinLine,
    text: "Delete Account",
    to: "#",
  },
];

const Settings = () => {
  const { user } = useAuth();
  return (
    <DashboardLayout title="Settings">
      <div className="p-2 ml-8 md:ml-16 flex items-center w-fit gap-4 border-b border-[#ddd]">
        <div className="bg-primary text-white grid place-items-center rounded-full size-12 font-bold uppercase">
          {user?.fname[0]}
          {user?.lname[0]}
        </div>
        <span className="text-xl font-semibold">Personal Profile</span>
      </div>
      <div className="mt-5 ml-8 md:ml-16 space-y-3">
        {links?.map((itm, idx) =>
          !itm?.targetBlank ? (
            <Link
              to={itm.to}
              key={idx}
              className="flex items-center gap-3 cursor-pointer p-2 w-fit"
            >
              <itm.icon size={22} />
              <span className="text-sm font-medium">{itm.text}</span>
            </Link>
          ) : (
            <a
              href={itm.to}
              key={idx}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-3 cursor-pointer p-2 w-fit"
            >
              <itm.icon size={22} />
              <span className="text-sm font-medium">{itm.text}</span>
            </a>
          ),
        )}
      </div>
    </DashboardLayout>
  );
};

export default Settings;
