import DashboardLayout from "../../layout/Layout";
import PayoutTable from "../../components/payout/Table";

const AdminPayout = () => {
  return (
    <>
      <DashboardLayout title={`Payouts`}>
        <PayoutTable isAdmin={true} />
      </DashboardLayout>
    </>
  );
};

export default AdminPayout;
