import React from "react";
import { useHistory } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";
import community from "../../assets/home/community.svg";
import { useTranslation } from "react-i18next";

const Newsletter = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="py-12 md:py-16 bg-secondary">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <div className="w-full flex flex-wrap gap-4">
          <div className="bg-white flex-grow-[2] rounded-xl p-4 md:p-6 border border-[#ddd]">
            <h2 className="text-2xl font-semibold mb-4">{t("subscribe_newsletter")}</h2>
            <div
              className="flex items-center w-fit gap-4 cursor-pointer"
              onClick={() => history.push("/auth/signup")}
            >
              <div className="p-3 w-fit rounded-full bg-primary">
                <FaUserPlus
                  fill="#FFFFFF"
                  size={28}
                />
              </div>
              <div className="md:max-w-[300px]">
                <p className="text-lg font-medium">{t("create_account")}</p>
                <p className="text-sm">{t("h_account_subtitle")}</p>
              </div>
            </div>
          </div>
          <div className="bg-white flex-grow-[1] md:max-w-[455px] rounded-xl p-4 md:p-6 border border-[#ddd]">
            <h2 className="text-2xl font-semibold mb-4 capitalize">{t("join_our_community")}</h2>
            <a
              href="https://discord.com/invite/nsGKMuQfUe"
              target="_blank"
              rel="noreferrer"
              className="mx-auto bg-[#5865f2] rounded-lg w-fit block p-[3px_10px]"
            >
              <img
                src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/6257d23c5fb25be7e0b6e220_Open%20Source%20Projects%20_%20Discord-7.svg"
                alt="discord"
                className="w-[150px]"
              />
            </a>
            <p className="text-center text-sm mt-0.5 font-semibold"> {t("members_online")} </p>
            <img
              src={community}
              alt="community"
              className="block mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
