import React from "react";
import { useTranslation } from "react-i18next";
import img1 from "../../assets/home/feature/img1.png";
import img2 from "../../assets/home/feature/img2.png";
import img3 from "../../assets/home/feature/img3.png";
import img4 from "../../assets/home/feature/img4.png";
import img5 from "../../assets/home/feature/img5.png";
import img6 from "../../assets/home/feature/img6.png";
const data = [img1, img2, img3, img4, img5, img6, img1, img2, img3, img4, img5, img6];

const Featured = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 md:py-16 bg-primary2">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <div className="flex flex-wrap items-center gap-5 w-full">
          <p
            className="max-md:w-full text-sm font-bold text-center uppercase text-white"
            style={{ letterSpacing: 0.5 }}
          >
            {t("we_are_featured_in")}
          </p>
          <div className="w-full md:w-[calc(100%_-_156px_-_20px)] overflow-hidden">
            <div className="flex overflow-hidden h-[42px] md:h-[62px] w-[calc(134px_*_12)] md:w-[calc(194px_*_12)] feature-scroll">
              {data?.map((itm, idx) => (
                <div
                  key={idx}
                  className="min-w-[110px] md:min-w-[170px] mx-3 flex items-center justify-center min-h-[42px]"
                >
                  <img
                    src={itm}
                    alt="feature"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
