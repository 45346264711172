import React from "react";
import { useTranslation } from "react-i18next";

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <div className="py-12 md:py-16">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <div className="bg-primary2 rounded-xl p-4 md:p-6">
          <p className="text-white">September 4, 2023</p>
          <div className="flex flex-wrap gap-4 items-center justify-between mt-12">
            <div>
              <p className="text-white font-semibold text-2xl">{t("welcome_back")} 😎!</p>
              <p className="text-white mt-2">{t("p_welcome_subtite")}</p>
            </div>
            <div className="text-center md:text-end max-md:w-full">
              <p className="text-6xl font-semibold text-white italic">
                0 <span className="text-base text-white">Affiliate Points</span>
              </p>
              <p className="text-2xl font-semibold text-white">~ 0.00 SVE</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
