import React from "react";

const Select = ({ title = "", children, options = [], ...rest }) => {
  return (
    <div className="relative mt-4">
      {title && <label className="capitalize font-semibold mb-1 block text-sm">{title}</label>}
      <select
        className="w-full bg-[#f9fafb] border border-[#ddd] rounded-md px-4 py-2 text-sm"
        {...rest}
      >
        {options?.map(itm => (
          <option value={itm.value}>{itm.label}</option>
        ))}
      </select>
      {children}
    </div>
  );
};

export default Select;
