import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Table = ({ data }) => {
  const { t } = useTranslation();
  const tHead = ["#", "name", "last_price", "change", "market_stats", "trade"];

  const series = [
    { x: "Jan", y: 0 },
    { x: "Feb", y: 0 },
    { x: "Mar", y: 0 },
    { x: "Apr", y: 0 },
  ];

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: series.map(item => item.x),
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const optionsUp = {
    ...options,
    series: [
      {
        color: "#20E19F",
        data: series,
      },
    ],
    stroke: {
      curve: "smooth",
      width: 1,
      colors: ["#20E19F"],
    },
  };

  const optionsDown = {
    ...options,
    series: [
      {
        color: "#ef4444",
        data: series,
      },
    ],
    stroke: {
      curve: "smooth",
      width: 1,
      colors: ["#ef4444"],
    },
  };

  return (
    <div className="w-full max-md:overflow-x-scroll">
      <table className="w-full max-md:min-w-[730px]">
        <thead>
          <tr className="border-b border-[#ddd]">
            {tHead?.map((itm, idx) => (
              <td
                key={idx}
                className="text-start font-semibold p-2"
              >
                {t(itm)}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((itm, idx) => (
            <tr
              key={idx}
              className="border-b border-[#ddd] font-medium text-sm [&>td]:p-2 hover:bg-secondary"
            >
              <td>{itm?.no}</td>
              <td>
                <div className="flex items-center gap-2">
                  <img
                    src={itm?.coinURL}
                    alt={itm?.coin}
                    className="rounded-full overflow-hidden size-8"
                  />
                  <span className="capitalize">{itm?.coin}</span>
                  {itm?.coinAbbr && (
                    <span className="uppercase opacity-85 pl-2 border-l border-[#ddd]">
                      {itm?.coinAbbr}
                    </span>
                  )}
                </div>
              </td>
              <td>{itm.lastPrice}</td>
              {/* <td className={`${!itm?.up && "text-red-500"}`}>{itm.change}</td> */}
              <td>---</td>
              <td>
                {/* <ReactApexChart
                  type="area"
                  options={itm?.up ? optionsUp : optionsDown}
                  series={itm?.up ? optionsUp.series : optionsDown.series}
                  width={120}
                /> */}
                <ReactApexChart
                  type="area"
                  options={optionsUp}
                  series={optionsUp.series}
                  width={120}
                />
              </td>
              <td>
                <Link to={"/autonomous-funding"}>
                  <button className="rounded-md text-white font-semibold bg-[#0FAE96] px-3 py-[2px]">
                    Trade
                  </button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
