import Header from "../common/Header";
import Footer from "../common/Footer";
import MarketTrend from "../components/market/Trend";
import MarketUpdates from "../components/market/MarketUpdates";

const Market = () => {
  const API_URL = "https://min-api.cryptocompare.com/data/price";

  return (
    <>
      <Header />
      <main>
        <MarketTrend API_URL={API_URL} />
        <MarketUpdates API_URL={API_URL} />
      </main>
      <Footer />
    </>
  );
};

export default Market;
