import React from "react";
import Header from "../common/Header";
import { EarnTabs, Footer } from "./apy";
import Button from "../common/Button";
import { CiLock } from "react-icons/ci";
import { GiWallet } from "react-icons/gi";
import { GrMoney } from "react-icons/gr";
import { IoFlash } from "react-icons/io5";
import { useTranslation } from "react-i18next";

const Staking = () => {
  const { t } = useTranslation();

  const balances = [
    {
      text: t("s_t11"),
      price: "$1349128",
    },
    {
      text: t("s_t12"),
      price: "15%",
    },
    {
      text: t("s_t13"),
      price: "$1212091",
    },
    {
      text: t("s_t14"),
      price: "2%",
    },
  ];

  const data1 = [
    { text: t("s_t21"), price: "108284 SVE", icon: CiLock },
    { text: t("s_t22"), price: "9380.33 USDC", icon: GiWallet },
    { text: t("s_t23"), price: "0", icon: GrMoney },
  ];

  const data2 = [
    { text: t("s_t41"), price: "0 SVE", desc: t("s_t42"), link: t("s_t43") },
    {
      text: t("s_t44"),
      price: "0 SVE",
      desc: t("s_t45"),
    },
    {
      text: t("s_t46"),
      price: "0 SVE",
      desc: t("s_t47"),
      link: t("s_t48"),
    },
    {
      text: t("s_t49"),
      price: "0 SVE",
      link: t("s_t410"),
    },
  ];

  return (
    <div className="bg-[#04060a]">
      <div className="bg-white">
        <Header />
      </div>
      <main>
        <div className="bg-[#101118] py-2">
          <EarnTabs />
        </div>
        <div className="pb-8 pt-8">
          <div className="mx-auto max-w-[1280px] px-6 w-full mb-6">
            <h3 className="font-bold text-xl mb-3 text-white">{t("s_rss")}</h3>
            <div className="flex max-md:flex-wrap gap-3 md:gap-5 justify-between bg-[#101118] p-4 rounded-md">
              {balances?.map((itm, idx) => (
                <>
                  <div className="text-center">
                    <p className="max-md:text-sm font-bold mb-2 text-white">{itm.text}</p>
                    <p className="text-xl md:text-2xl font-bold text-primary">{itm.price}</p>
                  </div>
                  {idx !== balances.length - 1 && (
                    <div className="w-[1px] min-h-full border border-[#ddd] my-4"></div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="pb-8">
          <div className="mx-auto max-w-[1280px] px-6 w-full">
            <div className="w-full flex gap-6 max-md:flex-wrap">
              <div className="space-y-6 md:flex-1 max-md:w-full">
                <div className="bg-[#101118] rounded-md">
                  {data1.map((itm, idx) => (
                    <div
                      key={idx}
                      className="border-b last:border-0 border-[#ddd] p-4 w-full flex items-center justify-between"
                    >
                      <div className="flex items-center gap-4 font-semibold">
                        <div className="size-8 grid place-items-center rounded-md border border-primary2">
                          <itm.icon
                            size={20}
                            stroke="#ccc"
                            fill="#ccc"
                          />
                        </div>
                        <span className="text-sm text-white">{itm.text}</span>
                      </div>
                      <span className="font-bold text-white">{itm.price}</span>
                    </div>
                  ))}
                </div>
                <div className="bg-[#101118] rounded-md p-4">
                  <div className="flex items-center justify-between pb-2 border-b border-[#ddd]">
                    <span className="text-lg font-bold text-white">{t("s_t31")}</span>
                    <p className="px-[6px] py-[2px] rounded border border-primary2 text-sm font-bold text-primary2">
                      APR 15%
                    </p>
                  </div>
                  <p className="text-sm pt-2 mb-4 opacity-70 font-medium text-white">
                    {t("s_t32")}
                  </p>
                  <div className="bg-[#04060a] rounded-md p-4 mb-4">
                    <div className="mb-4 flex items-center justify-between text-sm font-semibold">
                      <p className="text-white">{t("s_t33")}</p>
                      <p className="text-white">{t("s_t34")}</p>
                    </div>
                    <div className="flex items-center text-sm font-semibold">
                      <input
                        placeholder="0.0"
                        className="py-3 outline-none w-[calc(100%_-_80px)] text-white pr-3 bg-transparent"
                      />
                      <Button>{t("s_t35")}</Button>
                    </div>
                  </div>
                  <Button className="w-full">{t("s_t36")}</Button>
                </div>
              </div>
              <div className="space-y-6 md:flex-1 max-md:w-full">
                <div className=" bg-[#101118] rounded-md ">
                  {data2.map((itm, idx) => (
                    <div
                      key={idx}
                      className="border-b last:border-0 border-[#ddd] p-4 w-full flex items-center justify-between"
                    >
                      <div>
                        <p className="text-sm font-semibold text-white">{itm.text}</p>
                        {itm?.desc && (
                          <span className="text-xs opacity-70 text-white font-medium">
                            {itm.desc}
                          </span>
                        )}
                      </div>
                      <div className="text-end">
                        <p className="font-bold text-white">{itm.price}</p>
                        {itm?.link && (
                          <a
                            href="#"
                            className="text-xs text-primary2 font-medium"
                          >
                            {itm.link}
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="bg-[#101118] rounded-md p-4">
                  <div className="flex items-center justify-between pb-2 border-b border-[#ddd]">
                    <span className="text-lg font-bold text-white">{t("s_t51")}</span>
                    <div className="font-semibold text-sm">
                      <span className="text-primary2">APR 2%</span>
                      <span className="pl-2 pr-1 text-white">{t("s_t52")}</span>
                      <span className="text-white">0SVE</span>
                    </div>
                  </div>
                  <div className="mt-4 bg-[#04060a] rounded-md p-4 text-sm font-semibold h-[100px] grid place-items-center">
                    <div className="grid place-items-center gap-3">
                      <IoFlash
                        size={22}
                        fill="#ccc"
                      />
                      <span className="text-[#ccc]">{t("s_t53")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default Staking;
