import { useCallback, useEffect, useState } from "react";
import DashboardLayout from "../../layout/Layout";
import Table, { Td } from "../../common/Table";
import Api from "../../api/api";
import Loader from "../../common/Loader";
import { useLocation, useHistory } from "react-router-dom";
import { jsonToQueryParams } from "../../helpers/functions";
import Input from "../../common/Input";
import Pagination from "../../common/Pagination";

const columns = ["#", "Subject", "status", "created"];

const TicketsListing = () => {
  const [query, setQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const page = parseInt(params.get("page") || 1);
  const limit = parseInt(params.get("limit") || 10);

  const handleSearch = event => {
    const { value } = event.target;
    setQuery(value);
  };

  const getTickets = useCallback(
    async ({ search, page, limit }) => {
      try {
        const res = await Api.getUserTickets(search, page, limit);
        if (res?.status === 200) {
          setTickets(res.data.data.tickets);
          setTotalPages(res.data.data?.totalPages);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [setTickets],
  );

  useEffect(() => {
    const _query = jsonToQueryParams({ page: 1, limit, search: query || "" });
    history.push(`${location.pathname}?${_query}`);
  }, [query]);

  useEffect(() => {
    getTickets({ search: query, page, limit });
    setIsLoading(false);
  }, [location.pathname, location.search]);

  return (
    <>
      <DashboardLayout title={`Tickets`}>
        <div className="my-4 w-fit">
          <Input
            value={query}
            onChange={handleSearch}
            placeholder="Search..."
          />
        </div>
        <Table columns={columns}>
          {!isLoading &&
            tickets &&
            tickets?.map(itm => (
              <tr>
                <Td>#{itm?._id}</Td>
                <Td>{itm?.subject}</Td>
                <Td>
                  <p
                    className={`px-3 py-[3px] w-fit font-bold text-center ${itm?.status !== "closed" ? "bg-[#1dff1d40] text-[green]" : "bg-[#ff1d1d40] text-[#e90a0a]"}  rounded-md`}
                  >
                    {itm?.status}
                  </p>
                </Td>
                <Td>{new Date(itm?.createdAt).toLocaleString()}</Td>
              </tr>
            ))}
        </Table>

        <br />
        {isLoading ? (
          <div className="grid place-items-center">
            <Loader />
          </div>
        ) : (
          <Pagination totalPages={totalPages} />
        )}
      </DashboardLayout>
    </>
  );
};

export default TicketsListing;
