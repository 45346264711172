import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaShieldAlt } from "react-icons/fa";
import Switch from "react-switch";

const balances = [
  {
    text: "daily_earnings",
    price: "$0",
  },
  {
    text: "supply_balance",
    price: "$0",
  },
  {
    text: "borrow_balance",
    price: "$0",
  },
];

const APYStats = () => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(true);
  return (
    <div className="pb-12 md:pb-16 pt-8">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <div className="flex items-center justify-end gap-2 mb-4">
          <p className="font-bold text-white">APY with SVE</p>
          <Switch
            checked={toggle}
            onChange={() => setToggle(prev => !prev)}
          />
        </div>
        <div className="w-full flex gap-4 max-md:flex-wrap">
          <div className="max-md:w-full md:flex-1 p-4 md:p-6 rounded-lg border border-[#ddd]">
            <div className="p-4 md:p-6 flex justify-between items-center rounded-lg bg-primary2 [&>*]:text-white text-2xl font-bold">
              <p>Net APY</p>
              <p>0%</p>
            </div>
            <div className="h-[1px] border border-[#ddd] my-7"></div>
            <div className="flex gap-3 md:gap-5 justify-between">
              {balances?.map((itm, idx) => (
                <>
                  <div className="text-center">
                    <p className="max-md:text-sm font-bold mb-2 text-white">{t(itm.text)}</p>
                    <p className="text-xl md:text-2xl font-bold text-primary">{itm.price}</p>
                  </div>
                  {idx !== balances.length - 1 && (
                    <div className="w-[1px] min-h-full border border-[#ddd] my-4"></div>
                  )}
                </>
              ))}
            </div>
          </div>
          <div className="max-md:w-full md:flex-1 flex flex-col justify-between p-4 md:p-6 rounded-lg border border-[#ddd]">
            <div>
              <p className="font-bold text-[#5d6388]">{t("available_credit")}</p>
              <p className="text-2xl font-bold text-white">$0</p>
            </div>
            <div>
              <p className="flex justify-between items-center mb-3">
                <span className="font-bold text-[#5d6388] text-sm">{t("borrow_limit")}</span>
                <span className="font-bold text-xl text-white">0%</span>
              </p>
              <div className="h-2 rounded-[20px] bg-gray relative">
                <p className="w-1 h-full bg-red-600 absolute top-0 bottom-0 left-[70%]"></p>
              </div>
              <p className="flex justify-end items-center gap-2 mt-2">
                <FaShieldAlt fill="#22c55e" />
                <span className="font-bold text-white">Your safe limit: $0</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default APYStats;
