import { useEffect } from "react";
import Api from "../api/api";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";

export default function SuccessPayment() {
  const history = useHistory();

  useEffect(() => {
    let data = JSON.parse(sessionStorage.getItem("payment_data"));
    if (data) {
      const orderId = uuidv4();
      handleSubmit(data, orderId, null);
    }
  }, []);

  const handleSubmit = async (data, orderId, transaction) => {
    try {
      let res = await Api.placeOrder({ data: { ...data, orderId, status: "paid" }, transaction });
      if (res.status === 200) {
        toast.success("Order placed successfully");
        sessionStorage.removeItem("payment_data");
        history.push("/dashboard/order-history");
      }
    } catch (error) {
      toast.error("Unable to place Order: " + error.message);
    }
  };

  return <></>;
}
