import OverviewCards from "../../components/overview/Cards";
import OverviewGraphs from "../../components/overview/Graph";
import DashboardLayout from "../../layout/Layout";

const Overview = () => {
  return (
    <DashboardLayout title="Account Overview">
      <OverviewCards />
      <OverviewGraphs />
    </DashboardLayout>
  );
};

export default Overview;
