import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <main>
        <div className="py-12 md:py-16">
          <div className="mx-auto max-w-[1280px] px-6 w-full">
            <div className="py-10">
              <div className="flex items-center gap-y-10 gap-x-6 flex-wrap">
                <p className="border-b-[2px] border-primary text-2xl font-bold">{t("l_t1")}</p>
                <Link
                  to="/privacy-policy"
                  className="text-[15px] font-bold text-primary"
                >
                  {t("l_t2")}
                </Link>
                <Link
                  to="/cookie-policy"
                  className="text-[15px] font-bold text-primary"
                >
                  {t("l_t3")}
                </Link>
                <Link
                  to="/kyc"
                  className="text-[15px] font-bold text-primary"
                >
                  {t("l_t4")}
                </Link>
              </div>
              <div className="text-base font-semibold">
                <br />
                <br />
                <p>{t("tc_t1")}</p>
                <br />
                <br />
                <p>{t("tc_t2")}</p>
                <br />
                <p>{t("tc_t3")}</p>
                <p>{t("tc_t4")}</p>
                <p>{t("tc_t5")}</p>
                <p>{t("tc_t6")}</p>
                <p>{t("tc_t7")}</p>
                <p>{t("tc_t8")}</p>
                <p>{t("tc_t9")}</p>
                <p>{t("tc_t10")}</p>
                <p>{t("tc_t11")}</p>
                <p>{t("tc_t12")}</p>
                <p>{t("tc_t13")}</p>
                <p>{t("tc_t14")}</p>
                <p>{t("tc_t15")}</p>
                <p>{t("tc_t16")}</p>
                <p>{t("tc_t17")}</p>
                <p>{t("tc_t18")}</p>
                <p>{t("tc_t19")}</p>
                <p>{t("tc_t20")}</p>
                <p>{t("tc_t21")}</p>
                <p>{t("tc_t22")}</p>
                <p>{t("tc_t23")}</p>

                <br />
                <p>{t("tc_t24")}</p>
                <p>{t("tc_t25")}</p>
                <p>{t("tc_t26")}</p>
                <p>{t("tc_t27")}</p>
                <p>{t("tc_t28")}</p>
                <p>{t("tc_t29")}</p>
                <p>{t("tc_t30")}</p>
                <br />
                <p>{t("tc_t31")}</p>
                <p>{t("tc_t32")}</p>
                <p>{t("tc_t33")}</p>
                <p>{t("tc_t34")}</p>
                <p>{t("tc_t35")}</p>
                <p>{t("tc_t36")}</p>
                <p>{t("tc_t37")}</p>
                <p>{t("tc_t38")}</p>
                <br />
                <p>{t("tc_t39")}</p>
                <p>{t("tc_t40")}</p>
                <p>{t("tc_t41")}</p>
                <p>{t("tc_t42")}</p>
                <p>{t("tc_t43")}</p>
                <p>{t("tc_t44")}</p>
                <p>{t("tc_t45")}</p>
                <br />
                <p>{t("tc_t46")}</p>
                <p>{t("tc_t47")}</p>
                <p>{t("tc_t48")}</p>
                <br />

                <p>{t("tc_t49")}</p>
                <p>{t("tc_t50")}</p>
                <p>{t("tc_t51")}</p>
                <p>{t("tc_t52")}</p>
                <p>{t("tc_t53")}</p>
                <p>{t("tc_t54")}</p>
                <p>{t("tc_t55")}</p>
                <p>{t("tc_t56")}</p>
                <br />
                <p>{t("tc_t57")}</p>
                <p>{t("tc_t58")}</p>
                <p>{t("tc_t59")}</p>
                <p>{t("tc_t60")}</p>
                <p>{t("tc_t61")}</p>
                <p>{t("tc_t62")}</p>
                <br />
                <p>{t("tc_t63")}</p>
                <p>{t("tc_t64")}</p>
                <p>{t("tc_t65")}</p>
                <p>{t("tc_t66")}</p>
                <p>{t("tc_t67")}</p>
                <br />
                <p>{t("tc_t68")}</p>
                <p>{t("tc_t69")}</p>
                <p>{t("tc_t70")}</p>
                <p>{t("tc_t71")}</p>
                <p>{t("tc_t72")}</p>
                <p>{t("tc_t73")}</p>
                <br />

                <p>{t("tc_t74")}</p>
                <p>{t("tc_t75")}</p>
                <p>{t("tc_t76")}</p>
                <p>{t("tc_t77")}</p>
                <p>{t("tc_t78")}</p>
                <p>{t("tc_t79")}</p>
                <p>{t("tc_t80")}</p>
                <p>{t("tc_t81")}</p>
                <p>{t("tc_t82")}</p>
                <p>{t("tc_t83")}</p>
                <p>{t("tc_t84")}</p>
                <p>{t("tc_t85")}</p>
                <p>{t("tc_t86")}</p>
                <p>{t("tc_t87")}</p>
                <p>{t("tc_t88")}</p>
                <p>{t("tc_t89")}</p>
                <p>{t("tc_t90")}</p>
                <p>{t("tc_t91")}</p>
                <p>{t("tc_t92")}</p>
                <p>{t("tc_t93")}</p>
                <br />
                <p>{t("tc_t94")}</p>
                <p>{t("tc_t95")}</p>
                <p>{t("tc_t96")}</p>
                <br />
                <p>{t("tc_t97")}</p>
                <p>{t("tc_t98")}</p>
                <br />
                <p>{t("tc_t99")}</p>
                <p>{t("tc_t100")}</p>
                <br />
                <p>{t("tc_t101")}</p>
                <p>{t("tc_t102")}</p>
                <p>{t("tc_t103")}</p>
                <p>{t("tc_t104")}</p>
                <p>{t("tc_t105")}</p>
                <p>{t("tc_t106")}</p>
                <p>{t("tc_t107")}</p>
                <p>{t("tc_t108")}</p>
                <p>{t("tc_t109")}</p>
                <p>{t("tc_t110")}</p>
                <p>{t("tc_t111")}</p>
                <p>{t("tc_t112")}</p>
                <p>{t("tc_t113")}</p>
                <br />
                <p>{t("tc_t114")}</p>
                <p>{t("tc_t115")}</p>
                <p>{t("tc_t116")}</p>
                <p>{t("tc_t117")}</p>
                <p>{t("tc_t118")}</p>
                <p>{t("tc_t119")}</p>
                <p>{t("tc_t120")}</p>
                <p>{t("tc_t121")}</p>
                <p>{t("tc_t122")}</p>
                <p>{t("tc_t123")}</p>
                <p>{t("tc_t124")}</p>
                <p>{t("tc_t125")}</p>
                <p>{t("tc_t126")}</p>
                <p>{t("tc_t127")}</p>
                <p>{t("tc_t128")}</p>
                <p>{t("tc_t129")}</p>
                <br />
                <p>{t("tc_t130")}</p>
                <p>{t("tc_t131")}</p>
                <p>{t("tc_t132")}</p>
                <p>{t("tc_t133")}</p>
                <p>{t("tc_t134")}</p>
                <p>{t("tc_t135")}</p>
                <p>{t("tc_t136")}</p>
                <p>{t("tc_t137")}</p>
                <br />
                <p>{t("tc_t138")}</p>
                <p>{t("tc_t139")}</p>
                <p>{t("tc_t140")}</p>
                <p>{t("tc_t141")}</p>
                <p>{t("tc_t142")}</p>
                <br />
                <p>{t("tc_t143")}</p>
                <p>{t("tc_t144")}</p>
                <p>{t("tc_t145")}</p>
                <p>{t("tc_t146")}</p>
                <br />
                <p>{t("tc_t147")}</p>
                <p>{t("tc_t148")}</p>
                <p>{t("tc_t149")}</p>
                <p>{t("tc_t150")}</p>
                <p>{t("tc_t151")}</p>
                <br />
                <p>{t("tc_t152")}</p>
                <p>{t("tc_t153")}</p>
                <br />
                <p>{t("tc_t154")}</p>
                <p>{t("tc_t155")}</p>
                <p>{t("tc_t156")}</p>
                <p>{t("tc_t157")}</p>
                <p>{t("tc_t158")}</p>
                <p>{t("tc_t159")}</p>
                <p>{t("tc_t160")}</p>
                <p>{t("tc_t161")}</p>
                <p>{t("tc_t162")}</p>
                <p>{t("tc_t163")}</p>
                <p>{t("tc_t164")}</p>
                <p>{t("tc_t165")}</p>
                <p>{t("tc_t166")}</p>
                <p>{t("tc_t167")}</p>
                <p>{t("tc_t168")}</p>
                <p>{t("tc_t169")}</p>

                <p>{t("tc_t170")}</p>
                <p>{t("tc_t171")}</p>
                <br />
                <p>{t("tc_t172")}</p>
                <p>{t("tc_t173")}</p>
                <p>{t("tc_t174")}</p>
                <p>{t("tc_t175")}</p>
                <p>{t("tc_t176")}</p>
                <p>{t("tc_t177")}</p>
                <p>{t("tc_t178")}</p>
                <p>{t("tc_t179")}</p>
                <p>{t("tc_t180")}</p>
                <p>{t("tc_t181")}</p>
                <p>{t("tc_t182")}</p>
                <p>{t("tc_t183")}</p>
                <p>{t("tc_t184")}</p>
                <p>{t("tc_t185")}</p>
                <p>{t("tc_t186")}</p>
                <p>{t("tc_t187")}</p>
                <p>{t("tc_t188")}</p>
                <p>{t("tc_t189")}</p>
                <p>{t("tc_t190")}</p>
                <p>{t("tc_t191")}</p>
                <p>{t("tc_t192")}</p>
                <p>{t("tc_t193")}</p>
                <p>{t("tc_t194")}</p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Terms;
