import React from "react";
import { BsShieldFillCheck } from "react-icons/bs";
import { FaBitcoin } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import { IoDocumentText } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const data = [
  {
    icon: FaBitcoin,
    title: "earn_interest",
    desc: "earn_interest_desc",
    to: "/apy",
  },
  {
    icon: BsShieldFillCheck,
    title: "raw_spreads",
    desc: "raw_spreads_desc",
    to: "/portal",
  },
  {
    icon: IoDocumentText,
    title: "autonomous_funding",
    desc: "autonomous_funding_desc",
    to: "/autonomous-funding",
  },
];

const EcoSystem = () => {
  const { t } = useTranslation();
  return (
    <div className="py-12 md:py-16">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <h2 className="text-center text-2xl md:text-4xl font-semibold mb-5">
          {t("h_ecosystem_title")}
        </h2>
        <p className="text-center font-medium mb-8 mx-auto max-w-[600px]">
          {t("h_ecosystem_subtitle")}
        </p>

        <div className="flex flex-wrap gap-5">
          {data?.map((itm, idx) => (
            <div
              key={idx}
              className="max-md:w-full md:flex-1 border border-[#ddd] rounded-xl p-4 md:p-6 flex justify-between flex-col"
            >
              <div className="mb-4">
                <div className="p-3 w-fit rounded-full bg-primary">
                  <itm.icon
                    fill="#FFFFFF"
                    size={28}
                  />
                </div>
                <p className="text-lg font-semibold mt-3 mb-2">{t(itm.title)}</p>
                <p>{t(itm.desc)}</p>
              </div>
              <Link
                to={itm.to}
                className="w-fit text-sm font-semibold text-[#0FAE96] flex items-center gap-2"
              >
                {t("explore_more")} <FaArrowRight fill="#0FAE96" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EcoSystem;
