import "react-accessible-accordion/dist/fancy-example.css";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { IoIosPaper, IoIosSend } from "react-icons/io";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { FAQ1, FAQ2 } from "../utils/data";

const data = [
  {
    icon: IoChatboxEllipsesOutline,
    title: "Chat With Us",
    desc: "Give our Lexi chat a try, and she will connect you to a live human to chat with if needed! (Live chat hours: Mon-Fri 8am GMT-5pm GMT).",
  },
  {
    icon: IoIosSend,
    title: "Submit a ticket",
    desc: (
      <p className="text-sm">
        Get in touch with our friendly support team.{" "}
        <a
          className="underline text-primary"
          href="/submit-ticket"
          target="_blank"
        >
          Submit a ticket
        </a>
      </p>
    ),
  },
  {
    icon: IoIosPaper,
    title: "Support Information ",
    desc: "Login directly to have access to live updates to open tickets.",
  },
];

const FAQs = () => {
  return (
    <>
      <Header />
      <main>
        <div className="py-12 md:py-16 text-center bg-primary2">
          <div className="mx-auto max-w-[1280px] px-6 w-full">
            <h2 className="text-3xl md:text-6xl font-bold mb-6 text-white">How can we help?</h2>
            <div className="mx-auto w-full max-w-[600px] flex items-center justify-center gap-4 max-sm:flex-wrap">
              <input
                placeholder="Look up here...."
                className="placeholder:text-white rounded-[50px] bg-transparent px-6 py-3 border border-white text-white font-medium"
              />
              <button
                type="button"
                className="rounded-[50px] bg-primary text-[#ffffff] text-[16px] text-center px-6 py-3 font-semibold"
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="py-12 md:py-16">
          <div className="mx-auto max-w-[1280px] px-6 w-full">
            <div className="flex gap-6 max-md:flex-wrap w-full">
              {data?.map((itm, idx) => (
                <div
                  key={idx}
                  className="md:flex-1 border-[2px] border-[#ddd] rounded-lg p-4"
                >
                  <p className="flex items-center gap-3">
                    <itm.icon size={22} />
                    <span className="font-semibold">{itm.title}</span>
                  </p>
                  <p className="text-sm mt-2">{itm.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="pb-12 md:pb-16">
          <div className="mx-auto max-w-[1280px] px-6 w-full">
            <p className="text-xl font-semibold mb-4">Commonly Asked Question</p>
            <div className="w-full flex justify-center max-md:flex-wrap gap-16">
              <div className="w-full md:max-w-[370px] flex flex-col min-h-full justify-between">
                <div>
                  <Accordion allowZeroExpanded>
                    {FAQ1.map((item, idx) => (
                      <AccordionItem key={`faq1-${idx + 1}`}>
                        <AccordionItemHeading>
                          <AccordionItemButton>{item.q}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>{item.a}</AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
                {/*<p className="border-[2px] border-[#ddd] rounded-lg font-semibold mt-8 w-full py-8 text-center">*/}
                {/*  Customer Service Portal*/}
                {/*</p>*/}
              </div>
              <div className="w-full md:max-w-[370px] flex flex-col min-h-full justify-between">
                <div>
                  <Accordion allowZeroExpanded>
                    {FAQ2.map((item, idx) => (
                      <AccordionItem key={`faq2-${idx + 1}`}>
                        <AccordionItemHeading>
                          <AccordionItemButton>{item.q}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>{item.a}</AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
                {/*<p className="border-[2px] border-[#ddd] rounded-lg font-semibold mt-8 w-full py-8 text-center">*/}
                {/*  B2B Service Portal*/}
                {/*</p>*/}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default FAQs;
