import Header from "../common/Header";
import Footer from "../common/Footer";
import Crypto from "../components/home/Crypto";
import Welcome from "../components/portal/Welcome";
import PortalStats from "../components/portal/Stats";
import Refer from "../components/portal/Refer";

const Portal = () => {
  return (
    <>
      <Header />
      <main>
        <Welcome />
        <PortalStats />
        <Refer />
        <Crypto />
      </main>
      <Footer />
    </>
  );
};

export default Portal;
