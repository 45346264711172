import React from "react";
import { FiUsers } from "react-icons/fi";
import { PiGraph } from "react-icons/pi";
import { SlSettings } from "react-icons/sl";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { useTranslation } from "react-i18next";

const data = [
  {
    icon: FiUsers,
    desc: "talk_actively_desc",
  },
  {
    icon: SlSettings,
    desc: "no_waiting_desc",
  },
  {
    icon: PiGraph,
    desc: "funding_option_desc",
  },
  {
    icon: TfiHeadphoneAlt,
    desc: "support_team_desc",
  },
];

const data2 = [
  { count: "66k+", text: "active_user" },
  { count: "84+", text: "locations" },
  { count: "$9M+", text: "payouts" },
];

const WhyChoose = () => {
  const { t } = useTranslation();

  return (
    <div className="py-12 md:py-16 bg-secondary">
      <div className="mx-auto max-w-[1280px] px-6 w-full">
        <h2 className="text-center text-xl md:text-3xl font-semibold mb-5">
          {t("why_passve_funding")}
        </h2>
        <p className="text-center font-medium mb-10 md:mb-16 mx-auto max-w-[800px]">
          {t("why_passve_funding_desc")}
        </p>
        <div className="flex flex-wrap gap-7 w-full md:[&>div]:flex-1">
          {data?.map((itm, idx) => (
            <div
              key={idx}
              className="bg-[#060644] p-4 md:p-6 rounded-3xl md:rounded-[46px]"
            >
              <itm.icon
                fill="#FFFFFF"
                size={28}
              />
              <p
                className="text-white mt-2 text-sm"
                style={{ letterSpacing: 1 }}
              >
                {t(itm.desc)}
              </p>
            </div>
          ))}
        </div>
        <div className="mt-12 flex flex-wrap gap-7 items-center w-full md:[&>div]:flex-1">
          <div className="text-center">
            <p className="text-xl font-semibold mb-3">{t("global_community_reach")} </p>
            <p className="mb-6 font-medium">{t("global_community_reach_desc")}</p>
            <div className="flex items-center flex-wrap max-md:gap-4 justify-center">
              {data2?.map((itm, idx) => (
                <div
                  key={idx}
                  className="max-sm:w-full md:px-6 md:border-r md:border-primary2 md:last:border-none"
                >
                  <p className="text-3xl text-[#060644] md:text-5xl font-bold mb-1">{itm.count}</p>
                  <span className="text-lg font-medium">{t(itm.text)}</span>
                </div>
              ))}
            </div>
          </div>
          <div>
            <img
              className="w-full"
              src={`${process.env.REACT_APP_API_URL}/upload/globe.svg`}
              alt="globe"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
