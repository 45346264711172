const eng = {
  //header
  home: "Home",
  trade: "Trade",
  market: "Market",
  autonomous_funding: "Autonomous Funding",
  docs: "Docs",
  earn: "Earn",
  apy: "APY",
  staking: "Staking",
  open_portal: "Open Portal",
  client_area: " Client Area",
  submit_ticket: " Submit a Request",
  connect_wallet: " Connect Wallet",

  //footer
  cookies_policy: "Cookies Policy",
  term_condition: "Terms & Conditions",
  privacy_policy: "Privacy & Policy",
  rights_reserved: "All rights reserved",

  //home-hero
  h_hero_title: "All in One - Decentralised Hub",
  h_hero_subtitle:
    "Pioneering the industry in developing cut end technology to continue advancing end user experience.",
  get_started: "Get Started",

  //home-stats
  total_trading_volume: "Total Trading Volume",
  open_interest: "Open Interest",
  live_users: "Active Users",

  //home-ecosystem
  h_ecosystem_title: "Explore Our Ecosystem",
  h_ecosystem_subtitle:
    "Explore sensational features to aid your financial journey. No more barriers to reach your maximum potential.",
  trade_now: "Trade Now",
  view: "View",
  earn_interest: "Earn Interest",
  earn_interest_desc:
    "Supports a variety of digital currencies enabling users to participate in passive reward accumulation on their holdings.",
  raw_spreads: "Raw Spreads",
  raw_spreads_desc:
    "Enter and exit positions with minimal spread and low price impact. Get the optimal price without incurring additional costs.",
  autonomous_funding_desc:
    "Get access to unleash your trading potential with our funding technology. Choose from our native decentralised substitute.",
  explore_more: "Explore More",

  //home-feaured
  we_are_featured_in: "We are featured in",

  //home-newsletter
  subscribe_newsletter: "Subscribe to Our Newsletter",
  create_account: "Create an Account",
  h_account_subtitle: "Open your free account today and explore the range of services available.",
  join_our_community: "Join our community",
  members_online: "2000+ members online",

  //home-crypto
  new_in_cryptocurrency: "New In Cryptocurrency?",
  h_cryptocurrency_desc: `Don't invest unless you're prepared to lose all the money you invest. This is a
  high-risk investment and you should not expect to be protected if something goes
  wrong. Take 2 mins to learn more.`,
  learn_explore: "Learn & Explore Now",

  //portal-welcome
  welcome_back: "Welcome back",
  p_welcome_subtite: "Get to your intended destination with ease, through our seamless portals.",

  //portal-refer
  copy: "Copy",
  link_copied: "Link copied!",
  refer_friend: "Refer a Friend",
  refer_friend_desc: "Invite friends to earn up to 2000 USDT",

  //funding-hero
  markets_awaits_you: "Markets Awaits You",
  f_funding_capital: "Up to $900K In Funding Capital",
  f_funding_capital_desc:
    "Expedite your career and earn as a professional trader With our autonomous tailored trading programs. Choose from a dedicated Decentralised solution.",
  f_profit_desc:
    "Embark on a unique 2-step Evaluation Program for traders. This Evaluation Process consists of a single Challenge followed by a Verification in pursuit to find trading talent. Once Successfully completed, you will be offered a placement in the Passve Proprietary Trading firm where you can remotely manage an Account with a balance of up to 900,000 USD. Your journey to get there might be challenging, but our educational applications, account analysis and performance coaches are here to help you on the endeavour to financial independence.",
  learn_more: "Learn more",
  high_ratings: "High Ratings",
  certified_firm: "Certified Firm",
  full_profit_allocation: "Full Profit Allocation",

  //funding-profit
  trade_your_skill: "Trade Your Skill",
  amplify_your_results: "Amplify Your Results",
  f_transforming_success: "REAL TRADERS DESERVE SERIOUS CAPITAL",

  //funding-trading
  view_trading_objectives: "View Your Trading Objectives",
  most_popular: "Most Popular",
  profit_split: "Profit split",
  accelerated: "Accelerated",
  evaluation: "Evaluation",
  step: "Step",
  challenge: "Challenge",
  f_challenge_desc:
    "Complete Phase I by maintaining drawdown parameters and meeting the relevant profit target. When achieved you will be moved to the verification stage within 24hours.",
  verification: "Verification",
  f_verification_desc:
    "Phase II consist of proving your skills once again by following the rules and meeting the less strenuous profit target requirements. Once verified you will officially be offered a position to trade for our Propriety Trading Firm.",
  funded_trader: "Funded Trader",
  f_funded_trader_desc:
    "Now you are eligible to join our Proprietary Trading Firm. You must continue to display a professional level of risk management as well as consistency and you will receive up to a 100% profit split. Your performance will be evaluated regularly and if eligible you can scale up to $5,000,000 in account balance and get live liquidity order executions processed on the open market.",
  fast_track: "Fast Track",
  f_fast_track_desc:
    "10% Target - Start earning 80% of profits whilst maintaining account level drawdown parameters. Skip the evaluation and fast-track your journey by joining our accelerated plan get paid instantly on your performance.",
  select_plan: "Select Plan",

  //funding-payout
  traders_by_traders: "For Traders By Traders",
  traders_by_traders_desc:
    "Cultivating a movement and connecting the most skilful traders to join a community that rewards growth.",
  instant_payout: "Instant Auto Payout System",
  instant_payout_desc:
    "The default payout ratio for Passve Trader Live Accounts varies with each plan. We offer the opportunity to become eligible for our Scaling Plan. If you meet the terms depending on the account selected, we will increase your balance by up to 25% increments. You will also be updated to regulated live market liquidity order executions! As our Funded Trader you will be able to request payouts through the Client Area. The payout can be requested through various payout options including direct Bank Transfer, Cryptocurrency, Coinbase, PayPal, Payoneer, Revolut, Wise etc. Your withdrawal is our priority as we strive to deliver all payouts within 24 hours!",
  //funding-why
  why_passve_funding: "Why choose Passve Autonomous Funding?",
  why_passve_funding_desc:
    "At Passve we care about your Success. Developing and compounding is the core of our ethos. We constantly strive on investing in cutting-edge technologies in order to createthe best possible trading environment for our traders",
  talk_actively_desc:
    "Talk actively with us, our community and our fans on discord. We provide talks and information in real time to actively promote good behavior in trading. Join our discord and see what all the fuss is about!",
  no_waiting_desc:
    "No waiting, get your Account credentials instantly upon the purchase of your chosen program. You no longer have to wait to begin your journey.",
  funding_option_desc:
    "The very first Decentralised Funding option. Choose from a range of platforms to host your funded account. Including the native Passve Decentralised option.",
  support_team_desc:
    "Our 24/7 support team is available for all your FOREX Funded account needs, day or night we are there for you. When you need us we are always there to assist.",
  active_user: "Active user",
  locations: "Locations",
  payouts: "Payouts",
  global_community_reach: "Global Community Reach",
  global_community_reach_desc:
    "Traders are at the core of every decision. We strive to operate in a manner to continue to provide services across all borders to assist in helping traders reach their financial goals",

  //funding-insights
  insights_tips: "Insights, Tips & Latest Financial News",
  insights_tips_desc: "Get access to live research data feeds on current global economic trends.",
  see_all_articles: "See All Articles",
  email_here: "Enter email here for news updates",
  subscribe_now: "Subscribe Now",

  //market
  market_trend: "Market Trend",
  market_update: "Market Update",
  market_categories: "Market Categories",
  search_coin: "Search Asset",
  sr: "Sr.",
  name: "Name",
  last_price: "Last Price",
  change: "Change",
  market_stats: "Market Stats",

  //earn-apy
  dashboard: "Dashboard",
  available_credit: "Available Credit",
  daily_earnings: "Daily Earnings",
  supply_balance: "Supply Balance",
  borrow_balance: "Borrow Balance",
  supply: "Supply",
  borrow: "Borrow",
  borrow_limit: "Borrow Limit",
  asset: "Asset",
  wallet: "Wallet",
  collateral: "collateral",
  liquidity: "Liquidity",
  latest_block: "Latest Block",

  //
  support_hero_title: "How can we help? ",
  support_placeholder: "Look up here...",
  support_search: "Search",
  support_text11: "Chat With Us",
  support_text12:
    "Give our Lexi chat a try, and she will connect you to a live human to chat with if needed! (Live chat hours: Mon-Fri 8am GMT-5pm GMT).",
  support_text21: "Submit a ticket",
  support_text22: "Get in touch with our friendly support team.",
  support_text31: "Support Information",
  support_text32: "Login directly to have access to live updates to open tickets.",

  faq_title: "Commonly Asked Question",
  faq_text11: "How can verify my Identity? ",
  faq_text12: "How long does take for a  transaction to go through? ",
  faq_text13: "Why did I receive less than I bought?",
  faq_text14: "Why have I been charged twice?",
  faq_text15: "What is the minimum order amount?",
  faq_text16: "How can I update my information?",
  faq_text17: "Customer Service Portal",
  faq_text21: "Why is my payment getting declined?",
  faq_text22: "Common rejection reasons for verification documents ?",
  faq_text23: "How can I delete my data ?",
  faq_text24: "Can I cancel my order?",
  faq_text25: "How do I increase my limit?",
  faq_text26: "My transaction is still processing, is everything ok?",
  faq_text27: "B2B Service Portal",

  // stacking
  s_rss: "Revenue share staking",
  s_t11: "Total Locked",
  s_t12: "Lock APR",
  s_t13: "Total Vested",
  s_t14: "Vesting APR",
  s_t21: "Total SVE Locked",
  s_t22: "Reserve Vault",
  s_t23: "Penalty SVE Emission",
  s_t31: "Lock SVE",
  s_t32:
    "Lock SVE and earn platform revenue and penalty fees in unlocked SVE. The lock period is 2 weeks. Locked SVE will continue to earn fees after the locks expire if you don't withdraw",
  s_t33: "Amount",
  s_t34: "Balance 0SVE",
  s_t35: "Max",
  s_t36: "Approve",
  s_t41: "Claimable SVE",
  s_t42: "Vested SVE",
  s_t43: "Claim",
  s_t44: "SVE in Vesting",
  s_t45: "SVE amount that can be claimed with an early claim penalty",
  s_t46: "Claim all SVE above",
  s_t47: "Early exit penalty",
  s_t48: "Claim with penalty",
  s_t49: "Expired Locked",
  s_t410: "Withdraw",
  s_t51: "SVE Vests",
  s_t52: "Total",
  s_t53: "No vesting found",

  // legal title
  l_t1: "Terms and Conditions",
  l_t2: "Privacy and Policy",
  l_t3: "Cookies Policy",
  l_t4: "KYC/ Anti-Money Laundering",

  // legal term and conditions
  tc_t1: "Last updated: Dec 18th, 2022",
  tc_t2:
    "These Terms and Conditions set out your legal rights and obligations in using Passve Financial Services, the Passve Digital Account. We recommend you read these carefully. If you use the Digital Account, your right to redeem E-money is explained in clause 9.10. Any fees payable on redemption are explained in our Portal or on our website.",
  tc_t3: "1. CONTRACT FORMATION AND OVERVIEW:",
  tc_t4:
    "1.1 In this document, references to Passve/we/our/us are to Passve PLC. At all times, any handling or delivery of Cryptocurrency will be carried out by Passve PLC, and any handling or dealing in any fiat currency will be carried out by Passve PLC London Department (including any receipt of payments for purchases you make with Passve PLC). Any references to Customer/you/your are references to you as a customer of Passve and user of our services. Any references to the 'Service' includes websites, APIs, or mobile applications",
  tc_t5:
    "1.2 You agree that the liability of Passve PLC under this Agreement is bound and Passve shall be liable only for its own respective obligations under this Agreement, for any breaches.",
  tc_t6:
    "1.3 Please read this document carefully, as it sets out the terms and conditions on which Passve will provide services to you through our websites, Application Programming Interfaces ('APIs'), or mobile applications (together our 'Site').",
  tc_t7:
    "1.4 By signing up to create an Account with Passve and use our Services, you agree that you have read, understood, and accept all of the terms and conditions contained in this Agreement, as well as our Privacy Policy and Cookie Policy.",
  tc_t8:
    "1.5 This Agreement will govern the use of the Services provided by Passve. By using the Services, you agree to be bound by the terms and conditions of this Agreement. If you do not agree to any of the terms in this Agreement, or any subsequent modification to this Agreement, you will not be permitted to use the Services and your Account with us will be closed. This Agreement will come into effect when you confirm electronically that you agree to it. We recommend that you retain a copy of this Agreement and transaction records.",
  tc_t9:
    "1.6 Use of cryptocurrency may be illegal in some jurisdictions. It is your responsibility to know the regulatory requirements concerning transactions with cryptocurrency in your jurisdiction before using the Services.",
  tc_t10: "1.7 For the purposes of this Agreement:",
  tc_t11:
    "1.7.1 an 'Account' means an online account created by you in order to access the Passve Services;",
  tc_t12:
    "1.7.2 a 'Business Day' means any day which is not a Saturday, Sunday or public holiday in the respective jurisdiction referred to at clause 1.1, and which the banks are open for business in such jurisdiction;",
  tc_t13:
    "1.7.3 'Cryptocurrency' or 'Cryptocurrencies' means a cryptographically secured digital representation of value or contractual rights that uses a form of distributed ledger technology and can be transferred, stored or traded electronically. The definition of Cryptocurrency also includes 'right to, or interest in, the Cryptocurrency';",
  tc_t14: "1.7.4 'Order' means a request for the Services;",
  tc_t15:
    "1.7.5 'Partner' means third party firms who refer you to Passve in order to purchase and/or sell Cryptocurrencies;",
  tc_t16: "1.7.6 'Partner Sites' means Partner’s mobile applications and websites;",
  tc_t17: "1.7.7 'Services' means to the purchase and/or sale of Cryptocurrencies from Passve;",
  tc_t18: "1.7.8 'Wallet' means a secured digital facility in which Cryptocurrencies are held.",
  tc_t19: "1.8 You have understood, acknowledged and accepted the following DISCLAIMER:",
  tc_t20:
    "1.8.1 The risk of loss in trading or holding Bitcoin or any other Cryptocurrency can be substantial. You should therefore carefully consider whether trading or holding Bitcoin or any other cryptocurrency is suitable for you in light of your financial condition. In considering whether to trade or hold Cryptocurrency, you should be aware that the price or value of Cryptocurrency can change rapidly, decrease, and potentially even fall to zero.",
  tc_t21:
    "1.8.2 You acknowledge that Passve is not responsible for safeguarding or holding your Cryptocurrency, or any private keys or other security information to access your Cryptocurrency and that Passve is not responsible for any loss of Cryptocurrency resulting from theft, loss, or mishandling of Cryptocurrency private keys or other security information outside its control.",
  tc_t22:
    "1.8.3 Every purchase and sale of Cryptocurrency is effected on and confirmed by the respective network of that Cryptocurrency. The confirmation takes a period of time (usually less than one hour, but possibly one day or more). An Order is not complete until it is confirmed. Cryptocurrency associated with Orders that are in a pending state will be designated accordingly.",
  tc_t23:
    "1.8.4 In the United Kingdom, our Services are not covered by the Financial Ombudsman Service or the Financial Services Compensation Scheme.",
  tc_t24: "2. Eligibility",
  tc_t25: "2.1 To be eligible to use any of the Passve Services, you confirm that you are:",
  tc_t26: "2.1.1 at least 18 years old;",
  tc_t27: "2.1.2 have sufficient capacity to enter into legally binding contracts;",
  tc_t28: "2.1.3 reside in a country in which the relevant Passve Services are accessible; and",
  tc_t29:
    "2.1.4 willing to provide to us any current valid personal identification documents that we request (once reached threshold limit)",
  tc_t30: "2.2 The list of accessible countries can be found here.",
  tc_t31: "3. Services provided to you",
  tc_t32:
    "3.1 Once you have completed the registration process (as set out at clause 4 below), Passve will provide the Services to you.",
  tc_t33:
    "3.2 When using the Services, you are buying Cryptocurrency from, or selling Cryptocurrency to, Passve directly. Passve does not act as an intermediary or marketplace between other buyers and sellers of Cryptocurrency.",
  tc_t34:
    "3.3 Passve will send / deliver Cryptocurrency to the Wallet address indicated at the time of the Order subject to the conditions of this Agreement;",
  tc_t35:
    "3.4 At no point during the purchase or sale, will Passve be in possession or in control of client funds.",
  tc_t36:
    "3.5 Passve does not provide Cryptocurrency Wallets, nor does Passve host Wallets or is custodian of funds or assets; and",
  tc_t37: "3.6 Orders through Passve are executed individually, one by one.",
  tc_t38:
    "3.7 Passve DOES NOT facilitate or provide trading or investment or brokerage accounts or facilities, nor does Passve provide investment or any other financial advice.",
  tc_t39: "4. Customer registration process",
  tc_t40:
    "4.1 To use the Passve Services, you will need to register for an Account by providing your name, email address and accepting the terms of this Agreement. By using Passve Services, you agree and represent that you will use the Passve Services only for yourself, and not on behalf of any third party. Upon successful completion of the registration process, Passve will establish your Account. You are fully responsible for all activity that occurs under your Passve Account. We may, in our sole discretion, refuse to open a Passve Account for you, or limit the number of Accounts that you may hold or suspend or terminate any Account, and are not required to provide you with the reasons for taking any such action",
  tc_t41:
    "As part of the registration process, you must provide Passve with the information that is requested as part of the Account opening process to identify and verify your identity and for the detection of anti-money laundering, terrorist financing, fraud, or any other financial crime and permit us to keep a record of such information. You will need to complete certain verification procedures before you are permitted to start using the Passve Services.",
  tc_t42:
    "4.2 The information we request may include certain personal information including, but not limited to, your name, address, telephone number, email address, date of birth, taxpayer identification number, government identification number, information regarding your bank account (such as the name of the bank, the account type, routing number, and account number) network status, customer type, customer role, billing type, mobile device identifiers (IMSI and IMEI) and other subscriber status details. In providing us with this or any other information that may be required, you confirm that the information is accurate and correct and you agree to keep us updated if any of the information you provide changes. We will treat this information in accordance with the data protection provisions of this Agreement, as set out at clause 13.",
  tc_t43:
    "4.3 You authorise us to make enquiries, whether directly or through third parties, that we consider necessary to verify your identity or protect you and/or us against fraud or other financial crime, and to take action we reasonably deem necessary based on the results of such inquiries. When we carry out these enquiries, you acknowledge and agree that your personal information may be disclosed to credit reference and fraud prevention or financial crime agencies and that these agencies may respond to our enquiries in full. This is an identity check only and should have no adverse effect on your credit rating. Additionally, we may require you to wait some amount of time after completion of a transaction before permitting you to use further Passve Services and/or before permitting you to engage in transactions beyond certain volume limits.",
  tc_t44:
    "4.4 Failure to provide any information that Passve reasonably requests from you pursuant to applicable money laundering laws and regulations after you have become a Customer shall be grounds for the suspension of the provision of Services to you (including access to your Account) and/or the termination of this Agreement. The nature and extent of the information you are required to provide may differ, for example, based on the Services provided to you under this Agreement and/or the means of payment you use.",
  tc_t45:
    "4.5 Where you are referred to us by our Partners via Partner Sites, you will still be required to register for an Account with Passve and this Agreement will apply in full irrespective of any other terms you sign up to with our Partners",
  tc_t46: "5. Transactions limits and enhanced due diligence",
  tc_t47:
    "5.1 The use of all Passve Services is subject to a limit on the volume, stated in GBP, USD, EUR or other fiat currency you may transact or transfer in a given period (e.g., daily). To view your limits, login to your Passve Account. Your transaction limits may vary depending on your payment method, verification steps you have completed and other factors. We reserve the right to change applicable limits as we deem necessary and where possible will provide advance notice to you, although in some cases this will not be possible, and you will be informed after any changes to the applicable limits have taken place. If you wish to raise your limits beyond the posted amounts, you may submit a request at support@passve.com",
  tc_t48:
    '5.2 We may require you to submit additional information about yourself and provide additional records if you wish to raise your limits ("Enhanced Due Diligence"). In our discretion, we may refuse to raise your limits, or we may lower your limits at a subsequent time even if you have completed Enhanced Due Diligence.',
  tc_t49: "6. Cryptocurrency orders",
  tc_t50:
    "6.1 You will be able to purchase and sell Cryptocurrencies (if in a supported region) from and to us, through our Site and through Partner Sites subject to the applicable fees and limits displayed during the purchase and sale flow. The price, exchange rate and amount of the Cryptocurrency that you wish to purchase or sell will be confirmed at the time that you place an Order with us.",
  tc_t51:
    "6.2 Acceptance by us of an Order does not guarantee that you will receive the corresponding amount of Cryptocurrency or fiat currency. The Cryptocurrency Order is conditional upon actual receipt by us of the funds from your credit or debit card, as well as payment of any applicable fees.",
  tc_t52:
    "6.3 Subject to clause 6.2 and the Order being honoured by the Customer’s bank, card provider or other relevant party, Cryptocurrency purchases shall be credited to any Cryptocurrency Wallet, as provided by you at the time of the Order, as soon as possible once the Cryptocurrency purchase has been confirmed by the Cryptocurrency network. Once submitted to a Cryptocurrency network, a Cryptocurrency purchase will be unconfirmed for a period of time pending sufficient confirmation of the Order by the Cryptocurrency network. Cryptocurrency Orders that are in a pending state will not be credited to the Wallet.",
  tc_t53:
    "6.4 Subject to clauses 6.2 and 8, Cryptocurrency sales shall be debited to any Cryptocurrency Wallet, as provided by you at the time of the Order, as soon as possible once the Cryptocurrency sale has been confirmed by the Cryptocurrency network. Once submitted to a Cryptocurrency network, a Cryptocurrency sale will be unconfirmed for a period of time pending sufficient confirmation of the Order by the Cryptocurrency network. Cryptocurrency Orders that are in a pending state will not be debited to the Wallet. Once the Cryptocurrency sale is confirmed, fiat funds will be credited to the Customer’s bank, card provider or other relevant party.",
  tc_t54:
    "6.5 Passve may use a third-party payment processor to process any fiat payment between you and Passve.",
  tc_t55:
    '6.6 Recurring Transactions. If you set up a recurring purchase or sale of Cryptocurrencies (a "Future Transaction"), you authorise us to initiate recurring electronic payments in accordance with your selected Cryptocurrency Order. Your Future Transactions will occur in identical, periodic instalments, based on your period selection (e.g., daily, weekly, monthly), until either you or Passve cancels the Future Transaction. This authorisation will remain in full force and effect until you change your Future Transaction settings or until you provide us with written notice via support@passve.com',
  tc_t56:
    "6.7 Please note that as part of accessing our Services, you may be required to sign up to separate and independent terms when using the Partner Sites and with any third-party payment processor.",
  tc_t57: "7. Cryptocurrency storage",
  tc_t58:
    "7.1 Passve does not provide any Wallet or hold, safeguard or administer any private keys or other security information or Cryptocurrency for its Customers.",
  tc_t59:
    "7.2 When using the Services, you will be asked to provide us with the address for your Wallet by either:",
  tc_t60: "7.2.1 providing a QR code which represents your Wallet address; or",
  tc_t61: "7.2.2 manually typing your Wallet address where provided when placing an Order.",
  tc_t62:
    "7.3 It is your responsibility to provide us with a true, accurate and complete Wallet address when carrying out an Order. It is therefore important that the Wallet address that you provided for an Order is correct. You understand and agree that Passve accepts no liability for you providing an incorrect or inaccurate Wallet address as part of an Order. By providing a Wallet address to us, you confirm that this is the Wallet address that should be used for the relevant Order and we will not, and have no responsibility to, check whether you have provided a correct and accurate Wallet address for the Order.",
  tc_t63: "8. Transmission delays",
  tc_t64:
    "8.1 Passve will use good faith efforts to fulfil Orders at the time that you place such Order, including the applicable fee and costs. However, from time to time, it may be necessary for Passve to delay fulfilment of an Order until such time as we are able to execute the Order. In such cases, we will notify you prior to the completion of your Order both:",
  tc_t65: "8.1.1 the amount of Cryptocurrency you will receive; and",
  tc_t66: "8.1.2 the price at the time (including associated fees and costs).",
  tc_t67:
    "8.2 Passve does not have any control over transaction times for the Cryptocurrency Network and there may be instances where transaction times may take longer than usual. As such, you accept the risk that an Order facilitated by Passve may be delayed and you confirm that you will not hold Passve responsible for any losses, damages or injury arising out of or related to such delay.",
  tc_t68: "9. Cancellations and refunds",
  tc_t69: "9.1 Once you have placed an Order has been made it cannot be cancelled or recalled",
  tc_t70: "9.2 All Orders are final and cannot be refunded.",
  tc_t71:
    "9.3 Once an Order has been sent to the Cryptocurrency Wallet it cannot be recalled or retrieved under any circumstances.",
  tc_t72:
    "9.4 You hereby agree that upon delivery, you will not be entitled to any credit or refund and all purchases and sales of Cryptocurrency are final. Passve obligation towards you will be absolutely discharged upon delivery of the Cryptocurrency to your Wallet or fiat funds to your bank account and you shall have no claim or right against Passve upon such delivery.",
  tc_t73:
    "9.5 Please also note that Cryptocurrency transactions are irreversible. It is your sole responsibility to be vigilant of any fraud or mistake and to keep your private key safe. Passve will not take responsibility to issue refunds, regardless of whether you were the victim of fraud, mistake, or loss of private key.",
  tc_t74: "10. Suspension, termination and cancellation",
  tc_t75:
    "10.1 Passve may: (a) refuse to complete, or block or cancel you have authorised,(b) suspend, restrict, or terminate your access to any or all of the Passve Services, and/or (c) deactivate or cancel your Passve Account with immediate effect for any reason, including but not limited to where:",
  tc_t76: "10.1.1 we reasonably believe that we need to do so in order to protect our reputation;",
  tc_t77:
    "10.1.2 we are, in our reasonable opinion, required to do so by applicable law, regulation or any court or other authority to which we are subject in any jurisdiction;",
  tc_t78: "10.1.3 we reasonably suspect you of acting in breach of this Agreement;",
  tc_t79:
    "10.1.4 we have concerns that a transaction is erroneous or about the security of your Passve Account or we suspect the Passve Services are being used in a fraudulent or unauthorised manner;",
  tc_t80:
    "10.1.5 we suspect money laundering, terrorist financing, fraud, or any other financial crime;",
  tc_t81:
    "10.1.6 use of your Passve Account is subject to any pending litigation, investigation, or government proceeding and / or we perceive a heightened risk of legal or regulatory non compliance associated with your Passve Account activity; and / or",
  tc_t82:
    "10.1.7 you take any action that may circumvent our controls such as opening multiple Passve Accounts or abusing promotions which we may offer from time to time.",
  tc_t83:
    "10.1.8 you fail to provide on request such documentation as Passve (or any third party whose services we use in providing the Services to you under this Agreement) reasonably requires in order to comply with its obligations under applicable money laundering laws and regulations or otherwise to ensure the verification of your identity and/or funding sources to Passve satisfaction.",
  tc_t84: "10.1.9 any Cryptocurrency Order is significantly larger in size;",
  tc_t85:
    "10.1.10 Passve reasonably believes that it is necessary or desirable to do so in order to protect the security of the Account, including circumstances where any Account details may have been lost or stolen.",
  tc_t86:
    "10.2 In the case of any such suspension, Passve shall make reasonable efforts to inform you about the withholding or suspension, provided that such disclosure",
  tc_t87:
    "10.2.1 is not in breach of any applicable law or regulation and does not contravene the instruction of any competent authority or regulator; and",
  tc_t88: "10.2.2 would not compromise Passve reasonable security measures.",
  tc_t89:
    "10.3 Passve shall not be liable to you for any losses you may suffer as a result of any reasonable action it takes to suspend the Account or withhold settlement of a Cryptocurrency Order in accordance with this clause 10.",
  tc_t90:
    "Where the reasons for Passve actions under this clause 10 cease to exist, Passve may, at its discretion, either reinstate access to the Account and the Services and/or issue the Customer with new Account details and reserves the right to ask you to re-complete the Account opening procedures as outlined in this Agreement and to resolve any open issues with your Account before a restriction can be removed.",
  tc_t91:
    "Notwithstanding the above, we may suspend, restrict, or terminate your access to any or all of the Passve Services and/or deactivate or cancel your Passve Account, without reason by giving you one months’ notice. You acknowledge that our decision to take certain actions, including limiting access to, suspending, or closing your Passve Account, may be based on confidential criteria that are essential for the purposes of our risk management and security protocols. You agree that we are under no obligation to disclose the details of its risk management and security procedures to you.",
  tc_t92:
    "10.4 You will also be able to cancel your Passve Account, at any time and free of charge, and will only be required to pay for those Services used that are subject to charges. If any Order is in a pending status at the time your Passve Account is cancelled or suspended, such Order will be completed before cancellation is effected. You may not cancel your Passve Account to evade an investigation or avoid paying any amounts otherwise due to Passve.",
  tc_t93:
    "10.5 In the event that you or we terminate this Agreement or your access to the Services, or deactivate or cancel your Passve Account, you will remain liable for all amounts due under this Agreement prior to this, including all fees and charges.",
  tc_t94: "11. Fees",
  tc_t95:
    "11.1 All fees payable under this Agreement are displayed prior to the purchase or sale of Cryptocurrency by using the Services, and shall be paid in the applicable fiat currency. Please note, our fees are made clear to you at the point of sale, and on this point you will be asked to confirm that you are clear about the fee and that in proceeding you agree to the fee prior to Passve executing the Order.",
  tc_t96:
    "11.2 Passve reserves the right to pursue any financial losses suffered due to you filing a chargeback procedure with your bank. These can include administration fees levied by the card acquirer and card schemes as well as the monetary value of the Cryptocurrencies.",
  tc_t97: "12. Taxes",
  tc_t98:
    "You are responsible for determining whether, and to what extent, any taxes apply to any transactions associated with these Services, You must withhold, collect, report and remit the correct amounts of taxes to the appropriate tax authorities.",
  tc_t99: "13. Data protection law",
  tc_t100:
    "We are committed to keeping your personal information safe. We process personal information in accordance with applicable data protection legislation. Please read our privacy policy to understand how we use and protect the information you provide us (a copy of our privacy policy can be accessed here: https://www.passve.com/privacy-policy",
  tc_t101: "14. Liability",
  tc_t102:
    "14.1 Neither Passve, nor any of its directors, employees or agents, shall be liable for any loss or damage sustained by you as a direct or indirect result of the provision by Passve of its Services, save that nothing in this Agreement shall exclude or restrict any liability of Passve resulting from:",
  tc_t103: "14.1.1 death or personal injury;",
  tc_t104: "14.1.2 for fraud, fraudulent misrepresentation or fraudulent misstatement; and/or",
  tc_t105: "14.1.3 any statutory liability not capable of limitation.",
  tc_t106:
    "14.2 Passve shall not in any event be liable for loss of profits, loss of opportunity, loss of business, loss of savings, loss of goodwill, loss of Cryptocurrency, claims by third parties, loss of anticipated savings (whether direct or indirect) or for any type of special, direct, indirect or consequential loss howsoever caused, even if caused by Passve negligence and/or breach of contract and even if such loss was reasonably foreseeable or Passve had been advised of the possibility of you incurring the same.",
  tc_t107:
    "14.3 Passve disclaims all liability associated with the use of Cryptocurrency, including:",
  tc_t108: "14.3.1 unknown inherent technical defects;",
  tc_t109: "14.3.2 regulatory or legislative changes; and",
  tc_t110: "14.3.3 currency fluctuation.",
  tc_t111:
    "14.4 Passve shall not bear any liability for any damage or interruptions caused by any computer viruses, spyware, scareware, Trojan horses, worms or other malware that may affect your computer or other equipment, or any phishing, spoofing or other attack. It is your responsibility to use a reputable and readily available virus screening and prevention software. You should also be aware that SMS and email services are vulnerable to spoofing and phishing attacks and should use care in reviewing messages purporting to originate from Passve.",
  tc_t112:
    "14.5 You indemnify and hold us, our subsidiaries, members, directors, partners, officers, employees, contractors and agents harmless from and against any loss, liability, claim, demand, damages, costs, expenses (including legal fees) which may arise from or in connection with the Services, any content on the Services shared by you or other users, any third party websites or resources found through the services, any users of the services, or any breach of this Agreement, applicable laws or any law or regulation in any jurisdiction.",
  tc_t113:
    "14.6 Except for the express statements set forth in this Agreement, you hereby acknowledge and agree that you have not relied upon any other statement or understanding, whether written or oral, with respect to you use and access of the Passve Services and Site.",
  tc_t114: "15. Warranties and representations",
  tc_t115: "15.1 By agreeing to this Agreement, you represent, warrant and undertake to us that:",
  tc_t116: "15.1.1 you have full power and authority to enter into this Agreement",
  tc_t117:
    "15.1.2 you understand and acknowledge that we do not warrant that any of the Services available through our API are suitable or appropriate for your needs and that you must take your own independent legal and other advice on such Services;",
  tc_t118:
    "15.1.3 you are entering into this Agreement as principal and not on behalf of any third party;",
  tc_t119:
    "15.1.4 you will not violate any applicable laws by entering into this Agreement or receiving the Services provided under it;",
  tc_t120: "15.1.5 you will not provide false, misleading or inaccurate information;",
  tc_t121:
    "15.1.6 you will not facilitate any viruses, malware, worms, trojan horses or some other computer programming routines that may damage, corrupt, disrupt, misuse or gain unauthorized access to any data, system information or Passve services;",
  tc_t122:
    "15.1.7 funds or Cryptocurrencies transferred to the Wallet or any sub-Wallet have been acquired lawfully;",
  tc_t123:
    "15.1.8 you will not use an anonymizing proxy; use any other automatic devices, spider or manual process to copy or monitor our websites without our prior written permission;",
  tc_t124: "15.1.9 you will not harass and/or threaten our employees, agents, or other users;",
  tc_t125:
    "15.1.10 you understand and acknowledge that while we make reasonable endeavours to ensure the accuracy of the information that we provide, and which in turn, is provided to you, neither we nor any of our directors, employees or agents make any representation or warranty, express or implied, as to the accuracy or completeness of such information;",
  tc_t126:
    "15.1.11 any information provided by you to Passve under this Agreement is true, complete, accurate, up to date and not misleading; and",
  tc_t127:
    "15.1.12 you shall provide all assistance reasonably requested by Passve to enable Passve to comply with its obligations under this Agreement.",
  tc_t128:
    "15.2 The Passve Services are provided on an 'as is' and 'as available' basis, with no further promises made by us around availability of the Passve Services. Specifically, we do not give any implied warranties of title, merchantability, fitness for a particular purpose and/or non-infringement. We do not make any promises that access to the Site, any of the Passve Services, or any of the materials contained therein, will be continuous, uninterrupted, timely, or error-free.",
  tc_t129:
    "15.3 Passve makes no representation or warranty that the Services are applicable or appropriate for use by Customers in all jurisdictions and it is your responsibility to ensure compliance with the laws of any relevant jurisdiction of your residence.",
  tc_t130: "16. Stored card details",
  tc_t131:
    "16.1 We are legally obligated to secure your consent to allow us to store your card details for future use and cannot process your payment before you agree to such storing. By accepting this Agreement, you hereby give us permission to do so.",
  tc_t132:
    "16.2 Your card will remain stored against your Account for transactional purposes, unless you decide to remove it, or until the card expires. For legal purposes, we will continue to store records of your transactions in accordance with our Privacy Policy.",
  tc_t133:
    "16.3 We need to do this so that you will be able to deposit and withdraw more easily in future and it also helps us to prevent and detect any fraudulent activity.",
  tc_t134:
    "16.4 Stored details are primarily used so that you don’t have to re-enter all your details for every transaction you make. In your Account you will see the last 4 digits of the card number, and this forms the reference for you to reference the correct card you wish to use.",
  tc_t135:
    "16.5 We also compare stored details in our fraud screening systems, to prevent your details being used on any other Accounts and safeguard your data",
  tc_t136:
    "16.6 As per our policy, if you don’t agree to us storing your payment card details, we won’t be able to process your deposit from the card used",
  tc_t137:
    "16.7 Changes in the permitted use will require your agreement, we will notify you if this occurs.",
  tc_t138: "17. Right of withdrawal",
  tc_t139:
    "17.1 You have 14 (fourteen) calendar days to exercise your right of withdrawal from this Agreement, without having to justify any reason or pay any penalty. This withdrawal period begins on the day after the date that your application is accepted by us.",
  tc_t140:
    "17.2 We will not normally provide the Services during the withdrawal period. Any Services or Orders which are fully performed before a withdrawal cannot be reversed.",
  tc_t141:
    '17.3 You must notify your withdrawal request to us within the allotted period by email to <a href="mailto:support@passve.com">support@passve.com</a>',
  tc_t142:
    "17.4 If you exercise your right of withdrawal, this Agreement will be terminated at no cost to you.",
  tc_t143: "18. Term",
  tc_t144:
    "18.1 This Agreement will commence in the manner set out in clause 1 and will continue unless either party notifies the other of termination, in writing, in accordance with this Agreement.",
  tc_t145:
    "18.2 This Agreement can be terminated immediately by the Customer providing written notice to Passve.",
  tc_t146:
    "18.3 This Agreement can be terminated by Passve in accordance with all the provisions of this Agreement.",
  tc_t147: "19. Security",
  tc_t148:
    "19.1 You will be provided with security details from us which will be needed in order for you to access your Account with us. You must keep all such security details (including usernames and passwords) private and not share such details with any third party.",
  tc_t149:
    "19.2 You must monitor your Account and read all messages that have been sent to you. If you suspect that any feature of your Account (for example login details, password or other security feature) has been lost, stolen, misappropriated, used without authorisation or otherwise compromised, you must contact us immediately notifying us of such action and you agree to change your password immediately if necessary.",
  tc_t150:
    "19.3 We will never ask you to share your password with us or to any third party and you must never disclose this to anybody or allow someone to watch you when you are accessing your Account. It is advised that in order to keep your Account safe, you change your password regularly as this will minimise the risk of your Account being compromised.",
  tc_t151:
    "19.4 You must also ensure that your registered email account(s) are secure and can only be accessed by you, as your email address may be used in the process of resetting passwords or we may send communications to your email account regarding the security of your Account with us. In case any of the email addresses registered with your Account are compromised, you should, immediately contact us and also contact your email service provider, once you have been made aware of this.",
  tc_t152: "20. Force majeure",
  tc_t153:
    "Except as set out otherwise, neither party will be liable for any loss caused directly or indirectly from circumstances not within its control, including but not limited to acts of God, government restrictions, exchange or market rulings, actions affecting securities, clearing or commodity exchanges including suspensions of trading or extensions of trading hours, dealing cut-off times and holidays, acts of civil or military authority, national emergencies, natural disasters, wars, riots or acts of terrorism, industrial disputes, acts or regulations of any governmental or supranational bodies and authorities or the failure or malfunction of any telecommunication or computer service.",
  tc_t154: "21. Notices",
  tc_t155: "21.1 All notices and communications pursuant or in connection with this Agreement:",
  tc_t156:
    "21.1.1 Must be in English, in writing and legible (you confirm by signing this Agreement that you possess proper knowledge and understanding of the English language)",
  tc_t157: "21.1.2 Must be delivered and/or sent to us to the following:",
  tc_t158: "Address: 20-22 Wenlock Road, London, England, N1 7GU.",
  tc_t159: "Email: support@passve.com",
  tc_t160:
    "or at another address (within the United Kingdom) as we may from time to time notify you; and",
  tc_t161:
    "21.1.3 Will be delivered or sent to you at the postal address or email address that you have notified or provided to us in connection with this Agreement.",
  tc_t162:
    "21.2 Any notice or other communication sent by post will be sent by pre-paid first-class post (if to/from the United Kingdom) or by pre-paid airmail (if to/from outside of the United Kingdom).",
  tc_t163:
    "21.3 The parties acknowledge that any notice or other communication will be deemed to be given as follows :",
  tc_t164:
    "21.3.1 If delivered, at the time and on the date of delivery if delivered during a Business Day, or at the start of the next Business Day if delivered at any other time;",
  tc_t165:
    "21.3.2 If sent by post to and from a place within the United Kingdom, at the start of the second Business Day after it was put in the post;",
  tc_t166:
    "21.3.3 If sent by post to or from a place outside the United Kingdom, at the start of the fifth Business Day after it was put in the post; or",
  tc_t167:
    "21.3.4 If sent by email, at the time and on the date of transmission if transmitted during normal office hours (09:00-17:30) on a Business Day (local time at the place of receipt) and, in any other case, at the start of the Business Day following the date of transmission.",
  tc_t168:
    "21.4 This clause will not apply to the service of any proceedings or other documents in any legal action by the parties. Passve will not accept service of proceedings or any legal action by way of email by you or any third party.",
  tc_t169:
    "21.5 We may (where allowed to do so by law) communicate with you by posting information in your Account or on the Site, in which case the information will be treated as received by you when it is posted by us.",
  tc_t170:
    "21.6 Complaints and general queries If you have any feedback or questions contact us via our customer support email address at support@passve.com. Please provide your name, address, and any other information we may need to identify you, your Account, and the Order on which you have feedback or questions.",
  tc_t171:
    "21.7 For any complaint relating to the Services, you are advised to contact support@passve.com. Should we receive a complaint from you, we will immediately carry out an independent investigation of your complaint and will provide you with a written response. We will aim to respond to you within thirty (30) days of receipt of your written complaint with our final response. In relation to the UK, you will not be able to refer a complaint to the Financial Ombudsman Service",
  tc_t172: "22. General",
  tc_t173:
    "22.1 You must comply with all applicable laws, regulations, licensing requirements and third party rights (including, without limitation, data privacy laws) in your use of the Passve Services.",
  tc_t174:
    '22.2 We grant you a limited, non-exclusive, non-transferable licence, subject to the terms of this Agreement, to access and use the Site, and related content, materials, information (collectively, the "Content") solely for approved purposes as permitted by us from time to time. Any other use of the Site or Content is expressly prohibited and all other right, title, and interest in the Site or Content is exclusively the property of Passve and its licensors. You agree not to copy, transmit, distribute, sell, license, reverse engineer, modify, publish, or participate in the transfer or sale of, create derivative works from, or in any other way exploit any of the Content, in whole or in part',
  tc_t175:
    '22.3 " Passve", passve.com and all logos related to the Passve Services or displayed on the Site are trademarks or registered marks of Passve or its licensors. You may not copy, imitate or use them without our prior written consent or any third party\'s copyright, trade secret, patent or other intellectual property rights, or rights of publicity or privacy',
  tc_t176:
    "22.4 Your use of the Passve Services and the Site is subject to international export controls and economic sanctions requirements. By sending, buying, selling, Cryptocurrencies through the Site or Passve Services, you agree that you will comply with those requirements. You are not permitted to acquire Cryptocurrency or use any of the Passve Services through the Site if:",
  tc_t177:
    "22.4.1 you are in, under the control of, or a national or resident of Cuba, Iran, North Korea, Sudan, or Syria or any other country subject to United States embargo, UN sanctions, the European Union or HM Treasury's financial sanctions regimes (each a \"Sanctioned Country\"), or if you are a person on the U.S. Treasury Department's Specially Designated Nationals List or the U.S. Commerce Department's Denied Persons List, Unverified List, Entity List, or the EU or HM Treasury's financial sanctions regime (a \"Sanctioned Person\"); or",
  tc_t178:
    "22.4.2 you intend to supply any acquired or stored Cryptocurrency or Passve Services to a Sanctioned Country (or a national or resident of a Sanctioned Country) or Sanctioned Person.",
  tc_t179:
    "22.5 We will notify you of any change to this Agreement on your first use of the Passve Services after any amendment. You will be deemed to have accepted the change if you continue to use the Passve Services. If you do not accept the change, you should let us know, and the Agreement will terminate.",
  tc_t180:
    "22.6 We may make all other amendments to the Agreement by posting the revised Agreement on the Site or by emailing it to you, indicating when the revised Agreement becomes effective. Although we will endeavour to provide you with advance notice where possible, where lawful we may indicate that the revised Agreement shall be effective immediately and if you do not agree with any such modification, you should close your Passve Account and cease using the Passve Services",
  tc_t181:
    "22.7 Copies of the most up-to-date version of the Agreement will be made available in the Site at all times and will be provided to you by email on your request.",
  tc_t182:
    "22.8 Nothing in this Agreement shall be deemed or is intended to be deemed, nor shall it cause, either you or Passve to be treated as partners or joint ventures, or either you or Passve to be treated as the agent of the other.",
  tc_t183:
    "22.9 If you receive information about another user through the Passve Services, you must keep the information confidential and only use it in connection with the Passve Services. You may not disclose or distribute a user’s information to a third party or use the information except as reasonably necessary to carry out a transaction and other functions reasonably incidental thereto such as support, reconciliation and accounting unless you receive the user’s express consent to do so. You may not send unsolicited communications to another user through Passve.",
  tc_t184:
    "22.10 You are responsible for keeping your email address and telephone number up to date in your Passve Account profile in order to receive any notices or alerts that we may send you (including notices or alerts of actual or suspected Security Breaches).",
  tc_t185:
    "22.11 This Agreement (including documents incorporated by reference herein) comprise the entire understanding and agreement between you and Passve as to the subject matter hereof, and it supersedes any and all prior discussions, agreements and understandings of any kind (including without limitation any prior versions of this Agreement), and every nature between and among you and Passve.",
  tc_t186:
    "22.12 Section headings in this Agreement are for convenience only and shall not govern the meaning or interpretation of any provision of this Agreement.",
  tc_t187:
    "22.13 This Agreement is personal to you and you cannot transfer or assign your rights, licenses, interests and/or obligations to anyone else. We may transfer or assign our rights licenses, interests and / or our obligations at any time to any persons, including but not limited as part of a merger, acquisition or other corporate reorganisation involving Passve, provided that this transfer or assignment does not materially impact the quality of the Passve Services you receive. Subject to the foregoing, this Agreement will bind and inure to the benefit of the parties, their successors and permitted assigns.",
  tc_t188:
    "22.14 If any provision of this Agreement is determined to be invalid or unenforceable under any applicable law, this will not affect the validity of any other provision. If any provision is found unenforceable, the unenforceable provision will be severed, and the remaining provisions will be enforced",
  tc_t189:
    "22.15 We may not always strictly enforce our rights under this Agreement. If we do choose not to enforce our rights at any time, this is a temporary measure and we may enforce our rights strictly again at any time",
  tc_t190:
    "22.16 This Agreement and any information or notifications that you or we are to provide should be in English. Any translation of this Agreement or other documents is provided for your convenience only and may not accurately represent the information in the original English. In the event of any inconsistency, the English language version of this Agreement or other documents shall prevail.",
  tc_t191:
    "22.17 In the event that Passve is acquired by or merged with a third party entity, we reserve the right, in any of these circumstances, to transfer or assign the information we have collected from you and our relationship with you (including this Agreement) as part of such merger, acquisition, sale, or other change of control.",
  tc_t192:
    "22.18 Unless otherwise stated, nobody else has any rights under this Agreement. This Agreement is between you and us. No other person shall have any rights to enforce any of its terms including under the Contracts (Rights of Third Parties) Act 1999.",
  tc_t193:
    "22.19 All provisions of this Agreement which by their nature extend beyond the expiration or termination of this Agreement, including, without limitation, the Sections relating to suspension or termination, Passve Account cancellation, debts owed to Passve, general use of the Site, disputes with Passve, and general provisions will continue to be binding and operate after the termination or expiration of this Agreement.",
  tc_t194:
    "22.20 This Agreement will be governed by, and construed in accordance with, the laws of England and Wales and, subject to any overriding legal requirements, the parties irrevocably submit to the exclusive jurisdiction of the English and Welsh Courts. This Agreement and any information or notifications that you or we are to provide should be in English",

  // legal cookie policy
  cp_t1: "Last updated: Jan 1st, 2023",
  cp_t2:
    "A cookie is a small piece of data (text file) that a website – when visited by a user – asks your browser to store on your device in order to remember information about you, such as your language preference or login information. Those cookies are set by us and called first-party cookies. We also use third-party cookies – which are cookies from a domain different than the domain of the website you are visiting – for our advertising and marketing efforts. More specifically, we use cookies and other tracking technologies for the following purposes:",
  cp_t3: "Strictly Necessary Cookies",
  cp_t4:
    "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.",
  cp_t5: "Functional Cookies",
  cp_t6:
    "These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.",
  cp_t7: "Performance Cookies",
  cp_t8:
    "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance",
  cp_t9: "Targeting Cookies",
  cp_t10:
    "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising",
  cp_t11: "What are cookies?",
  cp_t12: "Our website, like many others, uses cookies in order to work properly.",
  cp_t13:
    "Cookies are small text files that are downloaded onto your device when you access our website. They allow us to recognize your device and store some information about your preferences or past actions on our website, ultimately allowing us to improve your experience. This cookie policy describes the kinds of information that we gather, how we use the information, and why we need to store these cookies. We will also explain how you can prevent these cookies from being stored; however, this may downgrade or break certain elements of the site's functionality",
  cp_t14: "For more general information on cookies, see this Wikipedia article.",
  cp_t15: "How we use cookies",
  cp_t16:
    "We use cookies to understand how users interact with our services and thus how we can improve them. Details of this usage are described below. Unfortunately, in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to the site. It is recommended that you accept our usage of cookies if you are not sure whether you need them or not, in case they are neccessary for a service that you use",
  cp_t17: "The types of cookies we use",
  cp_t18:
    "If you create an account at our site, then we will use cookies for the management of the sign-up process and general administration. These cookies will usually be deleted when you log out; however, in some cases they may remain afterwards to remember your site preferences when logged out",
  cp_t19:
    "We use cookies when you are logged in, so that we can remember this fact. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in.",
  cp_t20:
    "When you submit data through a form such as a contact page or comment box, cookies may be set to remember your user details for future correspondence.In order to provide you with a great experience on this site, we provide the functionality to set your preferences for how this site runs when you use it.",
  cp_t21:
    "In order to remember your preferences, we need to set cookies so that this information can be called whenever you interact with a page that is affected by your preferences. Some of the cookies we use are persistent, which means that they remain stored on your device for one year",
  cp_t22:
    "As we sell services, it is important for us to understand statistics on how many of the visitors to our site actually make a purchase, and thus cookies will track this kind of data. This is important to you as it means that we can accurately make business predictions that allow us to monitor our advertising and product costs to ensure the best possible price.",
  cp_t23:
    "The behavioural advertising cookies used by this site are designed to ensure that we provide you with the most relevant adverts where possible by anonymously tracking your interests and presenting similar things that you may like.",
  cp_t24:
    "In some cases, we may provide you with custom content based on what you tell us about yourself, either directly or indirectly if you link a social media account. These types of cookies simply allow us to provide you with content that we feel may be of interest to you",
  cp_t25: "Use of third-party cookies",
  cp_t26:
    "In some special cases we also use cookies provided by trusted third parties. The following section specifies which third-party cookies you might encounter at this site and reasons why we use them.",
  cp_t27:
    "Third-party analytics are used to track and measure usage of this site so that we can continue to produce engaging content. These cookies may track things such as how long you spend on the site or pages you visit, which helps us to understand how we can improve the site for you. From time to time we test new features and make subtle changes to the way the site is delivered. While we are still testing new features, these cookies may be used to ensure that you receive a consistent experience on our site. That way, we will be able to understand which optimizations our users appreciate most.",
  cp_t28:
    "Several partners advertise on our behalf and affiliate-tracking cookies simply allow us to see if our customers have come to the site through one of our partner sites. This lets us credit them appropriately, and also allows you to enjoy any bonuses they might be offering for use of their service",
  cp_t29: "How to disable cookies",
  cp_t30:
    "A lot of web browsers allow control of cookies through the settings. You can prevent the setting of cookies by adjusting the browser settings (see your browser's Help section for the instructions). Find out how to manage cookies on popular browsers:",
  cp_t31:
    "Be aware that disabling cookies will affect the functionality of our website. Disabling cookies will usually affect the functionality and certain features of sites.",
  cp_t32: "More information",
  cp_t33:
    "We hope this article has clarified things for you. As previously mentioned, it's best to leave cookies enabled just in case they are needed while you use our site. However, if you'd still like more information, feel free to contact us",

  // legal kyc
  kyc_t1: "Last updated: Dec 4th, 2022",
  kyc_t2:
    "Passve Anti-Money Laundering and Know Your Customer Policy (hereinafter - the “AML/KYC Policy”) is designated to prevent and mitigate possible risks of Passve being involved in any kind of illegal activity.",
  kyc_t3:
    'Both international and local regulations require Passve to implement effective internal procedures and mechanisms to prevent money laundering, terrorist financing, drug and human trafficking, proliferation of weapons of mass destruction, corruption and bribery and to take action in case of any form of suspicious activity from its Users. For Law Enforcement requests please direct your official document to our compliance team <a href="mailto:support@passve.com"> support@passve.com</a>',
  kyc_t4: "AML/KYC Policy covers the following matters:",
  kyc_t5: "Verification procedures",
  kyc_t6: "Compliance Officer",
  kyc_t7: "Monitoring transactions",
  kyc_t8: "Risk assessment",
  kyc_t9: "1. Verification procedures",
  kyc_t10:
    "One of the international standards for preventing illegal activity is customer due diligence (“CDD”). According to CDD, Passve establishes its own verification procedures within the standards of anti-money laundering and “Know Your Customer” frameworks, including enhanced due diligence for customers presenting a higher risk, such as Politically Exposed Persons (PEPs).",
  kyc_t11:
    "Passve identity verification procedure requires the User to provide Passve with reliable, independent source documents, data or information (e.g., national ID, international passport, bank statement, utility bill). For such purposes Passve reserves the right to collect User’s identification information for the AML/KYC Policy purposes.",
  kyc_t12:
    "Passve will take steps to confirm the authenticity of documents and information provided by the Users. All legal methods for double-checking identification information will be used and Passve reserves the right to investigate certain Users who have been determined to be risky or suspicious.",
  kyc_t13:
    "Passve reserves the right to verify User’s identity in an on-going basis, especially when their identification information has been changed or their activity seemed to be suspicious (unusual for the particular User). In addition, Passve reserves the right to request up-to-date documents from the Users, even though they have passed identity verification in the past.",
  kyc_t14:
    "User’s identification information will be collected, stored, shared and protected strictly in accordance with the Passve privacy policy and related regulations.",
  kyc_t15:
    "Once the User’s identity has been verified, Passve is able to remove itself from potential legal liability in a situation where its Services are used to conduct illegal activity.",
  kyc_t16:
    "Passve is prohibited from transacting with individuals, companies and countries that are on prescribed sanctions lists. Passve will therefore screen against United Nations, European Union, UK Treasury and US Office of Foreign Assets Control (OFAC) sanctions lists in all jurisdictions in which we operate.",
  kyc_t17: "2. Compliance Officer",
  kyc_t18:
    "The Compliance Officer is the person, duly authorized by Passve, whose duty is to ensure the effective implementation and enforcement of the AML/KYC Policy. It is the Compliance Officer’s responsibility to supervise all aspects of Passve anti-money laundering and counter-terrorist financing, including but not limited to:",
  kyc_t19: "Collecting Users’ identification information;",
  kyc_t20:
    "Establishing and updating internal policies and procedures for the completion, review, submission and retention of all reports and records required under the applicable laws and regulations;",
  kyc_t21:
    "Monitoring transactions and investigating any significant deviations from normal activity;",
  kyc_t22:
    "Implementing a records management system for appropriate storage and retrieval of documents, files, forms and logs;",
  kyc_t23: "Updating risk assessment regularly;",
  kyc_t24:
    "Providing law enforcement with information as required under the applicable laws and regulations.",
  kyc_t25:
    "The Compliance Officer is entitled to interact with law enforcement, which are involved in prevention of money laundering, terrorist financing and other illegal activity.",
  kyc_t26: "3. Monitoring transactions",
  kyc_t27:
    "The Users are known not only by verifying their identity (who they are) but, more importantly, by analysing their transactional patterns (what they do). Therefore, Passve relies on data analysis as a risk-assessment and suspicion detection tool. Passve performs a variety of compliance-related tasks, including capturing data, filtering, record-keeping, investigation management, and reporting. System functionalities include:",
  kyc_t28:
    "Daily check of Users against recognized “black lists” (e.g. OFAC), aggregating transfers by multiple data points, placing Users on watch and service denial lists, opening cases for investigation where needed, sending internal communications and filling out statutory reports, if applicable;",
  kyc_t29: "Case and document management.",
  kyc_t30:
    "With regard to the AML/KYC Policy, Passve will monitor all transactions and it reserves the right to:",
  kyc_t31:
    "Ensure that transactions of suspicious nature are reported to the proper law enforcement through the Compliance Officer;",
  kyc_t32:
    "Request the User to provide any additional information and documents in case of suspicious transactions;",
  kyc_t33:
    "Suspend or terminate User’s Account when Passve has reasonably suspicion that such User engaged in illegal activity.",
  kyc_t34:
    "The above list is not exhaustive and the Compliance Officer will monitor Users’ transactions on a day-to-day basis in order to define whether such transactions are to be reported and treated as suspicious or are to be treated as bona fide.",
  kyc_t35: "4. Risk assessment",
  kyc_t36:
    "Passve, in line with the international requirements, has adopted a risk-based approach to combating money laundering and terrorist financing. By adopting a risk-based approach, Passve is able to ensure that measures to prevent or mitigate money laundering and terrorist financing are commensurate to the identified risks. This will allow resources to be allocated in the most efficient ways. The principle is that resources should be directed in accordance with priorities so that the greatest risks receive the highest attention",

  // privacy policy
  pp_t1: "Last updated: Sep 7th, 2022",
  pp_t2: "Introduction",
  pp_t3:
    "Your privacy and keeping your personal information secure are extremely important to us. At Passve PLC, we are committed to protecting and respecting Your privacy and handling Your information in an open and transparent manner.",
  pp_t4:
    'This Privacy Policy ("Policy") explains among other things: what we do with your personal information; what we do to keep it secure; with whom we share your information; your rights in relation to the personal information we hold about you; and who you can contact for more information.',
  pp_t5:
    "We will amend or update this Policy regularly to reflect changes in our practices with respect to the processing of personal information, or changes in applicable law. We encourage you to read this Policy carefully, and to regularly check this page to review any changes we might make to the terms of this Policy.",
  pp_t6:
    "If you don't want to read all the details. Here are the highlights that you may really wish to know:",
  pp_t7:
    "Passve PLC is an international payment service that provides international money transfer and remittance services in more than 150 countries and in over 70 currencies.",
  pp_t8: 'You will know us by our brands: "Passve"',
  pp_t9:
    "Where appropriate, we share your personal information within the Passve Group, affiliates, partners and other companies. Upon request or where we are legally obliged, we may share your information with public agencies.",
  pp_t10:
    "We also collect from You information in relation to other people (for example, details of the recipients of Your money transfers). In providing this information you confirm to us that you have their permission to do so.",
  pp_t11:
    "We use some third parties to process your personal information on our behalf to perform our services for you, and due to the international nature of our business some of them are based outside the United Kingdom and the European Economic Area.",
  pp_t12:
    "You have several rights over your personal information, and the manner in which you can exercise those rights are contained in this Policy.",
  pp_t13:
    "We will send you direct Marketing if we are allowed to do so. We do this to encourage you to use our services and send you offers or promotions that may interest you. You can ask us to stop sending you marketing information at any time. Details of how to unsubscribe will be included on each email that we send you. Alternatively, send us an email to dataprivacy@passve.com with “URGENT - UNSUBSCRIBE REQUEST” in the subject line and the email address that you wish to be removed within the email.",
  pp_t14:
    "We also use your personal information to display online adverts and Marketing relating to our services on our website, online media channels or by sending you push notifications if you use our mobile app.",
  pp_t15:
    "We do not intentionally use special category information and children’s information (our website and mobile application are not intended for children).",
  pp_t16: "Who are we?",
  pp_t17:
    'This website is operated and provided by Passve PLC ("Passve", "we", "us", "our") London / Dubai.',
  pp_t18:
    'If you reside in a country where we provide our services to you and are a user of our website passve.com (“our website”, “our site”) and/or of our mobile applications (the “Mobile App”), we are a data controller for the purpose of Data Protection Legislation (as defined below). In this Policy, references to "You" and "Your" are references to a user of our website and/or of our Mobile Apps.',
  pp_t19: "Definitions",
  pp_t20:
    "To help clarify our meaning, we have used certain capitalised terms in this Policy. These terms and their meaning are:",
  pp_t21:
    "“Data Protection Legislation” means the United Kingdom General Data Protection Regulations as tailored by the Data Protection Act 2018 and all other applicable legislation relating to privacy or data protection.",
  pp_t22:
    "“Marketing” means any action or advertisement or promotion or marketing material like surveys, vouchers, research and events. This list is not exhaustive",
  pp_t23: "The personal information we collect about you",
  pp_t24:
    "We may collect, record and use information about You in physical and electronic form and will hold, use and otherwise process this data in accordance with the Data Protection Legislation and as set out in this Policy.",
  pp_t25: "The personal information we collect and use may include, amongst other things:",
  pp_t26: "Your name;",
  pp_t27:
    "Your contact information such as Your email address, postal address and telephone number or any telephone number used to call us;",
  pp_t28: "Your demographic information such as age, education, gender, and interests;",
  pp_t29: "evidence of Your identity (for example passport information);",
  pp_t30: "the reason for your transfer request;",
  pp_t31: "unique identifiers such as Your username, account number and password;",
  pp_t32:
    "Your profiles and postings on any other social media applications and services that we provide or that You make available to us;",
  pp_t33:
    "Your payment details and other financial data (for example, Your bank or payment method provider’s name and Your account number and sort code); and",
  pp_t34:
    "information about Your visit to our website, including the full Uniform Resource Locators (URL), clickstream to, through and from our website (including date and time), length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs) and methods used to browse away from the page.",
  pp_t35:
    "We also collect from You information in relation to other people (for example, details of the recipients of Your money transfers), where You provide us with such information. In providing this information you confirm to us that you have obtained all necessary permissions from them to the reasonable use of their information for the purposes and in the manner described in this Policy, or are otherwise permitted to give us this information on their behalf. Please also ensure that those other people are aware of this notice and that the provisions of this notice are clearly communicated to them.",
  pp_t36: "How we collect your personal information",
  pp_t37:
    "Information You give to us when You use our services. If You use our services You will need to provide certain information including Your name, address and card number, as well as the name and other details of the recipients of the money transfers that you instruct us to carry out. This information must be complete and accurate, and if not we may have to take additional steps to complete the transaction.",
  pp_t38:
    "Other information You give to us. This is information about You that You give us by filling in forms on our website (for example, the ‘contact us’ section) or when you communicate with us by phone, e-mail or otherwise. It includes, for example, information You provide when You register on the website for us to contact You, when You inquire about any of our Services, when You sign up to receive notifications, and when You report a problem with our website. The information You give us may include, among other data, Your name and email address. We may also record our telephone conversation with you for training and monitoring purposes.",
  pp_t39:
    "Social Media. Depending on Your settings or the privacy policies for social media and messaging services such as Facebook, LinkedIn and Instagram, You might give us permission to access information from those accounts or services.",
  pp_t40:
    "Other Sources. We may receive information about You from other sources, including publicly available databases and combine this data with information we already have about You. This helps us to update, expand and analyse our records and provide services that may be of interest to You.",
  pp_t41: "Information we collect about You:",
  pp_t42:
    'Like most websites, we use "cookies" to help us make our site – and the way You use it – better. Cookies mean that a website will remember You. They are small text files that websites transfer to Your computer (or phone / tablet). They improve website use and speed – for example by automatically filling Your name and address in text fields. Please see our cookies policy for further information.',
  pp_t43:
    "Log Files. In addition, with regard to each of Your visits to our site, we will automatically collect certain information (for example, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data). We may combine this automatically- collected log information with other information we collect about You.",
  pp_t44:
    "Social Media Widgets. Our website includes Social Media Widgets or Features, such as the Facebook Like button and Twitter button which are interactive mini-programs that run on our site to provide specific services from another company (e.g. displaying the news, opinions, music, etc). Personal information, such as Your email address, may be collected through the Widget. Cookies may also be set by the Widget to enable it to function properly. Information collected by this Widget is governed by the privacy policy of the company that created it.",
  pp_t45:
    "Mobile App. When You download our Mobile App, in addition to the information mentioned above we:",
  pp_t46:
    "automatically collect information on the type of device You use, operating system version, and system and performance information. We may send You push notifications from time-to-time in order to update You about events or promotions that we may be running. If You no longer wish to receive these types of communications, You may turn them off at the device level. To ensure You receive proper notifications, we will need to collect certain information about Your device such as operating system and user identification information; and",
  pp_t47:
    "may use mobile analytics software to allow us to better understand the functionality of our Mobile Software on Your device. This software may record information such as how often You use the Mobile App, the events that occur within it, aggregated usage, performance data, and where the Mobile App was downloaded from. We do not link the information we store within the analytics software to any personal information You submit within the Mobile App",
  pp_t48: "How we use your personal information",
  pp_t49:
    "Our primary purpose in collecting user information is to provide You with a safe, smooth, efficient, and customised experience and to provide the services You have requested from us. We may also use the information that You have provided to ensure that the content on our site is presented in the most effective manner for You and Your computer or device.",
  pp_t50: "We use Your information for the following specific purposes:",
  pp_t51:
    "to carry out our obligations arising from any contracts entered into between You and us or from applicable law such as anti-money laundering laws, and to provide You with the Services in accordance with our terms of use and with this Policy;",
  pp_t52: "to register You with a digital account;",
  pp_t53: "to fulfil Your Transaction Request;",
  pp_t54: "to send You confirmations or other notifications;",
  pp_t55:
    "to notify You about temporary or permanent changes to our services or other service-related messages;",
  pp_t56: "to assist you where online applications are not completed",
  pp_t57: "to prevent fraud, money laundering, and any other illegal activity;",
  pp_t58:
    "to ensure that content from our site is presented in the most effective manner for You and for Your computer;",
  pp_t59: "to promote our business;",
  pp_t60:
    "to send You Marketing communications, where You have shown interest; or purchased Our services and in the course of doing so You provided your contact details and You have not opted out from receiving marketing communications.",
  pp_t61:
    "to administer our site and for internal operations, including troubleshooting, data analysis, profiling and segmentation analysis, testing, research, statistical and survey purposes;",
  pp_t62: "as part of our efforts to keep our site, Mobile App and our services safe and secure",
  pp_t63:
    "to measure or understand the effectiveness of advertising we serve to You and others, and to deliver relevant advertising to You;",
  pp_t64:
    "to make suggestions and recommendations to You and other users of our site about services that may interest You or them",
  pp_t65:
    "to train, monitor and assess the effectiveness of the manner in which we provide the Services to You",
  pp_t66:
    "to consider and investigate any concerns or complaints you may have; and to display personal testimonials of satisfied customers on our website in addition to other endorsements, where You have agreed with a third-party review site that we may do so. If You wish to update or delete Your testimonial, contact us at dataprivacy@passve.com",
  pp_t67: "Automated decision-making",
  pp_t68:
    "You will not be subject to decisions that will have a significant impact on you based solely on automated decision-making, except if: (i) the automated decision-making is required or authorised by law; (ii) is necessary for entering into or performing the contract; or (iii) is based on your individual explicit written consent.",
  pp_t69: "Our Legal basis for processing your personal information",
  pp_t70:
    "We only use your personal information for a specific purpose; and before we do so we must have a legal reason. For example, when it is necessary for us to perform a contract with you for our services, for our legitimate interest, or where you have given us your consent, or to fulfil our legal obligations. These are all known as the “legal basis for processing”.",
  pp_t71: "Processing activity",
  pp_t72:
    "Processing is necessary for the performance of a contract, or to take steps prior to entering into a contract with us.",
  pp_t73:
    "Deliver our products and provide you with our services such as conducting money transfers, payment services, and mobile phone top-ups. This may include “know your client” checks and verifying your identity and confirming your financial details.",
  pp_t74:
    "Notifying you with any changes to our Services, Apps or our site or changes to our terms or Policy.",
  pp_t75: "Enable third parties to perform technical, logistical or other functions for us.",
  pp_t76: "Respond and resolve any complaints you may have.",
  pp_t77:
    "Processing is necessary for our legitimate interests (to the extent that such legitimate interests are not overridden by your interests).",
  pp_t78: "To fulfil our regulatory and compliance obligations;",
  pp_t79: "providing our Sites, our Apps, or our services to you;",
  pp_t80:
    "Establishing, exercising, protecting or defending our legal rights and business interests;",
  pp_t81:
    "Contacting you to facilitate or progress your use of our Services including any problems you may have in completing your transaction, subject always to compliance with applicable law;",
  pp_t82: "Managing and operating the financial and commercial affairs of our business;",
  pp_t83: "To promote and conduct our business;",
  pp_t84: "To personalise your experience of our Services;",
  pp_t85: "Conducting surveys, satisfaction reports and market research;",
  pp_t86:
    "To develop new services and products, and inform you of any new products and services that may interest you:",
  pp_t87:
    "When you register on our site and our App, we use the contact details that you provide on registration to send you Marketing on email, SMS and push notifications on our App EXCEPT YOU OPT OUT;",
  pp_t88:
    "To contact you with targeted Marketing about our Services on third-party online platforms or websites, including adverts we send or display to you on Google, Facebook etc;",
  pp_t89: "Analyse the effectiveness of our Marketing campaigns.",
  pp_t90: "Managing and maintaining our communications and IT systems;",
  pp_t91:
    "Detecting and protecting against: fraud; money laundering, criminal activity; illicit use of our Services; breaches of our policies and applicable laws;",
  pp_t92: "Recruitment activities and handling job applications;",
  pp_t93:
    "Security: physical security of our premises (including records of visits to our premises); CCTV recordings; and electronic security (including login records and access details).",
  pp_t94: "The processing is necessary for compliance with a legal or compliance obligation.",
  pp_t95:
    "Health and safety: health and safety assessments and record keeping; providing a safe and secure environment at our premises; and compliance with related legal obligations.",
  pp_t96: "Maintenance of records to comply with legal, audit, regulatory and tax requirements;",
  pp_t97:
    "To investigate fraud, money laundering, terrorist financing and any other illegal activity;",
  pp_t98:
    "To comply with legal and regulatory duties related to anti-money laundering terrorist financing; detection, prevention and prosecution of fraud and theft and preventing illicit use of our Services or any other illegal or wrongful activity;",
  pp_t99: "To comply with Data Protection legislation and any other applicable law;",
  pp_t100:
    "To comply with court orders, disclosures to law enforcement or tax agencies; requests from the Information Commissioner’s Office, the Financial Conduct Authority or other governmental or regulatory agency with supervisory authority over us.",
  pp_t101: "We have obtained your prior consent to the processing.",
  pp_t102:
    "With your consent, we may send Marketing and promotional communications by email, SMS and push notifications (depending on your choice of contact. For more information please see the Direct Marketing section of this policy.",
  pp_t103:
    "We may send targeted Marketing about Our services on third-party online services such as Google, Instagram and Facebook if we believe that you are likely to be interested in using our services based on your profile. Your consent to receive targeted adverts would have been obtained by these third parties on our behalf.",
  pp_t104:
    "Surveys: engaging with you for the purposes of obtaining your views on our Sites, our Apps, or our services",
  pp_t105: "Direct Marketing",
  pp_t106:
    "We may use the contact details You provided to send you marketing communications by email, telephone, direct mail or other communication formats about similar products or services where permitted by applicable law (unless you have opted out).",
  pp_t107:
    "In other cases, we may ask for Your consent to send You marketing communications about products or services offered by us.",
  pp_t108: "Opting Out of Direct Marketing",
  pp_t109:
    "If you have a Passve account, you can opt out of receiving Passve marketing communications by modifying your email or SMS subscriptions by clicking on the unsubscribe link or following the opt-out message included in the communication. Alternatively, simply send an email to dataprivacy@passve.com with “URGENT - UNSUBSCRIBE REQUEST” in the subject line and the email address that you wish to be removed within the email.",
  pp_t110:
    "Please note that if you request we stop sending you Marketing messages or you unsubscribe, we may continue to send you service and administrative communications such as transfer updates and other important information on your transaction.",
  pp_t111: "Who we might share your personal information with",
  pp_t112:
    "Your personal information is very important to us. However there are circumstances where it is necessary for us to share your information for legitimate business purposes (including operating our site and our Mobile App, and providing services to you), in accordance with applicable law and subject to applicable professional and regulatory requirements regarding confidentiality and professional secrecy. In addition, we may disclose your personal information to:",
  pp_t113: "for our internal business, operational, management and service needs;",
  pp_t114:
    "for compliance, regulatory and audit activity and in connection with legal, regulatory and tax claims and investigations;",
  pp_t115:
    "to understand and support our customers and other individuals who use our services, apps and visit our sites; and",
  pp_t116: "to send Marketing communications.",
  pp_t117:
    "Third party processors which include organisations or individuals with whom we do business or who provide services to us. We may disclose your personal information to respond to legal requirements (for example, as part of our “Know Your Customer” and due diligence obligations), enforce our policies, liaise with judicial or regulatory authorities where required under applicable law, understand the nature of the transaction You have made, provide our services, and to protect our rights and property. If we engage a third party processor to process your information, the Processor will be subject to binding contractual obligations to: only use the personal information in accordance with our prior written instructions; and use measures to protect the confidentiality and security of the personal information, together with any additional requirements. Processors may include:",
  pp_t118:
    "Credit reference agencies or other service providers to verify Your identity or the identity of recipients of the money transferred through our services or for any other purpose related to providing our services;",
  pp_t119: "Professional advisers, such as our auditors and lawyers",
  pp_t120:
    "Companies that capture or analyse information to help us understand how our services, sites and Mobile Apps are used and perform or to tailor our services and promotions, including for the purpose of allowing us to improve the services we provide;",
  pp_t121:
    "Companies that provide us with marketing assistance, including: the management of email marketing operations, SMS and other services that deploy marketing on the internet or social media platforms (such as Facebook and Google); the running of surveys and other feedback activity; as analysis of the effectiveness of any marketing or customer engagement we do;",
  pp_t122:
    "Banks, payment card processors and other service providers that process bank transfers, credit and debit card payments or otherwise provide financial infrastructure services to enable us to provide our services;",
  pp_t123:
    "Our service providers, including those who provide data hosting services, fraud prevention services; technology services, and technology tools that allow us to monitor, test and improve our services, sites and Mobile Apps;",
  pp_t124:
    "Companies that we have instructed to provide services to or for us for those purposes that may be reasonably ascertained from the circumstances in which the information was submitted;",
  pp_t125:
    "Other business entities should we plan to merge with, or be acquired by, or be invested in by that business entity, or if we undergo a corporate reorganisation; and",
  pp_t126: "Any successor in business to us.",
  pp_t127:
    "Fraud Prevention Agencies - Where we have a good reason to suspect fraud, money laundering, counter terrorist financing or any other illegal activity, we can upon request or where legally obliged share your personal information with crime prevention agencies and other third parties for the purpose of detecting, preventing or reporting crime or for actual or suspected breach of any applicable law or regulation. These third parties may include business partners and companies that provide services to us, law enforcement bodies, regulatory and supervisory authorities, providers of fraud prevention and detection services. Please note, we may suspend activity on your account or any transaction, refuse access to your account or cancel any transaction you may want to make, if we think there is a risk of any illegal activity.",
  pp_t128:
    "Public Agencies - We release accounts or other personal information when we consider it appropriate to comply with the law, to enforce our Terms and Conditions and any other agreement to protect the rights, property, or safety of Passve, our employees, customers, our business partners and the public. In these circumstances, we will only provide them with the information they require to perform their function.",
  pp_t129:
    "Google, We use Google Analytics, which uses cookies and similar technologies to collect and analyze information about use of the Sites and report on activities and trends. This service may also collect information regarding the use of other websites, apps, and online services. You can learn about Google’s practices by going to google.com/policies/privacy/partners, and opt out of them by downloading the Google Analytics opt-out browser add-on, available at tools.google.com/dlpage/gaoptout",
  pp_t130:
    "Research companies - We may share personal information in a secure way to allow research companies and feedback providers to contact you directly on our behalf to get your opinions on or refuse to take part in the research or surveys",
  pp_t131: "Links to Other Sites",
  pp_t132:
    "Our sites may contain links to other websites, including via our social media buttons. While we try to link only to websites that share our high standards and respect for privacy, we are not responsible for the content, security, or privacy practices employed by other websites and a link does not constitute an endorsement of that website. Once You link to another website from our site You are subject to the terms and conditions of that website, including, but not limited to, its internet privacy policy and practices. Please check these policies before You submit any data to these websites.",
  pp_t133: "International transfer of your personal information",
  pp_t134:
    "Due to the international nature of our business, we may transfer the personal information we collect to countries outside of the EEA which do not provide the same level of data protection as the country in which you reside and are not recognised by the European Commission as providing an adequate level of data protection. We only transfer personal information to these countries when it is necessary for the services we provide you, or it is necessary for the establishment, exercise or defence of legal claims or subject to safeguards that assure the protection of your personal information, such as:",
  pp_t135:
    "The European Commission approved standard contractual clauses in contracts for the transfer of personal information to third countries.",
  pp_t136:
    "Other EU approved mechanisms, as required by Data Protection Legislation. More information of these measures can be found at https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/rules-international-transfers-personal-data_e",
  pp_t137: "How we keep your personal information secure",
  pp_t138:
    "We have implemented appropriate technical and organisational security measures designed to protect your personal information against accidental or unlawful destruction, loss, alteration, unauthorised disclosure, unauthorised access, and other unlawful or unauthorised forms of Processing, in accordance with applicable law.",
  pp_t139:
    "Unfortunately, the transmission of information via the internet (including by email) is not completely secure. Although we will do our best to protect Your personal information, we cannot guarantee the security of Your data transmitted to our site; and any transmission is at Your own risk. Once we have received Your information, we will use strict procedures and security features to try to prevent unauthorised access.",
  pp_t140: "Protecting your own information",
  pp_t141:
    "It is important to keep your password secure to prevent fraudulent use of your account. Do not disclose your password to anyone else and especially anyone who requests it from you by telephone or email. Passve will never ask you to provide your password or personal information via email. Please be aware of the following:",
  pp_t142:
    "Change your password regularly and avoid using the same password on other websites to prevent hackers from access to your account;",
  pp_t143: "Avoid simple passwords that can easily be guessed;",
  pp_t144:
    "Be aware that public WiFi networks are risky and hackers may capture your online transactions and personal information. Only connect to a network you can trust;",
  pp_t145:
    "If you use a shared computer, make sure you log out immediately after you have finished using the website; and",
  pp_t146:
    "Beware of ‘phishing’ where criminals may attempt to steal your information by sending you bogus emails.",
  pp_t147: "How long will we keep your personal information for",
  pp_t148:
    "We take every reasonable step to ensure that your personal information is only retained for as long as it is required for the purposes set out in this Policy. Therefore, we will only keep the information we collect about You for as long as required for the purposes set out above, or as required to comply with any legal or regulatory obligations to which we are subject. This will involve us regularly reviewing our files to check that information is accurate, up-to-date and still required",
  pp_t149:
    "In addition, after you have ceased to be a Customer, we may take steps to delete your personal information or hold it in a form which no longer identifies you (for example, we may still use your data in an anonymised format for research and other business purposes). The period for which we will retain information about You will vary depending on the type of information and the purposes that we use it for. In general, unless applicable law requires a longer retention period, we will keep our records for as long as you are our Customer and for 6 years after the end of your relationship with us.",
  pp_t150:
    "For more details of the retention periods we apply to your personal information please contact dataprivacy@passve.com",
  pp_t151: "Your Rights",
  pp_t152:
    "Subject to applicable law, you may have a number of rights regarding our processing of your relevant personal information, including:",
  pp_t153:
    "the right not to provide your personal information to us (however, please note that we may be unable to provide you with the full benefit of our site, our Mobile App, or our services, if you do not provide us with your personal information);",
  pp_t154:
    "the right to request access to your personal information, together with information regarding the nature, Processing and disclosure of those personal information;",
  pp_t155:
    "the right to request for the correction of any inaccuracies in your personal information;",
  pp_t156:
    "the right to request for the erasure of your personal information. This is also known as the right to be forgotten. We will keep a note of your name if you ask for your personal information to be erased;",
  pp_t157: "the right that we restrict our use of your personal information;",
  pp_t158:
    "the right to object to our use of your personal information. Wherever we use legitimate interest as the ground for processing your personal information you can object to our use of it",
  pp_t159:
    "the right to have certain personal information transferred to another Controller, in a structured, commonly used and machine-readable format, to the extent applicable;",
  pp_t160:
    "where we use your personal information on the basis of your consent, you have the right to withdraw that consent (noting that such withdrawal does not affect the lawfulness of any processing performed prior to the date on which we receive notice of such withdrawal, and does not prevent the processing of your personal information in reliance upon any other available legal basis); and",
  pp_t161:
    "the right to lodge complaints with a Data Protection Authority regarding the use of your personal information by us or on our behalf.",
  pp_t162:
    "We may require proof of your identity before we can give effect to these rights. You should also be aware that some of these rights are qualified and not absolute; therefore exemptions or limitations may apply. For example, we can refuse to provide information if fulfilling your request would reveal the personal information about another person, or if you request that we delete information which we are required to retain by law, have compelling legitimate interests to keep, or need access to fulfil our legal obligations.",
  pp_t163:
    "The quickest and easiest way to make a data subject request is to contact us directly to exercise your rights by emailing dataprivacy@passve.com. We will endeavour to respond within a reasonable period and in any event within one month (three months for complex or numerous requests), in compliance with Data Protection Legislation.",
  pp_t164:
    "We reserve the right to charge a reasonable fee (reflecting the costs of providing the information) or to refuse to respond where requests are manifestly unfounded or excessive: in this case, we will explain the situation to You and we will inform You about Your rights.",
  pp_t165: "Identity Verification",
  pp_t166:
    "We will only process your data subject rights request where we are satisfied that we have successfully verified your identity, and you are the one asking for your personal data to be processed. We will not process personal data where we are in doubt of the identity of the requestor. You may be asked to prove your identity when making a data subject request.",
  pp_t167: "Requests made by third parties",
  pp_t168:
    "In line with our privacy practices and to protect your rights and freedoms, we are unable to process a data subject rights request made by a third party (such as Rightly) where; there is doubt that the third party has the appropriate authority to act on your behalf; and where we are not satisfied adequate evidence has been provided in support of identity verification. We would always aim to ensure that a third party has the appropriate consent or authority to act on your behalf, and most significantly that you are fully aware that the third party has made contact specifically with us, regarding exercising your data subject rights.",
  pp_t169:
    "Before we can process a data subject rights request made by a third party on your behalf, we require as a minimum from the third party:",
  pp_t170: "a copy of an identity document for you;",
  pp_t171: "evidence of your address; and",
  pp_t172:
    "a physically signed authority by you; or a verbal authority provided directly to us from you and noted on your account; and if provided electronically the authority is to come directly from your email address that matches our records.",
  pp_t173:
    "We will not access or process your personal data without being confident we are acting on your specific instructions.",
  pp_t174: "Cookies and similar technologies",
  pp_t175:
    "When you visit our site or use a Mobile App we may place Cookies onto your device, or read Cookies already on your device, subject always to obtaining your consent in accordance with applicable law. We use Cookies to record information about your device, your browser and, in some cases, your preferences and browsing habits. We may use your personal information through Cookies and similar technologies, in accordance with our Cookies policy.",
};

export const getEnglish = () => {
  return eng;
};
