import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBarsStaggered, FaGlobe } from "react-icons/fa6";
import Sidebar from "./Sidebar";
import { LINKS } from "../utils/links";
import logo from "../assets/logo.png";
import logo2 from "../assets/passveSupport.png";
import { IoChevronDown } from "react-icons/io5";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Header = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const activeLink = path => {
    if (location.pathname === path) return "text-primary font-semibold";
    return "text-main";
  };

  const getSelectedLng = lng => {
    if (localStorage.getItem("lng")) {
      if (localStorage.getItem("lng") === lng) return true;
    }
    return false;
  };

  useEffect(() => {
    setToggleSidebar(false);
    document.querySelector("body").style.overflowY = "scroll";
  }, [location.pathname]);

  return (
    <header className="max-w-[1367px] mx-auto px-4 xl:px-6 w-full flex gap-3 justify-between items-center py-3 md:py-4 relative z-20">
      <Sidebar
        links={LINKS}
        activeLink={activeLink}
        toggle={toggleSidebar}
        setToggle={setToggleSidebar}
        getSelectedLng={getSelectedLng}
        i18next={i18next}
      />
      <div className="flex gap-3 items-center justify-between">
        <FaBarsStaggered
          size={24}
          className="lg:hidden cursor-pointer"
          onClick={() => [
            setToggleSidebar(true),
            (document.querySelector("body").style.overflowY = "hidden"),
          ]}
        />
        <Link to="/">
          <img
            className="w-[140px] md:w-[200px]"
            src={location.pathname !== "/faqs" ? logo : logo2}
            alt="Passve Logo"
          />
        </Link>
      </div>

      <div className="flex items-center gap-3 max-lg:hidden">
        <div>
          {LINKS?.map((itm, idx) => (
            <Link
              key={idx}
              to={itm.to}
              className={`max-sm:hidden parent-link relative font-medium text-base 2xl:text-xl hover:text-primary p-4 hover:transition ${activeLink(itm.to)}`}
            >
              {!itm?.hasChildren ? (
                t(itm.title)
              ) : (
                <span className="inline-flex items-center gap-1">
                  <span
                    className={`hover:text-primary ${activeLink("/apy")} ${activeLink("/staking")}`}
                  >
                    {t(itm.title)}
                  </span>
                  <IoChevronDown />
                </span>
              )}
              {itm?.hasChildren && (
                <div className="child-link z-20 left-0 top-8 absolute bg-white shadow-md rounded-md p-3">
                  <Link
                    to="/apy"
                    className={`block px-2 py-1 hover:text-primary font-medium text-base 2xl:text-xl ${activeLink("/apy")}`}
                  >
                    {t("apy")}
                  </Link>
                  <Link
                    to="/staking"
                    className={`block px-2 py-1 hover:text-primary font-medium text-base 2xl:text-xl ${activeLink("/staking")}`}
                  >
                    {t("staking")}
                  </Link>
                </div>
              )}
            </Link>
          ))}
          <a
            target="_blank"
            href="https://passve.gitbook.io/docs"
            className="max-sm:hidden parent-link relative font-medium text-base 2xl:text-xl hover:text-primary p-4 hover:transition"
          >
            {t("docs")}
          </a>
        </div>
      </div>

      <div className="flex gap-4 max-sm:gap-2">
        <div className="max-sm:hidden flex items-center gap-1 text-sm">
          <FaGlobe
            fill="var(--primary)"
            size={16}
          />
          <select
            onChange={e => {
              i18next.changeLanguage(e.target.value);
              localStorage.setItem("lng", e.target.value);
            }}
          >
            <option
              value="en"
              selected={getSelectedLng("en")}
            >
              English
            </option>
            <option
              value="es"
              selected={getSelectedLng("es")}
            >
              Español
            </option>
          </select>
        </div>
        <div>
          {location.pathname === "/" ? (
            <Link
              to="/portal"
              className="rounded-[10px] bg-primary text-[#ffffff] text-sm md:text-[16px] text-center px-4 sm:px-6 py-2 md:py-3 font-[500]"
            >
              {t("open_portal")}
            </Link>
          ) : location.pathname === "/autonomous-funding" ? (
            <Link
              to="/dashboard"
              className="rounded-[10px] bg-primary text-[#ffffff] text-sm md:text-[16px] text-center px-4 sm:px-6 py-2 md:py-3 font-[500]"
            >
              {t("client_area")}
            </Link>
          ) : location.pathname === "/faqs" ? (
            <Link
              to="/submit-ticket"
              className="rounded-[10px] bg-primary text-[#ffffff] text-sm md:text-[16px] text-center px-4 sm:px-6 py-2 md:py-3 font-[500]"
            >
              {t("submit_ticket")}
            </Link>
          ) : (
            <Link
              to="#"
              className="rounded-[10px] bg-primary text-[#ffffff] text-sm md:text-[16px] text-center px-4 sm:px-6 py-2 md:py-3 font-[500]"
            >
              {t("connect_wallet")}
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
