import "./App.css";
import { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { REACT_APP_KEY_NAME } from "./utils/constants";
import Public from "./publicRoutes";
import Protected from "./protectedRoute";
import Admin from "./adminRoute";
import { ADMIN_ROUTES, AUTH_ROUTES, CLIENT_ROUTES, ROUTES } from "./utils/routes";
import { AuthProvider } from "./context/auth";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { getEnglish } from "./lang/en";
import { getEspanol } from "./lang/es";

const english = getEnglish();
const espanol = getEspanol();

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: english,
      },
      es: {
        translation: espanol,
      },
    },
    supportedLngs: ["en", "es"],
    lng: document.querySelector("html").lang,
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
  });

function App() {
  const location = useLocation();

  const verifyToken = () => {
    const token = Cookies.get(REACT_APP_KEY_NAME);
    if (token) {
      const decode_token = jwtDecode(token);
      if (
        decode_token.exp * 1000 < new Date().getTime() ||
        Cookies.get(REACT_APP_KEY_NAME) === undefined ||
        localStorage.getItem(REACT_APP_KEY_NAME) === "undefined"
      ) {
        Cookies.remove(REACT_APP_KEY_NAME);
        localStorage.removeItem(REACT_APP_KEY_NAME);
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    verifyToken();
    window.scrollTo(0, 0);

    if (localStorage.getItem("lng")) {
      i18next.changeLanguage(localStorage.getItem("lng"));
    }
  }, [location.pathname]);

  return (
    <>
      {ROUTES.map((route, index) => (
        <Route
          exact
          key={index}
          {...route}
        />
      ))}

      {AUTH_ROUTES.map((route, index) => (
        <Public
          exact
          key={index}
          {...route}
        />
      ))}

      <AuthProvider>
        {CLIENT_ROUTES.map((route, index) => (
          <Protected
            exact
            key={index}
            {...route}
          />
        ))}

        {ADMIN_ROUTES.map((route, index) => (
          <Admin
            exact
            key={index}
            {...route}
          />
        ))}
      </AuthProvider>

      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </>
  );
}

export default App;
