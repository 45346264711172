import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { REACT_APP_KEY_NAME, ROLES } from "../utils/constants";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import Api from "../api/api";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const isAdmin = () => {
    const token = Cookies.get(REACT_APP_KEY_NAME);
    const decode_token = token ? jwtDecode(token) : {};
    if (user && [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(decode_token?.role)) {
      return true;
    }
    return false;
  };

  const isLoggedIn = () => {
    return !!user;
  };

  const getMyProfile = async () => {
    try {
      const res = await Api.getMeProfile();
      if (res.status === 200) {
        setUser(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const value = useMemo(
    () => ({
      user,
      isAdmin,
      isLoggedIn,
      getMyProfile,
    }),
    [user],
  );

  useEffect(() => {
    if (Cookies.get(REACT_APP_KEY_NAME) !== undefined) {
      getMyProfile();
    }
  }, []);

  return <AuthContext.Provider value={{ ...value }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
