import { BsGraphUpArrow } from "react-icons/bs";
import { RiBitCoinLine, RiBroadcastLine } from "react-icons/ri";
import { AiOutlineHistory, AiOutlineQuestionCircle } from "react-icons/ai";
import { IoIosSettings, IoIosHome, IoIosCart, IoIosPaper } from "react-icons/io";
import { HiDownload } from "react-icons/hi";
import { FaUsers } from "react-icons/fa6";
import { PiListBullets } from "react-icons/pi";
import { GiPlatform } from "react-icons/gi";

export const REACT_APP_KEY_NAME = "passve";

export const LOADER_OVERRIDE = {
  display: "block",
  margin: "0 auto",
};

export const ROLES = {
  SUPER_ADMIN: 0,
  ADMIN: 1,
  USER: 2,
};

export const ROLES_MAP = {
  0: "Super Admin",
  1: "Admin",
  2: "User",
};

export const PAYMENT_METHOD = {
  STRIPE: "Card Payment",
  CRYPTO: "CRYPTO",
};

export const adminDashboardLinks = [
  {
    icon: IoIosHome,
    title: "Dashboard",
    to: "/admin/dashboard",
  },
  {
    icon: FaUsers,
    title: "Users",
    to: "/admin/dashboard/users",
  },
  {
    icon: IoIosCart,
    title: "Orders",
    to: "/admin/dashboard/orders",
  },
  {
    icon: GiPlatform,
    title: "Platforms",
    to: "/admin/dashboard/platforms",
  },
  {
    icon: PiListBullets,
    title: "Addons",
    to: "/admin/dashboard/addons",
  },
  {
    icon: RiBitCoinLine,
    title: "Payout",
    to: "/admin/dashboard/payouts",
  },
  {
    icon: IoIosPaper,
    title: "Tickets",
    to: "/admin/dashboard/tickets",
  },
  // {
  //   icon: IoIosSettings,
  //   title: "Settings",
  //   to: "/admin/dashboard/settings",
  // },
];

export const dashboardLinks = [
  // {
  //   icon: BsFillGrid1X2Fill,
  //   title: "Funding Dashboard",
  //   to: "/dashboard",
  // },
  // {
  //   icon: IoIosHome,
  //   title: "Account Overview",
  //   to: "/dashboard/account-overview",
  // },
  {
    icon: RiBitCoinLine,
    title: "Payout",
    to: "/dashboard/payout",
  },
  {
    icon: AiOutlineHistory,
    title: "Order History",
    to: "/dashboard/order-history",
  },
  {
    icon: HiDownload,
    title: "Downloads",
    to: "/dashboard/downloads",
  },
  {
    icon: BsGraphUpArrow,
    title: "Leaderboard",
    to: "/dashboard/leaderboard",
  },
  {
    icon: AiOutlineQuestionCircle,
    title: "FAQs",
    to: "/dashboard/faqs",
  },
  {
    icon: IoIosPaper,
    title: "Tickets",
    to: "/dashboard/tickets",
  },
  {
    icon: RiBroadcastLine,
    title: "Broadcast",
    to: "/dashboard/broadcast",
  },
  {
    icon: IoIosSettings,
    title: "Settings",
    to: "/dashboard/settings",
  },
];

export const program = ["Accelerated", "Evaluation"];
