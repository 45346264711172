export const LINKS = [
  {
    to: "/",
    title: "home",
  },
  {
    to: "#",
    title: "earn",
    hasChildren: true,
  },
  {
    to: "/autonomous-funding",
    title: "trade",
  },
  {
    to: "/market",
    title: "market",
  },
  {
    to: "/autonomous-funding",
    title: "autonomous_funding",
  },
];
